//  /*
//   * Feature Callout Block Component
//   * @template Lfi/Catalog/view/frontend/templates/product/view/features.phtml
//   * --------------
//   * div.features-callout-container
//   *  dl
//   *   dt
//   *   dd
//   *   hr
//  /*

.features-callout-container {
	@include body-light-text-link;
	background: map-get($lfi-palette, jet);
	color: map-get($lfi-palette, snow);
	height: 100%;
	padding: rem-calc(70) rem-calc(20);

	hr {
		display: none;
	}

	dt {
		@include theme-header-transformation();
		@extend .h5;
	}

	dl dd {
		margin-bottom: rem-calc(30);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(40);
		}
	}

	dd:last-of-type {
		margin-bottom: 0;
	}
}
