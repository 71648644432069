$slick-headerbackround-dot-color: $white;
$slick-headerbackround-dot-color-active: $white;
$slick-headerbackround-opacity-default: 1;
$slick-headerbackround-opacity-not-active: 0.7;
$slick-headerbackround-opacity-on-hover: 1;

$banner-widget-padding-small: percentage(divide(1, 0.88)) !default;
$banner-widget-padding-medium: percentage(divide(1, 1.59)) !default;
$banner-widget-padding-large: percentage(divide(1, 1.83)) !default;

$home-banner-widget-padding-small: percentage(divide(1, 0.75)) !default; //h x w
$home-banner-widget-padding-medium: percentage(divide(1, 1.28)) !default; //h x w
$home-banner-widget-padding-large: percentage(divide(1, 1.83)) !default; //h x w

$banner-foreground-transition: color 0.5ms ease !default;

.header-background {
	margin-bottom: 0 !important; // Removes Slick margin

	// Prevent content flash before slick is loaded
	&:not(.slick-initialized) {
		.header-banner {
			display: none;

			&:first-of-type {
				display: block;
			}
		}
	}

	.slick-slide {
		transform: translateZ(0);

		> div {
			line-height: 0; // Removes Slick-induced margin
		}
	}

	@extend .slick-arrow-styles;

	.slick-dots {
		position: absolute;

		//there's about 10px between the dot and the bottom of the containing elements
		bottom: rem-calc(15);

		@include breakpoint(large) {
			bottom: rem-calc(25);
		}

		button {
			background: transparent !important;

			&:before {
				font-size: 15px;
				text-shadow: 0 0 3px $dark-gray;
			}
		}

		&.slick-headerbackground-dots {
			button {
				&:hover,
				&:focus {
					background: transparent;

					&::before {
						opacity: $slick-headerbackround-opacity-on-hover;
					}
				}

				&::before {
					color: $slick-headerbackround-dot-color;
					opacity: $slick-headerbackround-opacity-not-active;
				}
			}

			.slick-active button::before {
				color: $slick-headerbackround-dot-color-active;
				opacity: $slick-headerbackround-opacity-default;
			}
		}
	}
}

.header-banner {
	h1,
	h2 {
		text-transform: uppercase;
	}

	p {
		@include bold-text();
		font-size: rem-calc(20);
	}

	.light-header-fg & {
		@include text-over-image-styles;

		button,
		.button {
			text-shadow: none;
		}
	}
}

.header-banner:not(.header-background-basic) {
	.banner-background {
		position: relative;
		background: {
			repeat: no-repeat;
			position: center center;
			size: cover;
		}

		//the div that makes centered content possible within the banner
		.banner-content-wrapper {
			@include xy-grid-container(100%);
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;
		}

		//the banner content. this is what makes the content centered
		.banner-content {
			text-align: center;

			.buttons {
				margin-top: rem-calc(24);

				.button + .button {
					margin-left: rem-calc(6);
				}
			}

			p + .buttons {
				margin-top: rem-calc(24);
			}
		}

		//the actual content within the banner
		h1,
		p {
			clear: both;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			transition: $banner-foreground-transition;

			.light-header-fg & {
				color: $body-inverted-font-color;
			}
		}

		p {
			font-size: rem-calc(24);
		}
	}
}

//the control over the h x w ratio of the banner image (and thus the banner itself)
.header-banner:not(.header-background-basic) .banner-background:before {
	content: "";
	display: block;
	padding-top: $banner-widget-padding-small;
	position: relative;
	width: 100%;
	z-index: -1;

	@include breakpoint(medium) {
		padding-top: $banner-widget-padding-medium;
	}

	@include breakpoint(large) {
		padding-top: $banner-widget-padding-large;
	}

	@at-root {
		body.cms-index-index & {
			padding-top: $home-banner-widget-padding-small;

			@include breakpoint(medium) {
				padding-top: $home-banner-widget-padding-medium;
			}

			@include breakpoint(large) {
				padding-top: $home-banner-widget-padding-large;
			}
		}
	}
}
