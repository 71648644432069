//
// Bootstrap Accordion
//

@mixin bootstrap-accordion() {
	margin-bottom: 0;
	// override to center the container
	margin-left: auto !important;
	margin-right: auto !important;

	.accordion-item {
		margin-bottom: rem-calc(16);
	}

	.accordion-button {
		align-items: unset;
		display: flex;
		font-size: rem-calc(20);
		line-height: rem-calc(26);
		padding: rem-calc(16 0);
		text-align: left;
		text-transform: none;

		@include breakpoint(large up) {
			font-size: rem-calc(24);
			line-height: rem-calc(28);
		}

		&:after {
			height: rem-calc(24);
			margin-left: 0;
			margin-right: rem-calc(12);
			order: -1;
			position: relative;
			top: rem-calc(4);
			width: rem-calc(24);
		}
	}

	.accordion-collapse {
		margin-left: rem-calc(26);

		a {
			text-decoration: underline;
		}

		// prevent jump during close animation
		:last-child {
			padding-bottom: rem-calc(24);
		}
	}
}
