/*
 * Go There Blocks Homepage Section
 * --------------------------------
 * section.go-there-blocks
 *  h5.subheader
 *  section.widget-banner-featured.feature-block-container
 *   div.go-there-block
 *    div.overlay
 *    div.go-there-block-content
 *     h2
 *     div.button-group.small
 *      a.button.hollow.white
 *      a.button.hollow.white
 *
 */

@include slick-block-slider(
	$container-selector: ".go-there-blocks",
	$list-selector: ".go-there-blocks .feature-block-container",
	$list-item-selector: ".go-there-block"
) {
	.go-there-block {
		flex: 0 0 rem-calc(315);

		@include breakpoint(medium) {
			flex: 0 0 rem-calc(318);
		}

		@include breakpoint(xlarge) {
			flex: 0 0 rem-calc(340);
		}
	}
}

.go-there-blocks {
	padding: rem-calc(50 0 30 0);
	max-width: map-get($breakpoints, xxlarge);
	margin: 0 auto;

	@include breakpoint(medium) {
		padding-top: rem-calc(70);
		padding-bottom: rem-calc(25);
	}

	.subheader {
		color: map-get($danner-palette, forest);
		line-height: rem-calc(22);
		margin-bottom: rem-calc(25);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(35);
		}
	}

	.widget-banner-featured {
		background: $white;

		&:not(.slick-initialized) {
			padding-bottom: rem-calc(20);
			.go-there-block:first-of-type {
				margin-left: rem-calc(15);
			}

			// Need an element for flex to add margin
			&:after {
				content: "";
				border: rem-calc(1) solid;
				visibility: hidden;
			}
		}

		// overrides
		.go-there-block {
			display: flex;
			min-height: rem-calc(420);

			@include breakpoint(medium) {
				min-height: rem-calc(424);
			}

			@include breakpoint(xlarge) {
				min-height: rem-calc(452);
			}
		}
	}

	.slick-track {
		margin-left: rem-calc(25);
	}
}

.go-there-block {
	background-repeat: no-repeat;
	background-size: cover;
	margin-right: rem-calc(10);

	// TODO - sizing and spacing can be cleaned up to be more reusable.
	h2 {
		color: $white;
		font-size: rem-calc(37); // No responsive header sizing here
		margin-bottom: rem-calc(20);
		line-height: rem-calc(36);
		white-space: normal;
		overflow-wrap: break-word;
	}
}

.go-there-block-content {
	padding: rem-calc(0 25 24 25);

	.button {
		font-size: rem-calc(13);
		padding: rem-calc(10 12 12 12);
	}
}
