@import "beerslider/dist/BeerSlider";

.beer-range:focus ~ .beer-handle {
	background-color: $white;
}
.beer-handle {
	background-color: $white;
	border-color: $white;
	border-style: solid;
	border-width: rem-calc(1);
	color: map_get($danner-palette, forest);
	height: rem-calc(20);
	margin-left: rem-calc(-2.5);
	width: rem-calc(20);

	@include breakpoint(large up) {
		height: rem-calc(44);
		margin-left: rem-calc(-3);
		width: rem-calc(44);
	}

	&:before,
	&:after {
		border-width: rem-calc(1);
		height: rem-calc(4);
		width: rem-calc(4);

		@include breakpoint(large up) {
			height: rem-calc(8);
			width: rem-calc(8);
		}
	}

	&:before {
		left: rem-calc(1);

		@include breakpoint(large up) {
			left: rem-calc(5);
		}
	}
	&:after {
		right: rem-calc(-3);
	}
}
.beer-reveal:after {
	background-color: $white;
	bottom: rem-calc(30);
	content: "";
	position: absolute;
	right: 0;
	top: rem-calc(30);
	width: rem-calc(4);

	@include breakpoint(large up) {
		width: rem-calc(6);
	}
}
