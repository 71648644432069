$menu-item-inner-border-color: rgba(0, 0, 0, 0.08); // stylelint-disable-line alpha-value-notation, color-function-notation
$menu-mobile-width: rem-calc(290);
$menu-open-close-transition-time: 300ms;

$menu-desktop-font-size: 16px !default;
$menu-desktop-heading-size: 20px !default;
$menu-mobile-heading-font-size: 23px !default;
$menu-mobile-parent-font-size: 18px !default;
$menu-mobile-subheading-font-size: 18px !default;
$menu-mobile-font-size: 16px !default;

body._has-nav {
	.page-wrapper {
		position: fixed;
		width: 100%;
	}
}

@include breakpoint(tablet) {
	body[class*="catalog-menu-active"] .page-wrapper {
		position: relative;

		@include breakpoint(tablet) {
			&:before {
				opacity: 0.5;
				z-index: 5;
			}
		}
	}

	.page-wrapper:before {
		background: black;
		content: "";
		height: 100vh;
		opacity: 0;
		position: absolute;
		top: $header-container-height;
		width: 100%;
		z-index: -1;
	}
}

.catalog-menu-container {
	align-items: center;
	display: flex;

	.js-off-canvas-overlay {
		background: rgba(0, 0, 0, 0.5); // stylelint-disable-line alpha-value-notation, color-function-notation
		transition-duration: $menu-open-close-transition-time;
	}

	// Cancelling out some more Foundation
	.drilldown .is-drilldown-submenu.is-closing {
		transform: translateX(0);
	}
}

.catalog-menu-wrapper {
	-webkit-overflow-scrolling: touch;
	transform: translateX(#{$menu-mobile-width});
	width: $menu-mobile-width;

	@include breakpoint(medium down) {
		background: map_get($lfi-palette, snow);
		position: fixed;
		transition-duration: $menu-open-close-transition-time;
		transition-property: transform;

		// Stop content flash
		&:not(.is-closed):not(.is-open):not(.off-canvas) {
			visibility: hidden;
		}
	}

	@include breakpoint(tablet) {
		transform: none;
		width: 100%;
	}

	.is-drilldown-submenu {
		transition-delay: 0.5s;
	}

	&:not(.is-closed) {
		@include breakpoint(medium down) {
			box-shadow: 0 rem-calc(10) rem-calc(20) 0 rgba(0, 0, 0, 0.5); // stylelint-disable-line alpha-value-notation, color-function-notation
		}
	}

	&.is-open {
		.is-drilldown-submenu {
			transition-delay: 0s;
		}
	}
}

.catalog-menu {
	height: 100%;

	> .is-drilldown {
		height: 100%;
	}

	li.bottom {
		background: map_get($lfi-palette, snow);

		a {
			justify-content: flex-start;
			padding: rem-calc(15 0);

			@include breakpoint(medium down) {
				border-bottom: rem-calc(1) solid $menu-item-inner-border-color;
			}
		}

		&:last-child > a {
			border-bottom: none;
		}
	}

	.drilldown {
		.is-drilldown-submenu-parent > a::after {
			@include svg-icon('../icons/icon-chevron-right.svg', 20px, 20px);
			border: none;
			margin: 0;
			position: unset;
		}
	}

	.is-drilldown-submenu a {
		padding: 0;
	}

	.my-account-link.bottom {
		margin-top: rem-calc(30);
		padding-top: rem-calc(13);

		@include breakpoint(tablet) {
			display: none;
		}
	}

	.icon-user {
		margin-right: rem-calc(8);

		&:before {
			@include svg-icon('../icons/icon-user.svg', 24px, 24px);
		}
	}

	a {
		align-items: center;
		background: inherit;
		color: $base-color;
		display: flex;
		font-size: rem-calc(16);
		justify-content: space-between;
		line-height: rem-calc(23);
		padding-left: 0;
		width: 100%;
	}
}

@include breakpoint(medium down) {
	.catalog-menu__inner-list--level1,
	.catalog-menu__list {
		> li:not(.catalog-menu-action) {
			padding-left: rem-calc(25);
			padding-right: rem-calc(25);
		}
	}
}

.catalog-menu__list {
	@include breakpoint(tablet) {
		display: flex;
		justify-content: center;

		// First level under top lists
		> li {
			align-items: stretch;
			cursor: pointer;
			display: flex;
			height: $header-container-height;
			justify-items: stretch;
		}
	}

	// A little hackery here
	@include breakpoint(medium down) {
		background: $white;

		> .secondary-link {
			font-weight: $font-weight__regular;
			margin-top: rem-calc(21);

			a {
				padding: rem-calc(7 0);
			}
		}

		> .secondary-link ~ .secondary-link {
			margin-top: 0;
		}
	}

	> li > a {
		color: $base-color;
		line-height: rem-calc(21);
		position: relative;

		@include breakpoint(tablet) {
			text-transform: uppercase;
		}

		@include breakpoint(large) {
			padding: rem-calc(0 15);

			&::before {
				left: rem-calc(15);
				right: rem-calc(15);
			}
		}
	}

	// Should be store switcher link
	> li:last-of-type {
		a {
			justify-content: flex-start;
		}

		img {
			margin-right: rem-calc(7);
			padding-top: rem-calc(1);
			width: rem-calc(20);
		}
	}
}

li.catalog-menu-action {
	padding: rem-calc(0 0 10 0);
	text-align: right;

	@include breakpoint(tablet) {
		display: none;
	}

	&.js-drilldown-back {
		text-align: left;
	}

	i,
	.action-close {
		padding: rem-calc(17 25);
	}

	i {
		cursor: pointer;
		padding-left: rem-calc(22);
	}

	.action-back {
		padding-left: rem-calc(25);
	}

	button:before {
		height: 20px;
		width: 20px;
	}
}

// Level 1
.catalog-menu__item {
	> .catalog-menu__inner-list {
		@include breakpoint(tablet) {
			background: $white;
			cursor: default;
			display: none;
			left: 0;
			max-height: rem-calc(365);
			position: absolute;
			top: $header-container-height;
			width: 100%;

			a {
				display: inline-block;
				width: auto;
			}
		}
	}

	&.mobile-hidden {
		display: none;

		@include breakpoint(tablet) {
			display: flex;
		}
	}

	&.mobile-only {
		font-size: $menu-mobile-font-size;

		@include breakpoint(tablet) {
			display: none;
		}
	}

	&.top {
		> a {
			font-size: $menu-mobile-parent-font-size;
		}
	}
}

.catalog-menu-returns-link a {
	&:before {
		@include svg-icon('../icons/icon-package-regular.svg', 24px, 24px);
		margin-right: rem-calc(8);
	}
}

.catalog-menu__item--parent {
	> a {
		font-size: $menu-mobile-parent-font-size;
		text-transform: uppercase;

		@include breakpoint(medium down) {
			border-bottom: rem-calc(1) solid $menu-item-inner-border-color;
			font-family: $header-font-family;
			line-height: 1;
			padding: rem-calc(20 0);
		}

		@include breakpoint(tablet) {
			font-size: $menu-desktop-font-size;
			position: relative;

			&:before {
				border-bottom: rem-calc(2) solid transparent;
				bottom: rem-calc(14);
				content: " ";
				left: rem-calc(10);
				position: absolute;
				right: rem-calc(10);
			}

			&:after {
				@include svg-icon('../icons/icon-navigation-down.svg', 24px, 24px);
				margin-left: -2px;
			}
		}
	}

	&.catalog-menu-active {
		@include breakpoint(tablet) {
			.catalog-menu__inner-list--level1 {
				display: flex;
				flex-flow: column wrap;
			}
		}
	}
}

// IE10+ (not edge)
// IE needs a set height in order for flex column to work
// this detriments a responsive menu height for IE which
// shouldn't be a problem unless we add a LOT more sub-menu nodes
@media screen and (min-width: #{map-get($breakpoints, medium)}), (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.catalog-menu__item--parent.catalog-menu-active .catalog-menu__inner-list--level1 {
		height: rem-calc(365);
	}
}

// Level 2
.catalog-menu__inner-item--parent-link {
	padding: rem-calc(23 0 18 0);

	@include breakpoint(tablet) {
		padding-top: 0;
	}

	a {
		font-family: $header-font-family;
		font-size: $menu-mobile-heading-font-size;
		letter-spacing: rem-calc(0.52);
		text-transform: uppercase;

		@include breakpoint(tablet) {
			//font-family: $header-font-bold;
			font-size: $menu-desktop-heading-size;
		}
	}
}

.catalog-menu__inner-item--level1 {
	&.catalog-menu__inner-item--parent {
		@include breakpoint(tablet) {
			flex: 1 1 auto;
		}
	}

	&.secondary-link a {
		font-size: $menu-mobile-font-size;
		padding: rem-calc(5 0);
	}

	&:not(.secondary-link) {
		padding-bottom: rem-calc(2.5);
		padding-top: rem-calc(2.5);

		> a {
			//font-family: $header-font-bold;
			font-size: $menu-mobile-subheading-font-size;
			line-height: rem-calc(29);

			@include breakpoint(tablet) {
				font-size: $menu-desktop-font-size;
				line-height: rem-calc(23);
			}
		}
	}
}

li.catalog-menu__inner-item--parent {
	@include breakpoint(medium down) {
		margin-top: rem-calc(23);
	}

	> a.catalog-menu__inner-link {
		padding-bottom: rem-calc(6);

		@include breakpoint(medium down) {
			border-top: rem-calc(1) solid $menu-item-inner-border-color;
			padding-top: rem-calc(20);
		}
	}
}

li.catalog-menu__inner-item--level2 {
	.catalog-menu__inner-link {
		padding-bottom: rem-calc(5);
		padding-top: rem-calc(5);
	}

	&.emphasized .catalog-menu__inner-link {
		font-weight: $font-weight__bold;
	}

	a {
		font-size: $menu-mobile-font-size;
	}
}

.catalog-menu__inner-list--level1 {
	@include breakpoint(tablet) {
		padding: rem-calc(50) 2vw;

		> li {
			border-left: rem-calc(1) solid $menu-item-inner-border-color;
			padding-left: rem-calc(20);
		}
	}

	@include breakpoint(large) {
		padding: rem-calc(50) 14vw;
	}

	@include breakpoint(xlarge) {
		padding: rem-calc(50) 18vw;
	}

	@include breakpoint(xxlarge) {
		padding: rem-calc(50) 22vw;
	}

	> li:last-of-type {
		margin-bottom: rem-calc(50);
	}
}
