.qs-slider-container {
	--qs-slider-slide-bg: #{$white};
	--qs-slider-content-color: #{$white};
	position: relative;
}

.qs-slider-background {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;

	.overlay {
		@include full-container-absolute-position(2);
		// stylelint-disable-next-line
    background: rgba(0, 0, 0, 0.2);
	}

	img {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
}

.qs-slider-wrapper {
	padding-bottom: 56px;
	padding-top: 80px;
	position: relative;
	z-index: 3;

	.newleaf-circle-button-wrapper {
		@include media-breakpoint-down(tablet) {
			display: none;
		}
	}
}

.qs-slider-content {
	color: var(--qs-slider-content-color);
	margin-left: 24px;
	max-width: 544px;
	padding-bottom: 88px;

	@include media-breakpoint-up(tablet) {
		padding-bottom: 204px;
	}

	.button {
		margin-bottom: 0;
	}

	p {
		line-height: 22px;
		margin-bottom: 8px;
		text-transform: uppercase;
	}

	h2 {
		font-family: $body-font-family;
		font-weight: $font-weight__semibold;
		line-height: 38px;
		margin-bottom: 24px;
	}
}

.qs-slider {
	position: relative;

	.newleaf-circle-button-wrapper {
		position: absolute;
		right: 0;
		top: -64px;
	}
}

.qs-slider-component {
	--newleaf-slide-snap-align: center;
	--newleaf-slider-slide-gap: 24px;
	--newleaf-slider-slide-size: 95%;
	--newleaf-slider-padding-horizontal: 24px;
	--hover-transition: all 300ms ease-in-out;

	@include media-breakpoint-up(md) {
		--newleaf-slider-slide-size: 45%;
	}

	@include media-breakpoint-up(lg) {
		--newleaf-slider-slide-size: 31%;
	}

	@include media-breakpoint-up(vlarge) {
		--newleaf-slider-slide-size: 27%;
	}

	.slide {
		background: var(--qs-slider-slide-bg);
		border-radius: 8px;
		/* stylelint-disable-next-line */
		box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
		margin: 24px 0;
		position: relative;
		transition: var(--hover-transition);
		z-index: 1;

		&:hover {
			img {
				transform: scale(105%);
			}
		}

		a {
			@include full-container-absolute-position(2);
		}
	}

	.slide-wrapper {
		align-items: center;
		display: grid;
		gap: 16px;
		grid-template-columns: 45% auto;
		height: 100%;
		min-height: 168px;
		padding: 16px;
	}

	.slide-image {
		border-radius: 8px;
		height: 100%;
		overflow: hidden;
		width: 100%;
	}

	p {
		line-height: 20px;
	}

	img {
		transition: var(--hover-transition);
	}
}
