.go-there-embodier-banner {
	.banner-background {
		position: relative;
		background: {
			position: center center;
			repeat: no-repeat;
			size: cover;
		}

		&:before {
			background-color: rgba(0, 0, 0, 0.2); // stylelint-disable-line color-function-notation, alpha-value-notation
			content: "";
			display: block;
			padding-top: percentage(divide(580, 375));
			width: 100%;

			@include breakpoint(medium) {
				padding-top: percentage(divide(560, 1024));
			}
		}
	}

	.banner-content-wrapper {
		@include xy-grid-container(100%);
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.banner-content {
		text-align: center;
		color: map-get($lfi-palette, sleet);
	}

	h4 {
		font-family: $body-secondary-font-bold;
		text-transform: none;
	}

	.go-there-play-icon {
		margin-top: rem-calc(30);
		margin-bottom: 0;
	}
}

.go-there-embodier {
	.intro {
		margin-bottom: rem-calc(30);
		font-family: $header-font-bold;
		font-size: rem-calc(18);
		line-height: rem-calc(28);
		text-transform: uppercase;
	}

	.embodier-read-more {
		position: relative;
		height: rem-calc(340);
		overflow: hidden;

		&.expanded {
			height: auto;
			overflow: visible;

			.embodier-expand {
				display: none;
			}
		}

		@include breakpoint(medium) {
			height: auto;
			overflow: visible;
		}

		.embodier-expand {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: rem-calc(100);
			background-color: $body-background;

			@include breakpoint(medium) {
				display: none;
			}

			&:before {
				content: "";
				display: block;
				position: absolute;
				bottom: 99%;
				width: 100%;
				height: rem-calc(80);
				background: linear-gradient(rgba(255, 255, 255, 0), $body-background);
			}

			h6 {
				margin: rem-calc(20 -20 20 0);
				text-align: center;

				.plus-symbol {
					position: absolute;
					display: inline-block;
					width: rem-calc(14);
					height: rem-calc(14);
					margin-top: rem-calc(2);
					margin-left: rem-calc(-20);

					&:before,
					&:after {
						content: "";
						position: absolute;
						display: block;
						background-color: map-get($danner-palette, forest);
					}

					&:before {
						height: 100%;
						width: rem-calc(2);
						margin-top: rem-calc(-7);
						top: 50%;
						left: rem-calc(6);
					}

					&:after {
						height: rem-calc(2);
						width: 100%;
						margin-left: rem-calc(-7);
						left: 50%;
						top: rem-calc(6);
					}
				}
			}
		}
	}

	p {
		@include breakpoint(medium) {
			font-size: rem-calc(18);
		}
	}

	> img {
		width: 100%;
		margin-top: rem-calc(50);
	}

	.image-wrap {
		margin-top: rem-calc(50);
		margin-bottom: rem-calc(50);
	}

	.most-worn,
	.products-slides {
		background: $white;
	}

	.block-products-list {
		padding-top: rem-calc(25);
		padding-bottom: rem-calc(35);

		@include breakpoint(medium) {
			padding-top: rem-calc(35);
			padding-bottom: rem-calc(40);
		}
	}

	.embodier-quote {
		margin-top: rem-calc(50);
		margin-bottom: rem-calc(50);
		background-color: map-get($danner-palette, forest);

		@include breakpoint(large) {
			margin-top: rem-calc(70);
			margin-bottom: rem-calc(70);
		}
	}

	.embodier-scaled-image {
		min-height: rem-calc(505);
		padding-bottom: 58%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	blockquote {
		padding-top: rem-calc(50);
		padding-bottom: rem-calc(70);
		border-left-width: 0;
		color: map-get($lfi-palette, sleet);
		font-family: inherit;

		@include breakpoint(medium) {
			padding-top: rem-calc(70);
			padding-bottom: rem-calc(80);
		}

		p {
			color: inherit;
			font: rem-calc(23) $body-secondary-font-bold;
			margin-bottom: rem-calc(25);

			@include breakpoint(medium) {
				font-size: rem-calc(27);
			}

			&:before {
				content: "\201C";
				display: block;
				margin: 0 auto rem-calc(-50);
				font-size: rem-calc(100);
				line-height: 1;
			}
		}

		footer {
			margin-top: rem-calc(25);
			font: rem-calc(16) $header-font-regular;
		}
	}

	.gallery {
		margin-top: rem-calc(50);
		margin-bottom: rem-calc(70);

		@include breakpoint(large) {
			margin-bottom: rem-calc(90);
		}
	}

	.go-there-story-container {
		padding-top: rem-calc(50);
		padding-bottom: rem-calc(70);
		background-color: map-get($lfi-palette, sleet);

		@include breakpoint(medium) {
			padding-top: rem-calc(70);
		}

		> h4 {
			margin-bottom: rem-calc(30);
			color: map-get($danner-palette, forest);
			text-align: center;

			@include breakpoint(medium) {
				margin-bottom: rem-calc(50);
			}
		}
	}
}

.cms-cory-carman,
.cms-greg-hennes,
.cms-christopher-van-tilburg {
	.page-main {
		margin-bottom: 0;
	}
}
