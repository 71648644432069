@import "../../../../default/styles/components/global/icons";

.icon-user-hollow {
	&:before {
		mask-image: url('../icons/icon-user-hollow.svg');
	}
}

.icon-menu {
	&:before {
		mask-image: url('../icons/icon-menu.svg');
	}
}

.icon-select-arrow {
	&:before {
		mask-image: url('../icons/icon-select-arrow.svg');
	}
}

.icon-bull-run {
	&:before {
		mask-image: url('../icons/icon-bull-run.svg');
	}
}

.icon-bull-run-fill {
	&:before {
		mask-image: url('../icons/icon-bull-run-fill.svg');
	}
}

.icon-caret-right-cutout {
	&:before {
		mask-image: url('../icons/icon-caret-right-cutout.svg');
	}
}

.icon-leather-hide {
	&:before {
		mask-image: url('../icons/icon-leather-hide.svg');
	}
}
