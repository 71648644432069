/*
 * Customer account landing page
 * ---------------------------
 * body.customer-account-index
 *  div.block-welcome-container
 *   div.welcome-container
 *   div.welcome-message
 *   ul.customer-account-navigation
 *    li.nav.item
 *
 */

.customer-account-index {
	@include account-address-grid;
}

.block-welcome-container {
	margin-bottom: rem-calc(40);

	.welcome-container {
		text-align: center;

		@include media-breakpoint-up(lg) {
			text-align: left;
		}
	}

	h1 {
		font-size: rem-calc(24);
		line-height: rem-calc(32);
	}
}

.block-dashboard-info {
	border-bottom: rem-calc(1) solid $light-gray;

	.box-newsletter {
		display: none;
	}

	.box-actions {
		display: none;
	}
}

.block-dashboard-addresses {
	.box-content {
		margin-bottom: rem-calc(16);
	}
}

.account-sign-out-link {

	li {
		margin-bottom: rem-calc(56);
	}

	a {
		@include button-hollow-primary;
		@include button-oval-adjustment(rem-calc(24), rem-calc(48));
		@include button-expand;
	}
}

.account-landing-benefits {
	border-bottom: rem-calc(1) solid $light-gray;
	margin-bottom: rem-calc(40);

	.block-content {
		padding-bottom: rem-calc(8);

		@include media-breakpoint-up(md) {
			padding-bottom: 0;
		}
	}

	div {
		padding-bottom: rem-calc(32);

		@include media-breakpoint-up(md) {
			padding-bottom: rem-calc(40);
		}
	}
}

.benefits-header {
	padding-bottom: rem-calc(40);

	@include media-breakpoint-down(lg) {
		text-align: center;
	}
}

.membership-questions {
	a {
		color: $base-primary-color;
	}
}
