//
// Simplified Checkout Footer Styles
//

.checkout-footer-container {
	max-width: $max-page-width;
	margin: 0 auto;
}

.checkout-footer {
	border-top: rem-calc(1) solid map_get($lfi-palette, light-gray);
	margin: rem-calc(0 15);
	max-width: rem-calc(904);
	padding: rem-calc(20 0 35 0);

	@include breakpoint(tablet) {
		margin: 0 auto;
		padding: rem-calc(20 0 60 0);
	}
}

.checkout-footer__list {
	display: flex;
	list-style: none;
	justify-content: center;
	margin: 0;
}

.checkout-footer__item {
	font-size: rem-calc(14);
	line-height: rem-calc(21);
	margin-bottom: 0;
	padding: rem-calc(0 10);
}
