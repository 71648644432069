// todo - move these styles to their partial
.header-background-basic {
	.banner-background {
		min-height: rem-calc(580);

		@include breakpoint(large) {
			min-height: rem-calc(788);
		}
	}

	.banner-content {
		color: map_get($lfi-palette, sleet);

		h2 {
			font-size: rem-calc(27);

			@include breakpoint(large) {
				font-size: rem-calc(44);
			}
		}
	}
}

// todo - this style is very broad, but was necessary because
// of global padding to the .category-cms element
// The Portland Select category design was a one off, but we need
// to decide if more categories will have these features and if so
// the default should probably be to not have padding.
// This style is better to leave here than in CMS as styles in CMS
// can grow untracked which will obscure any issues.
.category-portland-select {
	.category-cms {
		padding: 0 !important;
	}
}
