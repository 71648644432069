/*
 * Flip Tile
 */

.flip-tile {
	cursor: pointer;
	position: relative;
	width: 100%;

	&.active {
		.story-flash-card-front {
			display: none;
		}

		.story-flash-card-back {
			display: flex;
		}
	}

	.story-flash-card-content {
		--flash-card-content-margin: 0 16px;

		@include media-breakpoint-up(tablet) {
			--flash-card-content-margin: 0 24px;
		}
	}

	.story-flash-card-icon {
		--flash-card-icon-front-size: 24px;
		--flash-card-icon-back-size: 20px;
		margin: 16px 0 0;

		@include media-breakpoint-up(tablet) {
			--flash-card-icon-front-size: 32px;
			--flash-card-icon-back-size: 28px;
		}
	}

	.story-flash-card-front {

		.story-flash-card-content {
			background: var(--Centered-Gradient, linear-gradient(180deg, rgba(0, 0, 0, 0) 0.27%, #000 50.13%, rgba(0, 0, 0, 0.5) 100%));
			background-blend-mode: darken;
			margin: 0;
			padding: 104px 16px 16px;

			@include media-breakpoint-up(tablet) {
				padding: 104px 24px 32px;
			}
		}

		p {
			font-size: 12px;

			@include media-breakpoint-up(tablet) {
				font-size: 14px;
			}
		}
	}

	.story-flash-card-back {
		padding-top: 24px;

		@include media-breakpoint-up(tablet) {
			padding-top: 32px;
		}

		.story-flash-card-icon {
			margin: 16px;

			@include media-breakpoint-up(tablet) {
				margin: 32px 24px;
			}
		}

		p {
			font-size: 14px;
		}
	}

	p {
		@include bold-text;
		line-height: 20px;
		margin-bottom: 4px;
	}

	h5 {
		margin-bottom: 0;

		@include media-breakpoint-up(tablet) {
			font-size: 24px;
			line-height: 28px;
		}
	}
}

/*
 * CTA Tile
 */

.cta-tile {
	// Intentional non-use of variables
	// Note from Connor: "[Designers] specifically want to use any color here so I see no point is using a variable..."
	// "It's just confusing to a future dev because they could think these fallbacks are intentional."
	--tile-background: #ffffff;
	--tile-text-color: #2b2a27;
	--cta-color: #2b2a27;
	--cta-color-hover: #605e58;

	&:hover {
		.cta-text {
			color: var(--cta-color-hover);
		}
	}

	.wrapper {
		@include hover-zoom-image;
		display: flex;
		flex-flow: column nowrap;
		height: 100%;
		width: 100%;
	}

	.content {
		background: var(--tile-background);
		color: var(--tile-text-color);
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		min-height: 98px;
		padding: 16px 16px 24px;
		position: relative;

		@include media-breakpoint-up(md) {
			padding-bottom: 38px;
		}
	}

	.cta-text {
		align-items: center;
		color: var(--cta-color);
		display: flex;

		&:before {
			display: none;
		}

		&:after {
			height: 20px;
			margin-left: 4px;
			width: 20px;
		}
	}

	h5 {
		margin-bottom: 4px;
	}

	img {
		flex: 1 1 auto;
		object-fit: cover;
		width: 100%;
	}

	p {
		line-height: 20px;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}
