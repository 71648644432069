.store-switcher {
	.flag::after {
		background: url('../images/borderfree/US.gif') no-repeat;
		background-size: cover;
		content: "";
		display: inline-block;
		height: 16px;
		margin-left: 8px;
		position: relative;
		top: 1px;
		width: 24px;
	}
}
