/*
 * Go There Artist Callout Section
 * -------------------------------
 * section.go-there-callout
 *  div.widget.block.block-static-block
 *   div.content-wrapper
 *    div.content-top
 *     img
 *     h4.subheader
 *    div.content
 *     p
 *     p
 */

.go-there-callout {
	background: map-get($lfi-palette, sleet);

	.block-static-block {
		@include adjust-padding(
			rem-calc(70 25 65 25),
			rem-calc(90 60 85 60),
			rem-calc(90 189 85 189),
			rem-calc(110 200 105 200)
		);
	}

	.content-wrapper {
		text-align: center;
		max-width: rem-calc(690);
		margin: 0 auto;

		@include breakpoint(xlarge) {
			max-width: rem-calc(690);
		}
	}

	.content-top {
		margin-bottom: rem-calc(40);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(50);
		}

		@include breakpoint(xlarge) {
			margin-bottom: rem-calc(30);
		}
	}

	.content {
		text-align: left;
	}

	img {
		border-radius: rem-calc(50);
		margin-bottom: rem-calc(20);
		width: rem-calc(200);
	}

	h4 {
		color: map-get($danner-palette, forest);
		text-transform: none;
	}

	p {
		color: map-get($danner-palette, forest);
	}
}
