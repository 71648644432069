.product-specs--wrapper {
	color: map-get($lfi-palette, jet);
	height: 100%;
	padding: 60px 3%;

	@include breakpoint(medium) {
		background: map-get($lfi-palette, snow);
	}

	.table-caption {
		@include theme-header-transformation();
		text-align: center;
	}

	th {
		text-align: left;
	}

	.col {
		padding: rem-calc(7 9 5 9);
		width: 50%;
	}

	.table {
		--bs-table-bg: transparent;
		margin: rem-calc(30) auto;
	}

	tbody {
		border: 0;
	}

	tr {
		border-top: rem-calc(2) solid map-get($lfi-palette, latte);

		&:last-of-type {
			border-bottom: rem-calc(2) solid map-get($lfi-palette, latte);
		}
	}
}

.usa-made-callout {
	margin-top: rem-calc(70);
	text-align: center;

	@include breakpoint(large) {
		margin-left: auto;
		margin-right: auto;
	}
}
