/*
 * Video homepage section
 *
 * section.go-there-video
 *  div.widget.block.block-static-block
 *   div.go-there-video-container
 *   a
 *   div.go-there-video-content
 *    div.go-there-play-icon
 *    h3
 *    p
 */

.go-there-video-container {
	display: flex;
	align-items: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 24%;
	min-height: rem-calc(581);
	position: relative;

	@include breakpoint(medium) {
		min-height: rem-calc(512);
	}

	@include breakpoint(xlarge) {
		min-height: rem-calc(720);
	}

	a {
		position: absolute;
		height: 100%;
		width: 100%;
	}
}

.go-there-video-content {
	color: $white;
	flex: 0 0 100%;
	padding: rem-calc(35);
	text-align: center;

	h3 {
		text-transform: uppercase;
	}

	p {
		display: inline-block;
		max-width: rem-calc(450);
	}
}

.go-there-play-icon {
	@include svg-icon(
		$icon-url:	'../icons/icon-video-indicator.svg',
		$height: rem-calc(60),
		$width: rem-calc(60),
		$mask: false
	);

	margin-bottom: rem-calc(15);
}
