.text-with-shadow {
	@include text-over-image-styles;
}

.body-light-text-link {
	@include body-light-text-link;
}

.body-dark-text-link {
	@include body-dark-text-link;
}
