@import "../../../default/styles/components/messages.scss";

.message {
	> div,
	p {
		line-height: 1.2;
	}

	&.cart.item,
	&.field,
	&.small {
		> div,
		p {
			font-size: rem-calc(15);
		}
	}
}
