$dannerxtopo_base_color: #1e1f1d;

.danner-x-topo-category {
	.header-background .banner-content {
		max-width: rem-calc(600);
	}

	.brand-statement {
		// so far 1 off color for topo
		background: $dannerxtopo_base_color;

		@include breakpoint(small) {
			padding: rem-calc(120 50 110 50);
		}

		.banner-content h1 {
			@include breakpoint(small down) {
				font-size: rem-calc(26);
			}
		}

		p {
			background: linear-gradient(270deg, #d61a7e 0%, #eece42 100%);
			background-clip: text;
			-webkit-text-fill-color: transparent;

			font-family: $header-font-bold;
			font-size: rem-calc(27);
			line-height: rem-calc(38);
			text-align: center;

			@include breakpoint(small down) {
				font-size: rem-calc(23);
				line-height: rem-calc(33);
				text-align: left;
			}
		}
	}

	.brand-statement-wrapper {
		flex-flow: row wrap;
		max-width: rem-calc(800);
		margin: 0 auto;
		padding: 0;

		> * {
			flex: 1 1 100%;
		}
	}

	.slick-gallery-widget,
	.category-product-highlights {
		background: #f5f5f3; // snow
	}

	.product-controls-title,
	.slick-gallery-widget {
		h2,
		h4 {
			color: $dannerxtopo_base_color;
			font-size: rem-calc(34);
			font-family: $header-font-family;
			line-height: rem-calc(30);
		}
	}

	.slick-gallery-widget h4 {
		@include breakpoint(small down) {
			font-size: rem-calc(28);
		}
	}

	.product-controls-wrapper {
		@include breakpoint(small down) {
			padding-top: 0;
		}
	}

	.category-product-highlights {
		.button {
			// TODO darken on hover
			background: #452a62; // topo purple

			a {
				color: #f9f8f3; // why a new white, who knows
			}
		}
	}

	.product-items .price-container .price {
		font-family: $header-font-regular;
		font-size: rem-calc(12);
		letter-spacing: 0;
		line-height: rem-calc(15);

		@include breakpoint(small) {
			font-size: rem-calc(14);
			line-height: rem-calc(18);
		}
	}

	.cph-content {
		p {
			color: #2d2e2c; // 1 off color so far
		}

		h3 {
			color: $dannerxtopo_base_color;
		}
	}
}

.dxt-logo {
	margin-top: rem-calc(40);
	text-align: center;
}
