/*
 * Go There Homepage Alternative Hero
 * -------------------------------
 * .header-background-basic
 *  div.header-banner
 *   div.banner-background
 *    div.banner-content-wrapper
 *     div.banner-content
 *      img
 *      h2
 *      p
 *      a.button.hollow.hollow-white
 *
 */

.header-background-basic {
	.banner-background {
		display: flex;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	.banner-content-wrapper {
		flex: 0 0 100%;
		padding: rem-calc(125 25);

		@include breakpoint(medium) {
			padding: rem-calc(155 25);
		}
	}

	.banner-content {
		color: $white;
		text-align: center;
		position: relative;

		// Slick override
		img {
			display: inline-block;
		}
	}

	img {
		margin-bottom: rem-calc(14);
		width: rem-calc(99);
	}

	h2 {
		font-size: rem-calc(25);
		letter-spacing: rem-calc(0.47px);
	}

	p {
		padding-bottom: rem-calc(27);
	}
}

.header-background-basic:not(.header-custom-alignment) {
	.banner-background {
		align-items: center;
		min-height: rem-calc(580);

		@include breakpoint(medium) {
			min-height: rem-calc(560);
		}

		@include breakpoint(xlarge) {
			min-height: rem-calc(788);
		}
	}

	.banner-content {
		margin: 0 auto;
		max-width: rem-calc(482);

		@include breakpoint(xlarge) {
			max-width: rem-calc(450);
		}
	}
}
