// Need to undo some of our theme styles so Bootstrap looks correct
.accordion-item {
	.accordion-button {
		display: flex;
		padding: 0;
	}
}

// Copied from Bootstrap _reboot.scss (we should work to include that file)
// Tables
//
// Prevent double borders

table {
	border-collapse: collapse;
	caption-side: bottom;
}

caption {
	color: $table-caption-color;
	padding-bottom: $table-cell-padding-y;
	padding-top: $table-cell-padding-y;
	text-align: left;
}

// 1. Removes font-weight bold by inheriting
// 2. Matches default `<td>` alignment by inheriting `text-align`.
// 3. Fix alignment for Safari

th {
	// Using bold text mixin because of font-family bolds
	@include bold-text;
	font-weight: $table-th-font-weight; // 1
	text-align: inherit; // 2
	text-align: -webkit-match-parent; // 3
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}
