.company-users-index {
	.column.main {
		max-width: none;
	}

	.data-grid-filters-wrap {
		margin-bottom: rem-calc(24);

		.action {
			@include button-with-link-styles;
			margin-right: rem-calc(32);
		}
	}

	// Something is slightly off with the Magento modal styles
	// so we'll hide the content until it gets moved around by JS
	.modal-container {
		display: none;
	}

	.modal-popup {
		.modal-container {
			display: block;
		}
	}

	.table-wrapper {
		.action:last-child::before {
			color: $base-color;
			content: "|";
			padding: 0 rem-calc(8);
		}

		td::before {
			padding-right: rem-calc(2) !important;
		}
	}

	.data-grid-cell-content {
		display: inline;
	}

	.actions {
		.action {
			@include button-oval-adjustment(rem-calc(24), rem-calc(48));
		}
	}

	td:not(.actions) {
		vertical-align: middle;
	}
}
