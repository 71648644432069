@mixin big-badge-vars() {
	--bs-badge-padding-x: 7px;
	--bs-badge-border-radius: 5px;
	--lfi-badge-height: 22px;
}

@mixin little-badge-vars() {
	--bs-badge-border-radius: 4px;
	--bs-badge-font-size: 12px;
	--bs-badge-font-weight: unset;
	--bs-badge-padding-x: 6px;
	--lfi-badge-height: 20px;
	--lfi-badge-icon-size: 10px;
}

.lfi-badge {
	--bs-badge-padding-y: 0;

	@extend .badge;
	@include bold-text;
	@include little-badge-vars;

	display: inline-flex;
	gap: 2px;
	height: var(--lfi-badge-height);
	letter-spacing: 0.25px;
	line-height: var(--lfi-badge-height);
	width: max-content;

	@each $breakpoint in map-keys($grid-breakpoints) {
		$infix: breakpoint-infix($breakpoint);

		@include media-breakpoint-up($breakpoint) {
			&.big#{$infix} {
				@include big-badge-vars;
			}

			&.little#{$infix} {
				@include little-badge-vars;
			}
		}
	}

	[class^="icon-"],
	[class*=" icon-"] {
		align-content: center;
		display: grid;
		font-size: var(--lfi-badge-icon-size);
		margin-left: calc(-3 / 14 * var(--bs-badge-padding-x));
	}
}
