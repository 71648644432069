/**
 * Account Order PAges
 */
.sales-order-history,
.sales-guest-view,
.sales-order-view {
	.column.main {
		max-width: none;
	}
}

/**
 * Order History Page
 */
.sales-order-history {
	// Hide re-order button
	.order {
		display: none;
	}

	// some crazy selectors going on here so important for the override
	.actions:before,
	.shipping,
	.status {
		@include media-breakpoint-down(lg) {
			display: none !important;
		}
	}

	.empty {
		margin-bottom: rem-calc(40);
		margin-top: 0;

		@include media-breakpoint-up(lg) {
			text-align: left;
		}

		&::before {
			display: none;
		}
	}
}

.orders-history {
	margin-bottom: rem-calc(40);

	.table-caption {
		display: none;
	}
}

// End History Page Styles
//////////////////////////

/**
 * Order View Pages
 */
.sales-guest-view,
.sales-order-view {

	// Special fix for button when order is on hold
	// TODO - Either address globally or change html
	.messages {
		.button {
			display: block;
		}
	}

	.main {
		.page-title-wrapper {
			margin-bottom: 0;
		}
	}

	.page-title {
		margin-bottom: rem-calc(24);
	}

	.order-date {
		text-align: left;
	}

	.order-item-thumb {
		width: rem-calc(77);
	}

	.promisedate {
		color: $alert-color;
	}

	.shipments {
		margin-bottom: rem-calc(24) !important;

		li {
			margin-bottom: rem-calc(12);
		}

		p {
			margin-bottom: 0;
		}
	}

	.shipment {
		a {
			color: $base-primary-color;
			text-decoration: underline;
		}
	}

	.track-title {
		@include bold-text;
	}

	.product-name {
		@include bold-text;
	}

	.item-thumbnail {
		left: 0;
		position: absolute;

		@include media-breakpoint-up(md) {
			position: relative;
		}
	}

	.table-caption {
		display: none;
	}

	thead,
	tfoot {
		display: none !important;
	}

	li {
		margin-bottom: rem-calc(4);

		&:last-of-type {
			margin-bottom: 0 !important;
		}
	}

	tr {
		position: relative;

		@include media-breakpoint-down(md) {
			margin-bottom: rem-calc(15);
			padding-left: rem-calc(87);
		}

		td {
			padding-bottom: rem-calc(24);
			padding-top: rem-calc(24);
		}
	}

	td::before {
		display: none !important;
	}
}

.order-details-items {
	margin-bottom: rem-calc(40);
	margin-top: rem-calc(48);

	@include media-breakpoint-up(md) {
		margin-bottom: rem-calc(64);
	}

	.order-title {
		margin-bottom: rem-calc(24);

		@include media-breakpoint-down(md) {
			text-align: center;
		}
	}
}

.block-order-details-comments {
	background: map-get($lfi-palette, snow);
	padding: rem-calc(15);
}

.block-order-details-view {
	.block-content {
		display: grid;

		@include media-breakpoint-up(md) {
			column-gap: $account-grid-column-gap;
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.box-order-shipping-address {
	order: 2;
}

.box-order-billing-address {
	order: 3;
}

.block-payment-details {
	order: 1;

	@include media-breakpoint-down(md) {
		border-bottom: rem-calc(1) solid $light-gray;
		margin-bottom: rem-calc(64) !important;
		padding-bottom: rem-calc(64);
	}

	@include breakpoint(medium) {
		order: 4;
		text-align: right;
	}

	.payment-info-details {
		margin-bottom: rem-calc(20);

		span {
			display: block;
		}
	}

	.price-details {
		span:after {
			content: "";
			display: block;
		}

		span:before {
			content: ":";
			margin-left: rem-calc(-4);
			margin-right: rem-calc(4);
		}
	}
}

/**
 * Guest Order View Page
 */
.sales-guest-view {
	.page-main {
		.columns {
			display: block !important;
		}
	}
}
