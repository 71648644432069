@use "../../helpers/extensions/list-reset";
@use "../../globals/colors";
@import "../../helpers/typography";

.quick-add-list {
	@extend %list-reset;
}

.quick-add-list-item {
	margin-bottom: 0;
}

.quick-add-item {
	width: 100%;
}

.quick-add-item-wrapper {
	display: grid;
}

.quick-add-item-content {
	.model-name,
	.model-descriptor {
		@include bold-text;
		color: map-get(colors.$lfi-palette, jet);
	}

	.model-name {
		margin-bottom: 2px;
	}

	.price-box {
		justify-content: start;
		margin: 12px 0 20px;
	}

	.plp-feature-container {
		margin: 8px 0 4px;
	}

	.size-guide-modal-launcher {
		text-decoration: underline;
	}

	.gender {
		color: map-get(colors.$lfi-palette, jet);
		margin-bottom: 4px;
	}

	a {
		display: block;
		line-height: 22px;
	}
}

.quick-add-actions {
	position: relative;

	.product-link {
		margin-bottom: 0;
		margin-left: auto;
		padding-left: 15px;
		padding-right: 15px;
	}

	.validation-message {
		bottom: -22px;
		color: map-get(colors.$lfi-palette, cherry);
		left: 0;
		position: absolute;
	}

	.validation-error {
		border-color: map-get(colors.$lfi-palette, cherry);
	}

	select {
		border-color: map-get(colors.$lfi-palette, jet);
		border-width: 2px;
		margin-bottom: 0;
		min-height: 48px;
		min-width: 134px;
	}
}
