//
// Recrafting Landing Page
//

%recrafting-section {
	padding: rem-calc(40 16);

	@include breakpoint(medium) {
		padding-bottom: rem-calc(64);
		padding-top: rem-calc(64);
	}
}

%recrafting-section-alt {
	@extend %recrafting-section;

	background-color: $body-background-alt;
}

%recrafting-max-width {
	margin: 0 auto;
	max-width: $max-page-width;
}

.cms-recrafting {
	$border-color: map-get($lfi-palette, light-gray);

	h2 {
		@include breakpoint(medium) {
			text-align: center;
		}
	}

	.page-main {
		color: $base-color;
		font-size: rem-calc(18);
		margin-top: 0;
	}

	.get-started:not(.mobile-only) {
		display: none;

		@include breakpoint(medium) {
			display: block;
		}
	}

	.mobile-only {
		@include breakpoint(medium) {
			display: none;
		}
	}
}

//
// Sections
//

.cms-recrafting .anthem {
	max-height: 66vh;
	overflow: hidden;

	.media-container {
		align-items: center;
		display: flex;
		justify-content: center;

		video {
			display: block;
			object-fit: cover;
			object-position: center;
			width: 100%;
		}
	}
}

.cms-recrafting .intro {
	@extend %recrafting-section-alt;

	padding-left: rem-calc(20);
	padding-right: rem-calc(20);

	@include breakpoint(medium) {
		padding-top: rem-calc(64);
	}

	.newleaf-grid {
		margin-left: auto;
		margin-right: auto;
		max-width: rem-calc(1000);

		@include breakpoint(medium) {
			gap: rem-calc(72);
		}
	}

	h1 {
		font-size: rem-calc(52);
		margin-bottom: rem-calc(12);
		text-transform: uppercase;

		@include breakpoint(medium) {
			font-size: rem-calc(80);
			line-height: rem-calc(100);
			margin-bottom: 0;
			position: relative;
			top: rem-calc(-16);
		}
	}

	.subtitle {
		margin-bottom: rem-calc(24);
		text-align: unset;

		@include breakpoint(medium) {
			margin-bottom: 0;
		}
	}

	.copy {
		font-size: rem-calc(18);
		line-height: rem-calc(26);
	}

	.get-started {
		margin: rem-calc(48 0 0 0);

		.button {
			margin: 0;
		}
	}
}

.cms-recrafting .explain {
	@extend %recrafting-section;

	h2 {
		margin-bottom: rem-calc(16);

		@include breakpoint(large) {
			margin-bottom: rem-calc(56);
		}
	}

	.steps {
		@extend %recrafting-max-width;

		list-style: none;

		@include breakpoint(tablet down) {
			max-width: rem-calc(480);
		}

		span {
			@include bold-text();

			flex: 1;
			font-size: rem-calc(16);

			@include breakpoint(large) {
				display: block;
			}
		}

		a {
			color: $base-color;
			text-decoration: underline;
		}
	}

	.eta {
		@include bold-text();

		background-color: $body-background-alt;
		border: rem-calc(1) solid $border-color;
		border-radius: rem-calc(4);
		display: flex;
		font-size: rem-calc(14);
		gap: rem-calc(8);
		margin: rem-calc(16) auto 0 auto;
		max-width: rem-calc(480);
		padding: rem-calc(8);

		@include breakpoint(large) {
			margin-top: rem-calc(40);
		}

		&:before {
			height: rem-calc(18);
			left: rem-calc(2);
			position: relative;
			top: rem-calc(2);
			width: rem-calc(18);
		}

		p {
			flex: 1;
		}
	}

	.get-started {
		margin-top: rem-calc(40);
		text-align: center;

		@include breakpoint(tablet down) {
			display: none;
		}
	}
}

.cms-recrafting .services {
	@extend %recrafting-section-alt;

	h2 {
		margin-bottom: rem-calc(16);
	}

	.bottomLine {
		margin-bottom: rem-calc(32);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(64);
			text-align: center;

			.standalone-bottomline {
				display: inline-block;
			}
		}
	}

	h3 {
		margin-bottom: rem-calc(16);
	}

	.definitions {
		display: flex;
		flex-direction: column;
		margin: 0 auto rem-calc(32) auto;
		max-width: map-get($grid-breakpoints, tablet);

		@include breakpoint(medium) {
			flex-direction: row;
			margin-bottom: rem-calc(64);

			> div {
				padding: rem-calc(0 32);
			}
		}
	}

	.service:first-child {
		@include breakpoint(medium) {
			border-right: rem-calc(1) solid $border-color;
		}

		&:after {
			background-color: $border-color;
			content: '';
			display: block;
			height: rem-calc(1);
			margin: rem-calc(32 0);
			width: calc(100% - 32px);

			@include breakpoint(medium) {
				display: none;
			}
		}
	}

	.actions {
		@include breakpoint(medium) {
			text-align: center;
		}
	}

	.button span {
		align-items: center;
		display: flex;

		&:before {
			@include svg-icon('../icons/icon-compare.svg', 1rem, 1rem);

			margin-right: rem-calc(8);
		}
	}
}

.cms-recrafting .gallery {
	@extend %recrafting-section;

	padding-bottom: rem-calc(24);
	padding-left: 0;
	padding-right: 0;

	@include breakpoint(medium) {
		padding-bottom: rem-calc(48);
	}

	h2 {
		margin: rem-calc(0 16 40 16);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(64);
		}
	}

	.newleaf-slider-container {
		@extend %recrafting-max-width;
	}

	ul {
		list-style-type: none;
		margin: 0;
	}

	.slide {
		margin-bottom: 0;
	}

	.block-static-block {
		display: flex;
		min-height: rem-calc(160);

		@include breakpoint(medium) {
			min-height: rem-calc(300);
		}

		@include breakpoint(large) {
			min-height: rem-calc(440);
		}

		img,
		source {
			height: calc(100% - 36px);
		}
	}

	.before img {
		border-right: rem-calc(1) solid map-get($danner-palette, forest);
		padding-right: rem-calc(16);
	}

	.after img {
		padding-left: rem-calc(16);
	}

	figure {
		flex: 1;
	}

	figcaption {
		@include bold-text();

		font-size: rem-calc(14);
		line-height: rem-calc(20);
		margin-top: rem-calc(16);
		text-align: center;
		text-transform: uppercase;
	}
}

.cms-recrafting .stories {
	background-color: $body-background-alt;
	padding: rem-calc(40 0);

	@include breakpoint(medium) {
		padding: rem-calc(64 16);
	}

	h2 {
		margin: rem-calc(0 16 40 16);
	}

	.newleaf-slider-container {
		margin: 0 auto;
		max-width: rem-calc(1110);
	}

	ul {
		--newleaf-slide-snap-align: center;
		--newleaf-slider-slide-size: 92vw;
		list-style: none;
		margin: 0;

		@include breakpoint(medium) {
			--newleaf-slide-snap-align: start;
			--newleaf-slider-slide-gap: 2rem;
			--newleaf-slider-padding-horizontal: 1rem;
			--newleaf-slider-slide-size: 343px;
			padding: 0; // horizontal padding added to ancestor
		}
	}

	.newleaf-circle-button-wrapper {
		margin-top: 0;
		position: relative;
		top: rem-calc(-8);

		button[disabled] {
			background-color: $white;
		}
	}
}

.cms-recrafting .call-to-action {
	@extend %recrafting-section;

	padding-left: 0;
	padding-right: 0;

	@include breakpoint(small only) {
		padding-top: 0;
	}

	.wrapper {
		@extend %recrafting-max-width;
	}

	.newleaf-text-block {
		margin-top: rem-calc(32);
	}

	h2 {
		text-align: left;
	}

	p {
		margin-bottom: rem-calc(24);
	}

	.button-list {
		list-style: none;
		margin: 0;
		width: rem-calc(284);

		.button {
			width: 100%;
		}
	}
}

.cms-recrafting .faq {
	@extend %recrafting-section-alt;

	h2 {
		margin-bottom: rem-calc(24);
	}

	.questions {
		text-align: center;

		@include breakpoint(medium) {
			padding-top: rem-calc(24);
		}

		p {
			margin-bottom: rem-calc(16);
		}
	}
}

.cms-recrafting .reviews {
	@extend %recrafting-section;
}

//
// Organisms
//

.get-started.sticky {
	background-color: $white;
	bottom: 0;
	height: rem-calc(80);
	left: 0;
	padding: rem-calc(16 8);
	position: fixed;
	width: 100%;
	z-index: 9;

	.button {
		display: block;
		margin-bottom: 0;
		width: auto;
	}
}

.recrafting-logos {
	align-items: center;
	display: flex;
	gap: rem-calc(40);
	list-style: none;
	margin: rem-calc(24 0 0 0);

	img {
		// SVG fill: map-get($danner-palette, forest) equivalency
		filter: invert(14%) sepia(20%) saturate(1248%) hue-rotate(123deg) brightness(87%) contrast(86%);
	}

	li {
		display: inline-flex;
		height: rem-calc(64);
		margin: 0;

		&:first-child::after {
			background-color: $background-primary-color;
			content: '';
			height: 100%;
			left: rem-calc(20);
			position: relative;
			top: 0;
			width: rem-calc(1);
		}
	}
}

#widget-modal-recrafting-service-comparison {
	.modal-header {
		padding: 0;
		position: absolute;
		right: rem-calc(28);
		top: rem-calc(28);
		z-index: 1;
	}

	.modal-body {
		padding: rem-calc(24);
	}

	h3 {
		margin-bottom: rem-calc(12);
		text-align: left;
	}

	table {
		caption-side: bottom;
		font-size: rem-calc(16);
		line-height: rem-calc(20);
		margin-bottom: 0;

		thead,
		tbody {
			border: none;
		}

		> caption {
			@include lib-visually-hidden-reset();

			font-size: rem-calc(14);
			margin-top: rem-calc(14);
			text-align: left;
		}
	}

	col.spec {
		width: 25%;
	}

	th {
		padding: 0;
		text-align: center;
		text-wrap: none;
	}

	tbody {
		&:before {
			content: '';
			display: block;
			height: rem-calc(16);
		}

		tr:nth-child(odd) {
			td {
				background-color: $body-background-alt;
			}

			td:first-child {
				border-bottom-left-radius: rem-calc(4);
				border-top-left-radius: rem-calc(4);
			}

			td:last-child {
				border-bottom-right-radius: rem-calc(4);
				border-top-right-radius: rem-calc(4);
			}
		}

		td:not(:first-child) {
			font-size: rem-calc(20);
		}
	}

	td {
		padding: rem-calc(8);
	}

	td:first-child {
		text-align: left;
	}
}

.cms-recrafting .steps {
	counter-reset: step-counter;

	@include breakpoint(large) {
		flex-direction: row;
	}

	> li {
		border-bottom: rem-calc(1) solid $border-color;
		counter-increment: step-counter;
		margin-bottom: 0;
		padding: rem-calc(24 0);
		position: relative;

		@include breakpoint(tablet down) {
			align-items: center;
			display: flex;
			gap: rem-calc(8);
		}

		@include breakpoint(large) {
			border-bottom: none;
			padding-left: rem-calc(44);
			padding-right: rem-calc(44);
			text-align: center;

			&:after {
				background-color: map-get($danner-palette, forest);
				content: '';
				display: block;
				height: rem-calc(2);
				left: 50%;
				position: absolute;
				top: rem-calc(50);
				width: 100%;
				z-index: -1;
			}
		}

		&:before {
			background-color: $background-primary-color;
			border: rem-calc(8) solid $white;
			border-radius: rem-calc(56);
			color: $white;
			content: counter(step-counter);
			display: inline-block;
			font-family: $body-secondary-font-bold;
			font-size: rem-calc(28);
			height: rem-calc(56);
			line-height: rem-calc(40);
			text-align: center;
			width: rem-calc(56);
		}

		&:last-of-type {
			border-bottom-width: 0;
		}

		&:last-of-type::after {
			display: none;
		}
	}
}

.cms-recrafting .story {
	background-color: $white;
	border-radius: rem-calc(16);
	box-shadow: rem-calc(0 10 20) #00000014;
	font-size: rem-calc(16);
	line-height: rem-calc(20);
	margin-bottom: rem-calc(24);
	padding: rem-calc(24);

	.headshot {
		align-items: center;
		border: rem-calc(1) solid map-get($lfi-palette, metal);
		border-radius: rem-calc(44);
		display: flex;
		height: rem-calc(88);
		justify-content: center;
		margin: 0 auto;
		overflow: hidden;
		width: rem-calc(88);

		img {
			border-radius: rem-calc(40);
			height: rem-calc(80);
			width: rem-calc(80);
		}
	}

	.bio {
		margin: rem-calc(12 0 16 0);
		text-align: center;

		.name {
			@include bold-text();

			display: block;
		}
	}
}

.cms-recrafting .accordion {
	@include bootstrap-accordion();

	max-width: rem-calc(700);
}
