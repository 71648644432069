// todo - this appears to be used only in Foundation. Do we need to load it?
@font-face {
	font-family: "Liberation Mono";
	src: url("../fonts/LiberationMono-Regular-webfont.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}

///
// Custom Font Weights
$font-weight__lightest: 100 !default;
$font-weight__lighter: 200 !default;
$font-weight__light: 300 !default;
$font-weight__regular: 400 !default;
$font-weight__semibold: 500 !default;
$font-weight__bold: 600 !default;
$font-weight__bolder: 700 !default;
$font-weight__boldest: 800 !default;
$font-weight__heavy: 900 !default;
