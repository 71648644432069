/*
 * Catalog Search
 * -----------------
 * body.catalogsearch-result-index
 *  div.search-results-top-container
 *   div.page-title-wrapper
 *   form.results-form-search
 *    fieldset
 */

.catalogsearch-result-index {
	.page-main {
		background: $body-background-alt;
		margin-bottom: 0;
	}
}

.search-results-top-container {
	padding: rem-calc(30 0);
}

.results-form-search {
	@include xy-grid-container();
	display: flex;
	justify-content: center;

	fieldset {
		width: 100%;

		@include breakpoint(medium) {
			max-width: rem-calc(390);
		}
	}

	button {
		display: none;
	}
}

// When there are no search results, some product grid
// containers have padding we don't want
[data-product-count="0"] {
	display: none;
}

.product-results {
	padding: rem-calc(0 0 120 0);
}
