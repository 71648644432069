//
// Cart Summary
//

.cart-summary {
	@include xy-cell(12);
	@include rounded-container();
	align-self: flex-start;
	background: $body-background-alt;
	display: flex;
	flex-flow: row wrap;
	padding-bottom: rem-calc(30);

	@include breakpoint(medium) {
		@include xy-cell(5 of 12);
		order: 2;
	}
	@include breakpoint(large) {
		@include xy-cell(4 of 12);
	}

	span {
		display: inline-block;
	}

	.summary.title {
		@extend .h5;
		margin: rem-calc(50 0 10 0);
		order: -2;
		text-align: center;
		width: 100%;
	}

	.message {
		display: flex;
		font-size: rem-calc(14.4);
		line-height: 1.2;
		margin: rem-calc(12 0 6);
		padding: rem-calc(0 0 3);
		text-align: left;

		&::before {
			font-size: rem-calc(15);
			margin-right: rem-calc(6);
			margin-top: rem-calc(2);
		}
	}

	.block {
		border-top: 1px solid map-get($lfi-palette, latte);
		margin-bottom: 0;
		width: 100%;

		&.discount.active {
			margin-bottom: rem-calc(20);
		}

		&.items-in-cart {
			.title {
				display: none;
			}

			.actions-toolbar {
				margin-top: rem-calc(24);

				.action:only-of-type {
					margin: 0;
				}
			}
		}

		> .title {
			cursor: pointer;
			margin: rem-calc(20 0);
			position: relative;
			text-align: center;

			&::after {
				@include svg-icon('../icons/icon-chevron-down.svg', 14px, 14px);
				margin-bottom: -2px;
			}

			&:focus {
				outline: 0;
			}
		}

		&.active > .title::after {
			transform: rotate(180deg);
		}

		> .content {
			form {
				padding: rem-calc(0 20);
			}

			.fieldset {
				border: 0;
				margin: 0;
				padding: 0;

				.field {
					width: 100%;

					&:not(.note) {
						+ .actions-toolbar {
							margin-top: rem-calc(6);

							> * {
								width: 100%;
							}

							button {
								width: 100%;
							}
						}
					}
				}
			}
		}

		.borderfree &,
		.aafes & {
			&.shipping {
				display: none;
			}
		}
	}

	// Totals Tables
	.table-wrapper {
		margin: 0;
	}

	.cart-shipping-address-form {
		margin-bottom: rem-calc(20);
	}

	.shipping-information-content {
		padding-left: rem-calc(9);
		padding-right: rem-calc(9);
	}
}

.cart-totals {
	@include totals-table($padding: rem-calc(20));
	border-color: $body-background-alt;
	min-height: rem-calc(125);
	order: -1;
	width: 100%;

	.table {
		--bs-table-bg: transparent;
	}

	table.totals {
		margin-bottom: 0;
	}

	tr.totals .price {
		white-space: nowrap;
	}

	tr.totals.balance {
		.action.delete {
			@include bold-text();
			padding-left: rem-calc(2);
		}
	}

	.field-tooltip {
		height: auto;
		left: initial;
		position: relative;
		right: initial;
		top: rem-calc(-3);

		.field-tooltip-action {
			padding: rem-calc(0 0 0 3);
		}

		.field-tooltip-content {
			right: auto;
			top: rem-calc(-75);

			@include breakpoint(small only) {
				// Force positioning for small due to viewport overflow
				left: rem-calc(-101);
				width: rem-calc(239);

				&:before,
				&:after {
					border: none;
				}
			}

			@include breakpoint(medium) {
				right: rem-calc(35);
				top: rem-calc(-6);
			}
		}
	}

	.borderfree &,
	.aafes & {
		min-height: rem-calc(108);

		.grand {
			margin-top: 0;

			> th,
			td {
				padding-top: rem-calc(10);
			}
		}
	}

	th,
	td {
		border: none !important;
	}
}

// TODO add extra space to footer so that this doesn't block it?
#checkout-methods-wrapper {
	@include xy-cell(100%, $gutters: 0);
	margin-top: rem-calc(20);

	@include breakpoint(small only) {
		background: $body-background;
		border-top: rem-calc(1) solid map-get($foundation-palette, secondary);
		bottom: 0;
		left: 0;
		padding: rem-calc(15 15 28 15);
		position: fixed;
		z-index: 102; // above totals lib-loading-mask()

		.item {
			margin: 0;
		}

		button.primary.checkout {
			@include button-oval-adjustment;
		}

		.apple-pay-button-card {
			display: none !important; // fix flash prior to methods drawer initialize
		}
	}

	.borderfree &,
	.aafes & {
		margin-top: rem-calc(5);
	}
}

.checkout.methods {
	width: 100%;

	@include breakpoint(medium) {
		margin-bottom: rem-calc(20);
		padding: rem-calc(0 20);
	}

	.item {
		// ApplePay buttons are rendered by JS so methods should handle their own margin
		margin-bottom: 0;
	}

	button.primary.checkout {
		margin-bottom: 0;
		width: 100%;
	}
}
