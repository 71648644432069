@import "../../default/styles/components/utility/_elements.scss";

// @use
// -----------------------
// div (position relative)
//  a.full-element-overlay
//  (other-content)
// TODO - add to styleguide
.full-element-overlay {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

// Dark overlay built for header background widgets
// Added class via widget option
// @use
// -----------------------
// div.header-banner.dark-overlay
// TODO - add to styleguide
.dark-overlay {
	position: relative;

	.banner-overlay {
		@extend .full-element-overlay;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 1;
		@include breakpoint(medium) {
			background-color: $overlay-background-color;
		}
	}

	.banner-content {
		position: relative;
		z-index: 2;
	}
}

.link-button {
	color: map-get($danner-palette, danner-green);
	font-family: $header-font-condensed-bold;
	font-size: rem-calc(16);
	letter-spacing: rem-calc(1);
	line-height: rem-calc(20);
	text-decoration: underline;
	text-transform: uppercase;
}

.go-there-logo {
	background-image: url("../images/go-there-logo.svg");
	background-repeat: no-repeat;
	background-size: 100%;
	height: rem-calc(50);
	margin: 0 auto;
	width: rem-calc(300);
}

// TODO - add to styleguide
.underline-header {
	color: map-get($danner-palette, forest);
	margin-bottom: rem-calc(18);
	position: relative;
	text-align: center;

	&:after {
		content: "";
		display: block;
		position: absolute;
		bottom: rem-calc(-12);
		left: 50%;
		margin-left: rem-calc(-33);
		width: rem-calc(66);
		border-top: rem-calc(2) solid map-get($danner-palette, copper);
	}

	&.alt {
		color: $white;
	}

	&.alt:after {
		border-color: rgba(255, 255, 255, 0.3);
	}
}
