.banner-scroll-wrap {
	position: relative;
}

.banner-scroll {
	position: absolute;
	width: rem-calc(50);
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	top: rem-calc(-60);

	@include breakpoint(large up) {
		top: rem-calc(-80);
	}

	.scroll-button {
		color: $body-font-color;
		display: block;
		height: 100%;
		padding-bottom: rem-calc(11.5);
		padding-top: rem-calc(11.5);
		text-align: center;
		width: 100%;

		.light-header-fg & {
			color: $body-inverted-font-color;
		}
		&:before {
			@include svg-icon('../icons/icon-chevron-down.svg');
			vertical-align: middle;
		}
	}
}
