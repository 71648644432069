.filter-offcanvas {
	width: 100%;

	h1,
	h2 {
		@include bold-text;
	}

	h1 {
		font-size: rem-calc(24);
		font-weight: $font-weight__heavy;
		margin-bottom: 0;
	}

	h2 {
		@include theme-header-transformation;
	}
}

.filter-offcanvas-header {
	background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0));
	justify-content: space-between;
	padding: rem-calc(28 24 28 16);
	z-index: 1;
}

.filter-offcanvas-body {
	background: $body-background-alt;
	// bump body under header so we can scroll body under the
	// header and show grandient
	margin-top: rem-calc(-80);
	padding-top: rem-calc(100);
	padding-bottom: 0;

	h2 {
		@include bold-text;
		font-size: rem-calc(18);
		margin-bottom: rem-calc(26);
	}
}

.filter-action-close {
	align-items: center;
	border-radius: rem-calc(50);
	display: flex;
	height: rem-calc(32);
	justify-content: center;
	max-width: rem-calc(32);

	&.action-close {
		// override
		background: $background-primary-color;

		&:hover,
		&:focus {
			// override
			background: $background-primary-color;
		}
	}

	&:before {
		color: $white;
		font-size: rem-calc(16);
	}
}

////
// Filter Actions Bottom
////
.filter-actions-bottom {
	background: $white;
	bottom: 0;
	display: flex;
	left: 0;
	padding: rem-calc(10);
	position: fixed;
	right: 0;
	transform: translateY(100%);
	transition: transform 0.3s ease;
	z-index: 2;

	@include breakpoint(large) {
		display: none;
	}

	&.actions-active {
		transform: none;
	}

	button {
		flex: 1 1 50%;

		&:not(:first-of-type) {
			margin-left: rem-calc(10);
		}
	}
}

////
// Filter Options
// Specific filter styles can go in
// app/design/frontend/Lfi/default/ErgonTech_Mage2LayerApi/styles/_filters.scss
////

.filter-options {
	margin-bottom: rem-calc(100);
}

.filter-option {
	.accordion-button {
		@include bold-text;
		padding: rem-calc(16 0);
		text-transform: none;

		&:hover {
			color: $body-text-hover-color;
		}
	}
}

.accordion-button {
	&:after {
		@include svg-icon('../icons/icon-chevron-down.svg', 16px, 16px);
	}

	&:hover {
		&:after {
			color: $body-text-hover-color;
		}
	}
}

// Custom Checkbox for standard filter items
.filter-items:not(.filter-filter_color):not(.filter-wth_alias):not(.filter-sizes) {

	.item:hover {
		.filter-checkbox:not(:checked) {
			border-color: $filter-option-hover-color;
		}

		label {
			color: $body-text-hover-color;
		}
	}

	.item:last-of-type {
		margin-bottom: 0;
	}

	.filter-checkbox {
		background-color: $white;
		border-color: $background-primary-color;
		border-radius: rem-calc(2);
		flex: 0 0 rem-calc(24);
		height: rem-calc(24);

		&:before {
			font-size: rem-calc(20);
		}

		&:checked {
			background: $background-primary-color;
		}

		&:disabled {
			// no beating the disabled styles
			border-color: $input-border-disabled !important;
		}
	}
}

.filter-options-content {
	padding-bottom: rem-calc(32);

	.item {
		align-items: center;
		display: flex;
		margin-bottom: rem-calc(16);
		position: relative;
	}

	label {
		flex: 1 1 auto;
		margin: 0;
		padding-left: rem-calc(12);
	}
}
