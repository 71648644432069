//
// Checkout Simplified Navigation Styles
//

.checkout-index-index {
	.page-header {
		display: flex;
		justify-content: center;

		.header {
			@include logo-styles();
			margin: rem-calc(20 0);

			@include breakpoint(medium) {
				margin: rem-calc(40 0);
			}
		}
	}

	// Reset global set style...
	.page-main {
		margin: 0;
	}
}

// Order Page Items
.checkout-index-index .messages {
	order: 0;
}

.opc-progress-bar {
	order: 1;
}

.opc-summary-wrapper {
	order: 2;

	@include breakpoint(tablet) {
		order: 3;
	}
}

.opc-wrapper {
	order: 3;

	@include breakpoint(tablet) {
		order: 2;
	}
}

// Style Nav
.opc-progress-bar {
	display: flex;
	justify-content: center;
	flex: 1 1 100%;
	list-style: none;
	margin: 0 auto rem-calc(20) auto;

	@include breakpoint(medium) {
		margin-bottom: rem-calc(40);
	}
}
.opc-progress-bar-item {
	align-items: center;
	color: $header-color;
	display: flex;
	line-height: rem-calc(21);
	font-size: rem-calc(14);
	margin: 0;

	.checkout-index-index & {
		// The cart and any complete steps
		// Should be visibly clickable
		&:first-of-type,
		&._complete {
			span {
				cursor: pointer;
			}
		}
	}

	&._active {
		@include bold-text;
	}

	&:not(:last-of-type):after {
		@include svg-icon('../icons/icon-navigation-right.svg', 24px, 24px);
		margin-bottom: -2px;
	}
}
