//
// Account Navigation
// ------------------
//

.block-collapsible-nav {
	// stylelint-disable-next-line
	border-bottom: rem-calc(1) solid rgba(25, 50, 47, 0.08);
	// stylelint-disable-next-line
	box-shadow: rem-calc(0 8 16 0) rgba(0, 0, 0, 0.05);
	cursor: pointer;
	// prevent box-shadow from covering
	position: relative;

	@include media-breakpoint-up(lg) {
		border: rem-calc(1.25) solid $light-gray;
		border-radius: rem-calc(16);
		padding: rem-calc(40 32);
	}
}

.block-collapsible-nav-title {
	align-items: center;
	background: $white;
	display: flex;
	font-size: rem-calc(18);
	height: rem-calc(48);
	justify-content: space-between;
	padding: 0 rem-calc(24);
	// prevent box-shadow from covering
	position: relative;
	z-index: 6;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	&::after {
		@include svg-icon('../icons/icon-chevron-down.svg');
	}

	&.active::after {
		transform: rotate(180deg);
	}
}

.block-collapsible-nav-content {
	background: $white;
	border: none;
	// stylelint-disable-next-line
	box-shadow: rem-calc(0 20 40 0) rgba(0, 0, 0, 0.1);
	left: 0;
	padding: rem-calc(32 24 56 24);
	position: absolute;
	right: 0;
	z-index: 5;

	@include media-breakpoint-up(lg) {
		border: none;
		box-shadow: none;
		padding: 0;
		position: relative;
	}

	&:not(.active) {
		display: none;

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}

	.delimiter {
		border-top: rem-calc(1) solid $light-gray;
		display: block;
		margin: rem-calc(24) 0;
	}

	.current {
		font-weight: $font-weight__bolder;
		text-decoration: underline;

		@include media-breakpoint-up(lg) {
			text-decoration: none;
		}

		&::before {
			@include media-breakpoint-up(lg) {
				background: $base-color;
				content: '';
				height: 100%;
				left: rem-calc(-32);
				position: absolute;
				width: rem-calc(4);
			}
		}
	}

	li {
		margin-bottom: rem-calc(12);
		position: relative;
		width: 100%;

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(8);
		}
	}

	a {
		color: $base-color;
		font-weight: $font-weight__regular;
		line-height: rem-calc(24);
	}
}
