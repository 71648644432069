//
// Checkout sidebar
// ----------------
// Sidebar controls
//
// div.opc-sidebar-collapsible
//  div.opc-summary-title-wrapper.opc-summary-title-non-collapsible
//   span
//  div.opc-summary-title-wrapper.opc-summary-title-collapsible
//   span.opc-summary-title-closed
//   span.opc-summary-title-open
//

.opc-summary-title-wrapper {
	@include bold-text();
	align-items: center;
	background: map_get($lfi-palette, snow);
	border: rem-calc(1) solid map_get($lfi-palette, light-gray);
	border-radius: rem-calc(10);
	cursor: pointer;
	display: flex;
	justify-content: center;
	line-height: 1;
	padding: rem-calc(20 0);
	width: 100%;

	@include breakpoint(tablet) {
		padding: rem-calc(50 0 0 0);
	}

	span {
		font-size: rem-calc(16);
		position: relative;

		@include breakpoint(tablet) {
			font-size: rem-calc(18);
		}
	}
}

.opc-summary-title-collapsible {
	@include breakpoint(tablet) {
		display: none;
	}

	span::after {
		@include svg-icon('../icons/icon-chevron-up.svg');
		position: absolute;
		right: rem-calc(-18);
		top: rem-calc(2);
	}
}
.opc-summary-title-open {
	display: none;
}

span.opc-summary-title-closed::after {
	mask-image: url('../icons/icon-chevron-down.svg');
}

.opc-summary-title-non-collapsible {
	border-bottom: rem-calc(1) solid transparent;
	border-radius: rem-calc(10 10 0 0);
	cursor: auto;

	@include breakpoint(medium down) {
		display: none;
	}
}

.opc-sidebar-collapsible {
	@include breakpoint(medium down) {
		margin-bottom: rem-calc(40);
	}

	&.active {
		.opc-summary-title-wrapper {
			border-bottom: rem-calc(1) solid transparent;
			border-radius: rem-calc(10 10 0 0);
		}
		.opc-summary-title-closed {
			display: none;
		}

		.opc-summary-title-open {
			display: block;
		}
	}
}
