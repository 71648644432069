/**
 * Informational text blocks
 */
%newleaf-base-text-block {
	--newleaf-slider-information-margin: 24px 16px 8px;

	align-content: space-around;
	display: grid;
	margin: var(--newleaf-slider-information-margin);

	@include media-breakpoint-up(tablet) {
		--newleaf-slider-information-margin: 0 40px;
	}

	.icon-bag::before {
		font-size: 24px;
		vertical-align: bottom;
	}
}

.newleaf-slider-information {
	@extend %newleaf-base-text-block;
}

%newleaf-text-block {
	@extend %newleaf-base-text-block;

	@include media-breakpoint-up(xl) {
		--newleaf-slider-information-margin: 0 64px;
	}
}

.newleaf-standalone-link {
	@extend %newleaf-text-block;
	margin-right: 0;
}

.newleaf-text-block {
	@extend %newleaf-text-block;
}

.newleaf-img-block {
	img {
		@extend %img-fills-container;
	}
}
