///
// Currently used in checkout and in customer account address book
// ----
// See /customer/address for markup example
//
@mixin address-card($border-color: map_get($lfi-palette, light-gray)) {
	@include xy-cell();
	border: rem-calc(1) solid $border-color;
	border-radius: rem-calc(5);
	margin-bottom: rem-calc(25);
	padding: rem-calc(31 15 15 15);
	text-align: left;

	.action {
		@include button-secondary;
		@include button-expand;
		margin-top: rem-calc(15);
	}

	p {
		line-height: rem-calc(23);
		margin-bottom: rem-calc(3);

		&:last-of-type {
			margin-bottom: rem-calc(15);
		}
	}
}

@mixin account-address-grid() {

	.column.main {
		@include media-breakpoint-up(lg) {
			max-width: $account-grid-max-width;
		}
	}

	.main {
		.block-content {
			@include media-breakpoint-up(md) {
				column-gap: $account-grid-column-gap;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}
