//
// Checkout Address Forms
//

.shipping-address-items {
	display: flex;
	flex-flow: row wrap;
	margin-top: rem-calc(30); // override normal margin for logged in addresses
}

.checkout-address-item {
	@include address-card();
	position: relative;

	&.selected-item {
		border-color: $base-color;
		margin-top: rem-calc(16);

		.action-select-shipping-item {
			display: none;
		}
	}
}

.selected-shipping-address-heading {
	@include bold-text();
	left: 0;
	position: absolute;
	text-align: center;
	top: rem-calc(-16);
	width: 100%;

	> span {
		background: $base-color;
		border-color: $base-color;
		border-radius: rem-calc(4);
		color: $white;
		display: block;
		font-size: rem-calc(14);
		left: 0;
		line-height: rem-calc(20);
		margin: 0 auto;
		padding: rem-calc(5 0);
		width: rem-calc(89);
	}
}

.billing-address-items {
	display: flex;
}

.billing-address-item {
	margin-bottom: rem-calc(24);
	padding-top: rem-calc(16);
}

.checkout-shipping-address {
	margin-bottom: rem-calc(40);
}

.billing-address-form,
.form-shipping-address {
	.choice {
		margin: rem-calc(16 0 30 0);
	}

	[name$=".country_id"] {
		display: none;
	}
}

//
// Customer Login
// ------------
// div.checkout-account-login-actions
//  button.checkout-account-login
//  p.checkout-login-instructions
//  a.checkout-forgot-password

.checkout-account-login-actions {
	text-align: center;
}

.checkout-account-login,
.checkout-login-instructions,
.checkout-forgot-password {
	@include button-expand;
}

.checkout-login-instructions,
.checkout-forgot-password {
	display: block;
	font-size: rem-calc(14);
	line-height: rem-calc(20);
}

.checkout-account-login {
	@include button-secondary;
	margin-bottom: rem-calc(20);
}

.checkout-login-instructions {
	padding-bottom: rem-calc(14);
}

.checkout-forgot-password {
	padding-bottom: rem-calc(14);
}

//
// Marketing Consent
//

.form-shipping-address [name$='.kl_email_consent'] {
	input[type="checkbox"] {
		zoom: 1.4;
	}

	label {
		color: map-get($lfi-palette, metal);
		line-height: rem-calc(20) !important; // override float label
		margin-left: rem-calc(12) !important; // override float label
		margin-right: 0 !important; // override float label
		white-space: normal;
	}
}

//
// Billing Address
// ---------------
//

.checkout-billing-address {
	.actions-toolbar {
		display: flex;
		margin-bottom: rem-calc(24);
	}
}

.billing-address-same-as-shipping-block {
	display: flex;
	justify-content: center;
	margin-bottom: rem-calc(24);

	label {
		font-size: rem-calc(14) !important; // overriding float label
		line-height: rem-calc(20) !important;
		margin-right: 0;
	}
}

.billing-section-title {
	margin-top: rem-calc(5);
}

.billing-address-fieldset {
	margin-bottom: rem-calc(30);
}

.field-select-billing {
	> label {
		display: none;
	}
}
