// Specific style overrides for Yotpo lightbox elements
// stylelint-disable -- Because the CSS from the widget is hot garbage

.yotpo-modal,
.yotpo-modal-base {
	.yotpo-modal-mask.yotpo-active-display {
		background: rgba(43, 42, 39, 0.8) !important; // jet but with opacity
	}

	.yotpo-lightbox-arrows > span {
		border-radius: 50%;
		padding: 0 !important;
		text-align: center;
	}

	.yotpo-icon-left-arrow-thin {
		@include breakpoint($yotpo-desktop-breakpoint) {
			left: rem-calc(-50) !important;
		}

		&:before {
			@include svg-icon('../icons/icon-chevron-left.svg', 24px, 24px);
		}
	}

	.yotpo-icon-right-arrow-thin {
		@include breakpoint($yotpo-desktop-breakpoint) {
			right: rem-calc(-50) !important;
		}

		&:before {
			@include svg-icon('../icons/icon-chevron-right.svg');

			width: 1rem;
		}
	}

	input {
		margin: unset !important;
	}
}

.yotpo-modal-dialog {
	.yotpo-modal-title {
		border-bottom: none !important;
		color: #000 !important;
		font-size: rem-calc(28) !important;
		line-height: rem-calc(32);
		margin-top: rem-calc(38) !important;
		padding-bottom: 0 !important;

		.image-upload-flow-popup.thank_you & {
			margin-top: rem-calc(160) !important;
			padding-bottom: 1rem !important;
		}
	}

	.yotpo-modal-subtitle {
		color: #000 !important;
		font-size: rem-calc(18) !important;
		line-height: rem-calc(26);
	}

	.yotpo-caption-modal-main-content {
		margin-top: rem-calc(48);
	}

	.yotpo-modal-user-details-content {
		margin-top: 1rem;
	}
}

.yotpo-lightbox-container {
	.yotpo-lightbox.yotpo-lightbox-new.yotpo .yotpo-modal.yotpo-modal-active & {
		.yotpo-lightbox-arrows .yotpo-icon {
			&.yotpo-icon-left-arrow-thin,
			&.yotpo-icon-right-arrow-thin {
				background-color: $white !important;
				color: $base-color !important;
				height: rem-calc(32) !important;
				line-height: rem-calc(42);
				width: rem-calc(32) !important;

				&:hover {
					background: get-active-button-color($white) !important;
				}
			}
		}
	}

	.yotpo-icon-profile {
		height: rem-calc(40) !important;
		text-align: center !important;
		width: rem-calc(40) !important;

		.yotpo-user-letter {
			font-size: rem-calc(20) !important;
			line-height: rem-calc(40) !important;
			position: relative !important;
			top: 0 !important;
		}
	}

	.yotpo-user-name {
		font-size: rem-calc(18) !important;
		font-weight: $font-weight-bold !important;
		line-height: rem-calc(40) !important;
	}

	.yotpo-review-date {
		display: none !important;
	}

	.yotpo-instagram .yotpo-header-element {
		padding-top: unset !important;

		.yotpo-instagram-location {
			display: none;
		}
	}
}

.yotpo-lightbox-content-container {
	padding: rem-calc(60 30) !important;

	@include breakpoint($yotpo-desktop-breakpoint) {
		padding-top: rem-calc(150) !important;
	}

	.yotpo-instagram,
	.yotpo-review {
		border-bottom: none;
		border-top: rem-calc(1) solid map-get($lfi-palette, light-gray);
		padding: rem-calc(40 0) !important;

		.yotpo-header {
			margin: unset !important;
		}
	}

	.yotpo-review-stars {
		margin-top: 1rem !important;
	}
}

.yotpo-lightbox-product {
	display: flex;
	gap: rem-calc(28);
	margin-top: 1rem;

	.score-title {
		font-family: $yotpo-score-font !important;
		font-size: rem-calc(30) !important;
	}

	.rating-star {
		line-height: initial !important;
		vertical-align: baseline !important;

		&:before,
		&:after {
			height: rem-calc(23) !important;
			width: rem-calc(23) !important;
		}

		&.yotpo-icon-half-star:after {
			top: rem-calc(-10) !important;
		}
	}

	.yotpo-lightbox-product-name {
		margin: rem-calc(8 0) !important;
		overflow: unset !important;
		text-overflow: unset !important;
		white-space: unset !important;
	}
}

.yotpo-lightbox-mobile-content {
	padding: rem-calc(25 16) !important;
}

.yotpo-lightbox-product-details {
	margin-left: 0 !important;
}

.yotpo-lightbox-product-main-image {
	border: none !important;
}

.yotpo-lightbox-product-name {
	@include font-headlines($font-family: $header-font-family);

	font-size: rem-calc(20) !important;
	width: auto !important;
}

.yotpo-lightbox-product-button {
	@include button-oval-adjustment($border-radius-adjustment: rem-calc(24) !important, $line-height-radius-adjustment: rem-calc(46) !important);

	height: rem-calc(46) !important;
	margin-top: rem-calc(6);
}

//
// Image Upload Dialog
//

.image-upload-flow-popup.upload_images,
.image-upload-flow-popup.user_details,
.image-upload-flow-popup.thank_you {
	// Super nesting for selector wars
	.yotpo-modal-dialog {
		.yotpo-modal-content {
			padding: 0 !important;
			position: relative;

			.yotpo-modal-title {
				font-family: $header-font-family !important;
			}

			.yotpo-modal-subtitle {
				font-family: $body-font-family !important;
			}

			.yotpo-caption-modal-main-content .yotpo-upload-photos {
				margin-top: 0 !important;

				.yotpo-upload-button .yotpo-icon-upload-photo {
					color: $base-color !important;
				}

				.yotpo-caption-modal-textarea {
					display: block !important;
					float: right;
					font-family: $body-font-family !important;
					margin-right: rem-calc(20);
					width: rem-calc(252);

					&::placeholder {
						font-family: $body-font-family;
						text-transform: none;
					}
				}
			}

			.user_details_back {
				left: 0 !important;
				position: absolute;
				top: 0 !important;
			}

			.yotpo-caption-modal-bottom,
			.yotpo-modal-submit-button-flex-box-wrapper {
				bottom: 0;
				float: none;
				padding: rem-calc(24 18);
				position: fixed;
				width: 100%;

				.yotpo-add-more {
					display: none;
				}

				.yotpo-next-button,
				.yotpo-modal-submit-button {
					@include button-primary();
					@include button-typography();
					@include button-expand();
					@include button-oval-adjustment($border-radius-adjustment: rem-calc(24) !important, $line-height-radius-adjustment: rem-calc(46) !important);

					height: rem-calc(46);

					span {
						@include bold-text();

						color: $white;
					}

					.yotpo-next-submit {
						margin-top: 0 !important;

						.yotpo-next-label {
							margin: unset !important;
						}

						.yotpo-icon-right-arrow-2 {
							margin: rem-calc(2) !important;
						}
					}

					.yotpo-modal-submit-label {
						margin-top: 0 !important;
					}
				}
			}

			.yotpo-modal-user-details {
				margin-left: unset !important;
				margin-top: 1rem !important;
			}

			.yotpo-modal-user-details-content .validation-message-container .validation-message {
				margin-right: 0 !important;
			}

			.yotpo-modal-user-detail-input {
				font-family: $body-font-family !important;
				padding: rem-calc(0 24);
				width: 100%;

				.yotpo-user-details-modal-input {
					width: 100%;

					&::placeholder {
						font-family: $body-font-family;
						text-transform: none;
					}
				}
			}

			.yotpo-modal-close {
				bottom: 0;
				margin-top: 0 !important;
				padding: rem-calc(24 18);
				position: fixed;
				width: 100%;

				.close-button {
					@include button-primary();
					@include button-typography();
					@include button-expand();
					@include button-oval-adjustment($border-radius-adjustment: rem-calc(24) !important, $line-height-radius-adjustment: rem-calc(46) !important);

					height: rem-calc(46);
					margin-bottom: 0 !important;
					position: relative;

					.yotpo-modal-close-label {
						@include bold-text();

						color: $white;
						margin-top: 0 !important;
					}
				}
			}
		}
	}

	.yotpo-caption-modal-main-content {
		border-bottom: none !important;
		display: flex !important;
		flex-direction: column;
		gap: rem-calc(24);
		padding: 0 !important;
	}
}
