.modal-custom,
.modal-popup,
.modal-slide {
	&._inner-scroll._show {
		-webkit-overflow-scrolling: touch;
	}
}

.reveal-modal {
	display: none;
}
