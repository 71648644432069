/*
 * Account Pagers
 * :( The page on the Order page has different HTML than the one on the employee page
 */
/* stylelint-disable-next-line */
.account {

	.toolbar,
	.pager {
		align-items: center;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		@include media-breakpoint-up(md) {
			flex-wrap: nowrap;
		}
	}

	.toolbar {
		margin-bottom: rem-calc(56);
		margin-top: rem-calc(16);

		@include media-breakpoint-up(md) {
			margin-bottom: rem-calc(40);
		}

		&:before {
			display: none;
		}

		label,
		.toolbar-number,
		.label {
			color: $base-color;
			font-size: rem-calc(16);
			line-height: rem-calc(20);
		}

		.limiter-label,
		.limiter-options,
		.control,
		.label {
			margin-right: rem-calc(8);
		}
	}

	.limiter {
		align-items: center;
		display: flex;

		strong {
			font-family: $body-font-family;
		}
	}

	.order-products-toolbar {
		.pager {
			width: 100%;
		}
	}

	.limiter-options {
		margin-bottom: 0;
	}

	.limiter-text {
		white-space: nowrap;
	}

	.pages {
		.page {
			color: $base-color;
			font-size: rem-calc(16);
			font-weight: $font-weight__regular;
			line-height: rem-calc(20);
		}

		.action {
			border: none;
			margin: 0;

			&:before {
				font-size: rem-calc(36);
				margin: rem-calc(-1) 0 0 0;
			}
		}
	}
}

// End Account Pager

/*
 * Account Tables
 */
/* stylelint-disable-next-line */
.account {

	table {
		a {
			@include bold-text;
		}
	}

	table,
	.table-wrapper, {
		margin-bottom: 0;
	}

	th {
		// Important due to some very specific styling
		border: none !important;
		color: $body-font-color;
	}

	th,
	td {
		line-height: rem-calc(20);
		padding: rem-calc(16) rem-calc(8);

		&:first-of-type {
			padding-left: 0;
		}

		&:last-of-type {
			padding-right: 0;
		}
	}

	// override foundation table styles
	table tr {
		border-bottom: rem-calc(1) solid $light-gray;
	}

	thead,
	tbody {
		border: none;
	}

	// Need to override specific Magento table styles
	td::before {
		@include bold-text;
		color: $body-font-color !important;
	}
}

// End Account Tables
