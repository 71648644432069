.catalog-nav-support {
	.catalog-menu__inner-list--level1 {
		@include breakpoint(small) {
			padding: rem-calc(50) 5vw;
		}

		@include breakpoint(large) {
			padding: rem-calc(50) 14vw;
		}

		@include breakpoint(xlarge) {
			padding: rem-calc(50) 18vw;
		}

		@include breakpoint(xxlarge) {
			padding: rem-calc(50) 25vw;
		}

		& > li {
			border: none;
			padding: 0;
		}

		&:not(:last-of-type) {
			.nav-support-block {
				margin-left: auto;
			}
		}
	}

	.catalog-menu__inner-item--level1 {
		&:not(:first-of-type) {
			margin-left: rem-calc(20);
		}

		&:not(:last-of-type) {
			.nav-support-block {
				margin-left: auto;
			}
		}
	}
}

.nav-support-block {
	position: relative;
	height: rem-calc(148);
	max-width: rem-calc(220);
	width: 100%;

	h6 {
		color: $white;
		min-width: rem-calc(170);
		text-align: center;
		z-index: 2;
	}

	a {
		z-index: 3;
	}
}

.nav-support-image {
	align-items: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	height: 100%;
	position: relative;
	width: 100%;

	div {
		background-color: $overlay-background-color;
		z-index: 1;
	}
}
