// https://foundation.zurb.com/sites/docs/xy-grid.html#grid-container-full
body {
	overflow-x: hidden;
}

// Override foundation CSS
// The Reveal overlay is position: fixed, so if there are elements which are overflowing the page
// and the modal doesn't take up the full page, there is a bump in the background as the
// Foundation style is applied.
html.is-reveal-open {
	overflow-y: unset !important;

	body {
		overflow-y: scroll;
	}
}

// Override browser decision to make address' italic
address {
	font-style: normal;
}

// Custom Heading Sizes for the <p> element
p {
	&.large {
		font-size: rem-calc(20);
	}

	&.x-large {
		@include scaled-header();
	}

	// todo - too global, remove and re-style
	&:last-of-type {
		margin-bottom: 0;
	}
}
