@import "../../../default/styles/globals/fonts";

$body-font-family: "DIN W01 Regular", "Helvetica Neue", helvetica, arial,
	sans-serif;
$header-font-family: "DIN W01 Black", "Helvetica Neue", helvetica, arial,
	sans-serif;
$body-secondary-font-bold: "Windsor W01 Bold", "Helvetica Neue", helvetica,
	arial, sans-serif;
$header-font-regular: "DIN W01 Regular", "Helvetica Neue", helvetica, arial,
	sans-serif;
$header-font-light: "DIN W01 Light", "Helvetica Neue", helvetica, arial,
	sans-serif;
$header-font-condensed-black: "DIN W01 Cond Black", "Helvetica Neue", helvetica,
	arial, sans-serif;
$header-font-condensed-bold: "DIN W01 Cond Bold", "Helvetica Neue", helvetica,
	arial, sans-serif;
$header-font-condensed-medium: "DIN W01 Cond Medium", "Helvetica Neue",
	helvetica, arial, sans-serif;
$header-font-bold: "DIN W01 Bold", "Helvetica Neue", helvetica, arial,
	sans-serif;
$body-font-bold: $header-font-bold;
$header-link-context-font: $body-font-family;
