//
// Content Components
//

.customer-popup-launch-container {
	align-items: center;
	background: map-get($lfi-palette, jet);
	border-radius: rem-calc(37.5);
	bottom: rem-calc(18);
	/* stylelint-disable-next-line */
	box-shadow: rem-calc(0 10 20 0) rgba(0, 0, 0, 0.3);
	display: none;
	left: rem-calc(10);
	overflow: hidden;
	position: fixed;
	z-index: 5;

	@include breakpoint(medium) {
		bottom: rem-calc(25);
		left: rem-calc(25);
	}

	.login-prompt-visible:not(.customer-account-logoutsuccess) & {
		display: flex;
	}

	.customer-popup-launch {
		@include bold-text;
		flex: 0 0 auto;
		padding-left: rem-calc(20);
		padding-right: rem-calc(20);
	}

	.name {
		text-transform: capitalize;
	}

	.action-close {
		color: $white;
		margin: 0;
		padding-right: rem-calc(20);

		&:before {
			font-size: rem-calc(16);
		}
	}

	button {
		text-transform: none;

		&:hover {
			background-color: inherit;
			color: get-active-button-color($white);
		}
	}
}

// We already have the floating checkout drawer on mobile
// so remove floating login button
.checkout-cart-index {
	.customer-popup-launch-container {
		@include breakpoint(small down) {
			display: none;
		}
	}
}

.customer-popup-messages {
	@include inline-messages();

	.message {
		margin-bottom: rem-calc(15);
		text-align: center;

		&:before {
			display: block;
			margin-bottom: rem-calc(5);
		}
	}
}

.customer-popup-success-content {
	margin: auto;
	text-align: center;

	.button {
		margin-bottom: 0;
	}
}

.customer-popup-form {
	.submit {
		margin-bottom: rem-calc(40);
	}

	p {
		margin-bottom: rem-calc(30);
	}

	h4 {
		margin-bottom: rem-calc(15);
	}
}

//
// Buttons and Links
//

.login-to-forgotpassword-link {
	display: block;
	margin-bottom: rem-calc(30);
}

.customer-popup-content {
	.button {
		@include breakpoint(small down) {
			@include button-oval-adjustment(rem-calc(24), rem-calc(48));
		}
	}
}
