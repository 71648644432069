// Recrafting Slider
// ----------
// .recrafting-slider.wrapper
//  .recrafting-slider
//   .recrafting-slide
//  .recrafting-slider-pager

// @note - Using min-height to prevent content jumping
// Values calculated as a combination of padding, margin, and image ratio
.recrafting-slider-wrapper {
	min-height: calc(100vw - 76px);
	text-align: left;

	@include media-breakpoint-up(lg) {
		min-height: calc((100vw / 2) - 14px);
	}

	@include media-breakpoint-up(xxl) {
		min-height: rem-calc(703);
	}

	.recrafting-slider-nav {
		display: none;

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}

	// line-height reset
	div {
		line-height: 1;
	}
}

// stop content jump
.slick-slider {
	.recrafting-slide {
		display: inline-block !important;
	}
}

.recrafting-slider {
	margin-bottom: rem-calc(24);

	// stop content jump
	&:not(.slick-slider) {
		@include media-breakpoint-down(lg) {
			display: flex;
			padding: rem-calc(0 20);
		}

		.recrafting-slide {
			@include media-breakpoint-down(lg) {
				flex: 0 0 100%;
			}
		}
	}

	// stop content jump
	.recrafting-slide:nth-of-type(n + 2) {
		display: none;
	}

	.slick-arrow {
		@include icon-circle($c: $body-font-color, $dia: 48px, $hover-color: map-get($lfi-palette, light-gray-hover));
		bottom: rem-calc(16);
		padding: 0;
		position: absolute;
		top: unset;
		transform: none;
		transition: all 0.25s ease-in-out;
		z-index: 2;

		&:before {
			height: 1rem;
			width: 1rem;
		}
	}

	.recrafting-slider-next {
		right: rem-calc(16);
	}

	.recrafting-slider-previous {
		left: unset;
		right: rem-calc(80);
	}

	.slick-disabled {
		background: map-get($lfi-palette, snow) !important;
		color: get-disabled-color(map-get($lfi-palette, jet));
		cursor: not-allowed;
	}
}

.recrafting-slide {
	padding: rem-calc(0 4);
	position: relative;

	@include media-breakpoint-up(lg) {
		padding: 0;
	}

	img {
		border-radius: rem-calc(8);
	}
}

.recrafting-slider-nav {
	display: none;

	.slick-slide {
		cursor: pointer;
		margin-right: rem-calc(6);
		width: rem-calc(64) !important;
	}

	.slick-current {
		.recrafting-slide {
			border-color: map-get($lfi-palette, cream);
		}
	}

	.recrafting-slide {
		border: rem-calc(0.5) solid map-get($lfi-palette, light-gray);
		display: inline-block;
		padding: rem-calc(2);
	}

	img {
		border-radius: 0;
		max-width: rem-calc(60);
		width: 100%;
	}
}
