:root {
	--lfi-accordion-collapse-transition: height 300ms ease-out;
	--lfi-accordion-delay-per-item: 50ms;
	--lfi-accordion-item-base-delay: 0ms;
	--lfi-accordion-item-initial-oapcity: 0;
	--lfi-accordion-item-initial-transform: translateY(0);
	--lfi-accordion-opacity-transition: opacity 500ms ease-out;
	--lfi-accordion-transform-transition: transform 200ms ease-out;
	--lfi-nav-content-transition-duration: 250ms;
	--lfi-nav-content-transition-delay: 0ms;
	--lfi-nav-content-transition-end-left-position: 0;
	--lfi-nav-content-transition-end-opacity: 1;
	--lfi-nav-content-transition-initial-left-position: 8px;
	--lfi-nav-content-transition-initial-opacity: 0;
	--lfi-nav-submenu-transition-duration: 200ms;
	--lfi-nav-submenu-transition-delay: 0ms;
	--lfi-nav-submenu-content-transition-delay-fudge-ms: -50ms;
	--lfi-nav-flex-container-flow: column;
	--lfi-nav-flex-container-gap: 0;
	--lfi-nav-menu-padding-top: 24px;
	--lfi-nav-link-color: #{map-get($lfi-palette, jet)};
	--lfi-nav-link-font-size: 18px;
	--lfi-nav-link-font-family: inherit;
	--lfi-nav-link-letter-spacing: inherit;
	--lfi-nav-link-line-height: 26px;
	--lfi-nav-link-text-transform: none;
	--lfi-nav-top-link-position: relative;

	@include media-breakpoint-up(tablet) {
		--lfi-nav-link-line-height: 20px;
	}

	@media (prefers-reduced-motion) {
		--lfi-accordion-collapse-transition: none;
		--lfi-accordion-item-initial-oapcity: 1;
		--lfi-accordion-item-initial-transform: translateY(0);
		--lfi-nav-content-transition-initial-left-position: 0px;
	}
}

%offcanvas-nav-content-transitions {
	--left-position: var(--lfi-nav-content-transition-initial-left-position);
	--opacity: var(--lfi-nav-content-transition-initial-opacity);
	--position: relative;
	--transition-properties: opacity, left;
	left: var(--left-position);
	opacity: var(--opacity);
	position: var(--position);
	transition-delay: var(--lfi-nav-content-transition-delay);
	transition-duration: var(--lfi-nav-content-transition-duration);
	transition-property: var(--transition-properties);

	@at-root .show:not(.hiding) > & {
		--left-position: var(--lfi-nav-content-transition-end-left-position);
		--opacity: var(--lfi-nav-content-transition-end-opacity);
	}

	// reset transitions at layout swap
	@include media-breakpoint-up(tablet) {
		--left-position: var(--lfi-nav-content-transition-end-left-position);
		--opacity: var(--lfi-nav-content-transition-end-opacity);
		--transition-properties: none;
	}
}

%offcanvas-nav-submenu-content-transitions {
	--lfi-nav-content-transition-delay: calc(var(--lfi-nav-submenu-transition-duration) + var(--lfi-nav-submenu-content-transition-delay-fudge-ms));

	@extend %offcanvas-nav-content-transitions;

	@at-root .hiding & {
		--lfi-nav-content-transition-delay: 0;
	}
}

.offcanvas-nav,
.offcanvas-nav-submenu {
	--bs-offcanvas-width: 80%;
	--max-width: 320px;
	max-width: var(--max-width);
	overflow-x: hidden;
	overflow-y: auto;

	@include media-breakpoint-up(tablet) {
		--max-width: unset;
	}

	+ .offcanvas-backdrop.show {
		backdrop-filter: blur(2px);
		opacity: 1;
	}
}

.offcanvas-nav {
	.offcanvas-header {
		--bs-offcanvas-padding-x: 32px;
		--bs-offcanvas-padding-y: 24px;
		justify-content: flex-end;
		margin-top: 16px;
	}

	&.submenu-active::before {
		backdrop-filter: blur(2px);
		background: $offcanvas-backdrop-bg;
		bottom: 0;
		content: '';
		display: block;
		height: calc(100vh - 100%);
		left: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 100%;
	}
}

.offcanvas-nav .offcanvas-nav-icon-button {
	--lfi-nav-link-font-size: 16px;
	--lfi-nav-link-line-height: 18px;
	display: flex;
	flex-basis: fit-content;
	flex-grow: 0;
	gap: 4px;
	margin-bottom: 0;

	&::before {
		font-size: 20px;
	}
}

%lfi-nav-flex-container {
	display: flex;
	flex-flow: var(--lfi-nav-flex-container-flow);
	gap: var(--lfi-nav-flex-container-gap);
}

.offcanvas-nav-menu {
	--lfi-nav-flex-container-flow: column;
	--lfi-nav-flex-container-gap: 40px;

	@extend %lfi-nav-flex-container;
	@extend %offcanvas-nav-content-transitions;
	flex-grow: 1;
	padding-top: var(--lfi-nav-menu-padding-top);

	@include media-breakpoint-up(tablet) {
		--lfi-nav-flex-container-flow: row;
		--lfi-nav-flex-container-gap: 0;
		--lfi-nav-menu-padding-top: 0;
		--position: static;
	}
}

%offcanvas-padded-menu {
	--lfi-nav-menu-item-padding-bottom: 0;
	--lfi-nav-menu-item-padding-left: 32px;
	--lfi-nav-menu-item-padding-right: 32px;
	--lfi-nav-menu-item-padding-top: 0;

	// stylelint-disable declaration-block-no-redundant-longhand-properties
	padding-bottom: var(--lfi-nav-menu-item-padding-bottom);
	padding-left: var(--lfi-nav-menu-item-padding-left);
	padding-right: var(--lfi-nav-menu-item-padding-right);
	padding-top: var(--lfi-nav-menu-item-padding-top);
	// stylelint-enable

	@include media-breakpoint-up(tablet) {
		--lfi-nav-menu-item-padding-bottom: 0;
		--lfi-nav-menu-item-padding-left: 0;
		--lfi-nav-menu-item-padding-right: 0;
	}

	&:last-child {
		--lfi-nav-menu-item-padding-bottom: 60px;
	}
}

.offcanvas-nav-menu-item {
	@extend %offcanvas-padded-menu;

	&:last-child {
		flex-grow: 1;
	}

	&.offcanvas-nav-account-links {
		--lfi-nav-menu-item-padding-top: 44px;
	}

	> ul {
		--lfi-nav-flex-container-gap: 24px;

		@extend %lfi-nav-flex-container;

		@include media-breakpoint-up(tablet) {
			--lfi-nav-flex-container-flow: row;
			--lfi-nav-flex-container-gap: 0;
		}
	}
}

.offcanvas-nav-submenu {
	--bs-offcanvas-transition: opacity var(--lfi-nav-submenu-transition-duration) ease-in-out var(--lfi-nav-submenu-transition-delay);
	--lfi-nav-link-font-size: 18px;
	--opacity: 0;

	align-content: start;
	flex-flow: row wrap;
	opacity: var(--opacity);
	transform: none !important;

	@include media-breakpoint-up(tablet) {
		--lfi-nav-link-font-size: 16px;
		--opacity: 1;
	}

	&.showing,
	&.show:not(.hiding) {
		--opacity: 1;
	}

	&.hiding {
		transition-delay: var(--lfi-nav-content-transition-duration);
	}

	> .offcanvas-header {
		flex-basis: 50%;
		height: max-content;
		justify-content: end;
	}

	@at-root .hiding & {
		--bs-offcanvas-width: 100%;
	}
}

.offcanvas-header.offcanvas-submenu-dismiss-container {
	@extend %offcanvas-nav-submenu-content-transitions;
	justify-content: start;
}

.offcanvas-nav-submenu-list {
	--lfi-nav-flex-container-gap: 16px;

	@extend %offcanvas-padded-menu;
	@extend %lfi-nav-flex-container;
	@extend %offcanvas-nav-submenu-content-transitions;
	flex: 100% 0 0;
	padding-top: var(--lfi-nav-menu-padding-top);

	@include media-breakpoint-up(tablet) {
		--lfi-nav-flex-container-gap: 24px;
		--position: absolute;
		background: #fff;
		justify-content: center;
		left: 0;
		padding: 40px 88px;
		right: 0;

		@at-root .event-target:not(.active) & {
			display: none;
		}
	}
}

.offcanvas-nav-submenu-list-item {
	--lfi-nav-flex-container-flow: column;
	--lfi-nav-flex-container-gap: 8px;
	--lfi-nav-flex-item-align-self: stretch;

	@extend %lfi-nav-flex-container;
	align-self: var(--lfi-nav-flex-item-align-self);
	border: 0 solid #{map-get($lfi-palette, light-gray)};

	@include media-breakpoint-up(tablet) {
		--lfi-nav-flex-item-align-self: start;
		border-left-width: 1px;
		flex: 1 0 0;
		height: 100%;
		max-width: 212px;
		padding: 0 0 0 16px;

		&:first-child {
			border-left-color: transparent;
		}
	}

	&.highlight-group {
		--padding-bottom: 16px;
		padding-bottom: var(--padding-bottom);

		@include media-breakpoint-up(tablet) {
			--padding-bottom: 0;
		}
	}

	ul,
	ol { // custom template build for catalog-menu uses ol, vanilla Snowdog Menu uses ul, support both for now
		--lfi-nav-flex-container-flow: column;
		--lfi-nav-flex-container-gap: 8px;

		@extend %lfi-nav-flex-container;
	}
}

.offcanvas-nav-menu a {
	align-items: center;
	color: var(--lfi-nav-link-color);
	display: flex;
	font-family: var(--lfi-nav-link-font-family);
	font-size: var(--lfi-nav-link-font-size);
	gap: 8px;
	letter-spacing: var(--lfi-nav-link-letter-spacing);
	line-height: var(--lfi-nav-link-line-height);
	text-transform: var(--lfi-nav-link-text-transform);
}

.offcanvas-nav-category-link {
	@extend %offcanvas-nav-large-category-link;
	@extend %offcanvas-top-link-tablet-styles;
}

.offcanvas-nav-secondary-link {
	@extend %offcanvas-top-link-tablet-styles;

	> a {
		--lfi-nav-link-font-size: 18px;
		--lfi-nav-link-line-height: 24px;

		@include bold-text;
	}
}

%offcanvas-nav-large-category-link {
	> a {
		--lfi-nav-link-font-size: 24px;
		--lfi-nav-link-line-height: 32px;
	}
}

%offcanvas-top-link-tablet-styles {
	position: var(--lfi-nav-top-link-position);

	@include media-breakpoint-up(tablet) {
		--lfi-nav-top-link-position: static;
		padding: 0 12px;

		> a {
			--lfi-nav-link-font-size: 14px;
			--lfi-nav-link-text-transform: uppercase;
			--lfi-nav-link-line-height: #{$header-container-height};
		}
	}
}

.offcanvas-nav-submenu-large-link {
	@extend %offcanvas-nav-large-category-link;
	> a {
		@include media-breakpoint-up(tablet) {
			--lfi-nav-link-font-size: 20px;
			--lfi-nav-link-line-height: 24px;
			--lfi-nav-link-text-transform: uppercase;
		}
	}
}

.offcanvas-nav-account-link {
	--icon-size: 24px;
	a {
		--lfi-nav-link-line-height: var(--icon-size);
		--lfi-nav-flex-container-gap: 8px;
		--lfi-nav-flex-container-flow: row;
		--lfi-nav-link-font-size: 16px;

		@extend %lfi-nav-flex-container;
	}

	[class*="icon-"] {
		display: flex; // prevent height weirdness caused by static positioning
		&::before {
			font-size: var(--icon-size);
		}
	}

	&.catalog-menu-returns-link a::before {
		margin-right: 0;
	}

	&.offcanvas-nav-ship-to a:before {
		@include svg-icon('../icons/icon-location-hollow.svg', var(--icon-size), var(--icon-size));
	}
}

// styles for interactive rows in the offcanvas menu
// renders as ([thing][thing][   icon]) typically
%offcanvas-nav-ui-control {
	display: flex;
	justify-content: space-between;

	@include media-breakpoint-up(tablet) {
		display: none;
	}

	[class^="icon-"],
	[class*=" icon-"] {
		flex-grow: 1;
		text-align: right;
	}
}

a.offcanvas-nav-open-submenu {
	@extend %offcanvas-nav-ui-control;
}

a.offcanvas-nav-accordion-toggle {
	--lfi-nav-link-font-size: 20px;
	--lfi-nav-link-line-height: 28px;

	@extend %offcanvas-nav-ui-control;

	&[aria-expanded="true"] {
		.icon-plus {
			display: none;
		}
	}

	&[aria-expanded="false"] {
		.icon-subtract {
			display: none;
		}
	}
}

// at viewports narrower than tablet (i.e. offcanvas mode), hide the first anchor in a grouping
// it's been replaced by a submenu trigger
.offcanvas-nav [class*="-item--parent"] > a:first-child {
	display: none;

	@include media-breakpoint-up(tablet) {
		display: initial;
	}
}

.offcanvas-nav-collapse {
	// ensure a collapse is always visible at tablet+ viewports
	@at-root .event-target.active & {
		@include media-breakpoint-up(tablet) {
			display: block;
		}
	}

	// override bootstrap's collapse hiding technique to allow transitions of children
	@at-root [aria-expanded="false"] + & {
		@include media-breakpoint-down(tablet) {
			display: initial;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
	}

	&.collapsing {
		transition: var(--lfi-accordion-collapse-transition);
	}

	// hide the first li child of a collapse–its content is duplicated and used for the trigger
	> ul > li {
		&:first-child {
			display: none;

			// ... but show the collapse at tablet+ because we don't collapse there
			@include media-breakpoint-up(tablet) {
				display: initial;
			}
		}

		&:last-child {
			padding-bottom: 24px;
		}
	}
}

// define transition for items within a collapse
.offcanvas-nav-collapse-item {
	@include media-breakpoint-down(tablet) {
		--lfi-accordion-item-index: 1;

		opacity: var(--lfi-accordion-item-initial-oapcity);
		transform: var(--lfi-accordion-item-initial-transform);

		@at-root [aria-expanded="true"] + .offcanvas-nav-collapse & {
			opacity: 1;
			transform: translateY(0);
			transition: var(--lfi-accordion-opacity-transition), var(--lfi-accordion-transform-transition);
			transition-delay: calc(var(--lfi-accordion-delay-per-item) * var(--lfi-accordion-item-index) + var(--lfi-accordion-item-base-delay));
		}
	}
}

// prevent empty node elements (i.e. from wrappers) from interfering with display
.offcanvas-nav [data-menu]:empty {
	display: none;
}
