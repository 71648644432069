// /*
//  * Product Details
//  * ---------------
//  * div.product-top
//  *  div.page-title-wrapper
//  *  div.product.media
//  *  div.product-info-main-wrapper
//  *   div.product-info-main
//  * div.product-details-container
//  *  div.product-detail.description
//  *  div.product-detail.product-attributes-container
//  *  div.product-detail.meet_the_family
//  */

.product-top {
	margin: 0 auto;
	max-width: $grid-container;
	width: 100%;

	@include breakpoint($screen__m) {
		display: grid;
		grid-template:
			"gallery title" auto
			"gallery color-group" auto
			"gallery shopbox" 1fr;
		grid-template-columns: percentage(divide(7, 12)) percentage(divide(5, 12));
		margin-bottom: rem-calc(30);
		padding: 0 $global-padding;
	}

	.product-info-head-wrapper {
		padding: rem-calc(0 9);
		text-align: left;

		@include breakpoint($screen__m) {
			grid-area: title;
			padding: 0;
		}
	}

	.media {
		overflow: hidden;
		padding: 0;

		@include breakpoint($screen__m) {
			grid-area: gallery;
			height: fit-content;
			margin-bottom: 0;
			padding-right: rem-calc(30);
			position: sticky;
			top: 55px;
		}
	}

	.product-info-main-wrapper {
		@include breakpoint($screen__m) {
			grid-area: shopbox;
		}
	}

	.product-info-main {
		overflow: hidden;
	}

	.page-title {
		margin-bottom: rem-calc(8);
	}

	.gender-sizing {
		color: map-get($lfi-palette, jet);
		font-family: $body-font-family;
		margin-bottom: rem-calc(8);
	}

	.gender-sizing,
	.subtitle {
		font-size: rem-calc(16);
		line-height: rem-calc(24);
	}
}

.product-details-container {
	@include breakpoint($screen__m) {
		border-top: rem-calc(2) solid map_get($lfi-palette, snow);
		margin-top: rem-calc(60);
	}
}

// Detailed Description
.product-detail.description {
	@include xy-grid-container();

	display: flex;
	justify-content: center;
	padding-bottom: rem-calc(45);
	padding-top: rem-calc(45);
	text-align: center;

	@include breakpoint($screen__m) {
		padding-bottom: rem-calc(60);
		padding-top: rem-calc(60);
	}

	h2,
	.essence-title {
		margin-bottom: rem-calc(30);
		text-transform: uppercase;
	}
}

// Attribute List
.product-attributes-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: rem-calc(15);

	@include breakpoint($screen__m) {
		@include xy-grid-container();
	}

	.cell {
		@include breakpoint($screen__m) {
			flex: 0 0 auto; // IE has a hard time with flex basis so just use width
			width: calc(50% - (15px * 0.5));
		}
	}
}

.product.info.detailed {
	margin-bottom: 0;
}
