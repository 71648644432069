/*
 * To be used on the img parent container
 * ex:
 * div.slide-container
 *  div.slide -> @include hover-zoom-image
 *   img
 */
@mixin hover-zoom-image() {
	--hover-image-animation: transform 300ms ease-in-out;
	--hover-image-transform: scale(105%);
	--hover-initial-image-transform: scale(1.01);
	overflow: hidden;

	@media (prefers-reduced-motion) {
		// Request from https://lacrossefootwear.atlassian.net/browse/OS-2764
		// Remove scale all-together
		--hover-image-animation: none;
		--hover-image-transform: none;
		--hover-initial-image-transform: none;
	}

	img {
		// Small bug with flickering img border on zoom is fixed
		// by adding a small initial zoom.
		transform: var(--hover-initial-image-transform);
		transition: var(--hover-image-animation);
	}

	&:hover {
		img {
			transform: var(--hover-image-transform);
		}
	}
}
