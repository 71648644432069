@mixin adjust-padding(
	$base: rem-calc(10 10),
	$medium: false,
	$large: false,
	$xlarge: false
) {
	padding: $base;

	@if $medium {
		@include breakpoint(medium) {
			padding: #{$medium};
		}
	}

	@if $large {
		@include breakpoint(large) {
			padding: #{$large};
		}
	}

	@if $xlarge {
		@include breakpoint(xlarge) {
			padding: #{$xlarge};
		}
	}
}
