.inspection-start {
	padding-top: rem-calc(108);

	@include media-breakpoint-up(lg) {
		padding-top: rem-calc(176);
	}

	.icon-search-detailed::before {
		height: rem-calc(72);
		width: rem-calc(72);
	}

	p {
		margin-bottom: rem-calc(132);

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(225);
		}
	}
}

.inspection-failed {
	padding-top: rem-calc(124);

	.recrafting-button {
		margin-bottom: rem-calc(40);
		max-width: rem-calc(284);
	}

	p {
		margin-bottom: rem-calc(40);
	}

	a {
		display: block;

		&:last-of-type {
			padding-bottom: rem-calc(20);
		}
	}
}

.inspection-slide {
	padding-top: rem-calc(52);

	@include media-breakpoint-up(lg) {
		padding-top: rem-calc(96);
	}

	h3 {
		margin-bottom: rem-calc(32);
	}
}

.inspection-indicator {
	background: map-get($lfi-palette, snow);
	border-radius: rem-calc(16);
	padding: rem-calc(7 16);
	position: absolute;
	right: rem-calc(16);
	top: rem-calc(16);
	z-index: 2;

	span {
		@include bold-text;
		align-items: flex-end;
		display: flex;
		line-height: rem-calc(18);
	}

	.icon-cancel::before {
		color: map-get($lfi-palette, cherry);
		margin-right: rem-calc(4);
	}

	.icon-checkmark::before {
		color: map-get($lfi-palette, evergreen);
		height: rem-calc(11);
		margin: 0 rem-calc(8 2) 0;
	}
}
