//
// Boot Care Page Styles
// ----------------------
// Styles for Boot Care content appearing on Boot Care Landing,
// Boot Care Guide Pages, and Boot Care Article Pages
//
.page-layout-boot-care-landing,
.page-layout-boot-care-article,
.page-layout-boot-care-guide {

	.category-view {
		display: grid;
	}

	.page-title-wrapper {
		margin: 0 !important;
		max-width: none;
		order: 1;
	}

	.category-description {
		@include bold-text;
		order: 2;
	}

	.category-hero {
		order: 3;
	}

	.category-cms {
		order: 4;
	}

	.article-list-collapse-container {
		order: 5;
	}

	.article-list-slider-container {
		order: 5;
	}
}

.page-layout-boot-care-article,
.page-layout-boot-care-guide {
	--category-view-padding-x: 24px;
	--category-view-item-margin-bottom: 24px;

	.tip-wrapper {
		padding: 0 16px 24px;

		.tip-container {
			margin: 0 auto;
			max-width: 626px;
		}
	}

	.category-custom-back-link {
		margin-bottom: var(--category-view-item-margin-bottom);
		padding: 0 var(--category-view-padding-x);
	}

	.page-title-wrapper,
	.category-description {
		padding: 0 var(--category-view-padding-x);
		text-align: left;

		@include media-breakpoint-up(md) {
			text-align: center;
		}
	}

	.page-title {
		@include media-breakpoint-up(md) {
			margin-bottom: 4px;
		}
	}

	.category-description {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: var(--category-view-item-margin-bottom);

		@include media-breakpoint-up(md) {
			font-size: 22px;
			line-height: 28px;
		}

		div {
			// Override pagebuilder inline styles
			//  see Magento_PageBuilder/view/frontend/templates/catalog/category/view/description.phtml
			margin: 0 !important;
			padding: 0 !important;
		}
	}

	.category-hero {
		margin-bottom: var(--category-view-item-margin-bottom);
	}

	.category-cms {
		margin-bottom: 64px;
		padding: 0 24px;
	}
}

.boot-care-accordion {
	margin: 0 auto;
	max-width: 674px;

	.accordion-button {
		background: transparent;
		color: map-get($lfi-palette, jet);
		font-size: inherit;
		line-height: 32px;
		padding: 40px 0;
		text-transform: none;

		&:hover,
		&:focus {
			background: transparent;
			color: map-get($lfi-palette, jet);
		}

		&.collapsed {
			&:after {
				@include svg-icon('../icons/icon-plus.svg');
			}
		}

		&:after {
			@include svg-icon('../icons/icon-subtract.svg');
		}

		&.with-subtitle {
			padding-top: 72px;
		}

		&.with-image {
			/* stylelint-disable */
			// Ignore nesting rule
			span {
				display: grid;
				font-size: 14px;
				line-height: 20px;
				text-align: left;
			}

			img {
				margin-bottom: 8px;
				max-width: 160px;
			}
			/* stylelint-enable */
		}

		.subtitle {
			font-size: 14px;
			position: absolute;
			text-transform: uppercase;
			top: 40px;
		}
	}

	.accordion-item {
		// override bootstrap styles
		border-bottom: 1px solid map-get($lfi-palette, light-gray) !important;
	}

	> .tip-container {
		margin: 0 auto;
		max-width: 626px;
	}

	.tip-container {
		margin: 16px 0;
	}

	.icon-bag {
		@include bold-text;
		align-items: center;
		display: flex;
		width: fit-content;

		&::before {
			@include svg-icon('../icons/icon-bag.svg', 24px, 24px);
			margin-right: 4px;
		}
	}

	.icon-bag,
	.primary-hollow {
		margin-top: 16px;
	}

	.quick-add-modal-launch {
		p {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	p {
		font-size: 18px;
		line-height: 26px;
	}
}

.boot-care-quick-add-inline-container {
	border: 1px solid map-get($lfi-palette, light-gray);
	border-radius: 16px;
	margin-bottom: 24px;
	padding: 32px 24px;

	.icon-circle-check {
		&:last-of-type {
			margin-bottom: 0 !important;
		}

		p {
			margin-bottom: 0 !important;
		}
	}
}
