//
// Company Info Page
//

.company-profile-index {

	.column.main {
		@include media-breakpoint-up(md) {
			column-gap: $account-grid-column-gap;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}

		@include media-breakpoint-up(lg) {
			max-width: $account-grid-max-width;
		}
	}

	.block-company-profile-contacts,
	.page-title-wrapper {
		grid-column: 1 / span 2;
		width: 100%;
	}

	.block-company-profile-address,
	.block-company-profile-account {
		@include media-breakpoint-up(md) {
			margin-bottom: 0 !important;
		}

		.block-title {
			display: block;
		}
	}

	.block-company-profile-contacts {
		@include media-breakpoint-up(md) {
			border-top: rem-calc(1) solid $light-gray;
			padding-top: rem-calc(32);
		}

		.block-content {
			@include media-breakpoint-up(md) {
				column-gap: $account-grid-column-gap;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}

.edit-company-profile {
	@include bold-text;
}
