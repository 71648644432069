/*
 * Lfi Loader to be used in conjuction with Lfi_Theme/js/loader
 */

.loader-active {
	background-image: url("../images/lfi-loader.gif");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: rem-calc(50);

	* {
		// Ensures all elements are hidden while the loader is active
		visibility: hidden !important;
	}
}
