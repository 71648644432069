//  -------------------------------
//  .product-reviews-sort
//   form
//    div
//     select

.product-reviews-sort {
	width: 100%;

	@include media-breakpoint-up(lg) {
		min-width: rem-calc(200);
		width: auto;
	}

	select {
		margin-bottom: 0;
	}
}
