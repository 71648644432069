//
// Form messages

// Errors
.field-error {
	margin: rem-calc(-8 0 15 2);
}

.mage-error[generated="true"],
.field-error {
	color: $alert-color;
	font-size: rem-calc(14);
	line-height: rem-calc(18);
	margin-bottom: $form-input-margin-bottom;
}
