//
// Email Signup Modal
//

.email-modal-form {
	.modal-content {
		color: map_get($lfi-palette, jet);
	}

	.success-message {
		display: none;
	}

	.submission-success {
		.success-message {
			display: block;
		}

		.main-message,
		.email-privacy-message,
		.email-modal-bottom {
			display: none;
		}
	}
}

.close-email-modal-button {
	@include button-primary;
}

.email-modal-top {
	.subheader {
		margin-bottom: rem-calc(15);
	}

	p {
		margin-bottom: rem-calc(40);
	}
}

.email-modal-bottom {
	margin: 0 auto rem-calc(30) auto;
	max-width: rem-calc(360);
}

.email-privacy-message {
	opacity: 0.6;

	a {
		text-decoration: underline;
	}

	p,
	a {
		color: $base-color;
		font-size: rem-calc(13);
	}
}

.email-modal-logo {
	margin: 0 auto rem-calc(30) auto;
	width: $store-logo-width;
}

#email-modal-input-error {
	padding-top: rem-calc(5);
	position: absolute;
}

// IE10+ Fixes
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.email-modal-logo svg {
		max-height: rem-calc(35);
	}
}
