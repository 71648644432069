.product-items {
	.product-item {
		background: $body-background;
		display: flex;
		position: relative;
	}

	.product-indicator {
		position: absolute;
		right: rem-calc(8);
		top: rem-calc(8);
		z-index: 1;

		@include breakpoint(medium) {
			right: 1rem;
			top: 1rem;
		}
	}

	.product-item-info {
		align-content: center;
		display: flex;
		flex-flow: column wrap;
		padding: 16px 16px 24px;

		@include media-breakpoint-up(md) {
			padding: 24px 24px 32px;
		}
	}

	.product-item-link {
		flex: 0 0 100%;
		overflow: hidden; // IE
	}

	.product-item-photo {
		aspect-ratio: 1 / 1;
		display: inline-block;
		margin-bottom: 24px;
		width: 100%;

		@include media-breakpoint-up(md) {
			margin-bottom: 32px;
		}
	}

	.product-item-details {
		display: flex;
		flex-wrap: wrap;
	}

	.product-item-header {
		flex: 1 1 100%;
	}

	.product-family {
		@include bold-text();
		color: $body-dark-font-color;
		margin-bottom: 8px;
	}

	.subtitle {
		color: $body-medium-font-color;
		margin-bottom: 12px;
	}

	.gender-sizing {
		color: map_get($lfi-palette, jet);
		margin-bottom: 0;
	}

	.product-family,
	.subtitle,
	.gender-sizing {
		line-height: rem-calc(22);

		// hold space if no value
		&:empty::after {
			content: "\00a0";
		}
	}

	.price-box {
		justify-content: initial;
	}
}
