//
// PDP Shipping Info Styles
// -------------------------
// .pdp-shipping-container
//  .pdp-shipping-estimator
//  .pdp-shipping-customer
// .pdp-shipping-estimator-modal

.pdp-shipping-estimator-modal,
.pdp-shipping-container {
	.subtitle {
		color: $base-color;
	}

	.underline-text {
		cursor: pointer;
	}

	a {
		color: currentColor;
	}

	p {
		line-height: rem-calc(20);
	}

	.loading-mask .loader {
		img {
			display: none;
		}
	}
}

.pdp-shipping-container {
	// custom locally color
	background: fade-out($secondary-color, 0.5);
	border-radius: 15px;
	margin-top: rem-calc(20);
	padding: 20px 16px;

	.shopbox-callout {
		margin-bottom: 8px;
	}

	.icon-speedy-truck::before {
		margin-right: rem-calc(8);
		width: rem-calc(20);
	}

	.icon-rocket,
	.icon-orders {
		&::before {
			margin-right: rem-calc(10);
		}
	}

	.icon-rocket,
	.icon-speedy-truck {
		&::before {
			color: $base-color;
		}
	}

	.loading-mask {
		background: $pdp-shopbox-bg;
		opacity: 0.5;
	}
}

.pdp-shipping-estimator {
	.subtitle {
		margin-bottom: 6px;
	}

	> div {
		margin-bottom: 24px;
	}
}

.pdp-shipping-estimator-rate-date {
	display: flex;
	margin-bottom: rem-calc(6);
}

.pdp-shipping-customer {
	margin-bottom: rem-calc(32);

	.pdp-customer-free-ship-info {
		display: none;
	}

	p {
		margin-bottom: 0;

		&:first-of-type {
			margin-bottom: rem-calc(6);
		}
	}
}

.pdp-customer-free-ship-info {
	color: $base-color;
	margin-top: rem-calc(40);
	opacity: 0.75;
}

// Shipping Estimator Modal Styles
.pdp-shipping-estimator-modal {
	.modal-content {
		text-align: left;
	}

	.modal-footer {
		justify-content: flex-start;
		padding-bottom: rem-calc(72);

		.icon-rocket {
			margin-bottom: rem-calc(6);
		}

		p {
			flex: 0 0 100%;
		}
	}

	.input-group {
		margin-bottom: rem-calc(40);
	}

	.icon-rocket::before {
		margin-right: 6px;
	}

	h2 {
		font-size: rem-calc(24);
		line-height: rem-calc(32);
		margin-bottom: rem-calc(32);
		text-align: center;
	}
}

.pdp-shipping-estimator-modal-rates {
	@include list-reset;
}

.pdp-shipping-estimator-modal-rate {
	margin-bottom: rem-calc(16);

	.rate-info {
		span:first-of-type::after {
			content: '-';
			display: inline-block;
			padding-left: rem-calc(4);
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	::before {
		display: none;
	}
}

.pdp-rate-free {
	&:before {
		content: "-";
		padding-left: rem-calc(4);
		padding-right: rem-calc(4);
	}
}

#dummy-state-data {
	background: white;
	border: 2px solid black;
	left: 0;
	padding: rem-calc(16);
	position: fixed;
	top: 0;
	z-index: 5000;

	p {
		@include bold-text;
		display: block;
		margin-bottom: rem-calc(4);
	}

	.field {
		flex-flow: row wrap;

		div {
			align-items: center;
			display: flex;
		}
	}
}
