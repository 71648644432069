//
// Checkout Form Specific styles
//

.checkout-index-index {
	// no need for required asterisk in checkout
	.required,
	._required {
		label::after {
			display: none;
		}
	}

	// New Address button
	.action-show-popup {
		@include button-secondary;
		@include button-expand;
	}

	// Buttons appear when Address modal is open
	.action-save-address {
		@include button-primary;
	}

	// Continue to Payment button
	.action.continue {
		@include button-primary;
		@include button-expand;
	}

	// Submit payment
	.opc-submit-order {
		@include button-primary;
		@include button-expand;
	}
}
