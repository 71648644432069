//
// Checkout payment form
//

$opc-payment-border: rem-calc(1) solid map_get($lfi-palette, light-gray);

.opc-payment {
	border-radius: rem-calc(10);
	border: $opc-payment-border;
	margin-bottom: rem-calc(5);
	min-height: rem-calc(58);
}

.payment-section-title {
	margin-bottom: rem-calc(20);
	text-align: center;
}

.payment-method {
	&._active {
		.payment-method-content {
			display: flex;
		}
	}

	&:last-of-type {
		.payment-method-title {
			border-bottom: none;
		}

		.payment-method-content {
			border-bottom: none;
		}
	}
}

.payment-method-title {
	border-bottom: $opc-payment-border;

	input {
		margin-left: rem-calc(14);
		display: flex !important; // Magento hides radio button if only one payment
	}

	label {
		background-repeat: no-repeat;
		background-position: right rem-calc(14) center;
		background-size: auto rem-calc(24);
		font-size: rem-calc(16);
		line-height: rem-calc(23);
		margin: 0;
		padding: rem-calc(15 14 15 7) !important; //gotta override fl-styles :(
		width: 100%;

		&[for="cybersourceklarna"] {
			background-image: url("../images/klarna-payment-badge.svg");
		}

		&[for="adyen_klarna_account"] {
			background-image: url("../images/klarna-payment-badge.svg");
		}

		&[for="chcybersource"] {
			background-image: url("../images/credit-card-icons.svg");
		}

		&[for="adyen_cc"] {
			background-image: url("../images/credit-card-icons.svg");
		}

		&[for="adyen_applepay"] {
			background-image: url("../images/applepay-badge.svg");
		}

		> span {
			font-weight: $font-weight__bolder;
		}
	}
}

.payment-method-content {
	background: map_get($lfi-palette, snow);
	border-bottom: $opc-payment-border;
	display: none;
	flex-flow: row wrap;
	padding: rem-calc(35 14 30 14);

	> * {
		flex: 1 1 100%;
	}

	.klarna-logo {
		margin-bottom: rem-calc(20);
	}

	.actions-toolbar {
		order: 5;

		button {
			@include button-primary;
			@include button-expand;
		}
	}
}

.payment-method-billing-address,
.checkout-billing-address {
	order: 4;
}

// push billing address down from payment forms
.checkout-payment-method-form {
	margin-bottom: rem-calc(20);
}

.payment-method-content > .form {
	margin-bottom: rem-calc(6);
}
