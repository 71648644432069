@mixin inline-messages() {
	padding-left: 0;
	padding-right: 0;

	.message {
		@include breakpoint(medium) {
			margin-bottom: 0;
			padding: 0;
			text-align: left;
		}

		&:before {
			display: inline-block;
			font-size: 1rem;
			margin-bottom: 0;
			margin-right: rem-calc(6);
			position: relative;
			top: rem-calc(2);
		}

		> div,
		p {
			display: inline;
			text-align: inherit;
		}
	}
}
