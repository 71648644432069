.recrafting-billing-notice {
	align-items: flex-start;
	display: flex;
	font-weight: bold;
	padding: rem-calc(16 0 28 0);

	.icon-alert::before {
		color: $info-color;
		height: rem-calc(24);
		margin-left: rem-calc(2);
		margin-right: rem-calc(16);
		width: rem-calc(24);
	}

	p {
		@include bold-text();

		font-size: rem-calc(14);
		line-height: 1.5;
	}
}

.recrafting-order-success {
	h3 {
		margin-bottom: rem-calc(50);
		margin-top: rem-calc(40);
	}

	ol {
		list-style-type: none;
		margin: 0;
	}

	li {
		margin-bottom: rem-calc(40);

		&.icon-orders::before {
			height: rem-calc(37);
			width: rem-calc(37);
		}

		&.icon-speedy-truck::before {
			height: rem-calc(37);
			width: rem-calc(46);
		}

		&.icon-smile::before {
			height: rem-calc(37);
			width: rem-calc(37);
		}
	}
}
