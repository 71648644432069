// /* Promotion Bar
//  * -------------------
//  */

.promotion-bar {
	--promotion-bar-icon-size: 20px;
	align-items: center;
	background-color: map-get($lfi-palette, snow);
	display: flex;
	height: 56px;
	justify-content: center;
	overflow: hidden;

	.carousel-inner {
		@include list-reset;
		align-items: center;
		display: flex;
		padding: 10px 15px;
		text-align: center;

		span:before {
			margin-right: 8px;
		}
	}

	.carousel-control {
		background: none !important;
		display: flex;
		padding: 12px;
	}

	.carousel-icon {
		display: inline-block;
		height: var(--promotion-bar-icon-size);
		width: var(--promotion-bar-icon-size);

		&:before {
			background: $accent-font-color;
			height: var(--promotion-bar-icon-size);
			opacity: 0.75;
			width: var(--promotion-bar-icon-size);
		}
	}

	.carousel-item {
		font-size: 14px;
		margin-bottom: 0;
		// Bootstrap Carousel Fade Transitions have a delay built in with JS which UX doesn't want.
		transition: opacity 0.3s ease-in-out 0s !important;
	}

	div {
		* {
			line-height: 18px;
		}

		span:nth-of-type(n+2) {
			margin-left: 12px;
		}
	}

	div:nth-of-type(n+2) {
		margin-top: 4px;

		* {
			line-height: 14px;
		}
	}

	p,
	a {
		color: $accent-font-color;
	}
}
