//  Prices

@mixin price-style-1() {
	.price {
		&-tier_price .price-excluding-tax,
		&-tier_price .price-including-tax {
			display: inline;
		}
	}
}

@mixin price-style-2() {
	.price {
		&-including-tax,
		&-excluding-tax {
			display: inline !important;
		}

		&-including-tax:before {
			content: " / ";
		}

		&-including-tax:after {
			content: '("attr(data-label)")';
		}
	}
}

@mixin price-style-3() {
	.price-including-tax,
	.price-excluding-tax {
		.cart-tax-total {
			@extend .abs-tax-total;
			&-expanded {
				@extend .abs-tax-total-expanded;
			}
		}
	}

	.price-including-tax + .price-excluding-tax,
	.weee[data-label] {
		display: block;
		@include lib-font-size(18);

		&:before {
			content: attr(data-label) ": ";
			@include lib-font-size(11);
		}

		.price {
			@include lib-font-size(11);
		}
	}
}

@include price-style-1();
@include price-style-3();
