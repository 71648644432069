@use "../../globals/colors";
@use "../../globals/fonts";

$modal-backdrop-opacity: 0.2;
$modal-content-border-width: 0;
$modal-content-inner-border-radius: 0;
$modal-fade-transform: translate(0, 100%);
$modal-footer-margin-between: 0;
$modal-header-border-width: 0;
$modal-md: 600px;
$modal-transition: transform 0.3s cubic-bezier(0.5, 0, 0.5, 1);

$transition-fade: opacity 0.3s cubic-bezier(0.5, 0, 0.5, 1);
$transition-fade-background: background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1);

// scss-docs-start grid-breakpoints
// should match the Foundation breakpoints from _settings.scss
// I've copied the var below for reference.
// TODO - It would be nice to have $grid-breakpoints: $breakpoints
// but we need to map the keys
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 640px,
	tablet: 900px,
	lg: 1024px,
	xl: 1200px,
	xxl: 1440px,
	vlarge: 1920px
);
//$breakpoints: (
//	small: 0px,
//	medium: 640px,
//	tablet: 900px,
//	large: 1024px,
//	xlarge: 1200px,
//	xxlarge: 1440px
//);

$accordion-padding-y: 0;
$accordion-padding-x: 0;
$accordion-color: inherit;
$accordion-bg: transparent;
$accordion-border-color: none;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: inherit;
$accordion-button-focus-box-shadow: none;
$accordion-button-icon: none;
$accordion-button-active-icon: none;

$zindex-sticky: 4;

// Tables
$table-bg: colors.$white;
$table-striped-bg: colors.$background-secondary;
$table-border-color: map-get(colors.$lfi-palette, light-gray);
$table-th-font-weight: fonts.$font-weight__regular;
