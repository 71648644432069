$modal-slide__padding: 0;
$modal-slide-header__padding-vertical: 0 !default;

//
//  Lib -> Components -> Modals
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

$modal-popup__padding: 3rem !default;
$modal-popup__z-index: $modal__z-index !default;
$modal-slide__first__indent-left: 14.8rem !default;
$modal-slide__z-index: $modal__z-index !default;

//
//  Utilities
//  ---------------------------------------------

@mixin lib-modal() {
	bottom: 0;
	left: 0;
	min-width: 0;
	opacity: 0;
	position: fixed;
	right: 0;
	top: 0;
	transition: visibility 0s 0.3s, opacity 0.3s ease;
	visibility: hidden;

	&._show {
		opacity: 1;
		transition: opacity 0.3s ease;
		visibility: visible;

		.modal-inner-wrap {
			transform: translate(0, 0);
		}
	}

	.modal-inner-wrap {
		opacity: 1;
		pointer-events: auto;
	}
}

@mixin lib-modal-slide() {
	@include lib-css(left, $modal-slide__first__indent-left);
	@include lib-css(z-index, $modal-slide__z-index);

	&._show {
		.modal-inner-wrap {
			transform: translateX(0);
		}
	}

	.modal-inner-wrap {
		overflow-y: auto;
		position: static;
		transform: translateX(100%);
		transition: transform 0.3s ease-in-out;
	}
}

@mixin lib-modal-popup() {
	@include lib-css(z-index, $modal-popup__z-index);
	left: 0;
	overflow-y: auto;

	&._show {
		.modal-inner-wrap {
			transform: translateY(0);
		}
	}

	.modal-inner-wrap {
		@include lib-vendor-prefix-display(flex);
		@include lib-vendor-prefix-flex-direction(column);
		box-sizing: border-box;
		left: 0;
		position: absolute;
		right: 0;
		transform: translateY(-200%);
		transition: transform 0.2s ease;
	}
}

body {
	&._has-modal {
		height: 100%;
		overflow: hidden;
		width: 100%;
	}
}

.modal-slide,
.modal-popup {
	@include lib-modal();
}

.modal-slide {
	@include lib-modal-slide();

	&._inner-scroll {
		.modal-inner-wrap {
			@include lib-vendor-prefix-display(flex);
			@include lib-vendor-prefix-flex-direction(column);
			max-height: 100%;
			overflow-y: visible;
		}

		.modal-content {
			overflow-y: auto;
		}
	}
}

.modal-popup {
	@include lib-modal-popup();

	//  If applied, switching outer popup scroll to inner
	&._inner-scroll {
		overflow-y: visible;

		.ie11 &,
		.ie10 &,
		.ie9 & {
			overflow-y: auto;
		}

		.modal-inner-wrap {
			max-height: 90%;

			.ie11 &,
			.ie10 &,
			.ie9 & {
				max-height: none;
			}
		}

		.modal-content {
			overflow-y: auto;
		}
	}

	.modal-footer-actions {
		text-align: right;
	}
}

//
//  Mobile
//  _____________________________________________

//  Mobile transform to modal-slide
@include max-screen($screen__m) {
	.modal-popup {
		&.modal-slide {
			@include lib-modal-slide();

			.modal-inner-wrap {
				margin: 0;
				max-height: none;
			}
		}
	}
}
