@use "../../globals/colors" as colors;
@import "../../helpers/typography";
// TODO - Media Mixins

// Size Launcher and Modal Styles
// ----------------

.size-guide-modal-launcher {
	margin-left: auto;
}

.size-guide-modal {
	.modal-dialog {
		--bs-modal-margin: 16px 12px 56px;
		--bs-modal-width: 720px;
	}

	.modal-header {
		padding: 32px 16px 24px;

		@include media-breakpoint-up(md) {
			padding: 40px 40px 32px;
		}

		.action-close {
			flex: 1 1 auto;
		}

		h3 {
			margin-bottom: 0;
		}
	}

	.modal-body {
		padding: 0 16px 32px;

		@include media-breakpoint-up(md) {
			padding: 0 40px 40px;
		}
	}

	.modal-content {
		max-width: none !important;
	}
}

.size-guide-navigation {
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 16px;
	overflow-x: auto;
	padding-bottom: 16px;

	.tab-button {
		@include bold-text;
		background: colors.$background-secondary;
		border-radius: 32px;
		color: colors.$base-primary-color;
		flex: 0 0 auto;
		font-size: 16px;
		line-height: 20px;
		margin-right: 8px;
		padding: 8px 12px;
		text-transform: none;

		&:last-of-type {
			margin: 0;
		}

		&.active {
			background: colors.$base-primary-color;
			color: white;
		}
	}
}
