@import "../../default/styles/type";

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
dl dt {
	@include font-headlines(
		$font-family: $header-font-family,
		$text-transform: none
	);
}

.subheader {
	@include font-headlines(
		$font-family: $body-secondary-font-bold,
		$text-transform: none
	);
}
