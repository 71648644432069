/*
 * Quick Add Widget
 */

.quick-add-modal {
	.modal-content {
		background: map-get($lfi-palette, snow);
		max-width: none !important;
	}
}

.quick-add-list-inline,
.quick-add-modal {
	@extend %quick-add-list-row-layout;
}

