// /*
//  * Upsell block component
//  * ----------------------
//  * div.block.block-upsell
//  *  h2
//  *  p
//  *  div.products
//  *   ul
//  *   li.item
//  *    a
//  *    div.product-thumb
//  *     span.product-image-container
//  *      span.product-image-wrapper
//  *       image.product-image-photo
//  *    div.product-shop
//  *     h3.product-family.product.name.product-item-name
//  *     h4.product-name
//  *     div.price-box -> Inherited price styles
//  *     ...........
//  */

.block-upsell {
	.product-thumb {
		margin-bottom: rem-calc(20);
	}

	.item {
		margin-bottom: rem-calc(50); // and 20px of padding
		position: relative;

		// todo - margin on container
		&:first-child {
			margin-left: rem-calc(10);
		}

		&:last-child {
			margin-right: rem-calc(10);
		}
	}

	ul {
		width: auto;
		margin: rem-calc(30) auto 0 auto;
		white-space: nowrap;

		@include breakpoint(medium) {
			margin-top: rem-calc(40);
		}
	}

	li {
		display: inline-block;
		list-style: none;
		max-width: rem-calc(320);
		padding: rem-calc(20);
		vertical-align: top;
		white-space: normal;

		@include breakpoint(medium) {
			max-width: rem-calc(272);
		}
	}
}
