//
// Factory Tour Sign-up Landing Page
//

%factory-tour-sign-up-section {
	padding-bottom: rem-calc(80);
	padding-top: rem-calc(80);

	> .columns {
		margin: 0 auto;
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.cms-factory-tour-sign-up .intro {
	@extend %factory-tour-sign-up-section;

	padding-top: 0;

	> .columns {
		max-width: rem-calc(640);
	}

	.cta {
		margin: rem-calc(40 0);
	}

	.cta .button {
		margin-bottom: 0;
	}

	.info-message {
		font-size: rem-calc(14);
		max-width: rem-calc(472);
		padding: 0.5rem;
	}
}

.cms-factory-tour-sign-up .faq {
	@extend %factory-tour-sign-up-section;

	background-color: $body-background-alt;

	@include breakpoint(medium) {
		h2 {
			text-align: center;
		}
	}

	> .columns {
		max-width: rem-calc(700);
	}

	.accordion {
		@include bootstrap-accordion();
	}

	.questions {
		text-align: center;

		@include breakpoint(medium) {
			padding-top: rem-calc(24);
		}

		p {
			margin-bottom: rem-calc(16);
		}
	}
}

.cms-factory-tour-sign-up .digital-tour {
	@extend %factory-tour-sign-up-section;

	> .columns {
		max-width: rem-calc(340);
		text-align: center;
	}

	h5 {
		margin-bottom: rem-calc(4);
		margin-top: rem-calc(8);
	}

	.subtitle {
		font-size: rem-calc(14);
	}

	.wrapper:first-of-type {
		margin-bottom: rem-calc(44);
	}

	a.icon-arrow {
		@include bold-text();

		display: inline-flex;
		flex-direction: row-reverse;
		font-size: rem-calc(14);
		gap: rem-calc(4);

		&:before {
			height: 1rem;
			margin-top: rem-calc(4);
			width: 1rem;
		}
	}
}
