/*
 * Modal styles
 * @note we should only be using the Magento and Bootstrap modals on the site
 */

// Bootstrap and Magento Backdrop
.modal,
.modal-popup {
	backdrop-filter: $modal-overlay-filter;
}

/*
 * Outer Modal
 */
.modal-inner-wrap {
	// @Note - @bootstrap var
	background: $modal-content-bg;
	// @Note - @bootstrap var
	border-radius: $modal-content-border-radius;

	.modal-content {
		padding: 0 $modal-inner-padding rem-calc(40) $modal-inner-padding;

		@include media-breakpoint-up(md) {
			padding-left: $modal-inner-padding-desktop;
			padding-right: $modal-inner-padding-desktop;
		}
	}
}

.modal-dialog .modal-content,
.modal-inner-wrap {
	box-shadow: $modal-box-shadow;
	height: 100%;
	overflow: hidden;
	text-align: center;

	@include media-breakpoint-up(md) {
		height: auto;
		margin-left: auto;
		margin-right: auto;
		max-width: rem-calc(512);
	}
}

/*
 * Modal Contents
 */
.modal-header {
	flex-wrap: wrap;
	padding: $modal-inner-padding;
	padding-bottom: rem-calc(24);

	@include media-breakpoint-up(md) {
		padding: $modal-inner-padding-desktop;
		padding-bottom: rem-calc(24);
		padding-top: rem-calc(32);
	}
}

.modal-title {
	@extend h3;
	flex: 1 1 auto;
	margin: 0;
	order: 2;
	text-align: center;
}

.modal-body {
	padding: 0 $modal-inner-padding rem-calc(40) $modal-inner-padding;

	@include media-breakpoint-up(md) {
		padding-left: $modal-inner-padding-desktop;
		padding-right: $modal-inner-padding-desktop;
	}
}

.modal-footer {
	// Backoff Bootstrap
	border: none;
	display: flex;
	flex-flow: row wrap;
	padding: $modal-inner-padding;
	padding-top: 0;

	@include media-breakpoint-up(md) {
		padding-bottom: rem-calc(80);
		padding-left: $modal-inner-padding-desktop;
		padding-right: $modal-inner-padding-desktop;
	}

	&.centered {
		justify-content: center;
	}

	.action-primary,
	.action {
		@include button-expand;
		@include button-oval-adjustment(rem-calc(24), rem-calc(48));

		// basically just want the 2nd button (out of 3), but it doesn't have any classes and sometimes there are
		// less than three buttons
		&:not(.primary):not(.secondary):not(.action-primary) {
			@include button-hollow-primary;
			@include button-oval-adjustment(rem-calc(24), rem-calc(48));
			margin-top: rem-calc(12);
		}
	}

	.action-cancel,
	.action-hide-popup,
	.cancel {
		@include button-with-link-styles;
	}
}

.action-close {
	@include _lib-icon-text-hide(true);

	flex: 1 1 100%;
	line-height: 1;
	margin-bottom: rem-calc(8);
	order: 1;
	padding: 0;
	text-align: right;

	&:before {
		font-size: rem-calc(18);

		@include breakpoint(medium) {
			font-size: rem-calc(20);
		}
	}
}

.modal-header-empty {
	.action-close {
		margin-bottom: 0;
	}
}

/*
 * Magento Modal
 */
aside[data-role="modal"] {
	form {
		max-width: 100% !important;
	}
}

.modals-overlay {
	background: $modal-backdrop-bg;
	height: 100%;
	position: fixed;
	top: 0;
	width: 100%;
}

// Popup Modal
.modal-popup {
	align-items: center;
	display: flex;
	overflow-y: visible;

	.action-primary {
		@include button-primary;
	}

	.action-secondary {
		@include button-transparent();
	}
}

/*
 * Special Bootstrap Version pinned to bottom of screen
 */
.modal-dialog-pin-bottom {
	align-items: flex-end;
	margin-bottom: 0;
	margin-top: $modal-dialog-margin * 2;

	@include media-breakpoint-up(sm) {
		margin-top: $modal-dialog-margin-y-sm-up * 2;
	}

	.modal-content {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}
