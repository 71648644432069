@import "../../default/styles/components/global/_email-modal.scss";

.email-modal-top {
	.subheader {
		color: map_get($danner-palette, forest);
	}
}

.email-modal-bottom {
	@include input-group-color-adjustment(
		$button-and-border-color: map-get($danner-palette, forest)
	);
}
