.comfort-zone {
	padding-top: 50px;
	text-align: center;

	.temp-zone {
		display: block;
		position: relative;
		text-overflow: ellipsis;
		visibility: hidden;
		white-space: nowrap;

		&::before {
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
			border-top: 10px solid $body-font-color;
			content: "";
			height: 0;
			left: 50%;
			margin-left: -15px;
			position: absolute;
			top: -15px;
			width: 0;
		}
	}

	ul {
		list-style-type: none;
		margin: 20px 0 0;
	}

	h5 {
		border-bottom: 2px solid $body-font-color;
		border-top: 2px solid $body-font-color;
		padding: 4px 0;
	}

	li {
		display: inline-block;
		margin: 0;
		vertical-align: top;
		width: 32%;

		&.selected {
			h5 {
				background-color: $body-font-color;
				color: map-get($lfi-palette, snow);
				margin-bottom: 15px;
			}

			.temp-zone {
				visibility: visible;
			}
		}
	}
}
