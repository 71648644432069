//
// Checkout typography
// Unfortunate necessity to handle split base styles
//

.opc-sidebar-collapsible {
	color: $header-color;
}

.payment-section-title {
	@include bold-text();
	line-height: 1;
	text-transform: none;
}
