/*
 * Hero Section
 * ------------
 * div.header-background
 *  div.header-banner.no-slick.go-there-hero
 *   div.banner-background
 *    div.banner-content-wrapper
 *     div.banner-content
 *      h3
 *       span
 *      div.vertical-hr
 *      h1
 *      
 */

.go-there-hero {
	.banner-background {
		background-repeat: no-repeat;
		background-size: cover;
	}

	.banner-content-wrapper {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.banner-content {
		padding-top: rem-calc(82); // Stop content moving as JS initializes
		text-align: center;
		padding-bottom: rem-calc(120);

		@include breakpoint(medium) {
			padding-top: rem-calc(86); // Stop content moving as JS initializes
		}

		h3 {
			padding: rem-calc(0 30);
			margin-bottom: rem-calc(22);
		}

		span {
			background: $white;
			box-decoration-break: clone; // IE | Edge not supported
			color: map-get($danner-palette, danner-green);
			line-height: 1.8;
			font-size: rem-calc(20);
			padding: rem-calc(0 8 1 8);
		}
	}

	.vertical-hr {
		@include breakpoint(medium) {
			height: rem-calc(1000);
		}

		@include breakpoint(large) {
			height: rem-calc(1300);
		}
	}
}

body.light-header-fg {
	.go-there-hero {
		.banner-content {
			padding-top: rem-calc(136);

			@include breakpoint(medium) {
				padding-top: rem-calc(140);
			}
		}
	}
}
