/*
 * Most Worn Section
 * --------------------------------
 * section.most-worn
 */

.most-worn {
	background: map-get($lfi-palette, snow);

	.block-products-list {
		max-width: map-get($breakpoints, xxlarge);
		margin: 0 auto;
		padding: rem-calc(70 0);
	}

	.subheader {
		margin-bottom: rem-calc(30);
	}

	.products-slides {
		background: map-get($lfi-palette, snow);
	}

	.product-items {
		margin-bottom: rem-calc(15);
		padding-bottom: rem-calc(15);

		&.slick-initialized {
			margin-bottom: rem-calc(10);
			padding-bottom: rem-calc(25);
		}

		&:not(.slick-initialized) {
			.product-item:first-of-type {
				margin-left: rem-calc(15);
			}

			// Need an element for flex to add margin
			&:after {
				content: "";
				border: rem-calc(1) solid;
				visibility: hidden;
			}
		}
	}

	.product-item {
		max-width: rem-calc(340);
		margin-right: rem-calc(10);
	}

	.slick-track {
		margin-left: rem-calc(25);
	}

	.product-item-details {
		margin-top: rem-calc(35);
	}

	.product-item-link {
		width: 100%;
	}

	// todo - img markup should match category list pages
	.product-image-container {
		width: 100%;

		img {
			width: 100%;
		}
	}

	.button-container {
		text-align: center;
	}
}
