//
// Customer Popup Styles
// ---------------------
// div.modal.customer-popup
//  div.customer-popup-dialog
//   div.customer-popup-content
//    div.customer-popup-header
//    div.customer-popup-body
//     form.customer-popup-form

// Launcher should be hidden on small screens when prompt is visible
.login-prompt-visible {
	@include breakpoint(small) {
		#launcher {
			display: none;
		}
	}
}

.customer-popup-dialog {
	max-width: none;
}

.customer-popup-content {
	// Special width for this modal
	max-width: rem-calc(900) !important;

	@include media-breakpoint-up(md) {
		width: auto;
	}
}

.modal-content {
	.customer-popup-body {
		padding: 0;
	}
}

.customer-popup-header {
	position: absolute;
	right: 0;
	z-index: 1;
}

.customer-popup-body {
	.logo {
		@include logo-styles();

		display: none;
		margin-bottom: rem-calc(50);

		@include breakpoint(medium) {
			display: inline-block;
		}
	}
}

////
// Handle displaying multiple forms in popup
////
.customer-popup-form {
	display: none;

	&.active {
		display: flex;
	}

	// Hide the form initially on mobile while a content block is shown
	// There is a button that will toggle the content/form
	&.customer-popup-register {
		.customer-popup-form-wrapper {
			@include breakpoint(small down) {
				display: none;
			}
		}
	}
}

////
// Handle form success messaging
////
/* stylelint-disable-next-line */
.customer-popup-form {
	.customer-popup-success-content {
		display: none;
	}

	&.success {
		.customer-popup-success-content {
			display: block;
		}

		.customer-popup-form-wrapper {
			display: none;
		}
	}
}
