$float-labels: (
	alert-color: $alert-color,
	base-height: $form-text-input-height,
	color-required: #d32f2f,
	color-input: map-get($danner-palette, forest),
	color-placeholder: rgba(25, 50, 47, 0.75),
	color-label: rgba(25, 50, 47, 0.65),
	font-family: $header-font-regular,
	line-height: rem-calc(23)
);

@import "../../default/styles/components/forms/_float-label.scss";

// Current font's baseline requires bottom padding to vertically center in field
.input-text {
	&:not(.fl-input) {
		padding-bottom: rem-calc(12);
	}
}
