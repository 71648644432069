// FEATURE BLOCKS

// Background Image Dimensions
// 830x1120 = Standard
// 1514x996 = Wide

$regular-block-padding: percentage(divide(1120, 830)) !default;
//$wide-block-padding: percentage(996/1514) !default;

// Previously
//$regular-block-padding: percentage(4/3) !default;
$wide-block-padding: percentage(divide(2, 3)) !default;

// Fade-in Slick component
// https://github.com/kenwheeler/slick/issues/1741#issuecomment-292218446
.feature-block-slider {
	visibility: hidden;
	opacity: 0;
	transition: opacity 1s;

	&:not(.slick-initialized) {
		margin-bottom: rem-calc(60); // adjustment for slick initialization offset

		> div:not(:first-of-type) {
			padding: 0;
			margin: 0;
			width: 0;
			height: 0;
		}
	}

	&.slick-initialized {
		visibility: visible;
		opacity: 1;

		.slick-slide {
			display: block;
			visibility: visible !important;
		}
	}

	.slick-slide {
		display: none;

		&:first-child {
			display: block;
			visibility: hidden;
		}
	}
}

// Component Styles + Slick Overrides
.feature-block-slider {
	@include xy-grid-container($padding: 0);

	.columns {
		@include xy-cell($gutter-type: none);

		// Spoofing .cell behavior manually because of the xy-gutter calculation error
		padding: {
			bottom: $grid-margin-gutters;
			left: $grid-margin-gutters * 0.5;
			right: $grid-margin-gutters * 0.5;
		}
	}

	&.slick-slider {
		// Flexbox-Fix : Enforce + constrain width to prevent flex-children from scaling to match
		// the default Slick behavior of inheriting the width of all slides combined
		.slick-track {
			@include xy-grid($wrap: false);
			max-width: $grid-container;
			width: 100vw !important;
		}

		@include slick-controls();
	}
}

.feature-block {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;

	&:not(.sub-categories) {
		&::before {
			content: "";
			display: block;
			height: 0;
			width: 1px;
			margin-left: -1px;
			padding-top: $regular-block-padding;
		}

		&.wide-at-large::before {
			@include breakpoint(large up) {
				padding-top: $wide-block-padding;
			}
		}
	}
}

.feature-block-content {
	position: absolute;

	&.fully-centered {
		left: 50%;
		text-align: center;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 50%;
	}

	&.bottom-caption {
		color: $body-font-color;
		background-color: fade-out($body-background-alt, 0.15);
		bottom: 0;
		left: 0;
		padding: rem-calc(18 30);
		right: 0;
	}
}
