@import "../../../default/styles/components/menu";

.catalog-menu {
	a {
		font-family: $header-font-regular;
	}
}

.catalog-menu__inner-item--level1:not(.secondary-link) > a {
	font-family: $header-font-bold;
}

.catalog-menu__item--parent > a {
	font-family: $header-font-bold;
}

// Level 2
.catalog-menu__inner-item--parent-link a {
	font-family: $header-font-bold;
}

@include breakpoint(tablet) {
	.page-wrapper:before {
		top: calc(#{$header-container-height} + 1px);
	}

	// First level under top lists
	.catalog-menu__list > li {
		> a {
			font-family: $header-font-condensed-bold;
			letter-spacing: rem-calc(0.41);
		}

		// Should be store switcher link
		&:last-of-type {
			display: none;
		}
	}

	.catalog-menu__item--parent.catalog-menu-active > a:before {
		border-color: $base-color;
	}

	.catalog-menu__item > .catalog-menu__inner-list {
		top: calc(#{$header-container-height} + 1px);
	}
}
