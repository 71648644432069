// Specific style overrides for Yotpo UGC elements

.yotpo-ugc-pdp-intro {
	padding-top: rem-calc(48);
	text-align: center;

	p {
		margin: 0 rem-calc(20);
	}
}

.yotpo-gallery {
	min-height: rem-calc(48);
}

.yotpo.yotpo-pictures-widget {
	padding-bottom: rem-calc(32);

	.yotpo-pictures-gallery-upload-button-wrapper .yotpo-pictures-gallery-upload-button {
		@include button-primary();
		@include button-typography();
		@include button-appearance($button-color: $base-color !important, $active-color: $primary-hover !important, $padding: rem-calc(32));
		@include button-oval-adjustment($border-radius-adjustment: rem-calc(32), $line-height-radius-adjustment: rem-calc(48));

		height: rem-calc(48);

		.yotpo-icon {
			color: #fff !important;
		}
	}

	.yotpo-display-wrapper .y-arrows {
		.yotpo-icon-left-arrow-light::before {
			@include svg-icon('../icons/icon-chevron-left.svg');
		}

		.yotpo-icon-right-arrow-light::before {
			@include svg-icon('../icons/icon-chevron-right.svg');
		}

		.yotpo-icon-left-arrow-light,
		.yotpo-icon-right-arrow-light {
			border-radius: 0 !important;
			opacity: 0.7 !important;
			text-indent: 0 !important;
			width: rem-calc(50) !important;

			&:before {
				right: 0 !important;
				width: 1rem;
			}
		}
	}

	.yotpo-pictures-gallery-header-wrapper {
		margin-bottom: rem-calc(32);
	}

	.y-slider-container {
		display: flex;
		justify-content: center;

		.yotpo-single-image-container {
			margin: rem-calc(0 2.5);
		}
	}

	.y-image-wrapper:hover,
	.hover-preview {
		.y-image-overlay {
			background-color: #fff !important;
			opacity: 0.7;
		}

		.yotpo-hover-icon .yotpo-icon {
			color: $body-font-color !important;
			font-size: rem-calc(34) !important;

			&.yotpo-icon-star.pull-left {
				float: none !important;
				text-align: center !important;
			}
		}

		.yotpo-hover-cta {
			@include button-typography();
			@include button-appearance($button-color: $base-color !important, $active-color: $primary-hover !important, $padding: rem-calc(12 32));
			@include button-oval-adjustment($border-radius-adjustment: rem-calc(32), $line-height-radius-adjustment: rem-calc(40));

			height: rem-calc(40) !important;
			width: rem-calc(130) !important;
		}
	}
}
