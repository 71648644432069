@import './utilities';

@mixin ft-media {
	--ft-media-container-height: 100%;
	--ft-mobile-audio-controls-button-line-height: 24px;
	--ft-mobile-audio-controls-button-padding: 12px;
	--ft-mobile-audio-controls-container-height: calc(var(--ft-mobile-audio-controls-button-padding) * 2 + var(--ft-mobile-audio-controls-button-line-height));

	video {
		height: 100%;
		object-fit: cover;
		object-position: center center;
		width: 100%;
	}

	.media-bg {
		height: 100%;
		object-fit: cover;
		object-position: var(--media-bg-position, 50% 50%);
		width: 100%;
	}

	.media-container {
		background-color: $black;
		height: var(--ft-media-container-height);
		width: 100%;
	}

	.media-controls-container {
		--ft-media-controls-columns: repeat(3, auto) 1fr;
		--ft-media-controls-rows: auto;
		--ft-media-controls-padding: 8.5px 16px;

		display: grid;
		gap: 24px;
		grid-template-columns: var(--ft-media-controls-columns);
		grid-template-rows: var(--ft-media-controls-rows);
		padding: var(--ft-media-controls-padding);

		@include media-breakpoint-up(md) {
			--ft-media-controls-columns: none;
			--ft-media-controls-padding: 0 8px;
		}

		[class*="icon-"] {
			&::before {
				@include svg-icon($width: 1.5em, $height: 1.5em);
				background-size: contain;
				display: block;
				vertical-align: bottom;
			}
		}

		.icon-mute,
		.icon-unmute {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}
		}
	}

	.icon-play::before {
		mask-image: url('../Lfi_FactoryTour/icons/icon-play.svg');
	}

	.icon-pause::before {
		mask-image: url('../Lfi_FactoryTour/icons/icon-pause.svg');
	}

	.icon-mute::before {
		mask-image: url('../Lfi_FactoryTour/icons/icon-mute.svg');
	}

	.icon-unmute::before {
		mask-image: url('../Lfi_FactoryTour/icons/icon-unmute.svg');
	}

	.icon-caption::before {
		mask-image: url('../Lfi_FactoryTour/icons/icon-cc-enable.svg');
	}

	.icon-uncaption::before {
		mask-image: url('../Lfi_FactoryTour/icons/icon-cc-disable.svg');
	}

	.mobile-audio-controls-container {
		--ft-audio-controls-bottom-position: 88px;
		bottom: var(--ft-audio-controls-bottom-position);
		display: grid;
		height: var(--ft-mobile-audio-controls-container-height);
		justify-content: end;
		position: fixed;
		right: 16px;
		z-index: 1;

		button {
			background-color: $white;
			color: $default-button-light-text-color;
			display: grid;
			font-size: 24px;
			line-height: var(--ft-mobile-audio-controls-button-line-height);
			padding: var(--ft-mobile-audio-controls-button-padding);
		}
	}

	// stylelint-disable-next-line selector-pseudo-element-no-unknown
	video::cue {
		font-size: 18px;
	}
}
