// Article List

.article-list-collapse-container,
.article-list-slider-container {
	background: map-get($lfi-palette, snow);
	padding: 80px 0;

	.article-list-header {
		margin-bottom: 24px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 72px;
			text-align: center;
		}
	}

	.article-item {
		position: relative;

		p {
			@include bold-text;
			font-size: 22px;
			line-height: 28px;
		}

		a {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}
	}
}

.article-list-slider-container {
	display: none;

	@include media-breakpoint-up(lg) {
		display: block;
	}

	.newleaf-slider {
		--newleaf-slider-slides-visible: 4;
		overflow-y: hidden;
	}

	.newleaf-circle-button-wrapper {
		margin: 0;
		position: absolute;
		right: 40px;
		top: 68px;

		@include media-breakpoint-up(xxl) {
			right: 0;
		}
	}

	.article-image {
		margin-bottom: 12px;
	}
}

.article-list-slider-wrapper {
	margin: 0 auto;
	max-width: 1360px;
	position: relative;
}

.article-list-collapse-container {
	padding: 80px 16px;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	.article-item {
		align-items: center;
		display: flex;
		margin-bottom: 24px;
	}

	.article-image {
		border-radius: 5px;
		height: 106px;
		margin-right: 12px;
		overflow: hidden;
		width: 106px;
	}

	img {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
}
