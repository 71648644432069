// Story Icon Header Block
// @doc Block can come in multiple variations with changes to font sizes and spacing
// Background colors can be changed via inline styles
// -----------------------
// section.story-icon-header-container (option for .hero, .basic)
//  div.story-icon-header-wrapper
//   span.icon-*
//   h1 (option for h2)
//   p

.story-icon-header-container {
	background: $background-theme-color;
	color: $white;
	padding: 140px 32px;
	width: 100%;

	&.hero,
	&.basic {
		background: $white;
		color: $base-color;
	}

	&.hero {
		padding: 56px 24px 72px;

		span {
			border: 2px solid map-get($lfi-palette, light-gray);
			border-radius: 50%;
			margin-bottom: 24px;
			padding: 8px;
		}

		p {
			font-size: 22px;
			line-height: 28px;
		}
	}

	&.basic {
		padding: 80px 24px;

		.story-icon-header-wrapper {
			text-align: left;

			@include media-breakpoint-up(tablet) {
				max-width: 690px;
				text-align: center;
			}
		}
	}

	span {
		display: inline-block;
		line-height: 0;
		margin-bottom: 15px;

		&::before {
			height: 48px;
			width: 48px;
		}
	}

	h1 {
		margin-bottom: 24px;
	}

	h2 {
		margin-bottom: 16px;
	}

	p {
		@include bold-text;
		font-size: 18px;
		line-height: 22px;
	}
}

.story-icon-header-wrapper {
	margin: 0 auto;
	text-align: center;
}
