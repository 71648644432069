.order-links {
	border-bottom: $border-width__base solid $border-color__base;
	margin-bottom: 10px;

	.item {
		display: inline-block;
		margin-right: 20px;
	}
}

.order-actions-toolbar {
	.action {
		margin: 0 20px 0 0;
	}
}

.order-details-items {
	.items-qty {
		@extend .abs-reset-list;

		.item {
			white-space: nowrap;
		}

		.title {
			&:after {
				content: ": ";
			}
		}
	}

	.item-options {
		dt {
			margin: 0;
		}

		dd {
			margin: 0 0 15px;
		}

		&.links {
			dt {
				display: inline-block;

				&:after {
					content: ": ";
				}
			}

			dd {
				margin: 0;
			}
		}
	}
}

.order-status {
	@extend .abs-status;
}

.form-orders-search {
	.field {
		&.email,
		&.zip {
			margin-bottom: 0;
		}
	}
}

.block-order-details {
	&-view {
		.box-content {
			.payment-method {
				.title {
					font-weight: $global-weight-normal;
				}

				.content {
					margin: 0;

					> strong {
						font-weight: $global-weight-normal;

						&:after {
							content: ": ";
						}
					}
				}
			}
		}
	}
}

.order-tracking {
	border-bottom: $border-width__base solid $border-color__base;
	margin: 0;
	padding: 20px 0;

	.tracking-title {
		display: inline-block;
	}

	.tracking-content {
		display: inline-block;
		margin: 0 0 0 5px;
	}
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
	.account {

		.toolbar {
			@extend .abs-add-clearfix-mobile;

			.pages {
				float: right;
			}

			.limiter {
				clear: both;
			}
		}
	}

	.order-details-items {
		thead {
			display: block;

			tr,
			td {
				display: block;
			}
		}
	}
}

@include max-screen($screen__m) {
	.order-pager-wrapper {
		.toolbar-amount {
			left: inherit;
			position: relative;
			text-align: center;
			top: inherit;
		}

		.pages {
			text-align: center;
		}

		.action.previous,
		.action.next {
			margin: 0;
		}
	}
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
	.order-pager-wrapper {
		.order-pager-wrapper-top {
			padding-left: 0;
			padding-right: 0;
		}

		.pages {
			float: right;
		}
	}

	.order-actions-toolbar {
		.action.print {
			display: block;
			float: right;
		}
	}
}
