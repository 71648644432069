.backorder-message {
	line-height: 1.25;

	&::before {
		@include svg-icon('../icons/icon-truck.svg');
		margin-left: rem-calc(-20);
		vertical-align: bottom;
	}

	@at-root .checkout-cart-index & {
		border-top: rem-calc(1) solid map_get($lfi-palette, light-gray);
		margin: rem-calc(20);
		padding: rem-calc(20 0 20 20);
	}

	@at-root .checkout-shipping-method & {
		border-top: rem-calc(1) solid map_get($lfi-palette, light-gray);
		margin: rem-calc(0 0 0 5);
		padding: rem-calc(20 0 20 20);
	}

	@at-root .opc-summary-ship-method & {
		line-height: rem-calc(20);
		padding-left: rem-calc(20);
		padding-top: rem-calc(4);

		span {
			font-size: rem-calc(14);
			line-height: inherit;
		}
	}
}
