/*
 * Customer account create page
 * Customer account create password page
 * ---------------------------
 * body.customer-account-create
 *  form.form-create-account
 *   ....
 *
 */

.form-create-account {
	.customer-policy {
		margin-bottom: rem-calc(30);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(70);
		}
	}

	.submit {
		margin-bottom: rem-calc(15);
	}
}

.customer-account-createpassword {
	form {
		.choice {
			margin-bottom: rem-calc(40);
		}
	}
}
