@import "../../default/styles/components/plp/grid";

// Product Group
.group-by-info {
	h2 {
		margin-bottom: rem-calc(11);
	}

	p {
		font-size: rem-calc(18);
	}
}
