//
// Minicart Styles
// ---------------
// div.minicart-modal
//  div.minicart-modal-dialog
//   div.minicart-modal-content
//    div.minicart-modal-header
//    div.minicart-modal-body
//     div.minicart-content
//      div.minicart-items-wrapper
//       ol.minicart-items
//        li.minicart-item
//       div.minicart-actions
//

.minicart-modal-header {
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;

	.action-close {
		padding: rem-calc(16);

		@include media-breakpoint-up(md) {
			padding: rem-calc(24);
		}
	}
}

.minicart-modal-content {
	max-width: none !important;
}

.minicart-modal-body {
	background: $white;
	padding: 0;
}

.minicart-content {
	margin: rem-calc(56 16 24 16);

	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: space-between;
		margin: rem-calc(64 40 48 40);
	}
}

.minicart-items-wrapper {
	margin: rem-calc(0 16 24 16);

	@include media-breakpoint-up(md) {
		margin: 0;
	}
}

.minicart-item-details {
	text-align: left;
}

.minicart-items {
	margin: 0;
}

.minicart-item {
	align-items: flex-start;
	display: flex;
	margin: 0;

	@include media-breakpoint-up(md) {
		margin-right: rem-calc(32);
	}

	.emoji {
		font-size: rem-calc(18);
	}

	h5 {
		font-size: rem-calc(16);
		margin-bottom: 0;

		@include media-breakpoint-up(md) {
			font-size: rem-calc(20);
		}
	}

	.message {
		padding: 0;

		&::before {
			display: none;
		}

		div {
			font-size: rem-calc(16);
			line-height: rem-calc(16);
			text-align: left;
		}
	}
}

.minicart-item-image-container {
	margin-right: rem-calc(16);
	width: rem-calc(64);

	@include media-breakpoint-up(md) {
		margin-right: rem-calc(24);
		width: rem-calc(88);
	}
}

.minicart-item-name {
	font-size: rem-calc(16);
	line-height: rem-calc(20);
}

.minicart-actions {
	flex: 0 0 rem-calc(176);
	text-align: center;

	.viewcart {
		margin-bottom: 0;

		@include media-breakpoint-down(sm) {
			@include button-oval-adjustment(rem-calc(24), rem-calc(48));
		}

		@include media-breakpoint-up(md) {
			margin-bottom: rem-calc(12);
		}
	}

	.continue-shopping {
		color: $base-color;
		display: none;
		text-decoration: underline;

		@include media-breakpoint-up(md) {
			display: inline-block;
		}
	}
}

// Handle case when there are no crosssells in the cart
.no-cs {
	.viewcart {
		margin-bottom: rem-calc(16);

		@include media-breakpoint-up(md) {
			margin-bottom: rem-calc(12);
		}
	}

	.continue-shopping {
		display: block;
	}
}
