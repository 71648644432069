// header.header-container.page-header
//	.header-wrapper
//		.header.content
//			ul.nav-top
//				...
//			div.branding
//				a.logo
//			div.catalog-menu-container
//			div.header-links-container
//				ul.header.links
//				...

//$logo-width: 134px !default;

$header-link-context-padding-left: rem-calc(4);
$header-link-padding: rem-calc(12);
$header-link-font-size: 14px !default;

.header-container {
	background: $white;
	height: auto;
	position: sticky;
	top: 0;
	transition: var(--lfi-top-transition);
	z-index: z-index(header);

	@at-root .scrolling-down & {
		top: calc(#{-1 * $header-container-height} - 1px);
	}

	@at-root .scrolling-up {
		--lfi-top-space-used: #{$header-container-height};
	}

	.header-wrapper {
		height: 100%;
	}

	.header.content {
		@include xy-grid($wrap: false);
		height: $header-container-height;
		justify-content: space-between;

		.header-links-container,
		.branding {
			flex-basis: 0;
			flex-grow: 1;
		}
	}

	.branding {
		.logo {
			height: 100%;
			margin-left: rem-calc(16);

			@include breakpoint(medium) {
				margin-left: rem-calc(24);
			}
		}
	}

	.header.links {
		display: flex;
		height: 100%;
		justify-content: flex-end;
		padding-right: rem-calc(6.5);

		@include breakpoint(medium) {
			padding-right: rem-calc(15);
		}

		li,
		a,
		#bfx-cc-insert-after {
			height: 100%;
		}

		a,
		#bfx-cc-insert-after {
			align-items: center;
			display: flex;
			padding-left: $header-link-padding;
			padding-right: $header-link-padding;
			position: relative;

			i {
				display: flex;
			}
		}

		.slide-out-menu-link {
			@include media-breakpoint-up(tablet) {
				display: none;
			}
		}

		.my-account-link {
			display: none;

			@include media-breakpoint-up(tablet) {
				display: list-item;
			}
		}

		[class^="icon-"]::before {
			height: rem-calc(24);
			width: rem-calc(24);
		}

		[role="context"] {
			font-family: $header-link-context-font;
			font-size: $header-link-font-size;
			max-width: 150px;
			overflow: hidden;
			padding-left: $header-link-context-padding-left;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	[data-summary-count] {
		&:after {
			align-items: center;
			background: $primary-color;
			border-radius: 50%;
			color: $white;
			content: attr(data-summary-count);
			display: flex;
			font-size: 9px;
			font-weight: $header-font-weight;
			height: 16px;
			justify-content: center;
			margin-top: -8px;
			position: absolute;
			right: 6px;
			top: calc(#{$header-container-height * 0.5} - 11px);
			width: 16px;
		}

		&[data-summary-count="0"] {
			&::after {
				display: none;
			}
		}
	}

	ol,
	ul,
	li {
		list-style: none;
		margin: 0;
	}

	a,
	button {
		-webkit-tap-highlight-color: transparent;
	}
}

.header-banner ~ [id="maincontent"] .main {
	margin-top: 0;
}
