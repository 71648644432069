.borderfree {
	.bfx-cc-expanded {
		min-height: auto !important;
		color: $body-font-color !important;
		background-color: $light-gray !important;

		.bfx-cc-logo-wrapper {
			display: none !important;
		}

		.bfx-cc-label {
			color: $body-font-color !important;
		}

		/* stylelint-disable max-nesting-depth */
		.bfx-cc-text {
			color: $body-font-color !important;
			text-transform: none;

			a {
				color: $body-font-color !important;
				text-decoration: underline !important;

				&:hover,
				&:focus {
					color: $body-text-hover-color !important;
				}
			}
		}
		/* stylelint-enable max-nesting-depth */

		select {
			background-color: $white !important;
			color: $body-font-color !important;
		}

		#bfx-cc-btn {
			background-color: $button-color-alt !important;
			color: $default-button-text-color !important;
		}
	}

	.bfx-cc-collapsed {
		height: auto !important; // must use important to override inline style

		[id="bfx-cc-flag-link"] {
			img {
				width: auto;
			}
		}
	}

	// Styles to apply only in the context of the header nav
	.header.links {
		.bfx-cc-collapsed {
			@include breakpoint(small only) {
				display: none !important;
			}
		}
	}
}
