/*
 * Homepage Go There About
 */

.go-there-about {
	div.banner-background {
		min-height: rem-calc(580);

		@include breakpoint(xlarge) {
			min-height: rem-calc(596);
		}

		@include breakpoint(xlarge) {
			min-height: rem-calc(720);
		}
	}

	h3 {
		text-transform: uppercase;
	}

	.banner-content {
		p {
			font-family: $body-font-family;
			font-size: $global-font-size;
		}
	}
}
