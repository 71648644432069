//
// Boot Care Guide List
//

.boot-care-guide-list-container {
	padding: 64px 24px;
}

.boot-care-guide-list-header {
	@include media-breakpoint-up(lg) {
		margin: 0 auto;
		max-width: 674px;
		text-align: center;
	}

	p {
		font-size: 18px;
		line-height: 26px;
	}
}

.boot-care-guide-list {
	padding: 40px 0;

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-column-gap: 48px;
		grid-template-columns: repeat(3, auto);
		margin: 0 auto;
		max-width: 681px;
		padding: 56px 0;
	}
}

.boot-care-guide-list-item {
	align-items: center;
	display: flex;
	margin-bottom: 32px;
	position: relative;

	@include media-breakpoint-up(lg) {
		display: grid;
		justify-content: center;
	}

	&:hover {
		img {
			box-shadow: 0 0 0 4px $background-primary-color;
			outline: 2px solid $white;
		}

		a,
		p {
			color: $primary-hover;
		}
	}

	img {
		border-radius: 50%;
		margin-right: 24px;
		width: 106px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 24px;
			margin-right: 0;
			width: 195px;
		}
	}

	p {
		@include bold-text;
		color: $base-primary-color;
		font-size: 22px;
		line-height: 28px;

		@include media-breakpoint-up(lg) {
			text-align: center;
		}
	}

	a {
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 2;
	}
}
