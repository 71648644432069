@mixin ft-slide {
	--ft-menu-height-adjust: var(--ft-menu-height);
	--padding: 16px;

	@include media-breakpoint-up(md) {
		--ft-menu-height-adjust: 0px;
	}

	.slide {
		display: grid;
		height: calc(100vh - var(--ft-menu-height-adjust));
		overflow: hidden;
		pointer-events: none; // allow click-through to media controls
		position: relative;
		width: 100vw;

		&.chapter-title,
		&.floating-text {
			align-items: center;
			justify-content: center;

			.content {
				text-align: center;
			}
		}

		&.chapter-title {
			background-color: $background-primary-color;
			color: $body-inverted-font-color;
			height: auto;
			min-height: 100vh;

			.content {
				margin: var(--space-between);

				@include breakpoint(medium) {
					--padding: 0;
					--space-between: #{rem-calc(48)};
					--max-width: #{rem-calc(576)};
				}

				* + * {
					--space-between: #{rem-calc(40)};
					margin-bottom: 0;
					margin-top: var(--space-between);
				}
			}
		}

		&.floating-text {
			.media-container {
				--ft-media-container-height: 100vh;
				position: absolute;
				width: 100vw;
			}

			.content {
				color: $body-inverted-font-color;
				text-shadow: #000 0 0 3px;
			}
		}

		&.embed {
			--media-container-row-height: 52vh;
			--slide-padding: var(--padding);
			--upper-padding-row-height: var(--slide-padding);

			grid-template: var(--upper-padding-row-height) 0 var(--media-container-row-height) auto / calc(100vw - 2 * var(--slide-padding));
			grid-template-areas: "." "media-controls" "media" "content";
			height: auto;
			justify-items: center;
			padding: 0 var(--slide-padding);
			pointer-events: auto;

			@include media-breakpoint-up(lg) {
				--media-container-row-height: 61vh;
				--slide-padding: 10vw; // custom padding, no other instances known
				--upper-padding-row-height: 5vh;
			}

			&:last-of-type {
				height: 100vh;
			}
			+ *:not(.slide.embed) {
				margin-top: 17vh;
			}

			.media-container {
				grid-area: media;
				position: relative;
			}

			.mobile-audio-controls-container {
				--ft-audio-controls-bottom-position: 24px;
				position: absolute;
			}

			.content {
				grid-area: content;
				padding-left: rem-calc(17);
				padding-right: rem-calc(16);
				text-align: center;

				@include breakpoint(large) {
					--max-width: #{rem-calc(752)};
				}
			}
		}

		.content {
			--max-width: #{rem-calc(488)};
			--padding: #{rem-calc(24)};
			max-width: var(--max-width);
			padding: var(--padding);
			// stylelint-disable-next-line property-no-vendor-prefix
			-webkit-transform: translate3d(0, 0, 0);
			z-index: 1;
		}
	}

	.slide-group {
		position: relative;

		.media-container {
			--ft-media-container-height: calc(100vh - var(--ft-menu-height-adjust));
			overflow: hidden;
			position: absolute;
			top: 0;
		}
	}

	.crossfade {
		.slide-group:not(:last-of-type) {
			margin-bottom: -50vh;
		}
		.media-container-pinned.active {
			z-index: 1;
		}
	}

	.materials-zoom-group {
		display: grid;
		grid-template: 0 calc(75vh - var(--ft-menu-height-adjust)) 25vh / 100vw;
		grid-template-areas: "media-controls" "media" "content";

		.media-controls-container {
			display: none;
		}

		.media-container {
			grid-area: media;
			overflow: hidden;
		}

		.content-container {
			grid-area: content;
			position: relative;
			text-align: center;
		}

		.materials-zoom {
			background: $white;
			bottom: 0;
			left: 0;
			padding: rem-calc(24 32);
			position: absolute;
			right: 0;
			top: 0;

			.content {
				align-content: center;
				display: grid;
				height: 100%;
				justify-content: center;
				margin: 0 auto;
				max-width: 640px;
			}
		}
	}
}
