// div.category-custom-back-link
//  a
//   span.icon-arrow
//   span
//   span

.category-custom-back-link {
	.icon-arrow {
		margin-right: 12px;

		&::before {
			transform: rotate(180deg);
		}
	}

	a {
		display: flex;

		@include media-breakpoint-up(md) {
			justify-content: center;
		}
	}

	span {
		@include bold-text;
		font-size: 18px;
		line-height: 22px;

		&:last-of-type {
			margin-left: 5px;
		}
	}
}
