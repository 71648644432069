@include slick-block-slider(
	$container-selector: ".products-slides",
	$list-selector: ".products-slides .product-items",
	$list-item-selector: ".products-slides .product-item"
) {
	.products-slides .product-item {
		flex: 0 0 calc(100vw / 2 + 40px);

		@include breakpoint(medium) {
			flex: 0 0 calc(100vw / 3 - 23px);
		}

		@include breakpoint(xlarge) {
			flex: 0 0 calc(100vw / 4 - 20px);
		}
	}
}
