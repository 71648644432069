/*
 * Slick Gallery Widget
 * --------------------
 * section.slick-gallery-widget
 *  h4
 *  div.gallery-wrapper
 *   div.slick-gallery
 *    ...
 *    div.gallery-item
 */

.slick-gallery-widget {
	padding: rem-calc(70 15 40 15);
	overflow: hidden;

	@include breakpoint(medium) {
		padding-left: rem-calc(30);
		padding-right: rem-calc(30);
	}

	@include breakpoint(large) {
		padding: rem-calc(110 141 80 141);
	}

	.slick-initialized {
		@include slick-controls;

		@include breakpoint(medium down) {
			.slick-dots {
				visibility: hidden;
			}
		}
	}

	.gallery-wrapper {
		margin: 0 auto;
		max-width: rem-calc(1158);
	}

	.gallery-item {
		background-repeat: no-repeat;
		background-size: cover;
		height: rem-calc(460);
		width: 100%;

		@include breakpoint(medium) {
			height: rem-calc(724);
		}

		&:nth-of-type(n + 2) {
			display: none;
		}
	}

	h4 {
		text-align: center;
		margin-bottom: rem-calc(30);
	}
}
