@import "../../../default/styles/helpers/buttons";

@mixin button-primary {
	@include button-core;
	@include button-typography;

	@include button-appearance($button-color: map_get($danner-palette, forest), $active-color: $primary-hover);
}

@mixin button-primary-light {
	@include button-primary;
	@include button-light-appearance;
}

@mixin button-oval {
	@include button-primary;
	@include button-oval-adjustment;
}

@mixin button-secondary {
	@include button-core;
	@include button-typography;

	@include button-appearance(
		$button-color: map_get($lfi-palette, light-gray),
		$text-color: map_get($danner-palette, forest)
	);
}

@mixin button-type-hollow($color: false, $inverted: false) {
	@if ($color == false) {
		$color: $white;
	}

	$button-active-text-color: map-get($danner-palette, forest);
	@if ($inverted) {
		$button-active-text-color: $white;
	}

	@include button-core;
	@include button-typography;
	@include button-appearance(
		$active-color: $color,
		$active-text-color: $button-active-text-color,
		$button-color: transparent,
		$border-color: $color,
		$padding: rem-calc(14 30),
		$text-color: $color,
		$has-border: true
	);
}

@mixin button-input-combined {
	@include button-core;
	@include button-typography(
		$font-family: $header-font-bold,
		$font-size: rem-calc(15),
		$line-height: rem-calc(21)
	);

	@include button-appearance(
		$button-color: map_get($lfi-palette, light-gray),
		$text-color: map_get($danner-palette, forest)
	);

	// override Foundation :(
	border-radius: rem-calc(0 5 5 0) !important;
	padding: 0 rem-calc(32) !important;
}

@mixin button-hollow-primary() {
	@include button-core;
	@include button-typography;

	@include button-appearance(
		$active-color: $base-color,
		$active-text-color: $white,
		$border-color: $base-color,
		$button-color: transparent,
		$has-border: true,
		$text-color: $base-color,
		$padding: 13px 28px
	);
}
