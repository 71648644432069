/*
 * Go There Story Section
 * ----------------------
 * div.go-there-story-container
 *  div.go-there-story-wrapper
 *   div.story-image
 *   div.story-content
 *    h4.subheader
 *    p
 *    div.story-actions
 *     a.link-button
 *     a.link-button
 *  div.reveal
 *   ....
 */

.go-there-story-container {
	@include adjust-padding(
		rem-calc(70 15 20 15),
		rem-calc(90 50 20 50),
		rem-calc(90 107 20 107),
		rem-calc(110 107 40 107)
	);
}

.go-there-story-wrapper {
	margin: rem-calc(0) auto rem-calc(45) auto;
	max-width: rem-calc(923);

	@include breakpoint(medium) {
		margin-bottom: rem-calc(65);
	}

	.story-content p {
		margin-bottom: rem-calc(25);
	}
}

.story-image {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: rem-calc(460);
	margin-bottom: rem-calc(20);
	position: relative;

	@include breakpoint(medium) {
		height: rem-calc(506);
	}

	@include breakpoint(xlarge) {
		height: rem-calc(577);
	}
}

.story-content {
	text-align: center;

	h4 {
		margin-bottom: rem-calc(6);
	}

	.link-button:first-of-type {
		margin-right: rem-calc(15);
	}

	h4,
	p {
		color: map-get($danner-palette, forest);
	}
}

/*
 * Go There Story Section
 * ----------------------
 */

.go-there-story-2-columns,
.go-there-story-3-columns {
	background: map_get($lfi-palette, snow);

	.go-there-story-wrapper {
		flex: 1 1 100%;
	}

	.story-content {
		padding: rem-calc(0 15);

		.link-button {
			margin: 0;
		}
	}
}

/*
 * Go There Story Section 2 Columns
 * ----------------------
 * div.go-there-story-container.go-there-story-2-columns
 */

.go-there-story-2-columns {
	@include adjust-padding(
		rem-calc(70 25 20 25),
		rem-calc(90 50 20 50),
		rem-calc(110 141 40 141),
		rem-calc(110 141 40 141)
	);

	@include breakpoint(medium) {
		display: flex;
		justify-content: space-around;
	}

	.go-there-story-wrapper {
		max-width: rem-calc(574);

		@include breakpoint(medium) {
			margin-right: rem-calc(10);
		}

		&:last-of-type {
			@include breakpoint(medium) {
				margin-left: 0;
				margin-right: auto;
			}
		}
	}

	.story-image {
		height: rem-calc(325);

		@include breakpoint(medium) {
			height: rem-calc(454);
		}

		@include breakpoint(xlarge) {
			height: rem-calc(574);
		}
	}
}

/*
 * Go There Story Section 3 Columns
 * ----------------------
 * div.go-there-story-container.go-there-story-3-columns
 */

.go-there-story-3-columns {
	@include adjust-padding(
		rem-calc(70 25 20 25),
		rem-calc(90 50 20 50),
		$xlarge: rem-calc(110 141 40 141)
	);

	@include breakpoint(large) {
		display: flex;
		justify-content: space-around;
	}

	.go-there-story-wrapper {
		@include breakpoint(large) {
			margin-right: rem-calc(10);
			max-width: rem-calc(574);
		}

		&:last-of-type {
			@include breakpoint(large) {
				margin-left: 0;
				margin-right: auto;
			}
		}
	}

	.story-image {
		height: rem-calc(325);

		@include breakpoint(medium) {
			height: rem-calc(506);
		}

		@include breakpoint(large) {
			height: rem-calc(454);
		}

		@include breakpoint(xlarge) {
			height: rem-calc(574);
		}
	}
}
