/*
 * Content Feature Blocks Homepage Section
 * ---------------------------------------
 * section.go-there-content-feature-blocks
 *  section.widget-banner-featured.feature-block-container
 *   div.content-feature-block
 *    div.overlay
 *    a
 *    div.content-feature-block-wrapper
 *     h2
 *     p
 *     a.button.hollow-white-hollow
 *
 */

.go-there-content-feature-blocks {
	margin: 0 auto;
	max-width: rem-calc(1440);

	.feature-block-container {
		display: flex;
		flex-flow: row wrap;
		margin: rem-calc(10 10 0 10);

		@include breakpoint(medium) {
			margin: rem-calc(20);
		}
	}
}

.content-feature-block {
	width: auto;
}

.content-feature-block-container {
	align-items: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	margin-bottom: rem-calc(10);
	min-height: rem-calc(550);
	position: relative;

	@include breakpoint(medium) {
		margin: rem-calc(5);
		min-height: rem-calc(487);
	}

	@include breakpoint(xlarge) {
		min-height: rem-calc(695);
	}

	.overlay {
		@extend .full-element-overlay;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	> a {
		@extend .full-element-overlay;
		z-index: 3;
	}
}

.content-feature-block-wrapper {
	color: $white;
	max-width: rem-calc(450);
	text-align: center;
	padding: rem-calc(0 25);
	z-index: 2;

	h3 {
		text-transform: uppercase;
	}

	p {
		padding-bottom: rem-calc(26);
	}
}
