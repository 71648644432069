.catalog-product-view {
	.page-main {
		margin-top: rem-calc(24);

		@include breakpoint($screen__m) {
			margin-top: rem-calc(32);
		}
	}

	.breadcrumbs {
		@include xy-grid-container();
		display: block;
		justify-content: revert;
		margin: 0;
		min-height: 3rem;
		padding-bottom: 0;
		padding-top: 0;

		@include breakpoint($screen__m) {
			padding: 0;
		}
	}

	.page-title-wrapper {
		padding-bottom: 0;
		text-align: revert;

		@include breakpoint(small only) {
			margin-bottom: 1rem;
		}

		@include breakpoint($screen__m) {
			padding: 0;
		}

		.subtitle {
			color: $body-medium-font-color;
		}
	}

	.page-title {
		@extend .h3;
		line-height: rem-calc(24);
		margin-bottom: rem-calc(8);

		@include breakpoint($screen__m) {
			font-size: rem-calc(28);
		}
	}

	.subtitle {
		@include bold-text();
		margin-bottom: 0;
	}
}
