.page.messages {
	@include xy-grid-container();

	~ .columns .column.main {
		padding-top: 0;
	}
}

.messages {
	width: 100%;

	// Icon | Message (Stacked)
	.message {
		&::before {
			display: block;
			margin-bottom: rem-calc(12);
			width: 100%;
		}
	}

	&:blank {
		margin: 0;
	}
}

// Global Message Styles
.message {
	border-radius: $global-radius;
	font-size: $global-font-size;
	line-height: initial;
	margin: rem-calc(12) 0;
	mix-blend-mode: multiply;
	text-align: center;

	// For Company messages shown using JS templates
	&._hidden {
		display: none !important; // apply in checkout
	}

	> div,
	p {
		line-height: 1.2;
		text-align: center;
		width: 100%;

		a {
			color: inherit;
			font-weight: $global-weight-bold;

			&:not(.button) {
				text-decoration: underline;
			}
		}
	}

	> div,
	p:last-of-type {
		margin-bottom: 0;
	}

	p.message-text + a.button {
		margin-top: rem-calc(15);
	}

	+ .message {
		margin-top: 0;
	}

	// Icon | Message (Horizontal)
	&.field,
	&.small {
		align-items: center;
		display: flex;
		font-size: rem-calc(14);
		line-height: rem-calc(20);
		opacity: 0.75;

		p {
			flex-wrap: nowrap;
			line-height: initial;
			margin-top: rem-calc(1);
		}

		&.success {
			background: fade-out($success-color, 0.9);
		}

		&.error {
			background: $alert-color;
			color: $white;
		}
	}

	button,
	.button,
	[type="button"] {
		display: block;
		margin-bottom: 0;
		margin-top: rem-calc(15);
		margin-left: auto;
		margin-right: auto;
		max-width: rem-calc(300);
		width: 100%;

		&:hover {
			display: block;
		}
	}

	&.info::before,
	&.success::before,
	&.notice::before,
	&.warning::before,
	&.error::before {
		@include svg-icon;
	}

	&.notice,
	&.warning,
	&.error {
		color: $alert-color;

		button,
		.button,
		[type="button"] {
			@include button-type-hollow($alert-color, true);
		}
	}

	// Success Message
	&.success {
		color: $success-color;

		&::before {
			mask-image: url('../icons/icon-circle-success.svg');
		}

		button,
		.button,
		[type="button"] {
			@include button-type-hollow($success-color, true);
		}
	}

	// Info Message
	&.info {

		&::before {
			mask-image: url('../icons/icon-circle-info.svg');
			transform: rotate(180deg);
		}

		button,
		.button,
		[type="button"] {
			@include button-type-hollow(map-get($lfi-palette, jet), true);
		}
	}

	// Notice Message
	&.notice {
		&::before {
			mask-image: url('../icons/icon-circle-info.svg');
		}
	}

	// Error & Warning Message
	&.error,
	&.warning {
		&::before {
			mask-image: url('../icons/icon-circle-error.svg');
		}
	}
}

.note {
	color: map-get($lfi-palette, stone);
	display: flex;
	font-size: rem-calc(15);
	line-height: 1.2;
	margin: rem-calc(12 0);
	padding-left: rem-calc(21);
	position: relative;

	&::before {
		@include svg-icon('../icons/icon-circle-info.svg');
		left: 0;
		position: absolute;
		top: rem-calc(1.5);
		transform: rotate(180deg);
	}
}
