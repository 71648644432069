.checkout-cart-index {
	@include breakpoint(small only) {
		// Suppress Zendesk web widget on mobile
		iframe#launcher {
			display: none !important;
		}
	}

	.page.messages {
		@include inline-messages();
		margin-top: 15px;
	}
}

.cart-container {
	@include xy-grid();
	justify-content: space-between;
	margin-top: rem-calc(40);

	.aafes &,
	.borderfree & {
		margin-top: rem-calc(60);
	}
}

.cart-form-wrapper {
	@include xy-cell(12);

	@include breakpoint(medium) {
		@include xy-cell(6 of 12);
	}

	@include breakpoint(large) {
		@include xy-cell(7 of 12);
	}

	h5 {
		margin-bottom: 0;

		@include breakpoint(small only) {
			text-align: center;
		}
	}
}

.cart.item {
	@include xy-grid();
	border-bottom: rem-calc(1) solid map-get($lfi-palette, light-gray);
	padding: rem-calc(30 0);

	@include breakpoint(small only) {
		&:last-child {
			border-bottom: none;
		}
	}

	.cell:first-child {
		@include xy-cell(4 of 12, $gutters: 0);
	}

	.product-item-details {
		@include xy-cell(8 of 12, $gutters: 0);
		color: $header-color;
		line-height: rem-calc(21);
		padding-left: rem-calc(15);
		position: relative;

		@include breakpoint(medium) {
			margin-top: rem-calc(15);
		}

		.message {
			justify-content: left;
		}
	}

	.product-item-photo {
		display: block;
	}

	.item-options {
		@include item-options();
	}

	&.recrafting_service .item-options dt {
		display: inline-block;
		font-family: $body-font-family;
	}

	.price.cell,
	.subtotal.cell {
		// TODO $header-font-condensed-medium for danner
		margin: rem-calc(5 0);

		@include breakpoint(medium) {
			margin: 0;
			position: absolute;
			right: 0;
			text-align: right;
			top: 0;
			width: auto;
		}

		.has-markdown {
			color: $primary-color;
			padding-left: rem-calc(6);
		}
	}

	// Swap visibility between unit price and subtotal between domestic and international
	.price.cell {
		display: none;

		.borderfree & {
			display: block;
		}
	}
	.subtotal.cell {
		display: block;

		.borderfree & {
			display: none;
		}
	}

	.qty.cell {
		$qty-h: rem-calc(32);
		$qty-input-w: rem-calc(64);

		display: flex;
		margin-top: rem-calc(16);
		width: 100%;

		&:before {
			content: attr(data-label);
			line-height: $qty-h;
		}

		.control {
			height: $qty-h;
			margin: rem-calc(0 0 0 30);
			width: $qty-input-w;
		}

		select {
			color: $base-color;
			height: 100%;
			padding: rem-calc(0 9);
		}

		select[disabled]:hover {
			cursor: default;
		}
	}

	.item-actions {
		margin-top: rem-calc(16);

		a.action {
			@include bold-text();

			&:not(:first-child) {
				margin-left: rem-calc(16);
			}
		}
	}
}

.cart-expedited-ship-callout {
	@include breakpoint(small only) {
		text-align: center;
	}

	> div {
		@include rounded-container();
		margin-bottom: rem-calc(40);
		padding: rem-calc(19);
	}

	h5 {
		margin-bottom: rem-calc(6);
	}

	p {
		margin-bottom: 0;
	}

	a {
		text-decoration: underline;
	}
}
