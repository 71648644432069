@mixin totals-table(
	$border: rem-calc(1) solid rgba(0, 0, 0, 0.1),
	$padding: rem-calc(30 20)
) {
	border-bottom: $border;
	margin: 0;

	.grand {
		border-top: $border;
		margin-top: rem-calc(25);

		th,
		td {
			@include bold-text();
		}

		> * {
			padding-top: rem-calc(10);
		}
	}

	.mark {
		display: flex;
		flex-flow: row wrap;
	}

	.label {
		font-size: rem-calc(16);
		line-height: rem-calc(23);
	}

	.description,
	.value {
		color: $body-color;
		font-size: rem-calc(14);
		line-height: rem-calc(21);
		opacity: 0.7;
	}

	.label,
	.value {
		flex: 1 1 100%;
		text-align: left;
	}

	tbody {
		border: none;
		display: inline-block;
		padding: $padding;
		width: 100%;
	}

	th,
	td {
		font-weight: $font-weight__regular;
		padding: 0;
		text-align: left;
	}

	th {
		display: flex;
		flex: 1 1 100%;
		a {
			text-decoration: underline;
		}
	}

	tr {
		display: flex;
		justify-content: space-between;
		font-size: rem-calc(16);
		line-height: rem-calc(23);
		margin-bottom: rem-calc(10);

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
