// Global Loader
.loader img {
	max-width: rem-calc(50);
}

%img-fills-container {
	height: 100%;
	object-fit: cover;
	width: 100%;
}
