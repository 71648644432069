$filter-button-height: rem-calc(48);

// Color Swatches
$m2la-color-swatch-map: (
	"black": #2b2a27,
	"blue": #59729c,
	"brown": #8b664b,
	"gray": #a7a4a7,
	"green": #93a083,
	"red": #c6383f,
	"tan": #c3af83
) !default;

.filter-sizes,
.filter-wth_alias {
	display: grid;
	grid-column-gap: rem-calc(8);

	.item {
		height: rem-calc(48);
		margin-bottom: rem-calc(8) !important;

		label {
			display: flex;
			padding: 0;
		}
	}

	// Hover should only apply to non touch devices
	html.no-touch & {
		// hover state only on enabled options
		input:not(:disabled) ~ label:hover {
			background: $background-primary-color;
			color: $white;
		}
	}

	input {
		display: none;
	}

	input:checked + label {
		background: $background-primary-color;
		color: $white;
	}

	label {
		align-items: center;
		background: $white;
		border: rem-calc(1) solid map-get($lfi-palette, light-gray);
		border-radius: rem-calc(4);
		height: 100%;
		justify-content: center;
	}
}

.filter-sizes {
	grid-template-columns: repeat(4, 1fr);
}

.filter-wth_alias {
	grid-template-columns: repeat(2, 1fr);
}

.filter-filter_color {
	display: flex;
	flex-flow: row wrap;
	// margin adjustment for item gutters
	margin: rem-calc(0 -15) !important;

	@include breakpoint(medium) {
		// margin adjustment for item gutters
		margin: rem-calc(0 -20) !important;
	}

	@each $color-name, $color-value in $m2la-color-swatch-map {
		.#{$color-name} input {
			background-color: $color-value;
		}
	}

	.item {
		flex: 0 0 rem-calc(46);
		flex-direction: column;
		margin: rem-calc(0 20 34 20);

		@include breakpoint(medium) {
			margin-left: rem-calc(15);
			margin-right: rem-calc(15);
		}

		label {
			// overrides
			margin: 0;
			padding: rem-calc(10 0 0 0);
		}

		input:checked {
			box-shadow: 0 0 0 rem-calc(1) map-get($lfi-palette, jet);
		}

		// Hover should only apply to non touch devices
		html.no-touch & {
			&:hover input:not(:disabled) {
				box-shadow: 0 0 0 rem-calc(1) scale-color(map-get($lfi-palette, jet), $lightness: 20%);
			}

			&:hover label {
				color: scale-color(map-get($lfi-palette, jet), $lightness: 20%);
			}
		}
	}

	input {
		// special input needs to override defaults
		border: rem-calc(5) solid $white !important;
		border-radius: rem-calc(50);
		flex: unset;
		height: rem-calc(48);
		width: rem-calc(48);

		@include breakpoint(medium) {
			// special input needs to override defaults
			border-color: map-get($lfi-palette, snow) !important;
		}

		&:before {
			// override because of our custom input
			display: none !important;
		}

		&:disabled {
			background-color: $input-background-disabled;
		}
	}
}
