.hero-banner {
	@include aspect-ratio();
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;

	&::before {
		height: 100%;
		vertical-align: middle;
		margin-right: -0.25rem;
	}

	.hero-content {
		color: $white;
		text-transform: uppercase;
	}

	.play-video {
		margin-top: rem-calc(9);

		a {
			font-size: rem-calc(18);
		}
	}
}
