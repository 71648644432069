@mixin ft-footer {
	.factory-tour-footer {
		text-align: center;

		.factory-tour-footer-top {
			padding: rem-calc(64) 0;

			@include breakpoint(medium) {
				padding: rem-calc(144) 0;
			}

			h4 {
				margin-bottom: rem-calc(40);
			}
		}

		.factory-tour-footer-links {
			background-color: $body-background-alt;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
		}

		.factory-tour-footer-link {
			flex: 0 1 rem-calc(340);
			padding: rem-calc(40) 0;

			@include breakpoint(medium) {
				padding: rem-calc(64) 0;
			}
		}

		p {
			margin-bottom: 1rem;
		}

		a.learn-more {
			&::after {
				border: 0 currentColor solid;
				border-bottom-width: rem-calc(2);
				border-right-width: rem-calc(2);
				content: '';
				display: inline-block;
				height: rem-calc(8);
				margin-left: rem-calc(8);
				transform: rotate(-45deg);
				width: rem-calc(8);
			}
		}
	}

	*[class^="icon-footer-"]::before {
		font-size: rem-calc(56);
	}

	.icon-footer-story {
		&::before {
			mask-image: url('../Lfi_FactoryTour/icons/icon-footer-story.svg');
		}
	}

	.icon-footer-recrafting {
		&::before {
			mask-image: url('../Lfi_FactoryTour/icons/icon-footer-recrafting.svg');
		}
	}

	.icon-footer-go-there {
		&::before {
			mask-image: url('../Lfi_FactoryTour/icons/icon-footer-go-there.svg');
			width: 2em; // special case for a wide icon
		}
	}
}
