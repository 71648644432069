///
// Custom Colors
$lfi-palette: (
	jet: #2b2a27,
	jet-hover: #605e58,
	metal: #666,
	stone: #7d7a71,
	cream: #9a978f,
	light-gray-hover: #b3b3ad,
	cream-hover:#cac9c5,
	latte: #e5e3e1,
	light-gray: #e4e4e2,
	snow: #f7f7f6,
	sleet: #f1f3f2,
	white: #fff,
	evergreen: #317f56,
	cherry: #c10230
) !default;

$white: map-get($lfi-palette, white);
$white-hover: #ccc;
$black: #000;
$accent-color: gold;
$accent-font-color: map_get($lfi-palette, jet);
$anchor-color: $black;
$info-color: map-get($lfi-palette, evergreen);
$anchor-color-hover: $black;
$base-color: map_get($lfi-palette, jet);
$base-primary-color: map_get($lfi-palette, jet);
$dark-primary-color: $black;
$background-theme-color: map-get($lfi-palette, snow);
$background-primary-color: map_get($lfi-palette, jet);
$background-secondary: map-get($lfi-palette, snow);
$body-background-alt: map-get($lfi-palette, snow);
$body-background-inverted: map-get($lfi-palette, jet);
$body-dark-font-color: map-get($lfi-palette, jet);
$body-dark-font-color-hover: map-get($lfi-palette, jet-hover);
$body-inverted-font-color: $white;
$body-inverted-font-color-hover: $white-hover;
$body-medium-dark-font-color: map-get($lfi-palette, stone);
$input-group-color: map-get($lfi-palette, jet);
$primary-hover: map-get($lfi-palette, jet);
$base-disabled: mix(map-get($lfi-palette, jet), $white, 20%);
$primary-disabled: $base-disabled;
