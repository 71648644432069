.table-wrapper {
	// Pagination
	.cart-products-toolbar {
		background: $body-background-alt;
		border-radius: $global-radius;
		color: $body-font-color;
		padding: $global-padding * 0.5 $global-padding;

		&-top {
			margin: rem-calc(-21 0 21);
		}

		&-bottom {
			margin: rem-calc(21 0 0);
		}

		.toolbar-amount {
			font-size: rem-calc(12);
			font-weight: $global-weight-bold;
			text-transform: uppercase;
		}

		.pages {
			margin-top: rem-calc(9);

			span {
				font-size: $global-font-size;
			}

			ul.items {
				display: flex;
				align-items: center;
				width: 100%;
			}

			li.item {
				text-align: center;
				width: rem-calc(60);

				&.current {
					color: $body-background-alt;
				}
			}
		}

		.page {
			padding: 0;
		}

		a.action {
			border: 0;
			border-radius: $global-radius;
		}
	}
}
