//
// One Page Checkout base styles
//

.checkout-index-index {
	.page-title-wrapper {
		display: none;
	}

	.step-title {
		@include bold-text();
		font-size: rem-calc(18);
		line-height: rem-calc(18);
		margin-bottom: rem-calc(30);
		text-align: center;
	}
}

.opc-wrapper {
	align-items: flex-start;
	display: flex;
	flex-flow: row wrap;
	margin: 0 auto;
	max-width: $max-page-width;
	padding-bottom: rem-calc(50);

	@include breakpoint(tablet) {
		flex-flow: row nowrap;
		justify-content: center;
	}
}

.opc-sidebar-collapsible {
	order: 1;
	flex: 1 1 100%;

	@include breakpoint(medium down) {
		padding: rem-calc(0 15);
	}

	@include breakpoint(tablet) {
		max-width: rem-calc(352);
		order: 2;
	}
}

.opc {
	flex: 1 1 100%;
	list-style: none;
	padding: rem-calc(0 15);
	margin: 0;
	order: 2;

	@include breakpoint(tablet) {
		border: rem-calc(1) solid map_get($lfi-palette, light-gray);
		border-radius: rem-calc(10);
		margin-right: rem-calc(20);
		max-width: rem-calc(512);
		padding: rem-calc(40 20 30 20);
		order: 1;
	}

	@include breakpoint(large) {
		margin-right: rem-calc(40);
		padding: rem-calc(50 40 40 40);
	}
}
