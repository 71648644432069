:root {
	--hubbox-text-color: #2B2A27;
	--hubbox-title-font-size: 16px;
	--hubbox-subtitle-font-size: 14px;
	--hubbox-font: inherit;
	--hubbox-font-bold: DIN W01 Bold;
}

hb-widget-modal::part(modal-close) {
	background-image: url(https://www.danner.com/static/version1699298257/frontend/Lfi/danner/en_US/icons/icon-close.svg);
	background-color:transparent;
	background-size: 110%;
	right: 15px;
	top: 15px;
}

@media only screen and (max-width: 640px) {
	hb-widget-modal::part(modal-close) {
		border-radius: 0%;
		right: 1.5em;
		top: 1.5em;
		border: 0px;
	}
}

hb-core-group::part(container) {
	display:flex;
	flex-direction: row-reverse;
	gap: 8px;
	padding-bottom: 20px;
}

hb-core-search::part(container container-active) {
	text-align: center;
	border: 2px solid var(--hubbox-text-color);
	border-radius: 8px;
	padding: 16px;
	position: relative;
	width: 100%;
	font-weight: bold;
	font-family: var(--hubbox-font-bold);
}

hb-core-search::part(container container-inactive) {
	border-radius: 8px;
	align-items: center;
	text-align: center;
	border: 1px solid #e4e4e2;
	background: #F7F7F6;
	padding: 16px;
	position: relative;
	width: 100%;
	font-weight: 300;
}

hb-core-search::part(container):hover {
	background: #CCC;
}

hb-core-search::part(title-icon) {
	margin-bottom: 4px;
}

hb-core-search::part(title) {
	grid-column: 2;
	grid-row: 1;
	font-size: var(--hubbox-title-font-size);
	color: var(--hubbox-text-color);
	text-transform: var(--hubbox-text-transform);
}

hb-core-search::part(subtitle) {
	display: none;
}

hb-core-search::part(search-button) {
	left: 0px;
	top: 0px;
	background-color: transparent;
	border: none;
	color: transparent;
	position: absolute;
	width: 100%;
	height: 100%;
}

hb-core-home::part(container container-active) {
	text-align: center;
	border: 2px solid var(--hubbox-text-color);
	border-radius: 8px;
	padding: 16px;
	position: relative;
	width: 100%;
	font-family: var(--hubbox-font-bold);
}

hb-core-home::part(container container-inactive) {
	border-radius: 8px;
	align-items: center;
	text-align: center;
	border: 1px solid #e4e4e2;
	background: #F7F7F6;
	padding: 16px;
	position: relative;
	width: 100%;
	font-weight: 300;
}

hb-core-home::part(container):hover {
	background: #CCC;
}

hb-core-home::part(header-icon) {
	margin-bottom: 4px;
	content: url(https://cdn.hub-box.com/client/lacrosse/package.svg);
}

hb-core-home::part(header-icon__ups) {
	display:none;
}

hb-core-home::part(title) {
	grid-column: 2;
	grid-row: 1;
	font-size: var(--hubbox-title-font-size);
	color: var(--hubbox-text-color);
	font-size: var(--hubbox-title-font-size);
	letter-spacing: 0.5px;
}

hb-core-home::part(subtitle) {
	display: none;
}

hb-core-home::part(select-button) {
	left: 0px;
	top: 0px;
	background-color: transparent;
	border: none;
	color: transparent;
	position: absolute;
	width: 100%;
	height: 100%;
}

hb-core-pickup-confirmation::part(container) {
	display: inline-block;
	width: 100%;
	background: white;
}

hb-core-pickup-confirmation::part(title) {
	margin-bottom: 0.6em;
	font-size: var(--hubbox-title-font-size);
	color: var(--hubbox-text-color);
	font-family: var(--hubbox-font-bold);
}

hb-core-pickup-confirmation::part(subtitle) {
	margin-bottom: 16px;
	color: var(--hubbox-text-color);
	font-weight: 300;
	font-size: var(--hubbox-subtitle-font-size);
}

hb-core-pickup-confirmation::part(address) {
	margin-bottom: 16px;
	font-size: var(--hubbox-subtitle-font-size);
	color: var(--hubbox-text-color);
	font-weight: 300;
	font-style: normal !important;
}

hb-core-pickup-confirmation::part(find-collect-point-button) {
	margin-bottom:16px;
	text-decoration: underline;
	padding: 0;
	border: 0;
	background: transparent;
	text-align: left;
	line-height: inherit;
	font-size: var(--hubbox-subtitle-font-size);
	color: var(--hubbox-text-color);
	font-weight: 300;
}

hb-core-pickup-confirmation::part(opening-times) {
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-auto-rows: max-content;
	grid-gap: 0 1rem;
	grid-column: 2;
	grid-row-start: 1;
	grid-row-end: 3;
	text-transform: capitalize;
	font-size: var(--hubbox-subtitle-font-size);
	color: var(--hubbox-text-color);
	font-family: var(--hubbox-font);
	font-weight: 300;
	margin-bottom: 16px;
}

hb-core-pickup-confirmation::part(map) {
	margin-bottom: 16px;
	grid-column: 3;
	grid-row-start: 1;
	grid-row-end: 3;
}
