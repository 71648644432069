footer.page-footer {
	background: $body-background-alt;
	padding: 0 0 rem-calc(60);

	> *:not(:first-child) {
		margin-top: rem-calc(9);
	}

	ul {
		@include list-reset;
	}
}

.footer-links-container a {
	padding: rem-calc(9 15);

	&.store-switcher {
		.flag::after {
			background: url('../images/borderfree/US.gif') no-repeat;
			background-size: cover;
			content: "";
			display: inline-block;
			height: rem-calc(16);
			margin-left: rem-calc(10);
			width: rem-calc(24);
		}
	}
}

.footer-social-links-container {
	li:not(:last-of-type) {
		margin-right: rem-calc(15);
	}

	a {
		@include icon-circle($bg: $light-gray, $c: $dark-gray);
		font-size: rem-calc(18);
	}
}

footer .newsletter,
.form-subscribe {
	background-color: $primary-color;
	background-image: url('../images/footer-trees.png');
	background-position-x: rem-calc(-50);
	background-position-y: bottom;
	background-repeat: repeat-x;
	background-size: auto rem-calc(214);
	color: map-get($lfi-palette, snow);
	padding: rem-calc(50 15 194 15);
	text-align: center;

	.form-subscribe-header {
		margin-bottom: rem-calc(15);
	}

	.actions {
		margin-top: rem-calc(16);
	}
}
