@mixin svg-icon($icon-url: false, $width: 1em, $height: 1em, $mask: true) {

	content: "" !important; // replace content with icon
	display: inline-block;
	height: $height;
	width: $width;

	@if $icon-url and $mask {
		mask-image: url('#{$icon-url}');
	}

	@if $icon-url and not $mask {
		background-color: transparent;
		background-image: url('#{$icon-url}');
		background-size: contain;
	}

	@if $mask {
		background: currentColor;
		mask-position: 50%;
		mask-repeat: no-repeat;
		mask-size: contain;
	}
}

// Icon Styles
@mixin icon-circle(
	$bg: $light-gray,
	$c: $body-medium-dark-font-color,
	$dia: rem-calc(42),
	$fontsize: rem-calc(24),
	$hover-color: scale-color($bg, $lightness: -12%)
) {
	align-items: center;
	background: $bg;
	border-radius: 50%;
	color: $c;
	display: flex;
	font-size: $fontsize;
	height: $dia;
	justify-content: center;
	text-align: center;
	width: $dia;

	@if $hover-color {
		@media (hover: hover) {
			&:hover {
				background: $hover-color;
			}
		}

		&:active {
			background: $hover-color;
		}
	}
}
