/// Generate a list of classes that mapping the combination of breakpoints and a named property value map
//
// for example: mapping a set of colors that assign "background-color":
// @include responsive-properties(background-color, (sm: 0, md: 500px), (red: #f00, green: #0f0));
// creates:
//  .background-color-sm-red { background-color: #f00; }
//  .background-color-sm-green { background-color: #0f0; }
//  @media (min-width: 500px) {
//    .background-color-md-red { background-color: #f00; }
//    .background-color-md-green { background-color: #0f0; }
// }
//
@mixin responsive-properties($property, $breakpoints, $property-map) {
	@each $bpname, $width in  $breakpoints {
		@include media-breakpoint-up($bpname) {
			@each $propname, $propval in $property-map {
				.#{$property}-#{$bpname}#{"-" + $propname} {
					#{$property}: $propval;
				}
			}
		}
	}
}
