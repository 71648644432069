///
// Header Aligned Bottom Left
// --------------------------
// Suggested use -
// div.header-background-basic.header-bottom-left.header-custom-alignment
///
.header-bottom-left {
	.banner-background {
		align-items: flex-end;
		min-height: rem-calc(600);

		@include breakpoint(medium) {
			min-height: rem-calc(800);
		}

		@include breakpoint(xlarge) {
			min-height: rem-calc(900);
		}

		.banner-content-wrapper {
			padding: rem-calc(40 30);

			@include breakpoint(medium) {
				padding: rem-calc(100 75);
			}
		}

		.banner-content {
			text-align: left;
		}

		h1 {
			font-size: rem-calc(40);
			line-height: rem-calc(39);
			letter-spacing: rem-calc(-1.1);
			margin-bottom: rem-calc(25);
			max-width: rem-calc(600);

			@include breakpoint(medium) {
				font-size: rem-calc(60);
				line-height: rem-calc(55);
				letter-spacing: rem-calc(-1.54);
				margin-bottom: rem-calc(40);
			}

			@include breakpoint(xlarge) {
				font-size: rem-calc(70);
				line-height: rem-calc(67);
				letter-spacing: rem-calc(-1.8);
			}
		}
	}
}
