@import "../../default/styles/components/plp/_page";

.mla-products-container {
	background: map-get($lfi-palette, snow);
}

//
// CMS Content
.category-content {
	.feature-block-container {
		@include xy-grid-container($padding: 0);
		@include xy-grid();

		.columns {
			// Spoofing .cell behavior manually because of the xy-gutter calculation error
			padding: {
				bottom: $grid-margin-gutters;
				left: $grid-margin-gutters * 0.5;
				right: $grid-margin-gutters * 0.5;
			}
		}
	}

	@at-root {
		// List Cell
		.feature-block.sub-categories {
			background: map-get($lfi-palette, snow);
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;

			.sub-category--title {
				margin-bottom: rem-calc(15);
				text-align: center;
			}

			.sub-category--list.menu {
				padding: rem-calc(30);

				@include breakpoint(medium) {
					padding: rem-calc(9);
				}

				a {
					display: block;
					padding: rem-calc(1.75);
				}

				.sub-category {
					a[href*="/sale"],
					a[href*="/closeouts"] {
						color: $alert-color !important;
						padding: 0;
					}

					a[href*="/sale"] {
						border-bottom: 2px solid $light-gray;
						margin-bottom: rem-calc(9);
						padding-bottom: rem-calc(15);
					}
				}
			}
		}
	}
}
