// Hide details option in cart and checkout
// For recrafting services, we only want to show the style option
[data-option-label="Details"] {
	display: none !important;
}
// And we don't want the comma shown after the Style option value
[data-option-label="Style"] {
	&::after {
		display: none !important;
	}
}
