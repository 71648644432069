.cms-leaf-recrafting-partner {
	.header-container .header.content {
		height: auto;
	}

	.branding {
		margin: 16px 0;
		text-align: center;
	}

	.page-main {
		margin: 0 auto;
		max-width: map-get($grid-breakpoints, md); // is this a dumb way to do it?
	}

	section {
		display: flex;
		flex-direction: column;
		gap: 80px;
		padding-left: 24px;
		padding-right: 24px;

		.fill {
			margin: 0 -24px;

			img {
				width: 100%;
			}
		}

		> *:last-child {
			padding-bottom: 80px;
		}
	}

	.overlay-container {
		position: relative;
		.overlay {
			bottom: 9.375%; // 40px at 375px width
			left: 33%;
			position: absolute;
			width: 34%;
		}
	}

	.intro-text-container {
		margin: -40px 0;

		> * + * {
			margin-top: 24px;
		}
	}

	.declaration {
		align-items: center;
		display: grid;
		grid-gap: 12px;
		grid-template-columns: 1fr auto;
		margin: 0 auto;
		max-width: 359px; // a random slightly wider value meant to accommodate larger text

		&:nth-child(2n) {
			grid-template-columns: auto 1fr;
		}

		&:first-of-type {
			padding-top: 80px;
		}

		&:last-of-type {
			padding-bottom: 80px;
		}
	}

	.declaration-text {
		p {
			@include bold-text();
		}
	}

	.declaration-image {
		text-align: center;
	}

	.features-list {
		padding-bottom: 40px;

		h3 {
			margin-bottom: 40px;
		}

		ul {
			display: flex;
			flex-direction: column;
			gap: 16px;
			list-style-type: none;
			margin: 0;
		}

		li {
			align-items: center;
			display: flex;
			line-height: 24px;
		}

		i + span {
			@include bold-text;
			padding-left: 10px;
		}

		i {
			font-size: 24px;
			height: 1em;
			line-height: 1;
		}
	}

	.resole-service-outline {
		gap: 0;
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 48px;

		> * + * {
			margin-bottom: 0;
			margin-top: 0;
		}
	}

	.resole-service-list {
		color: $base-primary-color;
		display: grid;
		grid-gap: 26px;
		grid-template-rows: repeat(1fr);
		list-style-type: none;
		margin: 0;
		padding-top: 24px;

		li {
			@include bold-text;
			align-items: end;
			display: flex;
			margin-bottom: 0;
		}

		li::before {
			@include svg-icon('../icons/icon-circle-check.svg');
			margin-right: 1ex;
		}
	}

	.recrafting-steps {
		counter-reset: recrafting-steps;
		margin: 0 auto;
		max-width: 405px; // we're targeting specific widths to prevent line breaks now!
		padding-top: 80px;

		h3 {
			margin: 0;
			text-align: center;
		}
	}

	.recrafting-steps-list {
		margin: 0;
		margin-top: 40px;

		li {
			align-items: center;
			border-bottom: 1px solid $light-gray;
			counter-increment: recrafting-steps;
			display: grid;
			grid-gap: 24px;
			grid-template-columns: auto 1fr;
			list-style: none;
			margin: 0 0 20px;
			padding-bottom: 20px;

			&::before {
				content: counter(recrafting-steps) " ";
				display: block;
				font-family: $body-secondary-font-bold;
				font-size: 30px;
				line-height: 1;
			}
		}
	}

	// special style to limit a block element's width and center it
	.limited-max-width {
		margin-left: auto;
		margin-right: auto;
		max-width: 327px; // (375px [the design target] minus standard 24px side padding
	}

	// a very fragile button style
	// this is something design wants to use all over the place
	// but we have no implementation time to discuss the IRL behavior of such a thing
	// copied directly from Figma, then cleaned up (-MW, 2023-02-10)
	.learn-more-about-recrafting {
		border-radius: 24px;
		font-size: 1rem;
		line-height: 1;
		padding: 15px 30px;
		text-transform: uppercase;
	}
	///
	// classes for applying color to text and backgrounds
	//
	.accent-text {
		color: $accent-color;
	}

	.info-text {
		color: $info-color;
	}

	.alt-bg {
		background-color: $body-background-alt;
	}
}
