.play-video {
	line-height: 0;

	// TODO: Remove in CMS content
	img {
		display: none;
	}

	a {
		align-items: center;
		color: inherit;
		display: flex;
		font-size: $global-font-size;
		font-weight: $global-weight-normal;
		justify-content: center;

		.icon-play-video::before {
			@include svg-icon('../icons/icon-video-indicator.svg', $mask: false);
			font-size: rem-calc(27);
			margin-right: rem-calc(7.5);
			margin-top: rem-calc(-3);
		}
	}
}
