.lfi-productfamily-index-view {

	.page-main {
		@include clear-content-margin();
		background-color: map-get($lfi-palette, snow);
	}
}

.family-product-list-heading {
	margin-bottom: rem-calc(36);
	margin-top: rem-calc(60);

	h3 {
		text-align: center;
	}
}

.family-features {
	@include xy-grid-container($padding: $grid-margin-gutters);
}
