// Boot Care Landing Styles

.page-layout-category-basic {
	@extend %basic-category-styles;
}

%basic-category-styles {
	.page-title-wrapper {
		max-width: none;
		position: absolute;
		top: 25vw;
		width: 100%;

		@include media-breakpoint-up(md) {
			top: 15vw;
		}

		h1 {
			color: $white;
			text-align: center;
		}
	}

	.category-view {
		position: relative;
	}

	.category-description {
		padding: 80px 16px;
		text-align: center;
	}
}
