table {
	background: transparent;

	thead,
	tbody,
	tbody tr:nth-child(2n),
	tr,
	td {
		background: transparent;
	}
}
