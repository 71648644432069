@import "../../../default/styles/components/nav-evo";

.offcanvas-nav-secondary-link > a {
	--lfi-nav-link-letter-spacing: 0.4px;
}

.offcanvas-nav-category-link > a {
	--lfi-nav-link-font-family: #{inspect($header-font-family)};
}

.offcanvas-nav-submenu-large-link > a {
	--lfi-nav-link-font-family: #{inspect($header-font-family)};

	@include media-breakpoint-up(tablet) {
		--lfi-nav-link-font-family: #{inspect($header-font-bold)};
	}
}

%offcanvas-top-link-tablet-styles {
	@include media-breakpoint-up(tablet) {
		> a {
			@include bold-text;
		}
	}
}

.active > a {
	@include media-breakpoint-up(tablet) {
		border-bottom: 2px solid currentColor;
	}
}

a.offcanvas-nav-accordion-toggle {
	--lfi-nav-link-font-family: #{inspect($header-font-bold)};
}
