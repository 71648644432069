.klarna-promotion-wrapper {
	min-height: 24px;

	.cart-summary & {
		margin-bottom: rem-calc(20);
		text-align: center;
		width: 100%;

		@include breakpoint(medium) {
			// Move to bottom of cart-summary on medium up
			order: 1;
		}

		// Klarna's iframe is hardcoded to display: block
		// this will allow the whole thing to be centered in the wrapper
		klarna-placement {
			display: inline-block;
		}
	}
}
