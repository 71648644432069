// Product Grid Container

.products-updating {
	opacity: 0.1;
}

.products-grid {
	margin-bottom: rem-calc(60);
	overflow: hidden;

	@include breakpoint(large) {
		margin-top: rem-calc(60);
	}

	// Product Grid Items Container
	.product-items {
		@include xy-grid();
		@include xy-gutters($plp-gutters-mobile, $negative: true);


		@include xy-grid-layout(
			2,
			".product-item",
			$gutters: $plp-gutters-mobile
		);

		@include breakpoint(medium) {
			@include xy-grid-layout(
				3,
				".product-item",
				$gutters: $plp-gutters-mobile
			);
		}

		@include breakpoint(large) {
			@include xy-gutters($plp-gutters-desktop, $negative: true);
			@include xy-grid-layout(
				2,
				".product-item",
				$gutters: $plp-gutters-desktop
			);
		}

		@include breakpoint(xlarge) {
			@include xy-grid-layout(
				3,
				".product-item",
				$gutters: $plp-gutters-desktop
			);
		}

		@include breakpoint(vlarge) {
			@include xy-grid-layout(
				4,
				".product-item",
				$gutters: $plp-gutters-desktop
			);
		}
	}

	.product-item {
		margin-bottom: $plp-gutters-mobile;
		margin-top: 0;
		transition: width 0.15s ease;

		@include breakpoint(medium) {
			margin-bottom: $plp-gutters-desktop;
		}
	}
}

//////
// Placeholder
//////

.products-placeholder {
	--render-placeholder: 1;
	--product-count: 0;
	height: calc(300px * var(--product-count) * var(--render-placeholder));
	transition: height 1s;
}

////////
// Product Family Header
////////
.group-by-info {
	margin: rem-calc(0 15 30 15);
	text-align: center;

	h2 {
		@include breakpoint(medium) {
			font-size: rem-calc(30);
		}
	}

	p {
		margin-bottom: rem-calc(16);
	}
}

////////
// Product Group
////////
.product-group {
	@include list-reset();

	> li {
		margin-bottom: rem-calc(60);
	}
}
