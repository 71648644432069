.catalog-category-view {
	&.page-with-filter {
		.page-main {
			@include clear-content-margin();
		}
	}

	.page-title-wrapper {
		margin-top: rem-calc(50);
	}
}
