.recrafting-contact {
	padding-top: rem-calc(64);

	@include media-breakpoint-up(lg) {
		padding-top: rem-calc(130);
	}

	.recrafting-header {
		margin-bottom: rem-calc(42);
	}

	.contact-icon {
		line-height: 1;
		margin-bottom: rem-calc(12);
	}

	.icon-ring-volume::before {
		height: rem-calc(25);
		width: rem-calc(32);
	}

	.icon-chat-bubble::before {
		height: rem-calc(27);
		width: rem-calc(27);
	}

	.recrafting-button {
		display: block;
		// TODO - remove after global change to button margin
		margin-bottom: rem-calc(8);
		max-width: rem-calc(246);
		padding-left: rem-calc(1);
		padding-right: rem-calc(1);

		@include media-breakpoint-up(lg) {
			max-width: rem-calc(343);
		}
	}

	> div {
		padding-bottom: rem-calc(42);
	}

	span {
		display: block;
	}
}

.recrafting-product-check {
	padding-top: rem-calc(60);

	@include media-breakpoint-up(lg) {
		padding-top: rem-calc(104);
	}

	.icon-circle-info::before {
		height: rem-calc(56);
		width: rem-calc(56);
	}

	.recrafting-button {
		margin-bottom: rem-calc(80);

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(144);
		}
	}

	h3 {
		span {
			display: block;
		}
	}

	p {
		margin-bottom: rem-calc(40);
	}
}

.recrafting-product-contact {
	padding-bottom: rem-calc(60);

	.wizard-icon {
		padding-top: rem-calc(60);

		@include media-breakpoint-up(lg) {
			padding-top: rem-calc(160);
		}
	}

	h3 {
		max-width: rem-calc(462);
	}

	p {
		margin-bottom: rem-calc(40);
	}

	form {
		display: inline-block;
		margin-bottom: rem-calc(56);
		width: rem-calc(160);
	}

	.field:last-of-type {
		margin-bottom: 0;
	}
}

.recrafting-product-input {
	padding: rem-calc(60) 0;

	@include media-breakpoint-up(lg) {
		padding-top: rem-calc(208);
	}

	.field,
	h3,
	p {
		margin-left: auto;
		margin-right: auto;
		max-width: rem-calc(462);
	}

	p {
		margin-bottom: rem-calc(24);
	}

	form {
		margin: 0 rem-calc(16) rem-calc(82) rem-calc(16);

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(165);
		}
	}

	textarea {
		margin-bottom: 0;
		min-height: rem-calc(228);
	}
}

.recrafting-contact-help-message {
	color: $alert-color !important;
	font-family: $body-font-family !important;
	margin-bottom: rem-calc(40) !important;
}
