// Product List Filtering and Sorting Controls
$filter-sidebar-width: rem-calc(350);

.product-grid-controls-container {
	background: map-get($lfi-palette, snow);

	@include breakpoint(large) {
		align-items: center;
		border-bottom: rem-calc(1) solid map-get($lfi-palette, light-gray);
		display: flex;
		justify-content: flex-start;
	}

	button {
		text-transform: none;

		@include breakpoint(large) {
			padding-left: $plp-grid-margins-desktop;
			padding-right: $plp-grid-margins-desktop;
		}
	}
}

.product-grid-controls-wrapper {
	margin: 0 auto;
	max-width: rem-calc(1920);
	width: 100%;
}

.product-grid-controls {
	position: relative;
}

.filtered-item-count {
	align-items: center;
	display: flex;
	height: 100%;
	position: absolute;
	right: rem-calc(16);
	top: 0;

	@include breakpoint(large) {
		right: rem-calc(25);
	}
}

#mage-layer-api-products {
	flex: 1 1 calc(100% - #{$filter-sidebar-width});
	margin: 0 5px;
	opacity: 1;
	transition: opacity 100ms ease-in-out;

	@include breakpoint(large) {
		margin-left: $plp-grid-margins-desktop;
		margin-right: $plp-grid-margins-desktop;
	}
}

#mage-layer-api-products > .placeholder {
	@include xy-grid();
}

.filter-offcanvas-controls {
	@include breakpoint(large) {
		display: none;
	}

	button {
		padding: rem-calc(24 16);
	}
}

.filter-sidebar-controls {
	display: none;

	@include breakpoint(large) {
		display: flex;
	}

	button:hover {
		color: scale-color(map-get($lfi-palette, jet), $lightness: 20%);
	}
}

.icon-filter-toggle {
	margin-left: rem-calc(8);
}

.filter-sidebar-toggle {
	@include bold-text;
	padding-left: rem-calc(24);
	padding-right: rem-calc(20);
}

/////
/////
.products-list-container {
	background: map-get($lfi-palette, snow);
}

.products-list-wrapper {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	max-width: rem-calc(1920);
}

.filter-sidebar {
	border-right: rem-calc(1) solid map-get($lfi-palette, light-gray);
	display: none;
	height: 100vh;
	margin-left: 0;
	position: sticky;
	top: calc(#{rem-calc(55)} + var(--lfi-top-space-used, 0px));
	transition: width 0.35s ease, opacity 0.35s ease, var(--lfi-top-transition);
	z-index: 1;

	@include breakpoint(large) {
		display: initial;
	}

	@include breakpoint(xxlarge) {
		margin-left: 0;
	}

	&.collapsing {
		width: 0;
	}

	// Since we are transitioning opacity, hidden state can no longer
	// be display: none
	&.collapse:not(.show) {
		display: inherit;
		visibility: hidden;
		width: 0;
	}

	&.collapse,
	&.hiding {
		opacity: 0;
	}

	&.showing,
	&.show {
		opacity: 1;
	}

	> div {
		// Manually apply iOS "momentum" scrolling
		-webkit-overflow-scrolling: touch;
		height: 100%;
		overflow-y: scroll;
		padding-left: $plp-grid-margins-desktop;
		padding-right: $plp-grid-margins-desktop;
		padding-top: rem-calc(8);
		width: $filter-sidebar-width;
	}
}
