// Upsell List
.block-upsell {
	.skeleton-item {
		margin-left: 30px !important;
		margin-right: 30px !important;
	}

	.skeleton-image {
		min-height: 180px;
		min-width: 180px;
	}

	.skeleton-text-field {
		margin-bottom: 8px;
	}
}
