// Custom styling for collapse components
// Note - Bootstrap collapse components should be wrapped in a custom
// .collapse-container class

.collapse-container {
	[aria-expanded="true"] {
		.collapse-text-closed {
			display: none;
		}
	}

	[aria-expanded="false"] {
		.collapse-text-open {
			display: none;
		}
	}
}
