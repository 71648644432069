//
// Color Groups

.color-group {
	margin-top: 1rem;
	overflow: hidden;

	@include breakpoint($screen__m) {
		grid-area: color-group;
		margin-bottom: rem-calc(8);
		margin-top: 0;
	}

	.list {
		display: flex;
		gap: 1rem;
		overflow: scroll hidden;
		padding: 0 1rem;

		@include breakpoint($screen__m) {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			padding: 0;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.item {
		--color-group-item-bottom: 24px;
		align-items: flex-end;
		aspect-ratio: 1;
		background: revert;
		display: flex;
		flex: 0 0 143px;
		margin-bottom: var(--color-group-item-bottom);
		position: relative;

		@include breakpoint($screen__m) {
			--color-group-item-bottom: 14px;
			display: inline-block;
		}
	}

	.current {
		&:after {
			background: $dark-gray;
			bottom: calc(var(--color-group-item-bottom) * -1);
			content: '';
			display: inline-block;
			height: 2px;
			left: 0;
			position: absolute;
			width: 100%;

			@include breakpoint($screen__m) {
				height: 1.5px;
			}
		}
	}
}
