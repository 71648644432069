//
// .product-reviews-container
//  .product-reviews-wrapper
//   h3
//  .product-reviews-info-container
//   (product review summary)
//   (product review sort)
//  .product-reviews
//   .product-review
//    .review-header
//     .review-image
//     .review-info
//    .review-body
//     .review-title
//     .review-content
//     .review-images

.product-reviews-container {
	padding: rem-calc(40 15);

	.product-reviews-summary {
		cursor: initial;
	}

	h3 {
		margin-bottom: rem-calc(40);
		text-align: center;
	}
}

.product-reviews-wrapper {
	margin: 0 auto;
	max-width: rem-calc(956);
}

.product-reviews {
	text-align: left;

	.review-user,
	.review-content {
		font-family: $body-font-family;
		font-size: rem-calc(16);
		line-height: rem-calc(20);
	}
}

.product-reviews-info-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: rem-calc(40);
}

.product-review {
	padding: rem-calc(25) 0;

	@include media-breakpoint-up(lg) {
		padding: rem-calc(40) 0;
	}

	&:first-of-type {
		@include media-breakpoint-down(lg) {
			padding-top: 0;
		}

		@include media-breakpoint-up(lg) {
			border-top: rem-calc(1) solid map-get($lfi-palette, light-gray);
		}
	}

	&:not(:last-of-type) {
		border-bottom: rem-calc(1) solid map-get($lfi-palette, light-gray);
	}
}

.review-header {
	display: grid;
	grid-column-gap: rem-calc(12);
	grid-template-columns: min-content auto auto;
	margin-bottom: rem-calc(32);

	@include media-breakpoint-up(lg) {
		margin-bottom: rem-calc(40);
	}
}

.review-image {
	align-items: center;
	background: $base-color;
	border-radius: rem-calc(50);
	display: flex;
	height: rem-calc(48);
	justify-content: center;
	position: relative;
	width: rem-calc(48);

	.icon-checkmark {
		align-items: center;
		background: $info-color;
		border-radius: 50%;
		bottom: 0;
		display: flex;
		height: 16px;
		justify-content: center;
		outline: 2px solid;
		position: absolute;
		right: 0;
		width: 16px;

		&:before {
			height: 12px;
			width: 12px;
		}
	}

	span {
		color: $white;
		text-transform: uppercase;
	}
}

.review-user {
	display: flex;
	margin: 0 0 rem-calc(4) 0;

	p {
		margin: 0;

		&:first-of-type {
			margin-right: 8px;
		}
	}
}

.review-date {
	text-align: right;
}

.review-body {
	.review-title {
		@include bold-text;
		font-size: rem-calc(18);
		line-height: rem-calc(20);
		margin: 0 0 rem-calc(16) 0;
	}

	.review-content {
		margin: 0;
	}
}

.review-images {
	img {
		margin-right: 8px;
		margin-top: 16px;
	}
}
