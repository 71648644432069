/*
 * Customer account login page
 * ---------------------------
 * body.customer-account-login
 *  div.block-customer-login
 *   div.block-title
 *   div.block-content
 *   form.form-login
 *   .....
 *
 */

.customer-account-login {
	.form-login {
		.choice {
			margin-bottom: rem-calc(40);
		}
	}
}

.block-customer-login {
	margin-bottom: rem-calc(40);
}
