// Story Flash Card Block
// @doc Flash card color is configured through inline styles
// -----------------------
// section.story-flash-wrapper
//  div.story-flas-container
//   div.story-flash-header
//    h2
//    h3
//   div.story-flash-cards
//    div.story-flash-card
//     div.flash-card-front
//      ...
//		div.flash-card-back
//     ...
//   ...

.story-flash-wrapper {
	background: map-get($lfi-palette, snow);
	padding: 80px 24px 120px;

	@include media-breakpoint-up(md) {
		padding-left: 40px;
		padding-right: 40px;
	}

	@include media-breakpoint-up(tablet) {
		padding: 80px 40px;
	}
}

.story-flash-container {
	margin: 0 auto;
	max-width: 1029px;
}

.story-flash-header {
	margin-bottom: 40px;

	@include media-breakpoint-up(tablet) {
		margin-bottom: 64px;
		text-align: center;
	}

	h2 {
		margin-bottom: 16px;
	}
}

.story-flash-cards {
	--story-flash-card-width: 327px;
	display: grid;
	grid-gap: 24px;
	grid-template-columns: repeat( auto-fit, minmax(279px, var(--story-flash-card-width)));
	justify-content: center;
}

.story-flash-card {
	// Browsers that don't support this will fall back on the min-height and just not get an exact ratio.
	aspect-ratio: 2 / 3;
	background: $background-primary-color;
	border-radius: 8px;
	max-width: var(--story-flash-card-width);
	min-height: 418px;
	overflow: hidden;
	position: relative;
	width: 100%;

	&.active {
		.story-flash-card-front {
			display: none;
		}

		.story-flash-card-back {
			display: flex;
		}
	}
}

.story-flash-card-media {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;

	img {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
}

.story-flash-card-content,
.story-flash-card-icon {
	position: relative;
	z-index: 2;
}

.story-flash-card-content {
	--flash-card-content-margin: 0 24px;
	color: $white;
	margin: var(--flash-card-content-margin);

	&.dark-text {
		color: $base-color;
	}

	h3 {
		margin-bottom: 0;
	}
}

.story-flash-card-icon {
	--flash-card-icon-front-size: 40px;
	--flash-card-icon-back-size: 36px;
	margin: 16px;
	text-align: right;

	.icon-lightbulb-no-shine-regular {
		padding: 4px;

		&::before {
			height: var(--flash-card-icon-front-size);
			width: var(--flash-card-icon-front-size);
		}
	}

	.icon-check {
		padding: 6px;

		&::before {
			height: var(--flash-card-icon-back-size);
			width: var(--flash-card-icon-back-size);
		}
	}

	span {
		background: $white;
		border-radius: 50px;
		cursor: pointer;
		display: inline-block;
		line-height: 0;

		&::before {
			color: $base-color;
		}
	}
}

.story-flash-card-front,
.story-flash-card-back {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.story-flash-card-front {
	justify-content: flex-end;
}

.story-flash-card-back {
	display: none;
	justify-content: space-between;
	padding-top: 48px;

	.story-flash-card-content {
		span {
			@include bold-text;
			display: inline-block;
			margin-bottom: 8px;
		}
	}
}
