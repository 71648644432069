//
// Customize Bootstrap Tooltip to add borders
//

$tooltip-border-width: rem-calc(1) !default;
$tooltip-border-color: map-get($lfi-palette, light-gray) !default;

.tooltip[data-popper-placement="bottom"] {
	.tooltip-arrow {
		display: none !important; // disable the arrow
	}

	.tooltip-inner {
		border: $tooltip-border-width solid $tooltip-border-color;
		padding-top: 0 !important; // text vertical alignment
	}
}

.tooltip-anchor {
	border-bottom: 1px dashed;
	display: inline-block;
}
