/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// This file is modified to only import the features used by LFI

// scss-docs-start import-stack
// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "custom-bootstrap-variables";

// 3. Include remainder of required Bootstrap stylesheets

@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// 4. Include any optional Bootstrap components as you like

@import "bootstrap/scss/transitions";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/button-group";

// Layout & components
//@import "bootstrap/scss/reboot";
@import "bootstrap/scss/root";
//@import "../bootstrap/type";
//@import "../bootstrap/images";
//@import "../bootstrap/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/nav";
//@import "../bootstrap/forms";
@import "bootstrap/scss/forms/form-check"; // Only want one part of the forms component
//@import "../bootstrap/buttons";
//@import "../bootstrap/navbar";
//@import "../bootstrap/card";
//@import "../bootstrap/breadcrumb";
//@import "../bootstrap/pagination";
@import "bootstrap/scss/badge";
//@import "../bootstrap/alert";
//@import "../bootstrap/progress";
//@import "../bootstrap/list-group";
//@import "../bootstrap/close";
//@import "../bootstrap/toasts";
//@import "../bootstrap/popover";
@import "bootstrap/scss/carousel";
//@import "../bootstrap/spinners";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack
