@mixin vertical-hr($color: $white, $height: rem-calc(750)) {
	border-right: rem-calc(1) solid $color;
	display: inline-block;
	height: $height;
	width: rem-calc(1);
	margin-bottom: rem-calc(14);
}

.vertical-hr {
	@include vertical-hr();
}
