// Override yotpo styles loaded externally

// Prevent content jump on stores with Yotpo enabled
// (these divs only appear with yotpo enabled)
.product-info-head-wrapper {
	.yotpo-main-widget,
	.yotpo-widget-instance {
		min-height: 30px;
	}
}

// Bottomline reviews need to be pushed left on mobile
.yotpo-widget-instance {
	// Using this foundation breakpoint to match PDP styles
	@include max-screen($screen__m) {
		margin-left: 15px;
	}
}

// Bottomline needs pointer to show users it is clickable
.yotpo-sr-bottom-line-summary {
	cursor: pointer !important;
}

// Yotpo customer headline match others on PDP
.yotpo-headline {
	font-family: $header-font-family !important;
	font-size: 24px !important;

	@include media-breakpoint-up(md) {
		font-size: 28px !important;
	}
}

// Create Review and Review Summary buttons need adjustment
#yotpo-summary,
#yotpo-main-widget-btn {
	font-family: $header-font-family !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	height: auto !important;
	letter-spacing: 0.5px !important;
	line-height: 18px !important;
	padding: 15px 30px !important;
	text-transform: uppercase !important;
	width: auto !important;

	.yotpo-button-icon {
		margin-right: 4px !important;
	}
}

#yotpo-summary {
	margin-right: 35px !important;
}

// Move star rating inline with title
.yotpo-review-star-rating {
	padding-top: 3px !important;
}

// Our button styles are applying to this button so we need to reverse that
.yotpo-dropdown-closable {
	&:hover,
	&:focus {
		background: initial;
		color: initial;
	}
}

// Another Yotpo Dropdown Needing Styles
.yotpo-score-option {
	background: transparent !important;

	&:first-of-type,
	&:hover {
		// stylelint-disable-next-line
		background: rgba(43, 42, 39, 0.15) !important;
	}
}

// And some custom question modifications
.yotpo-custom-questions-wrapper {
	grid-template-columns: repeat(2, auto) !important;
}

.yotpo-custom-questions-range-question-title,
.yotpo-custom-questions-free-text-title,
.yotpo-custom-questions-title {
	color: $body-font-color !important;
	font-family: $body-font-bold !important;
	line-height: 18px !important;
}

.yotpo-custom-questions-free-text-value,
.yotpo-custom-questions-value {
	color: $body-font-color !important;
}

// Handle wrapping of container at smaller screen sizes
.yotpo-bottom-line,
.yotpo-layout-header-wrapper {
	@include media-breakpoint-up(sm) {
		flex-wrap: wrap;
	}
}

.yotpo-bottom-line > div {
	@include media-breakpoint-up(sm) {
		margin-bottom: 40px;
	}

	@include media-breakpoint-up(xl) {
		margin-bottom: 0;
	}
}

.yotpo-new-review-btn-wrapper {
	@include media-breakpoint-up(sm) {
		min-height: 100px;
	}
}
