.cms-recrafting-wizard {
	.page-wrapper {
		&::before {
			// Copied values from navigation SCSS to specifically counter extra space
			@include breakpoint(medium) {
				height: calc(100vh - (#{$header-container-height} + 1px));
			}
		}

		> main {
			background: map-get($lfi-palette, snow);
			flex: 1 1 100%;
		}
	}
}

.recrafting-wizard {
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100vw;

	.footer-link {
		color: $base-color;
		display: block;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: rem-calc(60);
	}

	// TODO - global form changes?
	.field {
		margin-bottom: rem-calc(16);
	}

	.textarea {
		position: relative;
	}

	textarea {
		border: rem-calc(2) solid map-get($danner-palette, forest);
		border-radius: rem-calc(16);
		min-height: rem-calc(87);
		resize: none;

		&::placeholder {
			@include bold-text;
		}
	}

	.choice {
		label {
			@include bold-text;
			align-items: initial;
			font-size: 20px;
			line-height: rem-calc(26);
			margin-left: rem-calc(16);
			margin-right: 0;
			text-align: left;
		}
	}

	.checkbox {
		border-radius: rem-calc(4);
		border-width: rem-calc(2);
		flex: 0 0 rem-calc(32);
		height: rem-calc(32);

		&:not(:checked) {
			background: $white;
		}

		&::before {
			height: rem-calc(15);
			mask-image: url('../icons/icon-checkmark.svg');
			width: rem-calc(20);
		}
	}
}

.recrafting-wizard-fonts {
	p {
		margin-left: auto;
		margin-right: auto;
		max-width: rem-calc(343);

		@include media-breakpoint-up(lg) {
			max-width: rem-calc(465);
		}
	}

	h3,
	h4,
	p,
	a:not(.button) {
		color: map-get($danner-palette, forest);
	}

	p,
	a:not(.button) {
		@include bold-text;
		line-height: rem-calc(20);

		@include media-breakpoint-up(lg) {
			font-size: rem-calc(18);
			line-height: rem-calc(22);
		}
	}

	h3 {
		margin-left: auto;
		margin-right: auto;
	}
}

.recrafting-navigation {
	display: flex;
	justify-content: space-between;
	padding: rem-calc(0 16 0 19);
	position: absolute;
	top: rem-calc(14);
	width: 100%;

	@include media-breakpoint-up(lg) {
		padding: rem-calc(0 34 0 39);
		top: rem-calc(27);
	}

	.icon-close {
		&:before {
			height: rem-calc(24);
			width: rem-calc(24);
		}
	}

	a {
		display: block;

		&::before {
			margin-bottom: -5px;
			margin-right: 13px;
		}
	}
}

.recrafting-continue {
	display: flex !important;
	text-decoration: none;

	&:disabled,
	&:disabled:hover {
		color: $primary-disabled;
	}

	.icon-arrow {
		margin-top: -2px;
		padding-left: 8px;
	}
}

.recrafting-button {
	margin-left: auto;
	margin-right: auto;

	&:disabled {
		background: $primary-disabled;
	}
}

.recrafting-header {
	margin: 0 auto rem-calc(24) auto;
	max-width: rem-calc(343);

	@include media-breakpoint-up(lg) {
		max-width: rem-calc(462);
	}
}

.wizard-icon {
	display: inline-block;
	line-height: 1;
	margin-bottom: rem-calc(24);

	&.icon-tools::before {
		height: rem-calc(72);
		width: rem-calc(63);
	}
}

.recrafting-wizard-container {
	display: flex;
	width: 300vw;
}

.recrafting-view {
	// need this background to cover slide behind
	background: map-get($lfi-palette, snow);
	width: 100vw;

	&.recrafting-slide-transition-next,
	&.recrafting-slide-transition-previous {
		transition: margin 300ms ease-out;
	}

	&.recrafting-slide-transition-previous {
		margin: 0;
	}

	&.recrafting-slide-reset {
		position: absolute;
		z-index: -1;
	}
}

.recrafting-previous-view {
	margin-left: -100vw;
}

.recrafting-current-view {

	&.recrafting-slide-transition-next {
		margin-left: -100vw;
	}
}

.recrafting-next-view {
	margin-right: -100vw;

	&.recrafting-slide-transition-next {
		margin-left: 0;
	}
}

.recrafting-add-to-cart {
	margin: 0 auto rem-calc(24) auto;
	max-width: rem-calc(500);
	padding: 0 rem-calc(24);
}
