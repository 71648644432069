// Story Accordion Styles
// -----------------------
// section.story-accordion-container
//  div.story-accordion-container-header
//   h2
//  <boot-care-accordion>

.story-accordion-container {
	background: map-get($lfi-palette, snow);
	padding: 48px 16px;

	@include media-breakpoint-up(tablet) {
		padding: 80px 20px;
	}
}

.story-accordion-container-header {
	margin-bottom: 24px;
	text-align: left;

	@include media-breakpoint-up(tablet) {
		text-align: center;
	}
}
