//
// Customer Popup Form Styles
// ---------------------
//

.customer-popup-login,
.customer-popup-forgot {
	@include media-breakpoint-up(md) {
		width: rem-calc(450);
	}
}

.customer-popup-success-content,
.customer-popup-form-wrapper {
	padding: $registration-padding-small;
	width: 100%;

	@include breakpoint(large) {
		padding: rem-calc(70 50);
	}
}

//
// Register Specific
//
.customer-popup-register {
	max-width: rem-calc(900);

	.customer-popup-success-content,
	.customer-popup-form-wrapper {
		flex: 1 1 50%;

		@include breakpoint(large) {
			padding: rem-calc(100 70);
		}
	}

	.choice {
		justify-content: center;

		@include breakpoint(medium) {
			justify-content: flex-start;
			margin-left: rem-calc(24);
		}

		.birthday-prompt {
			font-size: rem-calc(14);
		}
	}

	.submit {
		margin-bottom: rem-calc(15);
	}
}

.customer-popup-register-cms {
	flex: 1 1 50%;
	padding: $registration-padding-small;
	text-align: left;

	@include breakpoint(small down) {
		text-align: center;
	}

	@include breakpoint(medium) {
		background: $background-primary-color;
	}

	@include breakpoint(large) {
		padding: rem-calc(100 70);
	}

	.logo {
		cursor: pointer;
		margin-bottom: rem-calc(50);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(40);

			polyline,
			path {
				fill: map-get($lfi-palette, sleet);
			}
		}
	}

	ul {
		list-style: none;
		margin: 0;
	}

	h3 {
		margin-bottom: rem-calc(15);
	}

	.customer-popup-form-content {
		h4 {
			margin-bottom: rem-calc(25);
		}
	}
}

.customer-popup-register-cms-mobile {
	button,
	.button {
		margin-bottom: rem-calc(40);
	}
}

.customer-popup-register-cms-desktop {
	color: map-get($lfi-palette, sleet);

	// Special sized h1
	h1 {
		font-size: rem-calc(37);
	}

	li {
		font-family: $header-font-family;
		line-height: rem-calc(16);
		margin-bottom: rem-calc(14);
	}
}

//
// Login Specific
//

.customer-popup-login {
	.submit {
		margin-bottom: rem-calc(40);
	}
}

//
// Forgot Password Specific
//
.customer-popup-forgot {
	.message {
		margin-bottom: rem-calc(30);

		&:before {
			display: block;
			margin-bottom: rem-calc(5);
		}
	}
}
