// Story Key Feature
// ------------------------
// .story-key-feature
//   picture|video
//   .story-key-feature-text

.story-key-features {
	--feature-width: auto;
	--flex-flow: column;
	--gap: 0;
	display: flex;
	flex-flow: var(--flex-flow);
	gap: var(--gap);
	justify-content: center;

	@include media-breakpoint-up(tablet) {
		--feature-width: 424px;
		--flex-flow: row wrap;
		--gap: 64px 24px;
		--max-width: 900px;
		margin: 0 auto;
		max-width: var(--max-width);
		padding-bottom: 80px;
	}

	@include media-breakpoint-up(xxl) {
		--gap: 80px 24px;
		--max-width: auto;
	}
}

.story-key-feature {
	--padding: 0 0 80px;
	align-content: start;
	display: grid;
	flex: 0 0 var(--feature-width);
	gap: 24px;
	padding: var(--padding);

	@include media-breakpoint-up(tablet) {
		--padding: 0;
	}

	&.xxl-wide {
		@include media-breakpoint-up(xxl) {
			--feature-width: 100%;
			--padding: 80px 0;
			grid-template-columns: 3fr 2fr;
		}
	}
}

.story-key-feature-text {
	--padding: 0 24px;
	padding: var(--padding);

	@include media-breakpoint-up(xxl) {
		--padding: 0;

		@at-root .xxl-wide & {
			--padding: 0 calc(16px + 10vw / 3);
			align-self: center;
		}
	}

	p {
		@include media-breakpoint-up(tablet) {
			font-size: 18px;
			line-height: 22px;
		}
	}
}
