@import "../../default/styles/components/pdp/_meet-the-family";

.meet-the-family {
	img {
		width: rem-calc(170);
	}

	tr:not(:first-of-type) td {
		border-bottom: 2px solid fade-out($dark-gray, 0.9);
	}
}
