$story-video-icon-size: 80px;

.story-video {
	position: relative;

	video {
		--aspect-ratio: 3/4;
		aspect-ratio: var(--aspect-ratio);
		display: block;
		height: auto;
		object-fit: cover;
		width: 100%;

		@include media-breakpoint-up(tablet) {
			--aspect-ratio: auto;
		}
	}

	&.clip video {
		--aspect-ratio: 1/1;

		@include media-breakpoint-up(tablet) {
			--aspect-ratio: 3/2;
		}
	}

	&.unplayed::before {
		@extend %video-ready-to-play;
		@include svg-icon('../icons/icon-video-indicator.svg', $width: $story-video-icon-size, $height: $story-video-icon-size, $mask: false);
	}

	&.replay::before {
		@extend %video-ready-to-play;
		@include svg-icon('../icons/icon-video-replay-indicator.svg', $width: $story-video-icon-size, $height: $story-video-icon-size, $mask: false);
	}
}

%video-ready-to-play {
	left: calc(50% - #{0.5 * $story-video-icon-size});
	position: absolute;
	top: calc(50% - #{0.5 * $story-video-icon-size});
}

