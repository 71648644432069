//
// Form Fields
//

select,
input[type="email"],
input[type="text"] {
	border-radius: rem-calc(5);
}

// Note - This is very global, but most forms on the site should not be displaying legends
// so let's plan on more specific overrides
legend {
	display: none;
}

// Group 2
// --------
// Should look something along these lines
// ---------------------------------------
//  .group-2
//   .field
//   .field
//
.group-2 {
	display: flex;

	.field {
		flex: 1 1 50%;

		&:first-of-type {
			margin-right: rem-calc(15);
		}
	}
}

// Input Group
// -----------
// Should look something along these lines
// ---------------------------------------
//  .input-group
//   .some-input-container
//    label
//    input.input-text
//   .input-group-button
//    button
//
.input-group {
	&:not(.inline-input-group) {
		.input-text {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&.inline-input-group {
		.field:nth-of-type(n + 2) {
			margin-left: rem-calc(15);
		}
	}

	&.input-group-primary {
		.input-text {
			border-color: $base-color;
		}

		button {
			background: $base-color;
			color: $white;

			&:hover,
			&:active {
				background: $primary-hover;
				color: $white;
			}
		}
	}

	.input-group-button {
		flex-grow: 0;
	}

	.input-text {
		border-color: map-get($lfi-palette, light-gray);
		border-width: rem-calc(1);
		margin: 0;
	}

	> div {
		flex-grow: 1;
	}

	button {
		@include button-input-combined;
	}
}
