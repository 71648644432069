.bfx-disable-product {
	.bfx-disable-element[disabled="disabled"] {
		display: none;
	}

	.bfx-disable-element-container {
		@include breakpoint($screen__m) {
			grid-area: shopbox;
		}

		.restrictionMessage {
			border: rem-calc(2) solid map-get($foundation-palette, warning);
			color: map-get($foundation-palette, warning);
			font-size: $global-font-size;
			font-style: normal;
			padding: $grid-container-padding * 0.5;

			@include breakpoint(medium) {
				margin-top: $grid-container-padding;
			}
		}
	}
}
