//
//  Variables
//  _____________________________________________

$account-nav-background: $sidebar__background-color !default;
$account-nav-color: false !default;

$account-nav-current-border: 3px solid transparent !default;
$account-nav-current-border-color: $color-orange-red1 !default;
$account-nav-current-color: false !default;
$account-nav-current-font-weight: $header-font-weight !default;

$account-nav-delimiter__border-color: $color-gray82 !default;

$account-nav-item-hover: $color-gray91 !default;

$_password-default: $color-gray-light01 !default;
$_password-weak: #ffafae !default;
$_password-medium: #ffd6b3 !default;
$_password-strong: #c5eeac !default;
$_password-very-strong: #81b562 !default;

.block-addresses-list {
	.items.addresses {
		> .item {
			margin-bottom: $indent__base;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.form-address-edit {
	#region_id {
		display: none;
	}
}

.form-edit-account {
	.fieldset.password {
		display: none;
	}
}

//  Full name fieldset
.fieldset {
	.fullname {
		&.field {
			> .label {
				@extend .abs-visually-hidden;

				+ .control {
					width: 100%;
				}
			}
		}

		.field {
			@extend .abs-add-clearfix;
		}
	}
}

//
//  My account
//  ---------------------------------------------

.account {
	.column.main {
		h2 {
			margin-top: 0;
		}
	}

	.sidebar-additional {
		margin-top: 40px;
	}

	.table-return-items {
		.qty {
			.input-text {
				@extend .abs-input-qty;
			}
		}
	}
}

//
//  Account navigation
//  ---------------------------------------------

.account-nav {
	.title {
		@extend .abs-visually-hidden;
	}

	.content {
		@include lib-css(background, $account-nav-background);
		padding: 15px 0;
	}

	.item {
		margin: 3px 0 0;

		&:first-child {
			margin-top: 0;
		}

		a,
		> strong {
			@include lib-css(color, $account-nav-color);
			border-left: 3px solid transparent;
			display: block;
			padding: $indent__xs 18px $indent__xs 15px;
		}

		a {
			text-decoration: none;

			&:hover {
				@include lib-css(background, $account-nav-item-hover);
			}
		}

		&.current {
			a,
			strong {
				@include lib-css(border-color, $account-nav-current-border-color);
				@include lib-css(color, $account-nav-current-color);
				@include lib-css(font-weight, $account-nav-current-font-weight);
			}

			a {
				@include lib-css(border-color, $account-nav-current-border-color);
			}
		}

		.delimiter {
			border-top: 1px solid $account-nav-delimiter__border-color;
			display: block;
			margin: $indent__s 1.8rem;
		}
	}
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
	.control {
		@include lib-vendor-prefix-display();
		@include lib-vendor-prefix-flex-direction();

		.mage-error {
			@include lib-vendor-prefix-order(2);
		}

		.input-text {
			@include lib-vendor-prefix-order(0);
			z-index: 2;
		}
	}
}

.password-strength-meter {
	background-color: $_password-default;
	height: $form-element-input__height;
	line-height: $form-element-input__height;
	padding: $form-element-input__padding;
	position: relative;
	z-index: 1;

	&:before {
		content: "";
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		z-index: -1;
	}

	.password-none & {
		&:before {
			background-color: $_password-default;
			width: 100%;
		}
	}

	.password-weak & {
		&:before {
			background-color: $_password-weak;
			width: 25%;
		}
	}

	.password-medium & {
		&:before {
			background-color: $_password-medium;
			width: 50%;
		}
	}

	.password-strong & {
		&:before {
			background-color: $_password-strong;
			width: 75%;
		}
	}

	.password-very-strong & {
		&:before {
			background-color: $_password-very-strong;
			width: 100%;
		}
	}
}

.control.captcha-image {
	@include lib-css(margin-top, $indent__s);

	.captcha-img {
		vertical-align: middle;
	}
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
	.block-addresses-list {
		.items.addresses {
			@extend .abs-add-clearfix-desktop;
			font-size: 0;

			> .item {
				display: inline-block;
				font-size: $font-size__base;
				margin-bottom: $indent__base;
				vertical-align: top;
				width: 48.8%;

				&:nth-last-child(1),
				&:nth-last-child(2) {
					margin-bottom: 0;
				}

				&:nth-child(even) {
					margin-left: 2.4%;
				}
			}
		}
	}

	//
	//  Welcome block
	//  ---------------------------------------------

	.dashboard-welcome-toggler {
		@extend .abs-visually-hidden-desktop;
	}

	.control.captcha-image {
		.captcha-img {
			margin: 0 $indent__s $indent__s 0;
		}
	}
}
