// ------------------------------
// div.product-reviews-pager-container
//  div.product-reviews-pager
//   span.icon-chevron-left
//    ul
//     li
//     ...
//   span.icon-chevron-right

.product-reviews-pager-container {
	text-align: center;
}

.product-reviews-pager {
	display: inline-block;
	position: relative;

	.current {
		@include bold-text;
		display: inline-block;
	}

	.mobile-visible {
		display: inline-block;
	}

	.desktop-visible {
		@include media-breakpoint-up(lg) {
			display: inline-block;
		}
	}

	.icon-chevron-left {
		left: rem-calc(-16);
	}

	.icon-chevron-right {
		right: rem-calc(-16);
	}

	span {
		cursor: pointer;
		display: inline-block;
		position: absolute;
		top: 0;

		&::before {
			width: rem-calc(12);
		}
	}

	ul {
		@include list-reset;
		display: flex;
		justify-content: center;
	}

	li {
		cursor: pointer;
		display: none;
		padding: 0 rem-calc(4);
	}
}
