//
// Shopbox Styles
//

.product-info-main {
	background: $pdp-shopbox-bg;
	padding: 30px 24px;
}

.product-options-wrapper {
	.field {
		margin-bottom: rem-calc(12);
	}

	select {
		margin-bottom: 0;
		text-transform: none;
	}
}

// keep around for itemprop data :(
.attribute.overview,
.product-info-stock-sku {
	display: none;
}

// Info Actions
.product-info-actions {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;

	.size-guide-modal-launcher {
		@include bold-text;
	}
}

.product-info-sizeguide {
	margin-left: auto;
}

//
// Shopbox Actions
//

.product-add-form {
	margin-bottom: 24px;
}

.product-options-bottom {
	.actions {
		display: block;
	}
}

.action.alert,
.tocart {
	@include button-expand();
}

.action.alert {
	@include button-secondary;
}

//
// Shopbox custom content
//
.shopbox-callout {
	margin-bottom: rem-calc(32);

	a {
		@include bold-text();
		align-items: center;
		display: flex;
	}
}

.oos-link {
	margin: rem-calc(24) 0;
}

//
// Pricing
//
.product-info-price {
	margin-bottom: rem-calc(24);

	.price-box {
		justify-content: left;
	}

	.price {
		@include bold-text();
		font-size: rem-calc(22);
		white-space: nowrap;
	}
}
