.product-info-head-wrapper {
	.product-indicator {
		margin-top: 0.5rem;

		@include breakpoint($screen__m) {
			margin-top: 1rem;
		}
	}
}

.product-indicator.fake-sale-icon {
	color: #c10230;
	font-size: 14px;
	line-height: 20px;
}
