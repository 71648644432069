// Global Key Features block for all websites

.global-key-features-with-icons {
	.wrapper {
		margin: 0 auto;
		max-width: $max-page-width;

		@include media-breakpoint-up(tablet) {
			display: grid;
			grid-template-columns: repeat(2, auto);
		}
	}

	.key-feature-image {
		margin-left: auto;
		order: 2;

		@include media-breakpoint-up(tablet) {
			max-width: 864px;
		}
	}

	.key-feature-content {
		order: 1;
		padding: 24px 16px 64px;

		@include media-breakpoint-up(tablet) {
			display: block;
			margin: auto 0;
			padding: 64px 24px;
		}
	}

	img {
		object-fit: cover;
		width: 100%;
	}

	h3 {
		margin-bottom: 24px;
	}

	ul {
		@extend .key-features-list;
		@include list-reset;
	}

	li {
		@include bold-text;
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 14px;
	}
}

.key-features-list {
	> li {
		align-items: center;
		display: flex;
		gap: var(--key-feature-icon-gap-size, 12px);
		margin-bottom: var(--key-feature-item-margin-bottom, 12px);

		&::before {
			--size: var(--key-feature-icon-size, 24px);
			height: var(--size);
			width: var(--size);
		}
	}
}
