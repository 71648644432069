.slug-list,
.family-features {
	[data-equalizer] {
		display: block;

		// ::before pseudo-element adds extra height to show images
		// at small, this causes issues (because we're displaying as block)
		@include breakpoint(small only) {
			&::before {
				display: none !important;
			}
		}

		@include breakpoint(medium) {
			display: flex;
		}
	}
}
