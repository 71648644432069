//
// Checkout Customer Balance payment and summary
// ---------------------------------------------
//

.opc-customerbalance {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin-bottom: rem-calc(40);

	> * {
		flex: 1 1 100%;
	}
}

.opc-customerbalance-amount {
	margin-bottom: rem-calc(15);
	font-size: rem-calc(16);
	line-height: rem-calc(23);
	text-align: center;
}

.opc-customerbalance-apply {
	@include button-secondary;
	@include button-expand;
}
