/**
 * Class for a "new leaf" component container, assigning it 80px bottom margin
 */

.newleaf-component-container {
	margin-bottom: 80px;
}

/**
 * Adds padding to the element containing a slider at tablet width and above
 */
.newleaf-product-slider-container {
	@extend .newleaf-component-container;

	@include media-breakpoint-up(tablet) {
		padding-bottom: 16px;
		padding-top: 40px;
	}
}
