.story-page-link {
	$border-width: 3px;
	$outline-width: 1px;
	color: $base-primary-color;
	display: grid;
	font-size: 14px;
	gap: 8px;
	grid-template-areas: "image" "text";
	justify-items: center;
	margin: $border-width + $outline-width 0;
	text-align: center;
	width: 94px;

	&.current {
		@include bold-text;

		img {
			border: $border-width solid #0000;
			margin-top: -1 * $border-width;
			outline: $outline-width solid $base-primary-color;
		}
	}

	&.previous {
		grid-template-areas: "chevron image" ". text";
		grid-template-columns: 12px auto;
	}

	&.next {
		grid-template-areas: "image chevron" "text .";
		grid-template-columns: auto 12px;
	}

	&.previous,
	&.next {
		column-gap: 0;
		width: 134px;
	}

	img {
		border-radius: 50%;
		box-sizing: content-box; // content-box sizing puts border outside the set width
		grid-area: image;
		width: 80px;
	}

	.pagination-text {
		@include bold-text;
		font-size: 16px;
		grid-area: text;
	}

	[class*="icon-chevron"] {
		align-self: center;
		grid-area: chevron;
	}
}

.quality-stories-top__list {
	display: grid;
	gap: 16px;
	grid-auto-flow: column;
	justify-content: safe center; // safe <justify> switches to "start" when the content overflows
	list-style: none;
	margin: 0; // reset default ul style
	overflow: auto;
	padding: 0 16px;
}

.story-pillar-navigation {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px 0 20px;
}

.quality-stories-pagination {
	border: 1px solid  map-get($lfi-palette, light-gray);
	border-left-width: 0;
	border-right-width: 0;
	display: grid;
	grid-template-areas: "previous next";
	justify-content: space-between;
	padding: 24px 16px;

	.item-previous {
		grid-area: previous;
	}

	.item-next {
		grid-area: next;
	}
}
