/*
 * @use: positions and element absolutely and makes it fill the entire space available
 */
@mixin full-container-absolute-position($z-index: 1) {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: $z-index;
}
