.filter-chips {
	padding: rem-calc(0 16 8 16);

	@include breakpoint(large) {
		padding: rem-calc(0 24 4 24);
	}

	.secondary {
		@include bold-text;
		color: map-get($lfi-palette, jet);
		line-height: rem-calc(36);
		padding: 0;
		text-decoration: underline;

		&:hover {
			text-decoration: underline;
		}
	}

	ul {
		@include list-reset;
	}

	li {
		display: inline-block;
	}
}

.filter-chip {
	background: $white;
	border: rem-calc(1) solid map-get($lfi-palette, light-gray);
	border-radius: rem-calc(24);
	margin: 0 rem-calc(8) rem-calc(8) 0;

	.icon-close {
		margin-left: rem-calc(10);

		&::before {
			height: rem-calc(11);
			width: rem-calc(11);
		}
	}

	label {
		align-items: center;
		cursor: pointer;
		display: flex;
		line-height: rem-calc(20);
		padding: rem-calc(8) rem-calc(16);
	}

	input {
		display: none;
	}
}

.clear-filter-chips-button {
	margin: 0 0 rem-calc(8) rem-calc(8);
}
