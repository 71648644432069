@import "../../../Lfi_FactoryTour/styles/module";

#factory-tour {
	@include ft-menu;
	@include ft-slide;
	@include ft-media;
	@include ft-chapters;
	@include ft-footer;
	@include ft-progress-bar;
}
