//
// Checkout discount code block
// ----------------------------
//

.discountCouponApplied {
	label {
		// TODO - too many selectors until FL gets applied to whole site
		display: none !important;
	}

	input {
		color: $input-color-disabled;
		border-right: none;
		opacity: 1;
		// TODO - too many selectors until FL gets applied to whole site
		padding: rem-calc(13 15 14 15) !important;
		text-transform: uppercase;
	}

	button {
		background: map-get($lfi-palette, snow) !important;
		box-shadow: inset 0 0 0 rem-calc(1) map-get($lfi-palette, light-gray);
		margin-left: rem-calc(-1); // slight witchery to hide box shadow
	}
}

.discount-code-title {
	@include bold-text();
	cursor: pointer;
	display: flex;
	font-size: rem-calc(16);
	justify-content: center;
	line-height: rem-calc(21);
	padding: rem-calc(20 0);

	> span:after {
		@include svg-icon('../icons/icon-chevron-down.svg');
	}
}

.form-discount-input {
	flex: 1 1 100%;
}

.payment-option.discount-code {
	.payment-option-content {
		margin-bottom: rem-calc(14);
	}
}

.discount-code-button {
	@include button-secondary;
}
