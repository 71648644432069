// Story Media w/ Text Block
// @doc - Block has image or video on one side with text on the other in various colors
// ------------------------
// div.story-media-block-container
//  div.story-media-block-wrapper
//   div.story-media-block-media
//   div.story-media-block-content

.story-media-block-container {
	padding: 80px 24px 56px;

	@include media-breakpoint-up(tablet) {
		padding: 80px 0;
	}
}

.story-media-block-wrapper {
	display: grid;
	grid-gap: 24px;

	@include media-breakpoint-up(tablet) {
		align-items: center;
		grid-gap: 0;
		grid-template-columns: repeat(2, minmax(40%, max-content));
	}
}

.story-media-block-media {
	order: 1;
}

.story-media-block-content {
	color: map-get($lfi-palette, jet);
	order: 2;

	@include media-breakpoint-up(tablet) {
		padding: 0 40px;
	}

	@include media-breakpoint-up(lg) {
		padding: 0 64px;
	}

	@include media-breakpoint-up(vlarge) {
		padding: 0 80px;
	}

	.button {
		margin: 0;
	}
}

.story-media-block-media,
.story-media-block-content {
	@include media-breakpoint-up(tablet) {
		order: initial;
	}
}
