@import "../../../default/styles/globals/colors";

///
/// Brand Palette
$danner-palette: (
	danner-green: #527769,
	danner-dark-green: #416b62,
	mild-green: #5b7765,
	woodland: #3b766f,
	forest: #19322f,
	forest-background: mix(#3b766f, $white, 7%),
	copper: #aa6c10,
);

$accent-color: map-get($danner-palette, copper);
$accent-font-color: map-get($danner-palette, danner-green);
$base-color: map_get($danner-palette, forest);
$base-primary-color: map_get($danner-palette, forest);
$dark-primary-color: map-get($danner-palette, danner-mild-green);
$background-theme-color: map-get($danner-palette, danner-green);
$background-primary-color: map_get($danner-palette, forest);
$background-secondary: map-get($danner-palette, forest-background);
$info-color: map-get($danner-palette, danner-green);
$body-medium-font-color: map-get($lfi-palette, metal);
$input-group-color: map-get($danner-palette, forest);
$primary-hover: map-get($danner-palette, woodland);
$primary-disabled: mix(map-get($danner-palette, forest), $white, 20%);

//
// Depricated Colors
// * These colors should no long be used as they are not in the brand guidelines
// * Should you recieve a design with these colors, please communicate this message
// * To the designer and have them confirm they are purposefully extending the guidelines
//
// tangerine: #E56849
// mild-green: #5B7765
// pine: #222F2A
