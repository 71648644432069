.skeleton-item {
	border-radius: 10px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 10%);
}

.skeleton-field {
	animation: skeleton-pulse 1.5s linear infinite;
	background: linear-gradient(to right, map-get($lfi-palette, snow) 30%, map-get($lfi-palette, light-gray) 50%, map-get($lfi-palette, snow) 70%);
	background-size: 200% 200%;
}

@keyframes skeleton-pulse {
	0% {
		background-position: 200% 200%;
	}

	100% {
		background-position: 0 0;
	}
}

.skeleton-image {
	border-radius: 8px;
	min-height: 100px;
	min-width: 100px;
}

.skeleton-text-field,
.skeleton-button {
	display: block;
	min-width: 50px;
}

.skeleton-select {
	border-radius: 5px;
	min-height: 50px;
	min-width: 120px;
}

.skeleton-text-field {
	border-radius: 8px;
	min-height: 24px;
}

.skeleton-button {
	border-radius: 24px;
	min-height: 35px;
}
