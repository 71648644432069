.homepage-hero {

	.hero-image-wrapper {
		align-items: flex-end;
		display: flex;
		min-height: 600px;
		position: relative;

		@media screen and (min-width: 640px) {
			min-height: 800px;
		}

		@media screen and (min-width: 1200px) {
			min-height: 900px;
		}
	}

	.content-container {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.95) 100%);
		flex: 0 0 100%;
		padding: 104px 24px 40px;
		position: relative;
		z-index: 2;

		@media screen and (min-width: 640px) {
			padding: 104px 48px 64px;
		}
	}

	.content-wrapper {
		align-items: center;
		margin: 0;
		max-width: 368px;

		@media screen and (min-width: 640px) {
			max-width: 544px;
		}

		@media screen and (min-width: 1200px) {
			max-width: 638px;
		}
	}

	.image-container {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	.buttons-group {
		margin: 0;
		li {
			display: inline;
			margin-left: 12px;
			&:first-of-type {
				margin-left: 0;
			}
		}
	}

	.button {
		margin-bottom: 0;
	}

	h1 {
		color: $white;
		font-size: 32px;
		font-weight: 900;
		line-height: 40px;
		margin-bottom: 8px;

		@media screen and (min-width: 640px) {
			font-size: 56px;
			line-height: 68px;
		}

		@media screen and (min-width: 1200px) {
			font-size: 56px;
			line-height: 68px;
		}
	}

	p {
		color: $white;
		font-size: 18px;
		font-weight: 400;
		line-height: 26px;
		margin-bottom: 24px;

		@media screen and (min-width: 640px) {
			font-size: 32px;
			line-height: 40px;
		}
	}
}
