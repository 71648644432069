@mixin ft-chapters() {
	.icon-construction::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-construction.svg');
	}

	.icon-finishing-touches::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-finishing-touches.svg');
	}

	.icon-gallery::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-gallery.svg');
	}

	.icon-introduction::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-introduction.svg');
	}

	.icon-material-preparation::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-material-preparation.svg');
	}

	.icon-materials-components::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-materials-components.svg');
	}

	*[class^="icon-chapter-logo-"] {
		--font-size: #{rem-calc(40)};

		font-size: var(--font-size);
		line-height: 1;

		@include breakpoint(medium) {
			--font-size: #{rem-calc(80)};
		}
	}

	.icon-chapter-logo-construction::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-chapter-logo-construction.svg');
	}

	.icon-chapter-logo-finishing-touches::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-chapter-logo-finishing-touches.svg');
	}

	.icon-chapter-logo-gallery::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-chapter-logo-gallery.svg');
	}

	.icon-chapter-logo-introduction::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-chapter-logo-introduction.svg');
	}

	.icon-chapter-logo-material-preparation::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-chapter-logo-material-preparation.svg');
	}

	.icon-chapter-logo-materials-components::before {
		@include svg-icon('../Lfi_FactoryTour/icons/icon-chapter-logo-materials-components.svg');
	}
}
