@import "../../default/styles/components/global/_breadcrumbs.scss";

.breadcrumbs {
	font-family: $header-font-regular;
	min-height: rem-calc(51); // prevent content jump
	padding: rem-calc(15 0);

	a,
	strong {
		line-height: rem-calc(21);
	}
}

body.has-banner {
	.breadcrumbs {
		position: absolute;
		top: $header-container-height;
		z-index: z-index(raised);
	}
}

.light-header-fg {
	.breadcrumbs {
		a,
		span,
		strong,
		li::after {
			color: $white;
		}
	}
}
