.story-quote-block {
	--block-outer-padding: 80px 24px;
	--grid-gap: 48px;
	--grid-justify-content: space-evenly;
	--grid-template-areas: "image" "quote";
	display: grid;
	gap: var(--grid-gap);
	grid-template-areas: var(--grid-template-areas);
	justify-content: var(--grid-justify-content);
	justify-items: center;
	padding: var(--block-outer-padding);

	@include media-breakpoint-up(tablet) {
		--block-outer-padding: 80px 0;
		--grid-gap: 9.3%;
		--grid-justify-content: center;
		--grid-template-areas: "image quote";
		grid-template-columns: 32% 39%;
	}

	.image-wrapper {
		@include outline-element(
			$selector: img,
			$outline-width: 1px,
			$outline-style: solid,
			$outline-color: $base-color,
			$outline-offset: 5px
		);
		grid-area: image;

		img {
			--image-width: 272px;
			border-radius: 50%;
			width: var(--image-width);

			@include media-breakpoint-up(tablet) {
				--image-width: auto;
			}
		}
	}

	.quote-wrapper {
		align-content: center;
		display: grid;
		gap: 24px;
		grid-area: quote;
		grid-auto-rows: min-content;
		justify-items: center;
		text-align: center;

		@include media-breakpoint-up(md) {
			padding: 0 24px;
		}

		// first set the font size, then constraint element height, then constraint line height
		.icon-quote {
			$quote-icon-size: 64px;
			font-size: $quote-icon-size;
			height: $quote-icon-size;
			line-height: $quote-icon-size;
		}
	}

	blockquote {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 0;
	}

	.author {
		font-size: 14px;
		line-height: 20px;
	}
}
