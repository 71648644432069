// Hint Box
// @note - hint boxes do not have exterior margin by default. Apply it in a case by case basis.
// --------
// div.tip-container
//  p
//   span.icon-sparkle-bold
//   span

.tip-container {
	border: 1px solid map-get($lfi-palette, light-gray);
	border-radius: 4px;
	padding: 16px;

	.icon-sparkle-bold {
		@include bold-text;

		&:before {
			margin-right: 8px;
		}
	}

	p {
		display: grid;
		font-size: 18px;
		grid-column-gap: 8px;
		grid-template-columns: auto auto;
		line-height: 26px;
	}
}
