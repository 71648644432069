// /* Breadcrumb library component
//  * -------------------
//  * div.breadcrumbs
//  *  ul.items
//  *   li.item
//  *    a
//  *   ....
//  */

.breadcrumbs {
	display: flex;
	justify-content: center;

	li {
		line-height: 1;
		margin-bottom: 0;
	}

	a,
	strong,
	li:after {
		font-size: $breadcrumbs-item-child-font-size;
	}

	a:hover {
		text-decoration: none;
	}
}
