//
// Checkout sidebar content
// ------------------------
// div.opc-summary-content-wrapper
//  div.opc-summary-items-wrapper
//   ol.opc-summary-items
//    li.opc-summary-item
//
//
//

$opc-summary-borders: rem-calc(1) solid rgba(0, 0, 0, 0.1) !default;

.opc-summary-content-wrapper {
	background: map_get($lfi-palette, snow);
	border: rem-calc(1) solid map_get($lfi-palette, light-gray);
	border-radius: rem-calc(0 0 10 10);
	border-top: $opc-summary-borders;

	@include breakpoint(tablet) {
		border-top: none;
		display: block !important; //override JS collapsible component
		padding-bottom: rem-calc(20);
	}
}

.opc-summary-items {
	padding: rem-calc(30 20);
}

.summary-item-qty-price,
.opc-summary-items {
	list-style: none;
	margin: 0;
}

.summary-item-details .item-options {
	@include item-options();
}

.summary-item-details.recrafting_service .item-options dt {
	display: inline-block;
	font-family: $body-font-family;
}

.opc-summary-item {
	align-items: center;
	display: flex;
	margin: 0;

	&:not(:first-of-type) {
		border-top: $opc-summary-borders;
		padding-top: rem-calc(20);
	}

	&:not(:last-of-type) {
		margin-bottom: rem-calc(20);
	}
}

.summary-item-details {
	display: flex;
	flex: 1 1 100%;
	flex-flow: row wrap;

	> * {
		flex: 1 1 100%;
	}
}

.summary-image-container {
	flex: 0 0 rem-calc(90);
	margin-right: rem-calc(20);
}

.summary-item-name {
	@include bold-text();
	line-height: rem-calc(23);
	margin: 0;
	text-transform: none;
}

.summary-item-subtitle,
.summary-item-details .item-options,
.summary-item-details .item-options .label,
.summary-item-qty-price li {
	font-size: rem-calc(14);
	line-height: rem-calc(21);

	.has-markdown {
		color: $primary-color;
		padding-left: rem-calc(6);
	}
}

.summary-item-qty-price {
	li {
		margin: 0;
	}
}

//
// Order Summary section
.opc-summary-totals {
	@include totals-table();
	width: 100%;
}

//
// Shipping section
// ----------------
// div.opc-summary-shipping
//  div.opc-summary-bottom-container
//   div.opc-summary-address
//    div.opc-summary-ship-header
//    div.opc-summary-ship-content
//  div.opc-summary-bottom-container
//   div.opc-summary-ship-method
//    div.opc-summary-ship-header
//    div.opc-summary-ship-content
//

.opc-summary-bottom-container {
	border-top: $opc-summary-borders;

	* {
		font-size: rem-calc(16);
		line-height: rem-calc(23);
	}

	> div {
		padding: rem-calc(30 20);
	}
}

.opc-summary-ship-header {
	@include bold-text();
	display: flex;
	justify-content: space-between;
	margin-bottom: rem-calc(10);
}

.opc-summary-ship-content {
	p {
		margin-bottom: rem-calc(3);

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.summary-item-error-container {
	background: $white;
	border: 1px solid map-get($lfi-palette, cherry);
	border-radius: 4px;
	color: map-get($lfi-palette, cherry);
	display: flex;
	gap: 6px;
	margin-top: 8px;
	padding: 8px;

	.error-note {
		font-size: 14px;
		line-height: 18px;

		a {
			color: map-get($lfi-palette, cherry);
		}
	}
}
