.recrafting-products {
	margin-bottom: rem-calc(24);

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: 50% 50%;
		justify-content: center;
	}
}

.recrafting-product-selection {
	background: $white;

	.recrafting-product-price {
		font-size: rem-calc(22);
		line-height: rem-calc(32);
	}
}

.recrafting-product-recommended {
	margin: rem-calc(60 24 56 24);

	@include media-breakpoint-up(lg) {
		margin: rem-calc(90) rem-calc(100) 0 auto;
		max-width: rem-calc(327);
		width: 100%;
	}

	@include media-breakpoint-up(xl) {
		margin-right: rem-calc(193);
	}

	.icon-list-check {
		align-items: center;
		color: map-get($lfi-palette, evergreen);
		display: flex;
		justify-content: center;
		margin-bottom: rem-calc(24);

		&::before {
			margin-right: rem-calc(8);
		}
	}

	.recrafting-product-price {
		margin-bottom: rem-calc(40);
	}

	h2 {
		margin-bottom: rem-calc(16);

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(40);
		}
	}

	img {
		margin-bottom: rem-calc(20);
		max-width: rem-calc(100);
	}
}

.recrafting-product-details {
	.recrafting-product-turnaround-notes {
		display: flex;

		@include media-breakpoint-up(lg) {
			display: none !important;
		}
	}

	h3 {
		line-height: rem-calc(32);
		margin-bottom: rem-calc(24);
		text-align: left;
	}
}

.recrafting-product-details-recommended {
	margin: rem-calc(0 34 40 34);

	@include media-breakpoint-up(lg) {
		background: map-get($lfi-palette, snow);
		border-radius: rem-calc(16);
		margin: rem-calc(184) 0 0 0;
		max-width: rem-calc(432);
		padding: rem-calc(56);
	}
}

.recrafting-product-details-alternative {
	background: map-get($lfi-palette, snow);
	padding: rem-calc(40 26);

	@include media-breakpoint-up(lg) {
		background: $white;
		margin-left: 0;
		margin-right: 0;
		max-width: rem-calc(442);
	}

	.accordion-item {
		border-radius: 0;
		border-top: rem-calc(1) solid map-get($lfi-palette, light-gray);
		padding: rem-calc(40) 0;
	}

	.accordion-button {
		font-size: rem-calc(20);
		line-height: rem-calc(26);
		text-transform: none;

		&:after {
			height: 32px;
			width: 32px;
		}
	}

	.accordion-collapse {
		padding: rem-calc(40 8 0 8);
	}

	.recrafting-product-price {
		margin-bottom: rem-calc(24);
		margin-left: 0;
		text-align: left;
	}

	.recrafting-product-outsole-note {
		margin-bottom: rem-calc(24);
	}

	h3 {
		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(48);
		}
	}
}

.recrafting-product-details-list {
	@include list-reset;

	li {
		align-items: center;
		display: flex;
		line-height: rem-calc(20);
		margin-bottom: rem-calc(24);

		&::before {
			color: map-get($danner-palette, forest);
			height: rem-calc(17);
			margin-right: rem-calc(8);
			width: rem-calc(17);
		}
	}
}

.recrafting-product-outsole-note {
	display: inline-block;
	font-size: rem-calc(14) !important;
	line-height: rem-calc(20) !important;
	text-align: left;
	width: 100%;
}

.recrafting-product-turnaround-notes {
	background: map-get($lfi-palette, snow);
	border: rem-calc(1) solid map-get($lfi-palette, light-gray);
	border-radius: rem-calc(4);
	display: none;
	margin-top: rem-calc(24);
	padding: rem-calc(8 8 8 12);
	text-align: left;

	@include media-breakpoint-up(lg) {
		display: flex;
		margin-top: rem-calc(40);
	}

	.icon-calendar {
		margin-right: rem-calc(10);

		&::before {
			height: rem-calc(16);
			width: rem-calc(18);
		}
	}

	p {
		font-size: rem-calc(14);
		line-height: rem-calc(20);
		margin: 0;
		max-width: none;
	}
}
