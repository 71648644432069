///
// Category Product Highlight Blocks
// ---------------------------------
// Suggested use
// section.category-product-highlights
//  div.cph-wrapper
//   div.cph
//    div.cph-image
//     img
//    div.cph-content
//     ...
///

.category-product-highlights {
	padding: rem-calc(85 15 0 15);

	@include breakpoint(small) {
		padding: rem-calc(120 50 0 50);
	}

	.cph-content p {
		margin-bottom: rem-calc(30);
	}
}

.cph-wrapper {
	margin: 0 auto;
	max-width: rem-calc(1122);
}

.cph {
	@include breakpoint(small) {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: rem-calc(100);
	}

	&:nth-of-type(2n) {
		.cph-image {
			order: 2;
		}

		.cph-content {
			order: 1;
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}

.cph-image {
	flex: 1 1 50%;
	padding-bottom: rem-calc(40);

	@include breakpoint(small) {
		max-width: rem-calc(556);
		padding-bottom: 0;
	}
}

.cph-content {
	flex: 1 1 50%;
	padding-bottom: rem-calc(40);

	@include breakpoint(small) {
		max-width: rem-calc(556);
		padding: rem-calc(20 20);
	}

	@include breakpoint(medium) {
		padding: rem-calc(20 80);
	}

	p {
		font-size: rem-calc(18);
		line-height: rem-calc(30);
	}

	h3 {
		font-size: rem-calc(34);
		line-height: rem-calc(30);
		margin-bottom: rem-calc(35);
		text-transform: none;

		@include breakpoint(small) {
			font-size: rem-calc(28);
			margin-bottom: rem-calc(15);
		}
	}
}
