// A mixin for enforcing the small-centered text nodes
// instead of setting it with the cell widths
@mixin small-centered($max: 900px) {
	margin-left: auto;
	margin-right: auto;
	max-width: $max;
	padding-left: 4.5%;
	padding-right: 4.5%;

	justify-content: center;
	text-align: center;

	@include breakpoint(medium) {
		padding-left: 0;
		padding-right: 0;
	}
}
