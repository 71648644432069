////
// Input Button Adjustment Mixin
// Alters colors for button and input for different branded options
$input-button-color-adjusted: black !default;
$input-button-font-adjusted: inherit !default;
$input-button-input-text-color-adjusted: black !default;
$input-button-text-color-adjusted: black !default;
// @use Should be used at the .input-group level or above
@mixin input-group-color-adjustment(
	$button-and-border-color: $input-button-color-adjusted,
	$input-text-color: $input-button-input-text-color-adjusted,
	$button-text-color: $input-button-text-color-adjusted
) {
	.input-text {
		background: transparent;
		border-color: $button-and-border-color;
		color: $input-text-color !important; // override float label styles
	}

	::placeholder {
		color: $input-text-color; // override float label styles
	}

	button {
		background-color: $button-and-border-color;
		color: $button-text-color;
		font-family: $input-button-font-adjusted;

		&:active,
		&:hover {
			background-color: get-active-button-color($button-and-border-color);
			color: $button-text-color;
		}
	}
}
