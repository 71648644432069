body.cms-about-danner {
	.banner-background {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.danner-1932-badge {
		display: inline-block;
		width: rem-calc(100);
		margin-bottom: rem-calc(15);
	}

	.page-main {
		margin-top: 0;
		margin-bottom: 0;
	}

	.go-there-video-content {
		.go-there-logo {
			margin-bottom: rem-calc(20);
		}
	}

	.page-main {
		hr {
			margin-top: 0;
			margin-left: 0;
			border-color: map-get($danner-palette, copper);
			width: rem-calc(44);
		}
	}

	.built-to-last {
		padding-top: rem-calc(70);
		padding-bottom: rem-calc(70);
		background-color: map-get($lfi-palette, sleet);

		@include breakpoint(large) {
			padding-top: rem-calc(110);
			padding-bottom: rem-calc(110);
		}

		.subheader {
			color: map-get($danner-palette, forest);
		}

		p {
			margin-bottom: rem-calc(50);

			@include breakpoint(large) {
				margin-bottom: rem-calc(73);
			}
		}

		hr {
			margin-top: rem-calc(20);
			margin-left: auto;
		}
	}

	.about-us-content {
		margin: rem-calc(55) auto;

		@include breakpoint(large) {
			margin: rem-calc(75) auto;
		}

		img {
			margin-top: rem-calc(20);
			@include breakpoint(medium) {
				margin: rem-calc(10 0);
			}

			@include breakpoint(large) {
				margin: rem-calc(20 0);
			}
		}

		p {
			@include breakpoint(large) {
				font-size: rem-calc(18);
			}

			+ p {
				margin-top: rem-calc(40);
			}
		}

		hr {
			margin-bottom: rem-calc(18);
			margin-top: rem-calc(10);
		}

		.left-text-block {
			@include breakpoint(medium) {
				padding-left: percentage(divide(($column-width + $gutter-width), strip-unit($grid-container)) * 100);
				padding-right: percentage(divide(($column-width + $gutter-width), strip-unit($grid-container)) * 100);
			}

			@include breakpoint(large) {
				padding-left: percentage(divide(($column-width + $gutter-width) * 2, strip-unit($grid-container)) * 100);
			}
		}

		.right-text-block {
			@include breakpoint(medium) {
				padding-left: percentage(divide(($column-width + $gutter-width), strip-unit($grid-container)) * 100);
				padding-right: percentage(divide(($column-width + $gutter-width), strip-unit($grid-container)) * 100);
			}

			@include breakpoint(large) {
				padding-right: percentage(divide(($column-width + $gutter-width) * 2, strip-unit($grid-container)) * 100);
			}
		}
	}
}
