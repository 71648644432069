$button-background: $primary-color;
$button-background-hover: $dark-primary-color;

$defnlist-margin-bottom: 0;
$reveal-padding: rem-calc(25);
$grid-button-container-width: percentage(divide(2, 3));
$buttongroup-spacing: rem-calc(3);

$breakpoints: (
	small: 0,
	medium: 640px,
	tablet: 900px,
	large: 1024px,
	xlarge: 1200px,
	xxlarge: 1440px,
	vlarge: 1920px
);

// Expand the widescreen box slightly due to mobile Safari issue where embed sometimes disappears.
// Add the DCI standard that some of our videos use (256:135 ~ 1024 x 540) then add a little extra room.
$responsive-embed-ratios: map-merge(
	$responsive-embed-ratios,
	(widescreen: 16 by 9.5, dci: 256 by 138)
);
$responsive-embed-margin-bottom: 0;

$closebutton-offset-horizontal: map-merge(
	$closebutton-offset-horizontal,
	(small: 0.5rem, medium: 0.25rem)
);
$closebutton-offset-vertical: map-merge(
	$closebutton-offset-vertical,
	(small: 0.2rem, medium: 0)
);

$slick-font-family: "";

$overlay-background-color: rgba(0, 0, 0, 0.3);

////
// Foundation Settings
////
$body-antialiased: false;
$code-border: 1px solid $light-gray;
$global-font-size: 16px;
$input-background-disabled: $light-gray;
$input-label-disabled: scale-color($light-gray, $lightness: -20%);
$input-border-disabled: map-get($lfi-palette, cream);
$body-antialiased: false;
$hr-width: 100%;
$hr-width-xsmall: rem-calc(30);
$hr-width-small: rem-calc(80);
$hr-border: 2px solid $light-gray;
$blockquote-padding: 0;
$blockquote-border: unset;

// Foundation Colors
$body-font-color: map-get($lfi-palette, jet);
$blockquote-color: $body-font-color;
$cite-color: map-get($lfi-palette, stone);
$code-background: $white;
$label-color-alt: $medium-gray;
$helptext-color: $dark-gray;
$keystroke-background: $light-gray;

// Foundation Forms
$form-label-color: map-get($lfi-palette, jet);
$form-label-font-size: rem-calc(16);
$form-spacing: rem-calc(14);
$input-color: map-get($lfi-palette, jet);
$input-placeholder-color: mix($light-gray, $medium-gray);
$input-prefix-color: $dark-gray;
$input-prefix-border: 2px solid $light-gray;
$input-radius: rem-calc(5);
$input-shadow: none;
$input-shadow-focus: none;
$label-color: map-get($lfi-palette, jet);
$label-background: transparent;
$label-padding: 0;
$select-radius: $global-radius;
$select-triangle-color: $medium-gray;

// Foundation Typography
$list-lineheight: 1.2;
$header-lineheight: 1.2;
$header-margin-bottom: rem-calc(8);
$paragraph-lineheight: 1.5;
$subheader-lineheight: 1;
$subheader-font-weight: $font-weight__semibold;
$subheader-margin-top: 0;
$subheader-margin-bottom: 0.2rem;

// Foundation Layout
$fieldset-margin: 0;
$fieldset-padding: 0;
$fieldset-border: 0;
$grid-container: $global-width;
$grid-container-padding: $global-grid-container-padding;
$grid-margin-gutters: rem-calc(12);
$offcanvas-sizes: (
	small: 250px,
	medium: 330px
);

// Foundation Buttons
$button-color-alt: map-get($lfi-palette, jet);
$buttongroup-radius-on-each: false;

// Foundation Breadcrumbs
$breadcrumbs-item-child-font-size: rem-calc(14);
$breadcrumbs-item-margin: 0.5rem;
$breadcrumbs-item-font-size: 0; // small hack to remove whitespace, font size is set on the child elements

// Accordion
$accordion-background: transparent;

/*
 * Bootstrap
 */
$modal-content-border-radius: rem-calc(24);
$modal-header-padding: rem-calc(24 24 32 24);
/* stylelint-disable-next-line */
$modal-backdrop-bg: rgba(0, 0, 0, 0.4);

$tooltip-bg: map-get($lfi-palette, snow);
$tooltip-color: $body-font-color;
$tooltip-max-width: rem-calc(300);
$tooltip-opacity: 1;
$tooltip-padding-x: rem-calc(5);
$tooltip-padding-y: rem-calc(2);
