// Button Appearance Variables
// @see button-appearance()
$default-button-radius: 24px;
$default-button-padding: 18px 30px 17px 30px;
$default-button-color: black;
$default-button-width: 2px;
$default-button-text-color: white;

// Button Typography Variables
// @see button-typography()
$default-button-font-family: sans-serif;
$default-button-font-size: 16px;
$default-button-font-weight: 400;
$default-button-line-height: 1;
$default-button-letter-spacing: 0.5px;

// Button Light Appearance Variables
// @see button-light-appearance()
$default-button-light-color: gold;
$default-button-light-text-color: black;
$default-button-light-active-text-color: $default-button-light-text-color;

//
// Used to return color for active button state
@function get-active-button-color($color) {
	@return scale-color($color, $lightness: -15%);
}

@function get-disabled-color($color) {
	@return mix($color, #fff, 20%);
}

// Checkbox Button
// Use on a container that's wrapping a checkbox and a label to turn it into a button.
// @Usage
// @include checkbox-button();
// TODO
// 1.Pass color or button type instead of defaulting to secondary
@mixin checkbox-button() {
	background: $light-gray;

	// To properly center the label
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	// To properly absopos the checkbox
	position: relative;

	label {
		color: $body-font-color;
		font-size: rem-calc(15);
		font-weight: $global-weight-normal;
		margin: 0;
		padding: $button-padding;
		pointer-events: none;
		position: relative;
		text-align: center;
		z-index: 2;

		&[for] {
			pointer-events: auto;
		}
	}

	&[data-active="true"] {
		background: $dark-gray;

		label {
			color: $white;
			font-weight: $global-weight-bold;
		}
	}

	// Make checkbox invisible, but fill the entirety of parent
	input[type="checkbox"] {
		appearance: none;
		border: 0;

		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		height: 100%;
		width: 100%;

		&:before {
			display: none;
		}

		&:disabled,
		&[data-active="true"]:disabled {
			background: $light-gray;
			opacity: 1;

			+ label {
				color: $body-font-color;
				font-weight: $global-weight-normal;
				opacity: 0.3;
			}
		}

		&::-ms-check {
			// Essentially appearance: none for IE
			display: none;
		}
	}
}

// New Button mixins to offer more control outside of Foundation
// @usage should look like this
//  @mixin buttonUtilityMixin {
//  	@include button-core;
//  	@include button-typography(
//	  	$font-family: $header-font-family,
//	  	$font-size: rem-calc(15),
//		 $line-height: rem-calc(20),
//		 $letter-spacing: rem-calc(0.5)
//	 );
//
//	 @include button-appearance(
//	  	$border-radius: rem-calc(5),
//		 $button-color: map_get($danner-palette, forest),
//		 $text-color: $white
//	 );
// }
//
// .new-button {
//   @include buttonUtilityMixin;
// }
//

@mixin button-core {
	@include disable-mouse-outline;
	display: inline-block;
	transition: $button-transition; // keep with Foundation for now
	-webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
	text-align: center;
	cursor: pointer;

	&.expanded {
		@include button-expand; // keep with Foundation for now
	}

	&.disabled,
	&[disabled] {
		cursor: not-allowed;
	}

	&:focus {
		outline: 0;
	}

	&:hover {
		text-decoration: none;
	}

	// For the occasional child span / label / etc.
	* {
		cursor: inherit;
		line-height: inherit;
	}
}

//
// @use Adjusts button color and shape
@mixin button-appearance(
	$button-color: $default-button-color,
	$border-radius: $default-button-radius,
	$text-color: $default-button-text-color,
	$active-color: get-active-button-color($button-color),
	$active-text-color: $text-color,
	$border-color: $button-color,
	$border-width: $default-button-width,
	$has-border: false,
	$padding: $default-button-padding
) {
	background-color: $button-color;
	border-radius: $border-radius;
	color: $text-color;
	padding: $padding;

	@if $has-border != false {
		border: $border-width solid $border-color;
	} @else {
		border: none;
	}

	// Default and disabled states
	// Copied from node_modules/foundation-sites/scss/components/_button.scss
	&.disabled,
	&[disabled],
	&.disabled:hover,
	&[disabled]:hover,
	&.disabled:focus,
	&[disabled]:focus {
		background-color: $button-color;
		color: $text-color;
	}

	@media (hover: hover) {
		&:hover {
			background-color: $active-color;
			color: $active-text-color;

			@if $has-border != false {
				border-color: $active-color;
			}
		}
	}

	&:active,
	&:focus {
		background-color: $active-color;
		color: $active-text-color;

		@if $has-border != false {
			border-color: $active-color;
		}
	}
}

//
// @use Adjusts button typography
@mixin button-typography(
	$font-family: $default-button-font-family,
	$font-size: $default-button-font-size,
	$font-weight: $default-button-font-weight,
	$line-height: $default-button-line-height,
	$letter-spacing: $default-button-letter-spacing,
	$text-transform: uppercase
) {
	font-family: $font-family;
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: $line-height;
	letter-spacing: $letter-spacing;
	text-transform: $text-transform;
}

//
// @use Adjusts button appearance for light button version
@mixin button-light-appearance {
	background-color: $default-button-light-color;
	color: $default-button-light-text-color;
	font-size: 15px;
	$active-button-color: get-active-button-color($default-button-light-color);

	&:hover,
	&:focus {
		background-color: $active-button-color;
		border-color: $active-button-color;
		color: $default-button-light-active-text-color;
	}
}

@mixin button-oval-adjustment(
	$border-radius-adjustment: 15px,
	$line-height-radius-adjustment: 60px
) {
	border-radius: $border-radius-adjustment;
	line-height: $line-height-radius-adjustment;
	padding-bottom: 0;
	padding-top: 0;
}

@mixin button-transparent {
	background: transparent;

	&:hover,
	&:focus {
		background: transparent;
	}
}

//
// Define Button Prototypes here and override in each theme.
// Doing so will allow their use in the default theme.

@mixin button-primary {
	// override me!
}

@mixin button-primary-light {
	// override me!
}

@mixin button-oval {
	// override me!
}

@mixin button-secondary {
	// override me!
}

@mixin button-type-hollow($color: false, $inverted: false) {
	// override me!
}

@mixin button-hollow-primary() {
	// override me!
}

@mixin button-input-combined {
	// override me!
}

@mixin button-icon-with-text($icon-after: false) {
	padding: 12px 32px;

	span {
		align-items: center;
		display: flex;

		&::before {
			height: 24px;
			margin-right: 8px;
			width: 24px;

			@if $icon-after {
				margin-left: 8px;
				margin-right: 0;
				order: 2;
			}
		}
	}
}

@mixin button-with-link-styles {
	@include bold-text;
	background: transparent;
	color: $anchor-color;
	padding: 0;
	text-transform: none;

	&:hover,
	&:focus,
	&:visited {
		background: transparent;
		color: $anchor-color-hover;
	}
}

@mixin action-button($icon-path: false) {
	@include button-transparent();
	color: map-get($lfi-palette, jet);
	cursor: pointer;
	pointer-events: auto;

	@if $icon-path {
		&::before {
			@include svg-icon($icon-path);
		}
	}

	&:hover,
	&:focus {
		color: get-active-button-color(map-get($lfi-palette, jet));
	}

	&:focus {
		outline: revert;
	}
}
