@mixin ft-progress-bar {
	.progress-bar {
		--ft-progress-bar-align-self: center;
		--ft-progress-bar-chapter-marker-size: 6px;
		--ft-progress-bar-margin: 0;
		--ft-progress-bar-padding: 16px;
		--ft-progress-bar-position-top: 16px;
		--ft-progress-bar-progress-foreground: #{map-get($lfi-palette, jet)};
		--ft-progress-bar-progress-width: 3px;
		--ft-progress-bar-remaining-foreground: #{$white-hover};
		--ft-progress-bar-remaining-width: 3px;

		align-self: var(--ft-progress-bar-align-self);
		background: var(--ft-progress-bar-background);
		font-size: 0; // collapse whitespace
		margin: var(--ft-progress-bar-margin);
		padding: var(--ft-progress-bar-padding);
		position: relative;
		top: var(--ft-progress-bar-position-top);
		width: 100%;
		z-index: 2;

		@include media-breakpoint-up(md) {
			--ft-progress-bar-align-self: auto;
			--ft-progress-bar-chapter-marker-size: 8px;
			--ft-progress-bar-margin: 7vh 0 4vh;
			--ft-progress-bar-position-top: 0;
			--ft-progress-bar-progress-width: 4px;
			--ft-progress-bar-remaining-width: 1px;
		}

		.pb-bar {
			grid-template-columns: var(--progress) var(--remaining);

			// ensure the progress bar does not peek out behind the beginning and ending markers
			margin-left: 2px;
			margin-right: 2px;

			margin-top: calc(var(--ft-progress-bar-progress-width) / -2);
			transition: all 1s;

			@include media-breakpoint-up(md) {
				grid-template-rows: var(--progress) var(--remaining);

				// ensure the progress bar does not peek out behind the beginning and ending markers
				margin-bottom: 2px;
				margin-top: 2px;
			}
		}

		.pb-chapters {
			--segments: 1fr;
			grid-template-columns: var(--segments);
			margin-top: calc(var(--ft-progress-bar-chapter-marker-size) / -2);

			@include media-breakpoint-up(md) {
				grid-template-rows: var(--segments);
			}
		}

		.pb-bar,
		.pb-chapters {
			display: grid;
			grid-auto-flow: column;
			left: var(--ft-progress-bar-padding);
			position: absolute;
			right: var(--ft-progress-bar-padding);

			@include media-breakpoint-up(md) {
				bottom: var(--ft-progress-bar-padding);
				grid-auto-flow: row;
				grid-template-columns: 1fr;
				left: 0;
				//margin: 0 auto;
				right: 0;
				top: var(--ft-progress-bar-padding);
			}
		}

		.pb-progress {
			--background: var(--ft-progress-bar-progress-foreground);
			--height: var(--ft-progress-bar-progress-width);
			--margin-top: calc((max(var(--ft-progress-bar-progress-width), var(--ft-progress-bar-remaining-width)) - var(--ft-progress-bar-progress-width)) / 2);

			@include media-breakpoint-up(md) {
				--width: var(--ft-progress-bar-progress-width);
			}
		}

		.pb-remaining {
			--background: var(--ft-progress-bar-remaining-foreground);
			--height: var(--ft-progress-bar-remaining-width);
			--margin-top: calc((max(var(--ft-progress-bar-progress-width), var(--ft-progress-bar-remaining-width)) - var(--ft-progress-bar-remaining-width)) / 2);

			@include media-breakpoint-up(md) {
				--width: var(--ft-progress-bar-remaining-width);
			}
		}

		.pb-progress,
		.pb-remaining {
			background: var(--background);
			height: var(--height);
			margin-top: var(--margin-top);

			@include media-breakpoint-up(md) {
				height: initial;
				margin: 0 auto;
				width: var(--width);
			}
		}

		.pb-chapter {
			background: var(--ft-progress-bar-remaining-foreground);
			border-radius: 50%;
			content: '';
			display: block;
			height: var(--ft-progress-bar-chapter-marker-size);
			transition: background 1s;
			width: var(--ft-progress-bar-chapter-marker-size);

			@include media-breakpoint-up(md) {
				margin: 0 auto;
			}

			&.active {
				background: var(--ft-progress-bar-progress-foreground);
			}
		}
	}
}
