$story-timeline-color: $info-color;
$story-timeline-width: 4px;
$story-timeline-item-gap: 40px;
.story-timeline {
	display: grid;
	grid-auto-columns: 280px;
	grid-auto-flow: column;
	justify-content: safe center;
	margin: 0 auto;
	overflow: auto;
	padding: 12px 24px;
}

.story-timeline-item-content {
	display: grid;
	gap: 16px;
	padding-right: $story-timeline-item-gap;
}

.story-timeline-item-title {
	border-top: $story-timeline-width solid $story-timeline-color;
	margin-top: 12px;
	padding-top: 16px;
	position: relative;

	&::before {
		@extend %story-timeline-dot;
	}

	@at-root *:last-of-type > & {
		margin-right: $story-timeline-item-gap;

		&::after {
			$icon-size: 40px;

			@include svg-icon('../icons/icon-chevron-right.svg', $icon-size, $icon-size);
			color: $story-timeline-color;
			left: calc(100% - #{$story-timeline-width + 0.5 * $icon-size});
			position: absolute;
			top: -0.5 * ($icon-size + $story-timeline-width) - 0.1px; //extra 0.1px nudges the arrow to align with timeline
		}
	}
}

%story-timeline-dot {
	$dot-size: 16px;
	background-color: $story-timeline-color;
	border-radius: 50%;
	content: "";
	height: $dot-size;
	left: 0;
	position: absolute;
	top: -0.5 * ($dot-size + $story-timeline-width);
	width: $dot-size;
}
