$input-hover-color: mix($dark-gray, $input-placeholder-color);

label {
	&.disabled {
		color: fade-out($medium-gray, 0.5);
	}
}

fieldset {
	.fieldset-content {
		display: inline-flex;
		align-items: center;
		justify-content: space-around;
		margin-bottom: rem-calc(12);

		label {
			font-weight: $form-label-font-weight;
			text-transform: none;
			white-space: nowrap;
		}
	}

	&.fieldset {
		border: 0;
		margin-top: 0;
	}
}

.fieldset {
	position: relative;
	width: 100%;
}

$form-widget-d: 15px;

[type="checkbox"],
[type="radio"] {
	align-content: center;
	align-items: center;
	appearance: none;
	border: $input-border;
	border-radius: $global-radius;
	cursor: pointer;
	display: flex;
	flex: 0 0 $form-widget-d;
	height: $form-widget-d;
	justify-content: center;
	margin: rem-calc(0);
	position: relative;
	width: $form-widget-d;

	&:checked {
		background: $input-color;
		border-color: $input-color;
	}

	&:disabled {
		border-color: $input-background-disabled;
	}

	&:disabled ~ label {
		color: $input-label-disabled !important;
		cursor: $input-cursor-disabled;
	}
}

[type="checkbox"] {
	~ label {
		padding: 0;
	}

	&:checked {
		&::before {
			@include svg-icon('../icons/icon-check.svg');
			color: $white;
			font-size: rem-calc(12);
		}
	}
}

[type="radio"] {
	align-items: center;
	border-radius: 50%;
	justify-content: center;

	&:checked {
		&::before {
			$d: divide($form-widget-d, 3);
			background: $white;
			border-radius: 50%;
			content: "";
			height: $d;
			position: absolute;
			width: $d;
		}
	}
}

select {
	// todo - Foundation does a height calculation based upon font size/line-height/padding
	// so we override the font size here to keep the height calculation for now.
	font-size: $global-font-size;

	> option {
		padding-inline-end: 0;
		padding-inline-start: 0;
	}
}

// Hide M2 Password Strength Indicator
[id="password-strength-meter-container"] {
	display: none;
}

// Magento Overrides
.field {
	&.choice {
		align-items: center;
		display: flex;

		.input-checkbox {
			align-items: center;
			display: flex;
		}

		input {
			margin-bottom: 0;
		}

		input.radio {
			~ .label {
				padding: 0;
			}
		}

		.label {
			font-weight: $form-label-font-weight;
			padding-bottom: 0;
		}

		input:checked + .label {
			font-weight: $global-weight-bold;
		}

		+ .choice {
			margin-top: 0;
		}
	}

	.checkbox-options-container,
	.checkbox-options {
		width: 100%;
	}

	.checkbox-options {
		padding-left: 0;
	}
}

// When in a fieldset, the legend is the label
fieldset.field {
	// Remove extra fieldset labels
	.control {
		label.label:not(.fl-label) {
			display: none;
		}
	}
}

input,
select,
textarea {
	&:disabled,
	&[disabled="true"] {
		color: $base-color;

		&[value=""] {
			opacity: 0.3;
		}
	}
}

::placeholder {
	color: map-get($lfi-palette, jet);
	letter-spacing: rem-calc(0.3);
	text-transform: uppercase;
	font-size: rem-calc(16);
}

///
.required,
._required {
	// Apply asterisk to all required fields
	label,
	legend.label {
		&::after {
			display: none;
		}
	}
}

// prevent content jumping and handle no-js
.form,
form {
	.choice {
		.label {
			display: inline-block;
		}
	}

	.label {
		display: none;

		html[class="no-js"] & {
			display: inline-block;
		}
	}
}

select,
input[type="email"],
input[type="text"] {
	border-radius: rem-calc(5);
}
