.style-info-help-modal {
	a,
	h4,
	p {
		margin: 0 auto;
	}

	img,
	p {
		margin-bottom: rem-calc(40);
	}

	h4 {
		margin-bottom: rem-calc(30);
	}

	p,
	a {
		@include bold-text;
		line-height: rem-calc(20);
	}

	p:last-of-type {
		margin-bottom: 0;

		@include media-breakpoint-up(lg) {
			max-width: rem-calc(290);
		}
	}
}

.recrafting-product-details {
	.modal-body {
		text-align: left;
	}

	h3 {
		margin: 0 0 rem-calc(32) 0;
	}
}

.recrafting-exit-confirmation {

	h3 {
		margin-bottom: rem-calc(32);
	}

	button,
	p {
		margin-bottom: rem-calc(24);
	}

	a {
		display: block;
	}
}

.recrafting-detail-help {
	.image-container {
		align-items: center;
		background: map-get($lfi-palette, snow);
		border-radius: rem-calc(8);
		display: flex;
		height: rem-calc(287);
		justify-content: center;
		margin-bottom: rem-calc(40);
		width: 100%;

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(50);
		}
	}

	p {
		margin-bottom: rem-calc(24);

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(40);
		}
	}

	h3 {
		margin-bottom: rem-calc(8);
	}

	a {
		display: block;
	}
}
