/*
 * Common styles for customer account pages
 */

.sales-guest-form,
.customer-account-login,
.customer-account-forgotpassword,
.customer-account-create,
.customer-account-createpassword {
	.column.main {
		margin-left: auto;
		margin-right: auto;
		max-width: rem-calc(450);
		padding-left: rem-calc(16);
		padding-right: rem-calc(16);
		text-align: center;

		@include media-breakpoint-up(md) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.page-title-wrapper {
		max-width: rem-calc(450);
	}
}

.sales-guest-form,
.customer-account-login,
.customer-account-forgotpassword,
.customer-account-create,
.customer-account-createpassword,
body.account {

	.page-title {
		font-size: rem-calc(24);
		line-height: rem-calc(32);
		margin-bottom: rem-calc(8);

		@include media-breakpoint-up(lg) {
			font-size: rem-calc(28);
		}
	}

	.block-title,
	.note {
		display: none;
	}

	.header-note {
		margin-bottom: rem-calc(40);
	}

	.actions-toolbar {
		.action {
			@include button-expand();
		}

		.secondary {
			margin-top: rem-calc(16);
			text-align: center;
		}

		.oval-button,
		button {
			@include button-oval-adjustment(rem-calc(24), rem-calc(48));
		}
	}

	.back {
		@include bold-text;
	}
}

// some forms have the .account class so we need to account for that by upping our selector game
// (adding body)
body.account {
	.actions-toolbar {
		@include media-breakpoint-up(lg) {
			max-width: rem-calc(272);
		}
	}
}

.account {
	.page-main {
		margin: 0;

		ul {
			@include list-reset;
		}

		.columns {
			display: grid;
			margin: 0 auto;
			max-width: $max-page-width;

			@include media-breakpoint-up(lg) {
				column-gap: rem-calc(40);
				grid-template-columns: rem-calc(236) 1fr;
				padding: rem-calc(72) rem-calc(24);
			}

			@include media-breakpoint-up(xl) {
				column-gap: rem-calc(48);
				padding-left: rem-calc(160);
				padding-right: rem-calc(160);
			}
		}
	}

	.sidebar-main {
		order: 0;
	}

	.main {
		order: 1;
		// need to override global styles that are very specific
		padding: rem-calc(72) rem-calc(16) !important;

		@include media-breakpoint-up(lg) {
			max-width: rem-calc(450);
			padding: 0 !important;
		}

		.block {
			margin-bottom: rem-calc(32);
		}
	}

	.box-title {
		margin-bottom: rem-calc(8);
	}

	.box {
		margin-bottom: rem-calc(32);

		a {
			@include bold-text;
		}
	}

	.page-title-wrapper {
		margin-bottom: rem-calc(40);
		margin-top: 0;
		padding: 0;

		@include media-breakpoint-up(lg) {
			text-align: left;
		}
	}

	.choice {
		margin-bottom: rem-calc(12);
	}

	.fieldset:last-of-type {
		margin-bottom: rem-calc(40);
	}
}
