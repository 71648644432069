//
// .product-reviews-summary
//

.product-reviews-summary {
	cursor: pointer;
}

.product-reviews-info-container {
	.product-reviews-summary {
		cursor: initial;

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
}

.review-rating-summary {
	--percent: calc(var(--rating) / 5 * 100%);
	--star-base-color: #{map-get($lfi-palette, light-gray)};
	--star-rating-color: #{$base-color};

	&::before {
		@include svg-icon('../icons/icon-Whole-Star.svg', $width: 5em, $height: 1em);
		background: linear-gradient(90deg, var(--star-rating-color) var(--percent), var(--star-base-color) var(--percent));
		mask-repeat: repeat;
	}
}
