/*
 * Recrafting Help Section
 * -----------------------
 */

.recrafting-help {
	color: map-get($danner-palette, forest);
	background-color: map-get($lfi-palette, sleet);
	text-align: center;

	h2 {
		font-size: rem-calc(20);
		margin-bottom: rem-calc(15);
	}

	p {
		font-size: $global-font-size;
	}

	.action {
		margin-top: rem-calc(20);
	}
}
