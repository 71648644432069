// Customer Credit Page Styles

.magento-customerbalance-info-index {
	.column.main {
		max-width: none;
	}
}

.block-balance {
	.price {
		@include bold-text();
	}
}
