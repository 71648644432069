/*
 * Go There Landing Page
 * ---------------------------------------
 * section.brand-statement
 * section.some-stuff
 * section.go-there-callout
 *  div.widget.block.block-static-block
 *   div.content-wrapper
 *    div.content-top
 *     img
 *     h4.subheader
 *     p
 *    div.content
 *     p
 *     p
 */

.cms-go-there-landing {
	.page-main {
		margin: 0;
	}

	.go-there-play-icon {
		margin-bottom: 0;
	}

	.banner-content {
		line-height: 1;
	}

	.go-there-logo {
		height: rem-calc(45);
		margin-bottom: rem-calc(26);
		width: rem-calc(266);

		@include breakpoint(medium) {
			height: rem-calc(65);
			margin-bottom: rem-calc(20);
			width: rem-calc(387);
		}
	}

	.dark-overlay .banner-overlay {
		background-color: rgba(0, 0, 0, 0.2);

		@include breakpoint(medium) {
			background-color: rgba(0, 0, 0, 0.1);
		}
	}

	// TODO - remove !important once template stops rendering background-position (DBU-22)
	.banner-background {
		background-position-x: 58% !important;
	}
}
