#lcly-link-0 {
	color: $anchor-color !important;
	font-family: $body-font-family !important;
	font-size: $global-font-size !important;
	line-height: $global-lineheight !important;
	margin-bottom: 0;

	&:hover {
		color: $anchor-color-hover !important;
	}
}

.lcly-location-prompt-label {
	font-size: rem-calc(16);
	padding-right: rem-calc(8);
}

.lcly-location-features {
	font-weight: normal;
}

.locally-pdp-container {
	margin-top: rem-calc(24);

	//
	// Locally Store Selection
	//
	#lcly-button-0 {
		margin-top: 0;
	}

	.lcly-location-switcher-button {
		@include button-primary;
		height: rem-calc(39);
		padding-bottom: rem-calc(11); // so we need to adjust the padding
		padding-top: rem-calc(11); // forcing the height from 50 to 39 for now
	}

	.lcly-location-prompt {
		color: map-get($lfi-palette, jet);
		font-size: rem-calc(18);
		font-weight: $global-weight-normal;
		line-height: $global-lineheight;
		margin-top: 0;

		a {
			color: $anchor-color !important;
			font-size: 1rem;
			font-weight: $global-weight-normal;
			margin-left: 0;
			text-transform: initial;

			&:hover {
				color: $anchor-color-hover !important;
			}
		}
	}

	.lcly-anchor {
		margin-top: 0 !important;
		text-align: left !important;
	}

	.lcly-location-switcher-wrap {
		height: rem-calc(39);
		overflow: visible;
	}

	.lcly-location-switcher-outer {
		float: initial;
		margin-top: 0;
	}

	.lcly-location-switcher-input {
		border: 1px solid $secondary-color !important;
		color: $dark-gray !important;
		font-size: rem-calc(16) !important;
		font-weight: $global-weight-normal !important;
		height: rem-calc(39) !important;
		line-height: normal !important;
		margin: 0;
		padding: rem-calc(8) !important;
	}

	.lcly-location-switcher-a {
		position: relative;
	}

	.lcly-autocomplete-anchor {
		font-size: rem-calc(16);
	}

	.lcly-autocomplete-suggestions {
		border-color: $secondary-color !important;
		border-top-width: 0 !important;
		width: 100% !important;
	}

	.lcly-autocomplete-suggestion {
		font-family: $body-font-family;
	}

	//
	// Locally Store Availability
	//

	.lcly-dealers-wrap-outer {
		margin-bottom: rem-calc(16);
	}

	.lcly-dealers-wrap {
		.lcly-dealer {
			background: fade-out($secondary-color, 0.5);
			border: 0;
			border-radius: rem-calc(15);
			display: flex;
			flex-flow: row wrap;
			margin: rem-calc(15 0 0) !important;
			min-height: unset !important;
			padding: rem-calc(20 24);
			width: 100% !important;

			&:hover {
				background: $secondary-color;
			}
		}

		.lcly-dealer-name {
			@include bold-text();
			font-size: $global-font-size;
			line-height: $global-font-size;
			margin: rem-calc(0 10 0 0);
			width: auto;
		}
	}

	.lcly-dealer-distance {
		font-size: $global-font-size;
		line-height: $global-font-size;
		margin: 0;
	}

	.lcly-location-features {
		line-height: $global-font-size;
		margin: 0;
		width: 100%;
	}

	.lcly-primary-trigger,
	.lcly-icon-marker,
	.lcly-icon-check-mark {
		display: none;
	}

	.lcly-dealer-stock {
		font-size: rem-calc(14);
		margin-top: rem-calc(7);
		width: 100%;
	}

	.lfi-lcly-no-stock {
		font-size: rem-calc(16);
		margin-top: rem-calc(16);
	}

	hr {
		margin-top: 0;
	}
}
