// Address Landing, Add, and Edit styles
.customer-address-index {
	@include account-address-grid;

	.modal-popup {
		.action-secondary {
			display: none;
		}
	}

	.address-actions {
		strong {
			display: block;
			margin-bottom: rem-calc(16);
		}
	}

	.box-content {
		margin-bottom: rem-calc(16);
	}

	.add-address {
		@include bold-text;
		display: block;
	}
}

.block-addresses-default {
	@include media-breakpoint-up(lg) {
		border-bottom: rem-calc(1) solid $light-gray;
	}
}

.block-addresses-list {
	.edit {
		margin-right: rem-calc(36);
	}
}
