.three-quarter-image-container {
	@include breakpoint(tablet) {
		display: flex;
	}
}

.three-quarter-image {
	background-repeat: no-repeat;
	background-size: contain;
	height: 88vw;

	@include breakpoint(tablet) {
		background-position: center;
		background-size: cover;
		flex: 0 0 60%;
		height: auto;
	}
}

.three-quarter-image-content {
	margin: rem-calc(24 0 32 0);

	@include breakpoint(tablet) {
		margin: rem-calc(60 0);
	}

	@include breakpoint(large) {
		margin: rem-calc(87 0);
	}

	.wrapper {
		align-items: center;
		display: flex;
		flex-flow: column nowrap;
		padding: rem-calc(0 72);
		text-align: center;

		@include breakpoint(tablet) {
			padding: rem-calc(0 26);
		}
	}

	a {
		margin-bottom: 0;
	}

	h1 {
		margin-bottom: rem-calc(24);
		max-width: rem-calc(240);
		text-transform: uppercase;

		@include media-breakpoint-down(md) {
			font-size: rem-calc(18);
		}

		@include media-breakpoint-up(md) {
			margin-bottom: rem-calc(32);
			max-width: rem-calc(450);
		}
	}

	img {
		margin-bottom: rem-calc(16);

		@include breakpoint(tablet) {
			margin-bottom: rem-calc(24);
		}
	}
}
