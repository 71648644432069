///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
	$responsive-unitless: divide($responsive, ($responsive - $responsive + 1));
	$dimension: if(unit($responsive) == "vh", "height", "width");
	$min-breakpoint: divide($min, $responsive-unitless) * 100;

	@media (max-#{$dimension}: #{$min-breakpoint}) {
		font-size: $min;
	}

	@if $max {
		$max-breakpoint: divide($max, $responsive-unitless) * 100;

		@media (min-#{$dimension}: #{$max-breakpoint}) {
			font-size: $max;
		}
	}

	@if $fallback {
		font-size: $fallback;
	}

	font-size: $responsive;
}

///
/// Flexible Scaled Header Mixin
///
@mixin scaled-header($default: 3vw, $min: 21px, $max: 54px) {
	@include responsive-font($default, $min, $max);
	//font-weight: $header-font-weight;
}
