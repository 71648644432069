//
// Checkout Voucher payment and summary
// ---------------------------------------------
//

.opc-company-voucher {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin-bottom: rem-calc(40);

	> * {
		flex: 1 1 100%;
	}
}

.opc-company-voucher-amount {
	font-size: rem-calc(16);
	line-height: rem-calc(23);
	margin-bottom: rem-calc(15);
	text-align: center;
}

.opc-company-voucher-apply {
	@include button-primary;
	@include button-expand;
}
