// Product Reviews Fit Styles
// --------------------------
// div.product-reviews.fit
//  p

.product-reviews-fit {
	p {
		align-items: center;
		cursor: pointer;
		display: flex;

		&:before {
			background: $info-color;
			margin-right: 8px;
		}
	}
}
