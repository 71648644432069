// Reset .page-main Margin Mixin
@mixin clear-content-margin() {
	margin-top: 0;
	margin-bottom: 0;

	@include breakpoint(medium) {
		margin-top: 0;
		margin-bottom: 0;
	}
}

// Use this mixin instead of Magento's approach with
// @extend .abs-reset-list;
@mixin list-reset() {
	list-style: none;
	margin: 0;
	padding: 0;
}
