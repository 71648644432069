.rule-banner {
	background-color: var(--rule-banner-bg-color);
	border: rem-calc(1) solid $success-color;
	border-radius: rem-calc(4);
	line-height: rem-calc(20);
	padding: rem-calc(8);

	.main-message {
		@include bold-text();

		color: $success-color;

		&.icon-badge-dollar-solid::before {
			font-size: rem-calc(18);
			margin-right: rem-calc(2);
			position: relative;
			top: rem-calc(2.5);
		}
	}

	.sub-message {
		color: $base-color;
	}
}
