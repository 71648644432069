@property --newleaf-slider-is-peeking {
	inherits: true;
	initial-value: 1;
	syntax: "<integer>";
}

.newleaf-slider {
	--newleaf-slide-snap-align: center;
	--newleaf-slider-scroll-snap-type: x mandatory;
	--newleaf-slider-slides-visible: 1;
	--newleaf-slider-slide-gap: 16px;
	--newleaf-slider-slide-size: calc(
		(
			100% -
			var(--newleaf-slider-slide-gap) * (var(--newleaf-slider-slides-visible) - 1) -
			var(--newleaf-slider-is-peeking) * var(--newleaf-slider-slide-gap) * 2
		) / var(--newleaf-slider-slides-visible)
	);
	--newleaf-slider-is-peeking: var(--newleaf-slider-peek-length, 0);

	display: grid;
	gap: var(--newleaf-slider-slide-gap);
	grid-auto-columns: var(--newleaf-slider-slide-size);
	grid-auto-flow: column dense;
	overflow: scroll;
	padding: 0 var(--newleaf-slider-padding-horizontal, var(--newleaf-slider-peek-length, 0));
	scroll-padding-inline: calc(var(--newleaf-slider-is-peeking) * (var(--newleaf-slider-slide-gap) + var(--newleaf-slider-peek-length)));
	scroll-snap-type: var(--newleaf-slider-scroll-snap-type);
	scrollbar-width: none;

	/**
	 * A "product" newleaf slider acts just like a standard one, but aligns slides toward the end
	 * on tablet+ screen sizes, leaving more left-side start space
	 */
	&.product {
		--newleaf-slider-peek-length: 0.1px;
		padding-left: var(--newleaf-slider-slide-gap);

		@include media-breakpoint-up(tablet) {
			--newleaf-slide-snap-align: end;
			--newleaf-slider-peek-length: 0;
			--newleaf-slider-slide-gap: 40px;
		}

		@include media-breakpoint-up(xl) {
			--newleaf-slider-slide-gap: 64px;
			margin-right: 16px;
		}
	}

	/**
	 * A slider with the "peek" option applied shows hints of the "non-active" slides
	 * At small screen sizes, the previous and next slides peek
	 * At tablet+ sizes, the nth+1 slide peeks (nth being the configured slides to show)
	 *  (see the slides visible per breakpoint configuration later in this file)
	 */
	&.peek {
		--newleaf-slider-slide-gap: 8px;
		--newleaf-slider-peek-length: 12px;

		@include media-breakpoint-up(tablet) {
			--newleaf-slide-snap-align: start;
			--newleaf-slider-slide-gap: 12px; // possible to make this configurable??
			--newleaf-slider-peek-length: 4%;
			scroll-padding-inline-start: 0;
		}
	}

	&.no-snap {
		--newleaf-slider-scroll-snap-type: none;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	/**
	 * A slider with the "lifestyle" option renders slides at 1:1 aspect ratio for small screen sizes
	 * and 2:3 aspect ratio for tablet+
	 */
	&.lifestyle .slide {
		--newleaf-lifestyle-slide-ratio: 1/1;
		aspect-ratio: var(--newleaf-lifestyle-slide-ratio);
		position: relative;

		@include media-breakpoint-up(tablet) {
			--newleaf-lifestyle-slide-ratio: 2/3;
		}

		img {
			height: 100%;
			left: 0;
			object-fit: cover;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	// todo: eliminate needed override of Foundation's $list-style-margin?
	@at-root ul#{&} {
		margin-left: 0;
	}

	/**
	 * configuration options for slides visible at a time
	 * configurable per breakpoint
	 * example: .config-md-2: show two slides at the medium breakpoint and above
	 */
	@each $breakpoint, $width in $grid-breakpoints {
		@each $slidesVisible in (1, 2, 3, 4, 5, 6, 7, 8) {
			&.config-#{$breakpoint}-#{$slidesVisible} {
				@include media-breakpoint-up($breakpoint) { // stylelint-disable-line max-nesting-depth
					--newleaf-slider-slides-visible: #{$slidesVisible};
				}
			}
		}
	}

	.slide {
		scroll-snap-align: var(--newleaf-slide-snap-align);

		img {
			@extend %img-fills-container;
		}
	}
}

/**
 * navigation controls for a newleaf slider
 * only added when newleaf-slider-controls is initialized on the slider
 */
.newleaf-circle-button-wrapper {
	margin: var(--newleaf-button-wrapper-margin, 16px);

	> div {
		display: flex;
		gap: var(--newleaf-button-gap, 16px);
		justify-content: var(--newleaf-button-justify, end);
	}

	button[class*="icon-"] {
		@include icon-circle($c: $body-font-color, $dia: 48px, $hover-color: map-get($lfi-palette, light-gray-hover));
		font-size: 14px;
		padding: 0;

		&:disabled {
			background: var(--newleaf-disabled-button-background, #{map-get($lfi-palette, snow)});
			color: get-disabled-color(map-get($lfi-palette, jet));

			/**
			 * TODO: This is a bit messy to set this value here but this will have to do until we come up with a better way of
			 * managing button disabled states better in the future.
			 */
			opacity: 1;
		}

		&.icon-chevron-left::before {
			margin-left: -2px;
		}

		&.icon-chevron-right::before {
			margin-left: 2px;
		}
	}
}
