.cart-item-shipping {
	$cart-item-box-padding: 16;
	border: 1px solid map-get($lfi-palette, light-gray);
	border-radius: rem-calc(10);
	color: $base-primary-color;
	margin: rem-calc(32) 0 0;
	margin-left: divide(-100, 3) * 1vw; // matches the width of the thumbnail cell
	padding: rem-calc($cart-item-box-padding $cart-item-box-padding $cart-item-box-padding 48);
	position: relative;

	@include breakpoint(medium) {
		margin-left: 0;
	}

	&::before {
		@include svg-icon('../icons/icon-orders.svg');
		left: rem-calc(22);
		position: absolute;
		top: rem-calc(divide($cart-item-box-padding + 21, 2));
	}

	&.backorder {
		color: $alert-color;
		&::before {
			@include svg-icon('../icons/icon-truck.svg');
		}
	}

	.extra-information {
		color: map-get($lfi-palette, metal);
		font-size: rem-calc(14);
	}

	.summary-link {
		color: inherit;
		text-decoration: underline;

		@include breakpoint(medium) {
			text-decoration: none;
		}
	}
}

.summary-item-shipping {
	list-style: none;
	margin: rem-calc(8) 0;

	li {
		font-size: rem-calc(14);
		&::before {
			@include svg-icon('../icons/icon-orders.svg');
			margin-right: rem-calc(3);
			vertical-align: bottom;
		}
		&.backorder {
			color: $alert-color;
			&::before {
				@include svg-icon('../icons/icon-truck.svg');
			}
		}
	}
}
