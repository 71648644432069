:root {
	--lfi-scroll-direction-min-distance-px: 50;
	--lfi-scroll-direction-delay-ms: 100;
	--lfi-top-space-used: 0px;
	--lfi-top-transition: top 0.5s;
}

.avoid-header {
	top: var(--lfi-top-space-used, 0);
	transition: var(--lfi-top-transition);
}
