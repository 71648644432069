//
// Checkout Messages
// -----------------

.cart-item-message,
.checkout-item-message {
	align-items: center;
	display: flex;
	margin-bottom: 0;

	&.success {
		background: fade-out($success-color, 0.9);
	}
}

.cart-item-message {
	margin-top: rem-calc(10);
	padding: 0;

	&::before {
		font-size: rem-calc(14);
		margin-right: rem-calc(6);
	}

	div {
		font-size: rem-calc(14);
	}
}

.checkout-item-message {
	padding: 0;

	&.error {
		background: $alert-color;
		color: $white;
	}
}

// Submission Messages
//
.checkout-cart-index .cart-summary,
.checkout-cart-index .product-item-details,
.checkout-index-index {
	.message {
		align-items: center;
		display: flex;
		justify-content: center;

		> div,
		p {
			text-align: left;
			width: auto;
		}
	}
}

// TODO - separate for now until cart styles are tidied up
.checkout-index-index .message div {
	font-size: rem-calc(14);
	width: auto;
}

.opc-summary-item {
	.message {
		justify-content: flex-start;
		margin-top: rem-calc(5);
	}
}
