// General Pfas error styles
%pfas-error-container {
	border: 1px solid map-get($lfi-palette, cherry);

	.alert-icon-text {
		display: flex;
	}

	.icon-alert-triangle {
		display: flex;
		padding-top: 2px;
	}
}

.pfas-error-container {
	@extend %pfas-error-container;
	border-radius: 10px;

	p {
		line-height: 20px;
	}
}

.methods-shipping,
.cart-summary .errors {
	.alert-icon-text {
		margin-bottom: 16px;
	}

	.error-text-container {
		margin-bottom: 24px;
	}
}

// Cart Item Shipping Validation
.cart-form-wrapper {
	.pfas-error-container {
		margin-top: 32px;
		padding: 16px;

		.underline-text {
			@include media-breakpoint-up(md) {
				text-decoration: none;
			}
		}
	}

	.alert-icon-text {
		margin-bottom: 6px;
	}

	.error-text-container > p {
		margin-left: 22px;
	}
}

// Cart Summary Shipping Validation
.cart-summary .errors {
	.pfas-error-container {
		background: $white;
		border: none;
		margin: 16px 20px;
		padding: 24px 16px;
	}

	.error-instruction-container {
		p {
			margin-bottom: 12px;
		}
	}

	.button {
		margin-bottom: 16px;
	}
}

// Checkout Shipping Validation
.methods-shipping {
	.pfas-error-container {
		border-width: 2px;
		padding: 24px 16px;

		@include media-breakpoint-up(tablet) {
			padding: 32px;
		}
	}

	.button {
		margin-bottom: 0;
	}

	.button-group-stackable {
		margin-bottom: 28px;
	}
}

// PDP Shipping Estimation Containers (modal and buy box)
.pdp-shipping-container,
.pdp-shipping-estimator-modal {
	.error-text-container {
		margin-bottom: 6px;
	}

	.error-instruction-container {
		p {
			margin: 0 0 16px 22px;
		}
	}

	.button {
		width: 100%;
	}
}

.pdp-shipping-container {
	.pfas-error-container {
		background: $white;
		border: none;
		margin-bottom: rem-calc(24);
		padding: 16px;
	}

	.error-instruction-zip {
		cursor: pointer;
		text-decoration: underline;
	}

	.button {
		margin-bottom: 0;
	}
}

.pdp-shipping-estimator-modal {
	.pfas-error-container {
		border: none;
	}
}

.checkout-methods-error {
	@extend %pfas-error-container;

	align-items: center;
	border-radius: 4px;
	margin: 12px auto 28px;
	padding: 4px 8px;
	width: fit-content;

	.alert-icon-text {
		margin-bottom: 0;
	}
}

.pfas-no-checkout {
	.checkout-index-index & {
		display: none;
	}
}

.pfas-checkout-only {
	display: none;

	.checkout-index-index & {
		display: block;
	}
}
