[class^="icon-"],
[class*=" icon-"] {
	&::before {
		@include svg-icon;
	}
}

//
// A
//

.icon-alert {
	&:before {
		mask-image: url('../icons/icon-alert.svg');
	}
}

.icon-alert-triangle {
	&:before {
		@include svg-icon('../icons/icon-alert-triangle.svg', 16px, 16px);
	}
}

.icon-arrow {
	&:before {
		@include svg-icon('../icons/icon-arrow.svg', 24px, 24px);
	}
}

.icon-arrow-after {
	&:after {
		@include svg-icon('../icons/icon-arrow.svg', 24px, 24px);
	}
}

.icon-arrows-maximize-light {
	&:before {
		@include svg-icon('../icons/icon-arrows-maximize-light.svg', 24px, 24px);
	}
}

//
// B
//

.icon-bag {
	&::before {
		mask-image: url('../icons/icon-bag.svg');
	}
}

.icon-badge-dollar-solid {
	&::before {
		mask-image: url('../icons/icon-badge-dollar-solid.svg');
	}
}

.icon-baseball-cap {
	&:before {
		mask-image: url('../icons/icon-baseball-cap.svg');
	}
}

.icon-basket {
	&:before {
		mask-image: url('../icons/icon-basket.svg');
	}
}

.icon-boot {
	&::before {
		mask-image: url('../icons/icon-boot.svg');
	}
}

.icon-boot-dry {
	&::before {
		mask-image: url('../icons/icon-boot-dry.svg');
	}
}

.icon-boot-regular {
	&::before {
		mask-image: url('../icons/icon-boot-regular.svg');
	}
}

.icon-bootcare-brush {
	&::before {
		mask-image: url('../icons/icon-bootcare-brush.svg');
	}
}

.icon-bootcare-cleaner {
	&::before {
		mask-image: url('../icons/icon-bootcare-cleaner.svg');
	}
}

.icon-bootcare-cleaning-brush {
	&::before {
		mask-image: url('../icons/icon-bootcare-cleaning-brush.svg');
	}
}

.icon-bootcare-cloth {
	&::before {
		mask-image: url('../icons/icon-bootcare-cloth.svg');
	}
}

.icon-bootcare-dressing-wax {
	&::before {
		mask-image: url('../icons/icon-bootcare-dressing-wax.svg');
	}
}

.icon-bootcare-fg-kit {
	&::before {
		mask-image: url('../icons/icon-bootcare-fg-kit.svg');
	}
}

.icon-bootcare-spray {
	&::before {
		mask-image: url('../icons/icon-bootcare-spray.svg');
	}
}

.icon-bootcare-suede-brush {
	&::before {
		mask-image: url('../icons/icon-bootcare-suede-brush.svg');
	}
}

//
// C
//

.icon-cake {
	&:before {
		mask-image: url('../icons/icon-cake.svg');
	}
}

.icon-calendar {
	&:before {
		mask-image: url('../icons/icon-calendar.svg');
	}
}

.icon-care-light {
	&:before {
		@include svg-icon('../icons/icon-care-light.svg', 24px, 24px);
	}
}

.icon-camp {
	&:before {
		mask-image: url('../icons/icon-camp.svg');
	}
}

.icon-cart {
	&:before {
		mask-image: url('../icons/icon-cart.svg');
	}
}
.icon-cart-full {
	&:before {
		mask-image: url('../icons/icon-cart-full.svg');
	}
}

.icon-check {
	&:before {
		mask-image: url('../icons/icon-check.svg');
	}
}

.icon-checkmark {
	&:before {
		mask-image: url('../icons/icon-checkmark.svg');
	}
}

.icon-chevron-down {
	&:before {
		@include svg-icon('../icons/icon-chevron-down.svg', 24px, 24px);
	}
}

.icon-chevron-left {
	&:before {
		@include svg-icon('../icons/icon-chevron-left.svg', 24px, 24px);
	}
}
.icon-chevron-right {
	&:before {
		@include svg-icon('../icons/icon-chevron-right.svg', 24px, 24px);
	}
}

.icon-chevron-up {
	&:before {
		@include svg-icon('../icons/icon-chevron-up.svg', 24px, 24px);
	}
}

.icon-chat-bubble {
	&:before {
		mask-image: url('../icons/icon-chat-bubble.svg');
	}
}

.icon-circle-check {
	&::before {
		mask-image: url('../icons/icon-circle-check.svg');
	}
}

.icon-circle-check-solid {
	&::before {
		mask-image: url('../icons/icon-circle-check-solid.svg');
	}
}

.icon-circle-error {
	&:before {
		mask-image: url('../icons/icon-circle-error.svg');
	}
}
.icon-circle-info {
	&:before {
		mask-image: url('../icons/icon-circle-info.svg');
	}
}
.icon-circle-notice {
	&:before {
		mask-image: url('../icons/icon-circle-info.svg');
	}
}
.icon-circle-success {
	&:before {
		mask-image: url('../icons/icon-circle-success.svg');
	}
}

.icon-close {
	&:before {
		mask-image: url('../icons/icon-close.svg');
	}
}

.icon-clouds {
	&:before {
		mask-image: url('../icons/icon-clouds.svg');
	}
}

.icon-cog {
	&:before {
		mask-image: url('../icons/icon-cog.svg');
	}
}

.icon-compare {
	&:before {
		mask-image: url('../icons/icon-compare.svg');
	}
}

//
// D
//

.icon-dial-boa-light {
	&:before {
		mask-image: url('../icons/icon-dial-boa-light.svg');
	}
}

.icon-detergent-light {
	&:before {
		@include svg-icon('../icons/icon-detergent-light.svg', 24px, 24px);
	}
}

.icon-dryer-light {
	&:before {
		@include svg-icon('../icons/icon-dryer-light.svg', 24px, 24px);
	}
}

.icon-dumbbell {
	&:before {
		mask-image: url('../icons/icon-dumbbell.svg');
	}
}

//
// E
//

.icon-edit {
	&:before {
		mask-image: url('../icons/icon-edit.svg');
	}
}

.icon-email {
	&:before {
		mask-image: url('../icons/icon-email.svg');
	}
}

//
// F
//

.icon-fabric-regular {
	&:before {
		@include svg-icon('../icons/icon-fabric-regular.svg', 24px, 24px);
	}
}

.icon-facebook {
	&:before {
		mask-image: url('../icons/icon-facebook.svg');
	}
}

.icon-feather-regular {
	&:before {
		@include svg-icon('../icons/icon-feather-regular.svg', 24px, 24px);
	}
}

.icon-flame {
	&::before {
		mask-image: url('../icons/icon-flame.svg');
	}
}

.icon-navigation-down {
	&:before {
		@include svg-icon('../icons/icon-navigation-down.svg', 24px, 24px);
	}
}

.icon-navigation-right {
	&:before {
		@include svg-icon('../icons/icon-navigation-right.svg', 24px, 24px);
	}
}

.icon-filter {
	&:before {
		mask-image: url('../icons/icon-filter.svg');
	}
}
.icon-filter-toggle {
	&:before {
		mask-image: url('../icons/icon-filter-toggle.svg');
		vertical-align: middle;
	}
}

//
// G
//

.icon-gears-regular {
	&:before {
		mask-image: url('../icons/icon-gears-regular.svg');
	}
}

.icon-gift {
	&:before {
		mask-image: url('../icons/icon-gift.svg');
	}
}

.icon-globe {
	&:before {
		mask-image: url('../icons/icon-globe.svg');
	}
}

//
// H
//

.icon-hand-sparkle {
	&::before {
		mask-image: url('../icons/icon-hand-sparkle.svg');
	}
}

.icon-handshake-regular {
	&::before {
		@include svg-icon('../icons/icon-handshake-regular.svg', 40px, 40px);
	}
}

.icon-headgear-regular {
	&::before {
		mask-image: url('../icons/icon-headgear-regular.svg');
	}
}

.icon-heart {
	&::before {
		@include svg-icon('../icons/icon-heart.svg', 40px, 40px);
	}
}

.icon-hide {
	&::before {
		mask-image: url('../icons/icon-hide.svg');
	}
}

.icon-hiking-regular {
	&::before {
		mask-image: url('../icons/icon-hiking-regular.svg');
	}
}

.icon-hot {
	&::before {
		mask-image: url('../icons/icon-hot.svg');
	}
}

//
// I
//

.icon-instagram {
	&:before {
		mask-image: url('../icons/icon-instagram.svg');
	}
}

.icon-iron-light {
	&::before {
		@include svg-icon('../icons/icon-iron-light.svg', 24px, 24px);
	}
}

//
// L
//

.icon-lab-regular {
	&::before {
		mask-image: url('../icons/icon-lab-regular.svg');
	}
}

.icon-leaf {
	&::before {
		mask-image: url('../icons/icon-leaf.svg');
	}
}

.icon-leather-regular {
	&::before {
		mask-image: url('../icons/icon-leather-regular.svg');
	}
}

.icon-lightbulb-regular {
	&::before {
		mask-image: url('../icons/icon-lightbulb-regular.svg');
	}
}

.icon-lightbulb-no-shine-regular {
	&::before {
		mask-image: url('../icons/icon-lightbulb-no-shine-regular.svg');
	}
}

.icon-link {
	&:before {
		mask-image: url('../icons/icon-link.svg');
	}
}

.icon-list-check {
	&:before {
		mask-image: url('../icons/icon-list-check.svg');
	}
}

.icon-list-cancel {
	&:before {
		mask-image: url('../icons/icon-list-cancel.svg');
	}
}

.icon-location {
	&:before {
		mask-image: url('../icons/icon-location.svg');
	}
}

.icon-location-hollow {
	&:before {
		mask-image: url('../icons/icon-location-hollow.svg');
	}
}

//
// M
//

.icon-made-in-usa-flag {
	&:before {
		@include svg-icon('../icons/icon-made-in-usa-flag.svg', 46px, 46px);
	}
}

.icon-mail {
	&:before {
		mask-image: url('../icons/icon-mail.svg');
	}
}

.icon-menu {
	&:before {
		mask-image: url('../icons/icon-menu.svg');
	}
}

.icon-mesh-light {
	&:before {
		@include svg-icon('../icons/icon-mesh-light.svg', 24px, 24px);
	}
}

//
// O
//

.icon-oil-can {
	&::before {
		mask-image: url('../icons/icon-oil-can.svg');
	}
}

.icon-orders {
	&:before {
		mask-image: url('../icons/icon-orders.svg');
	}
}

.icon-outsole-tire {
	&:before {
		mask-image: url('../icons/icon-outsole-tire.svg');
	}
}

//
// P
//

.icon-packable-backpack {
	&::before {
		mask-image: url('../icons/icon-packable-backpack.svg');
	}
}

.icon-pickaxe {
	&::before {
		mask-image: url('../icons/icon-pickaxe.svg');
	}
}

.icon-pinterest {
	&:before {
		mask-image: url('../icons/icon-pinterest.svg');
	}
}

.icon-plane {
	&::before {
		mask-image: url('../icons/icon-plane.svg');
	}
}

.icon-plus {
	&:before {
		mask-image: url('../icons/icon-plus.svg');
	}
}

.icon-plus-minus {
	&::before {
		mask-image: url('../icons/icon-plus-minus.svg');
	}
}

//
// Q
//

.icon-quote {
	&::before {
		mask-image: url('../icons/icon-quote.svg');
	}
}

//
// R
//

.icon-recraftable {
	&::before {
		mask-image: url('../icons/icon-recraftable.svg');
	}
}

.icon-recraftable-medium {
	&::before {
		mask-image: url('../icons/icon-recraftable-medium.svg');
	}
}

.icon-recycled {
	&::before {
		mask-image: url('../icons/icon-recycled.svg');
	}
}

.icon-refresh {
	&:before {
		mask-image: url('../icons/icon-refresh.svg');
	}
}

.icon-remove {
	&:before {
		mask-image: url('../icons/icon-remove.svg');
	}
}

.icon-removable-hood {
	&:before {
		mask-image: url('../icons/icon-removable-hood.svg');
	}
}

.icon-ring-volume {
	&:before {
		mask-image: url('../icons/icon-ring-volume.svg');
	}
}

.icon-rocket {
	&:before {
		mask-image: url('../icons/icon-rocket.svg');
	}
}

.icon-ruler {
	&:before {
		mask-image: url('../icons/icon-ruler.svg');
	}
}
.icon-ruler-horiz {
	&:before {
		mask-image: url('../icons/icon-ruler-horiz.svg');
	}
}

//
// S
//

.icon-sink-light {
	&:before {
		@include svg-icon('../icons/icon-sink-light.svg', 24px, 24px);
	}
}

.icon-shield-check {
	&::before {
		mask-image: url('../icons/icon-shield-check.svg');
	}
}

.icon-tools {
	&::before {
		mask-image: url('../icons/icon-tools.svg');
	}
}

.icon-stretch-fabric {
	&::before {
		mask-image: url('../icons/icon-stretch-fabric.svg');
	}
}

.icon-subtract {
	&::before {
		mask-image: url('../icons/icon-subtract.svg');
	}
}

.icon-cancel {
	&::before {
		mask-image: url('../icons/icon-cancel.svg');
	}
}

.icon-scarf {
	&::before {
		mask-image: url('../icons/icon-scarf.svg');
	}
}

.icon-search {
	&:before {
		mask-image: url('../icons/icon-search.svg');
	}
}
.icon-search-detailed {
	&:before {
		mask-image: url('../icons/icon-search-detailed.svg');
	}
}

.icon-select-arrow {
	&:before {
		mask-image: url('../icons/icon-select-arrow.svg');
	}
}

.icon-sheep {
	&::before {
		mask-image: url('../icons/icon-sheep.svg');
	}
}

.icon-smile {
	&:before {
		mask-image: url('../icons/icon-smile.svg');
	}
}

.icon-snake {
	&::before {
		mask-image: url('../icons/icon-snake.svg');
	}
}

.icon-snowflake {
	&:before {
		mask-image: url('../icons/icon-snowflake.svg');
	}
}

.icon-socks {
	&:before {
		mask-image: url('../icons/icon-socks.svg');
	}
}

.icon-sound-wave {
	&:before {
		mask-image: url('../icons/icon-sound-wave.svg');
	}
}

.icon-sparkle {
	&:before {
		mask-image: url('../icons/icon-sparkle.svg');
	}
}

.icon-sparkle-bold {
	&:before {
		mask-image: url('../icons/icon-sparkle-bold.svg');
	}
}

.icon-sparkle-hollow {
	&::before {
		mask-image: url('../icons/icon-sparkle-hollow.svg');
	}
}

.icon-speedy-truck {
	&:before {
		mask-image: url('../icons/icon-speedy-truck.svg');
	}
}

.icon-split {
	&:before {
		mask-image: url('../icons/icon-split.svg');
	}
}

.icon-square-toe {
	&::before {
		mask-image: url('../icons/icon-square-toe.svg');
	}
}

.icon-star {
	&:before {
		mask-image: url('../icons/icon-star.svg');
	}
}
.icon-star-hollow {
	&:before {
		mask-image: url('../icons/icon-star-hollow.svg');
	}
}

//
// T
//

.icon-trapezium {
	&::before {
		mask-image: url('../icons/icon-trapezium.svg');
	}
}

.icon-triangle {
	&:before {
		mask-image: url('../icons/icon-triangle.svg');
	}
}

.icon-triangle-dashed {
	&::before {
		mask-image: url('../icons/icon-triangle-dashed.svg');
	}
}

.icon-truck {
	&:before {
		mask-image: url('../icons/icon-truck.svg');
	}
}

.icon-twitter {
	&:before {
		mask-image: url('../icons/icon-twitter.svg');
	}
}

//
// U
//

.icon-user {
	&:before {
		mask-image: url('../icons/icon-user.svg');
	}
}
.icon-user-hollow {
	&:before {
		mask-image: url('../icons/icon-user-hollow.svg');
	}
}

.icon-usa {
	&:before {
		mask-image: url('../icons/icon-usa.svg');
	}
}

//
// V
//
.icon-vector {
	&:before {
		mask-image: url('../icons/icon-vector.svg');
	}
}

.icon-video-indicator {
	&:before {
		mask-image: url('../icons/icon-video-indicator.svg');
	}
}
//
// W
//

.icon-washer-light {
	&::before {
		@include svg-icon('../icons/icon-washer-light.svg', 24px, 24px);
	}
}

.icon-wash-in-treatment-light {
	&::before {
		@include svg-icon('../icons/icon-wash-in-treatment-light.svg', 24px, 24px);
	}
}

.icon-washing-machine {
	&::before {
		mask-image: url('../icons/icon-washing-machine.svg');
	}
}

.icon-waterproof {
	&::before {
		mask-image: url('../icons/icon-waterproof.svg');
	}
}

.icon-waves-horiz,
.icon-waves-horizontal {
	&::before {
		mask-image: url('../icons/icon-waves-horiz.svg');
	}
}

.icon-waves-vertical-regular {
	&::before {
		@include svg-icon('../icons/icon-waves-vertical-regular.svg', 24px, 24px);
	}
}

.icon-wind {
	&::before {
		mask-image: url('../icons/icon-wind.svg');
	}
}

//
// X
//

.icon-x {
	&:before {
		mask-image: url('../icons/icon-x.svg');
	}
}

//
// Y
//

.icon-youtube {
	&:before {
		mask-image: url('../icons/icon-youtube.svg');
	}
}

//
// Z
//

.icon-zipper {
	&::before {
		mask-image: url('../icons/icon-zipper.svg');
	}
}
