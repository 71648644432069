@mixin aspect-ratio($padding-large: 100%, $padding-medium: 50%) {
	&::before {
		content: "";
		display: inline-block;
		padding-bottom: $padding-large;

		@include breakpoint(medium) {
			padding-bottom: $padding-medium;
		}
	}
}
