/*
 * Customer account edit page
 * ---------------------------
 * div.customer-account-edit
 *  div.customer-info-title-container
 *  form.form-edit-account
 *
 */

.form-edit-account {

	.choice {
		position: relative;
	}

	// TODO - current tooltip styles appear to be way to global since
	// tips can appear next to various elements
	.field-tooltip {
		height: auto;
		position: relative;
	}

	#tooltip-label {
		@extend .show-for-sr;
	}

	#tooltip {
		line-height: 1;
		padding: 0;
	}

	.back {
		text-align: center;
	}

	.dob_display {
		margin-bottom: 0;
	}

	// Send DOB input to bottom of form
	.field-dob {
		order: 10;
	}

	.fieldset.info {
		display: flex;
		flex-flow: column nowrap;
	}

	.dob_display .icon-cake {
		margin-left: rem-calc(5);
	}

	.field.choice:last-of-type {
		margin-bottom: $form-spacing;
	}

	br {
		display: none;
	}
}
