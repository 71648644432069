$product-grid-items-per-row-layout-default: 2 !default;

$product-grid-items-per-row-layout-1-screen-s: 3 !default;
$product-grid-items-per-row-layout-1-screen-m: 4 !default;
$product-grid-items-per-row-layout-1-screen-l: 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l: "" !default;

$product-grid-items-per-row-layout-2-right-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l: "" !default;

$product-grid-items-per-row-layout-3-screen-s: 3 !default;
$product-grid-items-per-row-layout-3-screen-m: "" !default;
$product-grid-items-per-row-layout-3-screen-l: "" !default;

$product-grid-items-padding: 0 $indent__base $indent__base !default;
$product-grid-items-margin: 0 0 $indent__s !default;

$product-name-text-decoration: none !default;
$product-name-text-decoration-hover: $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size: 26px !default;
$product-h1-margin-bottom-desktop: $indent__base !default;

@import "module/listings";
@import "module/toolbar";
@import "module/gallery";

//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
	text-decoration: line-through;
}

.prices-tier {
	.price-container {
		.price-including-tax {
			+ .price-excluding-tax {
				&:before {
					content: "(" attr(data-label) ": ";
				}

				&:last-child:after {
					content: ")";
				}
			}
		}

		.weee[data-label] {
			display: inline;

			.price {
				@include lib-font-size(11);
			}

			&:before {
				content: " +" attr(data-label) ": ";
			}
		}
	}
}

.actual-price {
	font-weight: $header-font-weight;
}

.product.name a {
	@extend .abs-product-link;
}

.page-with-filter .category-cms {
	padding: {
		top: rem-calc(70);
		bottom: rem-calc(26);
	}
	@include breakpoint(large) {
		padding: {
			top: rem-calc(100);
			bottom: rem-calc(76);
		}
	}
}

//
//  Product view
//  ---------------------------------------------

.product.media {
	.product.photo .photo.image {
		@extend .abs-adaptive-images-centered;
	}

	.placeholder .photo.container {
		max-width: 100%;
	}

	.notice {
		@include lib-css(color, $text__color__muted);
		@include lib-font-size($font-size__s);
		margin: $indent__s 0;
	}

	.product.thumbs {
		margin: $indent__base 0 $indent__l;
	}

	.items.thumbs {
		@include lib-list-inline();

		.active {
			display: block;
			line-height: 1;
		}
	}
}

.product.info.detailed {
	clear: both;
	margin-bottom: 30px;

	.additional-attributes {
		width: auto;
		@include lib-table-resize(
			$_th-padding-left: 0,
			$_th-padding-right: $indent__l,
			$_th-padding-bottom: $indent__s,
			$_td-padding-bottom: $indent__s
		);
	}
}

.product-info-main {
	.page-title-wrapper {
		.page-title {
			line-height: $line-height__base;
			margin-bottom: $indent__s;
		}
	}

	.stock {
		&.available,
		&.unavailable {
			display: inline-block;
			font-weight: $header-font-weight;
			margin-right: $indent__base;
			text-transform: uppercase;
			vertical-align: top;
		}
	}

	.product {
		&.attribute {
			&.sku {
				display: inline-block;
				vertical-align: top;
				@include lib-css(color, $text__color__muted);

				> .value {
					display: inline-block;
					vertical-align: top;
					word-break: break-all;
				}

				.type {
					margin-right: $indent__xs;
				}
			}
		}
	}

	.product-reviews-summary .reviews-actions {
		@include lib-font-size($font-size__base);
	}
}

.product-options-wrapper {
	.fieldset-product-options-inner {
		//  Date & Time custom option (Affect Time that goes only after Date)
		input.datetime-picker {
			~ select.datetime-picker {
				margin-top: $indent__s;
			}
		}
	}

	.field {
		.note {
			display: block;
		}

		.price-notice {
			@extend .abs-adjustment-incl-excl-tax;
		}
	}
}

.product-info-main,
.product-options-bottom {
	.special-price {
		display: block;
		margin: $indent__s 0;

		.price-container {
			@include lib-font-size(14);
		}

		.price-label + .price-wrapper {
			display: inline-block;
		}
	}

	.old-price,
	.special-price {
		.price-label {
			&:after {
				content: ": ";
			}
		}
	}

	.product-addto-links {
		margin: $indent__base 0;
	}

	.action.tocompare {
		@extend .abs-action-addto-product;
		vertical-align: top;
	}
}

.prices-tier {
	@extend .abs-reset-list;
	@include lib-css(background, $sidebar__background-color);
	margin: $indent__s 0;
	padding: $indent__s (0.75 * $indent__base);

	.price-container {
		display: inline-block;
	}

	.price-including-tax,
	.price-excluding-tax,
	.weee {
		display: inline-block;

		.price {
			@include lib-font-size(14);
			font-weight: $global-weight-bold;
		}
	}
}

.ui-dialog-titlebar-close {
	@include lib-button-as-link();
}

.block.related {
	.action.select {
		margin: 0 $indent__xs;
	}
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
	.catalog-product-view {
		.column.main {
			@include lib-vendor-prefix-display(flex);
			@include lib-vendor-prefix-flex-direction(column);
		}
	}

	.block.related {
		.action.select {
			display: block;
			margin: $indent__xs 0;
		}
	}

	.compare,
	.product-addto-links .action.tocompare,
	.product-item-actions .actions-secondary > .action.tocompare,
	[class*="block-compare"] {
		display: none;
	}
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
	.action.print {
		float: right;
		margin: 15px 0;
	}
}

.table-wrapper.comparison {
	clear: both;
	max-width: 100%;
	overflow-x: auto;
}

.table-comparison {
	table-layout: fixed;

	.cell.label.remove,
	.cell.label.product {
		span {
			@extend .abs-visually-hidden;
		}
	}

	.cell.label,
	td:last-child {
		border-right: $table__border-width $table__border-style $table__border-color;
	}

	.cell {
		padding: 15px;
		width: 140px;

		.attribute.value {
			overflow: hidden;
			width: 100%;
		}

		&.product.info,
		&.product.label {
			border-bottom: $table__border-width $table__border-style
				$table__border-color;
		}

		&.label {
			.attribute.label {
				display: block;
				width: 100%;
				word-wrap: break-word;
			}
		}

		&.attribute {
			@include lib-font-size(13);
			img {
				height: auto;
				max-width: 100%;
			}
		}
	}

	.product-item-photo {
		display: block;
		margin: 0 auto 15px;
	}

	.product-image-photo {
		margin-left: 0;
	}

	.product-item-actions,
	.price-box,
	.product.rating,
	.product-item-name {
		display: block;
		margin: 15px 0;
	}

	.product-addto-links {
		margin-top: 15px;

		.action.split,
		.action.toggle {
			@include lib-button-s();
		}

		.action.toggle {
			padding: 0;
		}
	}

	.cell.remove {
		padding-bottom: 0;
		padding-top: 0;
		text-align: right;

		.action.delete {
			@extend .abs-remove-button-for-blocks;
		}
	}

	.product-item-actions {
		> .actions-primary {
			+ .actions-secondary {
				margin-top: $indent__s;
			}
		}
	}

	.action {
		&.tocart {
			white-space: nowrap;
		}
	}
}

.comparison.headings {
	@include lib-css(background, $page__background-color);
	left: 0;
	position: absolute;
	top: 0;
	width: auto;
	z-index: 2;
}

.block-compare {
	.block-title {
		@extend .abs-block-title;
	}

	.product-item .product-item-name {
		margin-left: 22px;
	}

	.action {
		&.delete {
			@extend .abs-remove-button-for-blocks;
			left: -6px;
			position: absolute;
			top: 0;
		}

		&.compare {
			@extend .abs-revert-secondary-color;
		}
	}

	.counter {
		@extend .abs-block-items-counter;
	}

	.actions-toolbar {
		margin: 17px 0 0;
	}
}
