/*
 * Customer forms shared elements
 */

// Elements

.customer-policy {
	font-size: rem-calc(13);
	margin-bottom: rem-calc(40);
	text-align: center;
	opacity: 0.8;

	a {
		text-decoration: underline;
	}
}

// Buttons, Links, Inputs

.choice.newsletter {
	justify-content: center;
	margin-bottom: rem-calc(25);
}

.customer-form-link {
	color: map-get($lfi-palette, jet);
	display: block;

	span:last-of-type {
		text-decoration: underline;
	}
}

// Birthday Input and Label
.dob_display {
	margin-bottom: $form-spacing;

	.icon-cake {
		margin: 0 rem-calc(2);

		&::before {
			margin-bottom: rem-calc(-2);
		}
	}

	label {
		cursor: pointer;
		line-height: 1;
	}
}
// Wording in the prompt changes in the customer account section
.birthday-prompt-account {
	display: none;

	.form-edit-account & {
		display: inline-block;
	}
}

.form-edit-account .birthday-prompt-normal {
	display: none;
}
