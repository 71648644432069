.checkout-onepage-success {
	.page-title-wrapper {
		margin-bottom: rem-calc(25);
	}

	.checkout-success {
		margin-bottom: rem-calc(50);
		padding: rem-calc(10 15 0 15);
		text-align: center;

		p {
			@include breakpoint(medium) {
				margin-left: auto;
				margin-right: auto;
				width: 50%;
			}
		}
	}

	.block-static-block {
		margin-top: 1rem; // adjust for p:last-of-type margin reset
	}
}

.checkout-success-registration {
	text-align: center;
}
