@mixin item-options() {
	font-family: $body-font-family;

	dt {
		display: none;
	}

	dd {
		display: inline-block;
	}

	dd:not(:last-child)::after {
		content: ', ';
	}
}
