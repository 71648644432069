.boot-care-guide-lookup-wrapper {
	@include media-breakpoint-up(lg) {
		text-align: center;
	}

	.input-field {
		@include media-breakpoint-down(lg) {
			margin-left: 0;
		}
	}

	.underline-text {
		color: $base-primary-color;
	}

	.loading-mask {
		background: none;
	}
}

.boot-care-guide-lookup-info {
	margin-bottom: 24px;

	p {
		@include bold-text;
		font-size: 18px;
		line-height: 24px;
		margin: 0;
	}
}
