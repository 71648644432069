//
// Float Label and related styles
// ------------------------------
//

// todo - fl-styles should apply more globally to prevent elements jumping around the page
// as the JS applies
form,
.form {
	@include fl-style();

	// Non FL elements also need labels
	input[type="checkbox"] + label,
	input[type="radio"] + label {
		visibility: visible;
	}

	::placeholder {
		text-transform: none;
	}

	select {
		background-image: url("../icons/icon-chevron-down.svg");
		background-position: center right;
		background-size: 16px;
		text-transform: none;
	}
}

// Also apply padding to non-fl inputs
.input-text {
	:not(.fl-input) {
		padding-left: rem-calc(12);
	}
}

// Some forms are not float labeled
// In that case, let the labels be visible
.form#product_addtocart_form,
.form#search_mini_form {
	label {
		visibility: visible;
	}
}
