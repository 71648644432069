/*
 * Danner Branded Statement Section
 * ---------------------------------------
 * section.brand-statement
 *  h4
 */

.brand-statement {
	@include adjust-padding(
		rem-calc(70 25),
		rem-calc(80 100),
		rem-calc(90 189),
		rem-calc(100 258)
	);
	background: map-get($danner-palette, forest);
	color: map-get($lfi-palette, sleet);

	h4 {
		letter-spacing: rem-calc(0.4);
		line-height: rem-calc(35);
		margin: 0 auto;
		max-width: rem-calc(923);
		text-transform: uppercase;

		@include breakpoint(medium) {
			font-size: rem-calc(27);
			letter-spacing: rem-calc(0.61);
			line-height: rem-calc(46);
		}
	}
}

/*
 * Danner Branded Statement 2 Col Section
 * ---------------------------------------
 * section.brand-statement.brand-statement-2-col
 *  div.brand-statement-wrapper
 *   h4
 *   p
 */

.brand-statement.brand-statement-2-col {
	padding: 0;
}

.brand-statement-2-col {
	h4 {
		margin-bottom: rem-calc(28);

		@include breakpoint(medium) {
			padding-right: rem-calc(30);
		}
	}

	p {
		@include breakpoint(medium) {
			font-size: rem-calc(18);
		}
	}
}

.brand-statement-wrapper {
	@include adjust-padding(
		rem-calc(70 25),
		rem-calc(80 50),
		rem-calc(90 100),
		rem-calc(110 140)
	);

	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	max-width: $max-page-width;
	margin: 0 auto;

	@include breakpoint(medium) {
		flex-flow: row nowrap;
	}

	> * {
		flex: 1 1 100%;

		@include breakpoint(medium) {
			flex: 1 1 50%;
		}
	}
}
