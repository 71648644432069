// Product Gallery Component
// OPTIMIZE - styles mostly copied straight from M1
// (un-slicked structure)
// ----------------
//  div.product-img-box
//   div.product-image
//    a?
//     figure
//      img/video
//   div.more-views
//    h2
//    ul
//     li
//      button
//       img

.product-img-box {
	.product-image {
		margin-bottom: rem-calc(36);
		padding: 0 1rem;

		@include breakpoint($screen__m) {
			margin-bottom: 1.5rem;
		}

		&.slick-initialized {
			padding: 0;
		}

		// During load, hide all except the first figure
		&:not(.slick-initialized) {
			> a:nth-child(n+2),
			> figure:nth-child(n+2) {
				position: absolute;
				visibility: hidden;
			}
		}

		.slick-slide {
			padding: 0 1rem;
		}
	}

	.slick-dots {
		@include slick-dot-dashes;
		bottom: rem-calc(-14);
	}

	figure {
		video {
			display: block;
			height: auto;
			width: 100%;
		}

		figcaption {
			color: map-get($lfi-palette, metal);
			font-size: rem-calc(14);
			line-height: 1.5rem;
			margin-top: 0.5rem;
			text-align: center;

			@include breakpoint($screen__m) {
				margin-top: 1.5rem;
			}
		}
	}
}

.more-views {
	@include breakpoint($screen__m down) {
		display: none;
	}

	.video-thumb button:before {
		@include svg-icon('../icons/icon-video-indicator.svg', $width: 1.5em, $height: 1.5em, $mask: false);
		color: map-get($lfi-palette, jet);
		left: 50%;
		margin-left: -0.75em;
		margin-top: -0.75em;
		position: absolute;
		top: 50%;
	}

	h2 {
		display: none;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		gap: rem-calc(6);
		justify-content: center;
		list-style-type: none;
		margin-left: 0;
	}

	li {
		margin-bottom: 0;
		position: relative;

		&.selected {
			button {
				border-color: map-get($lfi-palette, latte);
				cursor: default;
			}
		}

		button[data-slide-target] {
			background-color: unset;
			border: rem-calc(1) solid map-get($lfi-palette, snow);
			border-radius: unset;
			display: inline-block;
			padding: rem-calc(5);

			&:hover {
				border-color: map-get($lfi-palette, latte);
			}
		}

		img {
			max-height: rem-calc(60);
			max-width: rem-calc(60);
		}
	}
}
