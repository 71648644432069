// /*
//  * Meet the Family Component
//  * -------------------------
//  *
//  *
//  */

.meet-the-family {
	.product-image-photo {
		position: relative;
	}

	.product-image-wrapper {
		height: auto;
		overflow: initial;
		// override inline styles from template
		padding-bottom: 0 !important;
	}

	.cta {
		margin: 0 auto rem-calc(32) auto;
		width: 100%;

		.button {
			margin-bottom: 0;
		}
	}

	table {
		$h-border-spacing: 12;
		border-collapse: separate;
		border-spacing: rem-calc($h-border-spacing 0);
		// the first column (row labels), though hidden, still generates border spacing; counteract that with left positioning
		left: rem-calc(-$h-border-spacing);
		margin: 0 auto rem-calc(70) auto;
		position: relative;
		width: auto;

		@include breakpoint(medium) {
			margin-bottom: rem-calc(90);
		}

		td {
			background: transparent;
			line-height: 1;
			min-width: rem-calc(180);
			padding: rem-calc(6);
			text-align: left;
		}

		// Hide table headings (row and column headers) from non-screenreader users
		th {
			padding: 0;

			strong {
				@include element-invisible;
			}
		}
	}

	.price-box {
		justify-content: flex-start;
	}
}

.compare-products-header {
	@include theme-header-transformation();

	margin: rem-calc(50) auto rem-calc(32) auto;
	padding: rem-calc(0 15);
	width: 100%;
}
