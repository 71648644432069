@import "../../default/styles/custom-variables";
//
// Custom variables

$store-logo-width: rem-calc(75);
$input-color-disabled: rgba(25, 50, 47, 0.75);
$header-container-height: rem-calc(54);
$navigation__background: transparent;

// buttons
$default-button-font-family: $header-font-family;
$default-button-font-size: rem-calc(15);
$default-button-line-height: rem-calc(18);
$default-button-padding: rem-calc(15 30);
$default-button-light-color: $white;
$default-button-light-text-color: map_get($danner-palette, forest);
$input-button-font-adjusted: $header-font-condensed-black;

$yotpo-score-font: $body-secondary-font-bold;

$product-indicator-text-color: map-get($danner-palette, danner-green);
$product-indicator-font-family: $header-font-bold;
