//
// applepay specific styles
//

.cart-summary {
	.apple-pay-button-card {
		margin-bottom: $list-item__margin * 2;
		margin-top: 0;
	}
}

.payment-method-content {
	.adyen-checkout__applepay__button {
		height: 46px;
		width: 100%;
	}
}

.adyen_applepay {
	.payment-method-billing-address,
	.checkout-billing-address {
		order: 3;
	}

	.payment-method-content {
		> form {
			order: 4;
		}
	}
}
