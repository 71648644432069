// Since Danner uses a bold font
// And Lacrosses uses a bold font weight
// This mixin allows use in the default theme and can apply to both themes
@mixin bold-text {
	// override me theme
}

// This mixin is an attempt to reduce theme specific overrides
// Danner headers currently are not being text transformed as a default
// Lacrosse headers are BUT we plan to change that with the next Lacrosse
// theme redesign
@mixin theme-header-transformation {
	// override me in theme
}

@mixin text-over-image-styles(
	$shadow-properties: 0 0 3px #000000
) {
	text-shadow: $shadow-properties;
}

@mixin font-headlines(
	$font-weight: $global-weight-bold,
	$font-letter-spacing: $header-letter-spacing,
	$font-family: $header-font-family,
	$text-transform: uppercase
) {
	display: block;
	font-family: $font-family;
	font-weight: $font-weight;
	letter-spacing: $font-letter-spacing;
	margin-bottom: $header-margin-bottom;
	text-transform: $text-transform;
}

// all links will match the "body text" color and be underlined
@mixin body-dark-text-link {
	a {
		color: $body-dark-font-color;
		text-decoration: underline;
		&:hover {
			color: $body-dark-font-color-hover;
		}
	}
}

@mixin body-light-text-link {
	a {
		color: $body-inverted-font-color;
		text-decoration: underline;
		&:hover {
			color: $body-inverted-font-color-hover;
		}
	}
}
