/*
 * Customer account logout page
 * ---------------------------
 */

body.customer-account-logoutsuccess {
	.column.main p {
		text-align: center;
	}
}
