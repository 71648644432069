.style-lookup-container {
	padding-top: rem-calc(84);

	@include media-breakpoint-up(lg) {
		padding-top: rem-calc(136);
	}

	.loading-mask {
		// stylelint-disable-next-line
		background: rgba(247, 247, 246, 0.5);
	}

	.icon-bull-run::before {
		height: rem-calc(72);
		width: rem-calc(109);
	}

	.recrafting-header {
		margin-bottom: rem-calc(32);
		max-width: rem-calc(290);

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(24);
			max-width: rem-calc(456);
		}
	}

	p {
		margin-bottom: rem-calc(40);

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(80);
		}
	}
}

.style-not-recraftable-container,
.style-recraftable-container {
	.recrafting-header {
		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(64);
		}
	}
}

.style-not-recraftable-container {
	.button {
		margin-bottom: rem-calc(37);

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(140);
			width: rem-calc(343);
		}
	}
}

.recrafting-style-image {
	padding: rem-calc(52 0 32 0);

	@include media-breakpoint-up(lg) {
		padding: rem-calc(104 0 40 0);
	}

	.container {
		align-items: center;
		background: $white;
		border-radius: 50%;
		display: grid;
		height: rem-calc(318);
		margin: 0 auto;
		padding: rem-calc(0 50);
		position: relative;
		width: rem-calc(318);

		@include media-breakpoint-up(lg) {
			height: rem-calc(458);
			padding: rem-calc(0 70);
			width: rem-calc(458);
		}
	}

	.icon-bull-run::before {
		color: map-get($lfi-palette, light-gray);
		height: rem-calc(115);
		left: 0;
		margin: 0 auto;
		position: absolute;
		right: 0;
		width: rem-calc(174);
		z-index: 1;
	}

	img,
	object {
		position: relative;
		width: 100%;
		z-index: 2;
	}
}
