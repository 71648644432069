//
// This file is for Foundation related variables.
// Please use _custom-variables.scss for non Foundation vars

// imports Foundation defaults
@import "foundation-sites/scss/foundation";
// imports Foundation utility classes
@import "foundation-sites/scss/util/util";

///
// Colors
//
$foundation-palette: (
	primary: map-get($danner-palette, danner-green),
	secondary: map_get($lfi-palette, light-gray),
	tertiary: map_get($lfi-palette, jet),
	success: map-get($lfi-palette, evergreen),
	warning: map-get($lfi-palette, cherry),
	alert: map-get($lfi-palette, cherry)
);

// Creates $*-color vars for $foundation-palette defaults
@include add-foundation-colors;

$dark-gray: map-get($lfi-palette, jet);
$light-gray: map-get($lfi-palette, light-gray);
$medium-gray: map-get($lfi-palette, cream);

$anchor-color: map-get($danner-palette, danner-green);
$anchor-color-hover: map-get($danner-palette, danner-dark-green);
$breadcrumbs-item-color-current: $primary-color;
// todo- hover color should match link hover color
// also, why are we bothering with Foundaions breadcrumb styles
// we don't even really match their markup
$breadcrumbs-item-color: $primary-color;
$breadcrumbs-item-separator-color: map_get($lfi-palette, jet);
$subheader-color: map-get($danner-palette, forest);

///
// Layout
//
$global-menu-padding: 0.15rem 1rem;
$global-radius: 2px;

///
// Typography
//
$blockquote-padding: 9px 18px !default;
$blockquote-border: 1px solid $light-gray;
$defnlist-term-margin-bottom: 3px;
$global-weight-bold: $font-weight__semibold;
$global-weight-normal: $font-weight__light;
$global-letter-spacing: 0;
$header-font-weight: $font-weight__regular;
$header-letter-spacing: 0;
$header-styles: (
	small: (
		'h1': ('font-size': 40, 'line-height': 56),
		'h2': ('font-size': 32, 'line-height': 40),
		'h3': ('font-size': 24, 'line-height': 32),
		'h4': ('font-size': 20, 'line-height': 26),
		'h5': ('font-size': 16, 'line-height': 20),
		'h6': ('font-size': 16, 'line-height': 20)
	),
	medium: (
		'h1': ('font-size': 44, 'line-height': 52),
		'h2': ('font-size': 36, 'line-height': 44),
		'h3': ('font-size': 28, 'line-height': 32),
		'h4': ('font-size': 24, 'line-height': 28),
		'h5': ('font-size': 18, 'line-height': 22),
		'h6': ('font-size': 16, 'line-height': 20)
	)
);

///
// Buttons
//
$button-font-weight: $font-weight__semibold;
$button-letter-spacing: rem-calc(0.9);
$button-margin: 0 0 6px 0;
$button-padding: rem-calc(15 30);

///
// Forms
//
$fieldset-border: none;
$form-label-line-height: 1.5;
$form-label-font-weight: $font-weight__light;
$input-border-focus: 1px solid mix($light-gray, $medium-gray);
$input-font-size: rem-calc(18);
$input-placeholder-color: mix($light-gray, $medium-gray);
$input-prefix-border: 2px solid $light-gray;
$input-radius: $global-radius;
$input-shadow: none;
$input-shadow-focus: none;
$select-radius: $global-radius;
$select-triangle-color: $base-color;

///
// Breadcrumbs
//
$breadcrumbs-margin: rem-calc(9 0);
$breadcrumbs-item-uppercase: false;

// todo - fix Foundating setting import
$button-palette: $foundation-palette;
