//
// Checkout Cart Styles
//

.checkout-cart-index {
	.product-name,
	.product-descriptor {
		display: block;
	}

	.product-name a {
		color: $body-font-color;
	}
}

//
// Checkout Drawer on Mobile
//
.checkout-drawer-modal {
	.modal-dialog {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.modal-content {
		border-radius: 0;
		padding: rem-calc(28 15);
	}

	.modal-body {
		padding: 0;
	}

	button.primary.checkout,
	.apple-pay-button {
		@include button-oval-adjustment;
	}
}
