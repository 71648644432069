.applied-salesrules {
	display: flex;
	flex-wrap: wrap;
	gap: rem-calc(6);
	list-style: none;
	margin: rem-calc(6 0);

	> li {
		@include bold-text();

		background-color: map-get($lfi-palette, snow);
		border: rem-calc(1) solid $success;
		border-radius: rem-calc(4);
		color: $success;
		font-size: rem-calc(12);
		line-height: rem-calc(14);
		padding: rem-calc(4 8);
	}
}
