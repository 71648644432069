//
// Applies styles to a container which is meant to be a callout with an icon beside the message
// This treatment is found in the design system
//

@mixin info-message($background-color: transparent, $border-color: map-get($lfi-palette, light-gray)) {
	background-color: $background-color;
	border: rem-calc(1) solid $border-color;
	border-radius: 0.25rem;
	display: flex;
	gap: 0.5rem;
	padding: 0.5rem;

	@include breakpoint(large up) {
		padding: 0.75rem 0.5rem;
	}
}
