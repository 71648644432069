section.cross-sells,
section.upsells {
	background-color: $body-background-alt;
}

// todo - remove meet-the-family styles
.meet-the-family,
.block-related,
.block-upsell {
	@include xy-grid;
	background-color: $body-background-alt;
	text-align: center;
	width: 100%;

	.product-shop {
		color: map-get($lfi-palette, jet);
	}

	.product-item-name {
		@include bold-text();
	}

	.products {
		@include xy-cell();
		@include xy-grid-collapse();
		margin: 0 auto;
		max-width: $global-width;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;

		@include breakpoint(medium) {
			border-right: none;
		}
	}
}

.product-sells-heading-container {
	margin: 0 auto;
	padding: rem-calc(50 15 0 15);
	width: 100%;
}

.block-upsell {
	min-height: 620px;

	.product-item-name {
		margin-bottom: rem-calc(4);
	}

	.product-name {
		margin-bottom: rem-calc(9);
	}
}
