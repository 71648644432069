@import "../../default/styles/components/plp/family-page";

// the h x w ratio of the "narrow" family feature images
$family-image-narrow-ratio-small: 133% !default; //h x w
$family-image-narrow-ratio-medium: 133% !default; //h x w
$family-image-narrow-ratio-large: 75% !default; //h x w

// the h x w ratio of the "wide" family feature images
$family-image-wide-ratio-small: 100% !default; //h x w
$family-image-wide-ratio-medium: 65% !default; //h x w
$family-image-wide-ratio-large: $family-image-wide-ratio-medium !default;

// the padding around the "copy" family features (narrow only)
$family-small-copy-padding: rem-calc(60 30) !default;
$family-medium-copy-padding: $family-small-copy-padding !default;
$family-large-copy-padding: rem-calc(90 50) !default;

// the cms block itself, which content editors won't have access to
.family-cms-block {
	background: $white;
	padding-bottom: rem-calc(5);

	@include breakpoint(large) {
		padding-bottom: rem-calc(70);
	}

	.small-centered {
		@include small-centered();
		padding-top: rem-calc(90);
	}

	h2,
	h3 {
		margin-bottom: rem-calc(20);
	}
}

.family-features {
	padding-top: rem-calc(70);

	@include breakpoint(medium) {
		padding-top: rem-calc(100);
	}
}

// these classes are what make an element a "feature"
// For example, use things like the "copy" class to make a copy feature, or the "image" class to make it an image feature
// Use the "wide" class to make a wide feature, or "narrow" for a narrow feature.
// see danner-responsive-styleguide.html for more information
.family-feature {
	@include xy-cell-base();
	@include xy-cell(12, $gutter-type: none);
	border: solid $white;
	border-width: $grid-margin-gutters * 0.5;
	padding-top: rem-calc(75);

	@include breakpoint(medium) {
		&.narrow {
			@include xy-cell(6, $gutter-type: none);
			padding: $family-medium-copy-padding;
		}
	}

	@include breakpoint(large) {
		&.narrow {
			&.copy {
				@include xy-cell(4, $gutter-type: none);
				padding-left: $family-large-copy-padding;
			}
			&.image {
				@include xy-cell(8, $gutter-type: none);
			}
		}
	}

	&.copy {
		padding: $family-small-copy-padding;

		@include breakpoint(large) {
			padding: $family-large-copy-padding;
		}

		@include breakpoint(medium) {
			&.wide {
				text-align: center;
			}
		}

		&.dark-gray {
			background-color: $dark-gray;
			color: $body-inverted-font-color;
		}

		&.medium-gray {
			background-color: $medium-gray;
		}

		&.light-gray {
			background-color: $body-background-alt;
		}

		&.danner-green {
			background-color: $primary-color;
		}

		&.dark-gray,
		&.danner-green,
		&.medium-gray {
			h2,
			h3,
			h4,
			p {
				color: $body-inverted-font-color;
			}
		}
		h2,
		h3 {
			margin-bottom: rem-calc(10);
		}
	}
	&.image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center; // default to centered positioning, can be overridden on element level

		&:before {
			display: block;
			content: "";
		}
		&.wide {
			&:before {
				padding-bottom: $family-image-wide-ratio-small;
				@include breakpoint(medium) {
					padding-bottom: $family-image-wide-ratio-medium;
				}
				@include breakpoint(large) {
					padding-bottom: $family-image-wide-ratio-large;
				}
			}
		}
		&.narrow {
			&:before {
				padding-bottom: $family-image-narrow-ratio-small;
				@include breakpoint(medium) {
					padding-bottom: $family-image-narrow-ratio-medium;
				}
				@include breakpoint(large) {
					padding-bottom: $family-image-narrow-ratio-large;
				}
			}
		}
	}
}
