$discount-price-color: $primary-color !default;

.price-box {
	align-items: center;
	color: $body-font-color;
	display: flex;
	font-size: $global-font-size;
	justify-content: center;
	width: 100%;

	> span {
		display: inline-block;
	}

	.price-label {
		@extend .show-for-sr;
	}

	[class$="-price"] {
		padding: 0;
	}

	.old-price + [class$="-price"] {
		color: $discount-price-color;
		padding-left: rem-calc(12);
	}
}

.old-price,
.old.price {
	text-decoration: line-through;
}
