@mixin slick-controls($dot-size: rem-calc(15), $dot-spacing-width: 20px) {
	&.slick-dotted {
		margin-bottom: 0;
	}

	&:not(.slick-dotted) {
		margin-bottom: rem-calc(60);
	}

	.slick-dots {
		height: rem-calc(60);
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 0;
		position: static;

		li {
			display: flex;
			align-items: center;
			justify-content: center;
			width: $dot-spacing-width;
		}

		button {
			width: $dot-spacing-width;
			&:before {
				font-size: $dot-size;
				width: $dot-spacing-width;
				font-family: initial;
			}

			&:hover,
			&:focus,
			&:focus:hover {
				background: none;
			}
		}
	}
}

@mixin slick-dot-dashes() {
	li {
		border: rem-calc(2) solid $light-gray;
		border-width: rem-calc(0 0 2 0);
		margin: rem-calc(0 1);

		&.slick-active {
			border-color: $dark-gray;
		}

		button:before {
			display: none;
		}

		button:focus,
		button:hover {
			background-color: transparent;
		}
	}
}
