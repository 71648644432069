#adyen-cc-form {
	//added by adyen's js so the only thing we can do is hide
	.adyen-checkout-form-instruction,
	.adyen-checkout__card__brands {
		display: none;
	}

	.adyen-checkout__card__form {
		margin-bottom: 14px;
	}
}
