$product-indicator-icon-size: rem-calc(18) !default;
$product-indicator-bg-color: map-get($lfi-palette, snow) !default;
$product-indicator-text-color: map-get($lfi-palette, metal) !default;
$product-indicator-font-family: $body-font-family !default;

.product-indicator {
	align-items: center;
	background-color: $product-indicator-bg-color;
	border-radius: 1rem;
	color: $product-indicator-text-color;
	display: inline-flex;
	font-family: $product-indicator-font-family;
	gap: rem-calc(6);
	height: 2rem;
	padding: 0 rem-calc(10);

	&::before {
		height: $product-indicator-icon-size;
		width: $product-indicator-icon-size;
	}

	&[class*='icon-'] > span {
		position: relative;
		top: -1px;
	}
}

.plp-feature-container {
	display: flex;
	flex-flow: column;
	gap: 6px;
	margin: 0 0 20px;
}

.plp-feature {
	--plp-feature-font-size: 14px;
	--plp-feature-line-height: 16px;

	@include bold-text;
	align-items: start;
	color: $product-indicator-text-color;
	display: flex;
	font-size: var(--plp-feature-font-size);
	gap: 4px;
	line-height: var(--plp-feature-line-height);
	margin: 0;
	text-transform: uppercase;

	&::before {
		flex-shrink: 0;
		height: var(--plp-feature-line-height);
		margin-top: 1px;
		mask-position: center;
		width: 1em;
	}
}
