// Input with Submit Button Form
// --------------

.input-with-submit-form {
	margin-bottom: rem-calc(32);
	position: relative;

	@include media-breakpoint-up(lg) {
		margin-bottom: rem-calc(24);
	}

	.error {
		bottom: rem-calc(-25);
		color: map-get($lfi-palette, cherry);
		left: 0;
		position: absolute;
		width: 100%;
	}

	.input-field {
		margin: 0 auto;
		max-width: rem-calc(178);
		position: relative;
	}

	.icon-caret-right-cutout {
		display: grid;

		&::before {
			height: rem-calc(33);
			width: rem-calc(33);
		}
	}

	input {
		// custom input override
		border: rem-calc(2) solid $base-primary-color !important;
		border-radius: rem-calc(36) !important;
		height: rem-calc(63);
		padding: rem-calc(16 24) !important;

		&:valid + button {
			// custom input button override
			color: $base-primary-color !important;
		}

		&,
		&::placeholder {
			@include bold-text;
			font-size: rem-calc(24);
		}

		&::placeholder {
			color: map-get($lfi-palette, cream-hover);
		}
	}

	button {
		// custom input button override
		background: transparent !important;
		color: map-get($lfi-palette, cream-hover) !important;
		height: 100%;
		padding: 0 rem-calc(16);
		position: absolute;
		right: 0;
		top: 0;
	}
}
