@use "sass:color";

$top-search-background-color: $white !default;
$top-search-button-color: map-get($lfi-palette, jet) !default;
$top-search-icon-color: map-get($lfi-palette, jet) !default;
$top-search-input-color-active: map-get($lfi-palette, jet) !default;
$top-search-input-color: color.change($top-search-input-color-active, $alpha: $btn-disabled-opacity) !default;

[id="top-search"] {
	background: $top-search-background-color;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: z-index(header) + 2;

	[role="context"] {
		@include media-breakpoint-up(md) {
			@include visually-hidden;
		}
	}

	.block-content {
		align-items: center;
		display: grid;
		grid-auto-flow: column;
		grid-template-areas: ". search close";
		grid-template-columns: 1fr minmax(264px, 80%) 1fr;
		height: $header-container-height;
		justify-content: center;
		margin: 0 auto;
		max-width: 688px;
	}

	form {
		display: flex;
		grid-area: search;
		justify-content: center;
		justify-self: center;
		width: 100%;
	}

	.search.field {
		margin-top: -3px;
		max-width: 480px;
		width: 100%;
	}

	.control {
		align-items: center;
		display: flex;
		height: 45px;
		position: relative;
		width: 100%;

		&::before {
			@include svg-icon('../icons/icon-search.svg');
			color: $top-search-icon-color;
			font-size: 21px;
			left: 0;
			position: absolute;
		}
	}

	input[id="search"] {
		background: transparent;
		border-color: $top-search-input-color;
		border-radius: 0;
		border-width: 0 0 2px;
		color: $top-search-input-color;

		height: 36px;
		margin: 0;
		padding: 0;
		padding-left: 30px; // icon-search offset
		padding-top: 3px; // ::placeholder vertical-centering

		transition: all 0.3s ease-in-out;
		width: 100%;

		&:hover,
		&:focus {
			border-color: $top-search-input-color-active;
			color: $top-search-input-color-active;
		}
	}

	.actions {
		align-items: center;
		display: flex;
		position: absolute;
		right: 0;
		z-index: z-index(header) + 3;

		button.search {
			background: transparent;
			color: $top-search-button-color;
			cursor: pointer;
			display: block;
			font-size: 30px;
			text-align: right;
			width: 60px;
		}
	}

	.action-close {
		align-items: center;
		color: $top-search-button-color;
		display: flex;
		grid-area: close;
		height: 100%;
		justify-content: center;
		margin: 0;
		min-width: 40px;

		&:hover {
			color: $top-search-button-color;
		}
	}
}
