.error-text-container {
	color: map-get($lfi-palette, cherry);

	.icon-alert-triangle {
		&:before {
			margin-right: 6px;
		}
	}

	a:not(.button) {
		color: inherit;
	}
}

.error-instruction-container {
	a:not(.button) {
		color: inherit;
	}
}
