@use "../../globals/colors";
@import "../../helpers/typography";

@mixin quick-add-action-icon {
	align-items: center;
	border: 2px solid colors.$base-primary-color;
	border-radius: 50px;
	color: colors.$base-primary-color;
	display: flex;
	height: 48px;
	justify-content: center;
	min-width: unset;
	padding: 0;
	position: relative;
	width: 48px;

	&:hover {
		background: colors.$base-primary-color;
		color: colors.$white;
	}

	.button-text {
		@include bold-text;
		bottom: -18px;
		display: none;
		font-size: 14px;
		left: 0;
		line-height: 14px;
		position: absolute;
		right: 0;
		text-transform: none;
	}

	.success {
		display: none;
	}

	&.success,
	&.success:hover,
	&.success:focus {
		background: map-get(colors.$lfi-palette, snow);
		border-color: map-get(colors.$lfi-palette, evergreen);
		color: map-get(colors.$lfi-palette, evergreen);
		cursor: not-allowed;
	}

	&.success {
		.button-text {
			display: inline-block;
		}

		.success {
			display: inline-block;
		}

		.initial {
			display: none;
		}
	}

	i {
		display: inline-block;
	}
}
