@import "../../../default/styles/components/header";

$header-container-height: rem-calc(54) !default;
// stylelint-disable-next-line
$header-border-bottom: rem-calc(1) solid rgba(25, 50, 47, 0.08);

.branding {

	> * {
		display: inline-flex;
		vertical-align: middle;
	}

	.logo {
		fill: $body-background-inverted;
		// Safari Fix
		// Danner svg logo viewbox size has half pixel measurements
		// which safari chokes on as the page state changes.
		// This ensures measurements are treated equally
		transform: scale(1);

		@include breakpoint(medium) {
			width: $store-logo-width + rem-calc(50);
		}

		> svg {
			@include logo-styles();
		}
	}

	.aafes {
		@include breakpoint(medium only) {
			margin-left: $grid-margin-gutters;
		}
	}
}

.header-links-container {
	a {
		color: $body-background-inverted;
	}

	[role="context"] {
		color: map_get($danner-palette, forest);
		line-height: rem-calc(24);
	}
}

.header-container {
	border-bottom: rem-calc(1) solid map-get($lfi-palette, snow);
	letter-spacing: normal;

	.header.links {
		order: 3;
	}

	.header.content {
		width: 100%;
	}
}

body:not(.has-banner) .header-container {
	border-bottom: $header-border-bottom;
}

.light-header-fg {
	&[class*="catalog-menu-active"] {
		.header-container {
			background: $white;
			border-bottom: $header-border-bottom;
		}
	}
}
