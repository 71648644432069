//
// Checkout Shipping method styles
// ----------------------------------------
// div.checkout-shipping-method
//  div#checkout-step-shipping_method.step-content
//  form.form.methods-shipping
//   div.checkout-shipping-options-container
//    div.checkout-shipping-option
//    input
//    label
//   div#onepage-checkout-shipping-method-additional-load
//   div.actions-toolbar
//    div.primary
//     button.button.action.continue.primary

.checkout-shipping-method {
	.no-quotes-block {
		font-size: rem-calc(14);
		line-height: rem-calc(21);
		text-align: center;
	}
	.actions-toolbar {
		margin-top: rem-calc(40);
	}
}

.checkout-shipping-option {
	align-items: center;
	cursor: pointer;
	display: flex;
	padding: rem-calc(15 5);

	&:not(:last-of-type) {
		border-bottom: rem-calc(1) solid map_get($lfi-palette, light-gray);
	}

	label {
		display: grid;
		font-weight: $font-weight__regular;
		grid-template-rows: auto auto;
		grid-template-columns: auto auto;
		grid-template-areas: "label price" "delivery price";
		margin: rem-calc(0 0 0 7);
		width: 100%;
	}

	.price {
		font-size: rem-calc(16);
		grid-area: price;
		line-height: rem-calc(23);
		text-align: right;
	}
	.checkout-shipping-option-method {
		@include bold-text;
		font-size: rem-calc(16);
		grid-area: label;
		line-height: rem-calc(23);
	}
	.checkout-shipping-method-delivery {
		grid-area: delivery;
	}
}

// Cart rates
//

.cart-shipping-address-form {
	.field:not([name="shippingAddress.postcode"]) {
		display: none;
	}
}

.cart-shipping-rate-form {
	.checkout-shipping-option {
		padding-left: 0;
		padding-right: 0;
	}
}
