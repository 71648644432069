// /* Configuration `design/head/demonotice` library component
//  * -------------------
//  * div.message.global.demo
//  *  div.content
//  *   p
//  */

body > .message.demo {
	margin: 0;
	padding: rem-calc(8px);
	border-radius: 0;
	text-align: center;
	background-color: map-get($foundation-palette, alert);

	p {
		text-transform: none;
		color: $white;
	}
}
