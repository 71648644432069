.my-cart-link {
	[role="context"] {
		@include element-invisible;
	}
}

#open-top-search [role="context"] {
	@include breakpoint(large) {
		display: inline-block;
	}
}

.header.links,
#top-search {
	[class*="icon-"] + [role="context"] {
		@include media-breakpoint-down(xl) {
			@include visually-hidden;
		}
	}
}
