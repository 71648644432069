// Close button
.action-close {
	@include action-button('../icons/icon-close.svg');
}

.action-back {
	@include action-button('../icons/icon-chevron-left.svg');
}

.bold-text {
	@include bold-text;
}

.underline-text {
	text-decoration: underline;
}

.uppercase-text {
	text-transform: uppercase;
}

.max-width-wrapper {
	margin-left: auto;
	margin-right: auto;
	max-width: $max-page-width;
	width: 100%;
}

.info-message {
	@include info-message();

	font-size: rem-calc(14);

	// Add the padding to the icon
	&:before {
		font-size: 1rem;
		margin-left: 0.25rem;
		margin-top: 0.25rem;
	}

	// Position icon when used with a title
	> strong:before {
		margin-left: 0.25rem;
		margin-right: 0.5rem;
		position: relative;
		top: rem-calc(1);
	}

	> p {
		flex: 1;
		padding: rem-calc(2) 0;
		text-align: left;
	}
}

.full-width-image-container {
	img {
		width: 100%;
	}
}

// TODO - remove
.quick-add-to-cart-list-item {
	@include button-hollow-primary;
	align-items: center;
	display: inline-block;
	justify-content: center;
	min-width: 122px;
	padding: 9px 10px 10px;
	width: auto;

	&.success,
	&.success:hover,
	&.success:focus {
		background: map-get($lfi-palette, light-gray);
		border-color: map-get($lfi-palette, light-gray);
		color: map-get($lfi-palette, stone);
		cursor: not-allowed;
	}

	// Mobile buttons have no text, only Icons
	// except for success state
	.button-text {
		display: inline-block;
	}

	&.success {
		.initial {
			display: none;
		}

		.success {
			display: inline-block;
		}
	}

	// Hide success messaging unless
	// parent class has success class
	// Hide initial state if success
	.success {
		display: none;
	}
}
