// @Use - Designed to style elements which are slicked at larger breakpoints
// and scroll (unslicked) at smaller breakpoints
@mixin slick-block-slider(
	$container-selector,
	$list-selector,
	$list-item-selector
) {
	#{$container-selector} {
		overflow: hidden;
		width: 100%;
	}

	#{$list-selector} {
		display: flex;

		&:not(.slick-initialized) {
			overflow-x: auto;
			white-space: nowrap;
			-webkit-overflow-scrolling: touch;
		}

		&.slick-initialized {
			@include slick-controls(
				$dot-size: rem-calc(18),
				$dot-spacing-width: rem-calc(20)
			);
			flex-flow: row wrap;
		}

		.slick-track {
			display: flex;
		}

		.slick-dots {
			width: 100vw;

			li {
				margin: 0;
			}

			button {
				padding: 0;

				&[aria-selected="true"] {
					&::before {
						opacity: 1;
					}
				}
			}
		}
	}

	#{$list-item-selector} {
		display: flex;
		align-items: flex-end;
	}

	@content;
}
