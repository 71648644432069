// Category Content Types
// ----------------
// .category-content
// .category-content-check-list
// .category-content-with-background
// .category-content-step-list
//

.category-content {
	a {
		&.button {
			margin-bottom: 24px;
		}

		&:not(.button) {
			display: inline-block;
			margin-top: 16px;
		}
	}
}

.category-content-check-list {
	.section {
		border-bottom: 1px solid map-get($lfi-palette, light-gray);

		&.intro,
		&:last-of-type {
			border-bottom: none;
		}
	}

	.icon-circle-check {
		display: flex;
		margin-bottom: 16px;

		&::before {
			flex: 0 0 16px;
			margin-right: 12px;
			margin-top: 4px;
		}

		&:last-of-type {
			margin-bottom: 48px;
		}
	}

	.swatch-list {
		margin-top: 48px;

		img {
			border-radius: 50%;
			margin-right: 24px;
			max-width: 64px;
		}

		> div {
			align-items: center;
			display: flex;
			margin-bottom: 16px;
		}

		p {
			margin: 0;
		}
	}

	.info-image {
		text-align: center;

		img {
			margin-bottom: 8px;
		}

		p {
			@include bold-text;
		}
	}

	.tip-container {
		margin: 24px 0;
	}

	h4 > p {
		margin-bottom: 24px;
	}

	.skip-to-shop {
		margin-bottom: 48px;
	}
}

.category-content,
.category-content-check-list {
	.section {
		margin-bottom: 48px;
	}

	h4 {
		margin-bottom: 24px;
	}
}

.category-content-with-background {
	background: $background-secondary;
	border-radius: 16px;
	margin-bottom: 72px;
	padding: 40px 24px;

	.section-header {
		@include bold-text;
		margin: 0;
	}

	.section:not(:last-of-type) {
		border-bottom: 1px solid map-get($lfi-palette, light-gray);
		margin-bottom: 24px;
		padding-bottom: 24px;
	}

	.timeframe {
		p {
			margin-bottom: 0;
		}

		span:first-of-type {
			margin-right: 8px;
		}
	}

	.care-products {
		margin-bottom: 32px;

		.section-header {
			margin-bottom: 8px;
			margin-top: 24px;
		}

		p {
			align-items: center;
			display: flex;
			gap: 8px;
			margin-bottom: 16px;
		}
	}

	p {
		&::after {
			margin-right: 8px;
		}

		span:first-of-type {
			@include bold-text;
		}
	}

	[class^="icon-"]::before {
		flex: 0 0 24px;
	}
}

.category-content-step-list {
	.step-count {
		@include bold-text;
		margin-bottom: 8px;
	}

	.step-count:nth-of-type(n+2) {
		margin-bottom: 24px;
	}

	.section {
		margin-bottom: 72px;

		&:last-of-type {
			margin-bottom: 64px;
		}
	}

	h4 {
		margin-bottom: 16px;
	}

	img {
		display: block;
		margin: 36px auto 42px;
		max-width: 250px;
	}
}
