.recrafting-reviews-summary {
	display: inline-block;
	margin-bottom: rem-calc(40);

	.review-image {
		.icon-checkmark {
			@include media-breakpoint-up(lg) {
				outline-color: map-get($lfi-palette, snow);
			}
		}
	}
}

.product-reviews-container {
	text-align: left;

	@include media-breakpoint-up(lg) {
		background: map-get($lfi-palette, snow);
	}
}

// Temporary until review count is displayed in stars
.product-review {
	.review-count {
		font-family: $body-font-family !important;
	}
}

.product-reviews-summary,
.product-reviews-container {
	p {
		margin-left: 0;
		margin-right: 0;
		max-width: none;
	}
}
