.card-evo {
	aspect-ratio: var(--card-evo-aspect-ratio, auto);
	background: var(--card-evo-background, #{$white});
	border-radius: 10px;
	display: flex;
	overflow: hidden;
	position: relative;

	&.aspect-2-3 {
		--card-evo-aspect-ratio: 2/3;
	}
}

.card-evo-shadow {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, var(--card-evo-box-shadow-opacity, 10%));

	&.light {
		--card-evo-box-shadow-opacity: 5%;
	}
}

.card-evo-img-bg {
	@extend %img-fills-container;
	position: absolute;
}

.card-evo-zoom-on-hover {
	transition: scale var(--card-evo-hover-transition-time, 0.45s) var(--card-evo-hover-transition-function, ease-in-out);

	@at-root .card-evo:hover & {
		scale: var(--card-evo-hover-end-scale, 1.05);

		@media (prefers-reduced-motion) {
			scale: 1;
		}
	}
}

.card-evo-content {
	--gap: var(--card-evo-content-gap, 16px);

	@extend %card-evo-flex-container;
	align-self: var(--card-evo-content-alignment, end);
	color: var(--card-evo-content-text-color, $white);
	padding: var(--card-evo-content-padding, 32px);
	width: 100%;
	z-index: 1;
}

.card-evo-gradient {
	background: linear-gradient(180deg, var(--card-evo-gradient-start-color, #0000), var(--card-evo-gradient-end-color, #000000f2) 100%);
	background-blend-mode: darken;
	padding-top: var(--card-evo-gradient-top-padding, 104px);
}

.card-evo-product-content {
	--card-evo-content-gap: 52px;
	--card-evo-content-padding: 24px 24px 38px;
	--card-evo-content-text-color: #{map-get($lfi-palette, jet)};
}

.card-evo-product-information {
	--gap: 20px;

	@extend %card-evo-flex-container;
	line-height: 1.25;
}

.card-evo-product-details {
	--gap: 10px;

	@extend %card-evo-flex-container;
}

.card-evo-product-name {
	--gap: 4px;

	@extend %card-evo-flex-container;
}

.card-evo-product-family {
	@include bold-text;
}

.card-evo-product-subtitle {
	color: map-get($lfi-palette, metal);
}

.card-evo-product-price .price-box {
	justify-content: start;
}

.card-evo-headline {
	@include bold-text;
	font-size: var(--card-evo-headline-font-size, 32px);
	line-height: var(--card-evo-headline-line-height, 44px);
}

%card-evo-flex-container {
	display: flex;
	flex-flow: var(--flex-flow, column);
	gap: var(--gap, 8px);
}
