/*
 * Product List Sort Styles
 * ------------------------
 * Sort options can be in the form of a list (mobile)
 * or a dropdown (desktop)
 */

$sort-dropdown-border: 1px solid map-get($lfi-palette, light-gray) !default;

////
// Sort List
////
.sort-list {
	margin-bottom: rem-calc(48);
}

.sort-list-option {
	align-items: center;
	display: flex;
	margin: rem-calc(8 0 16 0);

	// Custom radio buttons for filter sort list
	[type="radio"] {
		background: $white;
		flex: 0 0 rem-calc(24);
		height: rem-calc(24);
		width: rem-calc(24);

		&:checked {
			background: map_get($lfi-palette, jet);

			&:before {
				height: rem-calc(8);
				width: rem-calc(8);
			}
		}

		+ label {
			line-height: rem-calc(1);
			margin-left: rem-calc(12);
			padding-bottom: rem-calc(2);
		}
	}
}

////
// Sort Dropdown
////
.sort-dropdown-toggle {
	// override button styles
	padding-left: rem-calc(24) !important;
	padding-right: rem-calc(24) !important;
	transition: none !important;

	.sort-title-prefix {
		@include bold-text;
	}

	.sort-title-selected {
		font-family: $body-font-family;
	}

	&.show {
		// override inherit button styles
		background: white !important;
		border: $sort-dropdown-border;
		border-bottom: none;
		border-radius: rem-calc(4 4 0 0);

		&:after {
			transform: $accordion-icon-transform;
		}
	}

	// todo - make global
	&:after {
		@include svg-icon('../icons/icon-chevron-down.svg');
		margin-bottom: -2px;
		transition: $accordion-icon-transition;
		vertical-align: unset;
	}
}

.sort-dropdown-options {
	border: $sort-dropdown-border;
	border-radius: rem-calc(0 0 4 4);
	border-top: none;
	padding: 0;
	// manual adjustment because Bootstrap drops items below button
	top: rem-calc(-3) !important;
	width: 100%;

	// Override button styles
	.sort-dropdown-option {
		border-radius: 0;
		font-family: $body-font-family;
		line-height: rem-calc(24);
		padding: rem-calc(7.5 24);
		text-align: left;
		text-transform: none;

		&:hover,
		&:active,
		&:focus {
			background: map-get($lfi-palette, light-gray);
		}
	}

	li {
		margin: 0;
	}
}
