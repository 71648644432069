.assessment-slide {
	padding-bottom: rem-calc(40);
	padding-top: rem-calc(52);

	@include media-breakpoint-up(lg) {
		padding-bottom: 0;
		padding-top: rem-calc(104);
	}

	.recrafting-button-group {
		margin-bottom: rem-calc(32);

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(40);
		}
	}
}

.assessment-start {
	padding-bottom: rem-calc(132);
	padding-top: rem-calc(148);

	@include media-breakpoint-up(lg) {
		padding-bottom: rem-calc(160);
		padding-top: rem-calc(323);
	}

	.recrafting-header {
		margin-bottom: 0;
	}
}

.assessment-soles {
	padding-bottom: rem-calc(20);
	padding-top: rem-calc(148);

	@include media-breakpoint-up(lg) {
		padding-bottom: rem-calc(40);
		padding-top: rem-calc(323);
	}

	.recrafting-header {
		margin-bottom: rem-calc(40);
		max-width: rem-calc(462);
	}

	form {
		margin: 0 auto rem-calc(80) auto;
		max-width: rem-calc(430);
		padding: rem-calc(0 37);

		@include media-breakpoint-up(lg) {
			margin-bottom: rem-calc(180);
		}
	}

	textarea {
		position: absolute;
	}
}

.assessment-info {
	padding-bottom: rem-calc(20);
	padding-top: rem-calc(68);

	@include media-breakpoint-up(lg) {
		padding-bottom: rem-calc(40);
		padding-top: rem-calc(104);
	}

	.assessment-info-image {
		margin: 0 auto;
		padding: rem-calc(0 44 24 44);

		@include media-breakpoint-up(lg) {
			padding-bottom: rem-calc(40);
		}
	}

	img {
		max-width: rem-calc(400);
		width: 100%;
	}

	p {
		margin-bottom: rem-calc(24);
	}
}

.assessment-results {
	padding-bottom: rem-calc(150);
	padding-top: rem-calc(214);

	.recrafting-header {
		margin-bottom: rem-calc(28);
	}

	.wizard-icon {
		animation: recrafting-assessment 2s infinite;

		&::before {
			border: rem-calc(1) solid map-get($lfi-palette, snow); // fix for FF border
			height: rem-calc(48);
			width: rem-calc(75);
		}
	}
}

@keyframes recrafting-assessment {
	from {
		transform: rotate(0deg);
	}

	15% {
		transform: rotate(-15deg);
	}

	45% {
		transform: rotate(15deg);
	}

	to {
		transform: rotate(-360deg);
	}
}
