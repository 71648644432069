// Product Images Component
.catalog-product-view {
	.pswp {
		--pswp-bg: #fff;
		--pswp-icon-color: #000;
		--pswp-icon-color-secondary: #fff;
		--pswp-icon-stroke-width: 0;
	}

	.pswp__dynamic-caption {
		background-color: var(--pswp-bg);
		color: map-get($lfi-palette, metal);
		font-size: rem-calc(14);
		line-height: 1.5rem;
		text-align: center;

		&.pswp__dynamic-caption--below {
			max-width: unset;
			padding-top: 0;
		}
	}

	.media {
		// Slick Overrides
		.slick-prev,
		.slick-next {
			height: 100%;
			width: rem-calc(54);
			z-index: z-index(raised);

			&::before {
				@include svg-icon;
				color: $body-font-color;
				font-size: rem-calc(24);
			}
		}

		.slick-prev,
		.slick-next {
			&::before {
				@include svg-icon;
			}
		}

		.slick-prev {
			&::before {
				mask-image: url('../icons/icon-chevron-left.svg');
			}
		}

		.slick-next {
			&::before {
				mask-image: url('../icons/icon-chevron-right.svg');
			}
		}
	}
}
