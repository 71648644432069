///
// Just a little merchandising help to control colors
// Example output: .danner-color-cherry as a merchandisable class
// to control element color
///
@each $name, $hex in $danner-palette {
	.danner-color-#{$name} {
		color: $hex;
	}
}
