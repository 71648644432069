@import "../../default/styles/_elements.scss";

///
// Theme-Specific Overrides

body {
	height: 100%;
}

strong {
	font-family: $header-font-bold;
}

///
// Custom Elements

.info-color {
	background: $info-color;

	code {
		color: $info-color;
	}
}

.callout {
	background: $body-background-alt;
	border: 0;
	border-radius: $global-radius;
}

.step-title {
	font-family: $header-font-bold;
}

.underline-dotted {
	border-bottom: rem-calc(2) dashed;
	cursor: pointer;
	display: inline-block;
	position: relative;
}
