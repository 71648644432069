.recrafting-form-container {
	@include media-breakpoint-up(lg) {
		align-items: center;
		display: grid;
		grid-column-gap: rem-calc(40);
		grid-template-columns: 50% 50%;
		margin: 0 auto rem-calc(60) auto;
		max-width: $max-page-width;
		padding: rem-calc(0 80);
	}

	@include media-breakpoint-up(xl) {
		grid-column-gap: rem-calc(101);
	}
}

.recrafting-form-actions {
	@include media-breakpoint-up(lg) {
		max-width: rem-calc(463);
	}

	a {
		display: block;
	}
}

.recrafting-button-group {
	margin-bottom: rem-calc(128);
	max-width: rem-calc(321);
	width: 100%;

	@include media-breakpoint-up(lg) {
		margin-bottom: 0;
	}

	.btn {
		@include bold-text;
		background: $white;
		flex: 0 0 calc(100% / 3);
		font-size: rem-calc(16);
		line-height: rem-calc(20);
		margin: 0 !important; // Override some core Bootstrap .btn group styles
		padding-left: rem-calc(5);
		padding-right: rem-calc(5);
		text-transform: none;

		&:not(:first-of-type):not(:last-of-type) {
			border-left: none;
			border-right: none;
		}
	}
}
