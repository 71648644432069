//
// Form Tooltip styles
//

._with-tooltip {
	position: relative;
}

.field-tooltip {
	height: $form-text-input-height;
	top: 0;

	.label {
		display: none;
	}

	// override
	.field-tooltip-content {
		background: $dark-gray;
		border: none;
		border-radius: $global-radius;
		color: $white;
		left: auto;
		right: rem-calc(40);
		top: 0;

		&::before {
			border-right: none;
		}

		&::after {
			border-right-color: $dark-gray;
			left: auto;
			margin: 0;
			right: rem-calc(-20);
			transform: rotate(180deg);
			top: rem-calc(12);
		}
	}
}

.field-tooltip-action {
	padding: rem-calc(12);
}
