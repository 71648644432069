.slug-list {
	//
	// Global Slug List Styles
	max-width: none;

	> .columns {
		@include aspect-ratio();
		border-bottom: rem-calc(3.9) solid $white;
		padding: 0;
	}

	.slug-bg {
		@include aspect-ratio();
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
	}

	.slug-copy {
		display: flex;
		align-items: center;
		flex-flow: column wrap;
		justify-content: center;
		align-content: center;
		padding: rem-calc(60 0);
		height: 100%;
		width: 100%;
	}

	h2 {
		@include scaled-header($min: 36px);
		margin-bottom: rem-calc(6);
		letter-spacing: rem-calc(0.6);
		line-height: 1;

		.alpha-x {
			font-size: 85%;
		}
	}

	p {
		font-size: rem-calc(21);
	}

	.button-set {
		margin-top: rem-calc(36);

		a.button {
			font-size: $global-font-size;
		}
	}

	//
	// Slug-Type Specific

	.slug-full .slug-bg,
	.slug-half .slug-copy-wrap {
		background-color: $body-background-inverted;
		color: $white;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		text-align: center;

		a.button {
			margin-bottom: 0;
		}
	}

	.slug-copy-wrap {
		@include breakpoint(small down) {
			height: auto;
		}

		&.alt {
			background: map-get($lfi-palette, latte);
			color: $body-font-color;
		}

		&:not(.alt) {
			color: map-get($lfi-palette, latte);
		}
	}

	.slug-full {
		h2,
		p {
			text-shadow: rem-calc(0.5 0.5 0) fade-out($body-font-color, 0.81);
		}
	}

	.slug-half {
		@include breakpoint(medium) {
			// Alternate img|content
			&:nth-of-type(odd) {
				.slug-copy {
					order: 1;
				}
				.slug-bg {
					order: 2;
				}
			}
		}
	}
}
