@use "../../globals/colors" as colors;
// TODO - Media Mixins

// Size Guide Fit Styles
// ----------------

.size-fit-container {
	background: colors.$background-secondary;
	margin: 0 -16px;

	@include media-breakpoint-up(md) {
		border-radius: 8px;
		margin: 0;
	}
}

.size-fit-product-name {
	padding: 16px 16px 0;
}

.size-fit-illustrations {
	display: flex;
	gap: 8px;
	justify-content: space-between;
	padding: 18px 9px;

	@include media-breakpoint-up(md) {
		padding: 32px 16px;
	}
}

.size-guide-illustration {
	flex: 1 1 calc(100% / 3);

	&.active {
		.fit-label {
			text-decoration: underline;
		}
	}

	img {
		// ensure image height against inherited styles
		height: auto !important;
		padding: 12px 18px 8px;

		@include media-breakpoint-up(md) {
			padding: 0 44px 7px;
		}
	}
}

.size-guide-illustration-details {
	padding: 0 4px 12px;

	@include media-breakpoint-up(md) {
		padding: 0 8px;
	}

	p {
		margin-bottom: 0;
	}
}
