@import "../../default/styles/components/checkout/address";

.shipping-address-item {
	font-family: $header-font-regular;

	p {
		color: map_get($danner-palette, forest);
	}
}

//
// Custom Login
//

.checkout-login-instructions,
.checkout-forgot-password {
	font-family: $header-font-bold;
}
