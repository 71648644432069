@use "../../globals/colors";
@use "buttons";
@import "../../vendor/bootstrap/custom-bootstrap-variables";
@import "bootstrap/scss/mixins/breakpoints";

%quick-add-list-column-layout {
	.quick-add-item-wrapper {
		display: flex;
		flex-flow: column wrap;
		height: 100%;
	}

	.quick-add-item {
		padding: 16px 16px 20px;
	}

	.quick-add-image {
		background: map-get(colors.$lfi-palette, snow);
		border-radius: 6px;
		margin-bottom: 12px;
		padding: 16px;
		text-align: center;
	}

	.quick-add-actions {
		display: flex;
		grid-column: 1 / 3;
		justify-content: space-between;
		margin: auto 0 16px;
		padding-top: 16px;
	}

	.quick-add-action {
		@include buttons.quick-add-action-icon;
	}

	img {
		max-width: 184px;
	}
}

%quick-add-list-row-layout {
	.quick-add-list-item {
		border-bottom: 1px solid map-get(colors.$lfi-palette, light-gray);
		padding: 30px 0;

		@include media-breakpoint-up(md) {
			padding: 40px 0 14px;
		}

		&:first-of-type {
			// ensure no top padding on first element
			padding-top: 0 !important;
		}

		&:last-of-type {
			border-bottom-style: none;
		}
	}

	.quick-add-item-wrapper {
		column-gap: 8px;
		grid-template-columns: minmax(64px, 88px) auto max-content;

		@include media-breakpoint-up(md) {
			column-gap: 24px;
		}
	}

	.quick-add-item-content {
		text-align: left;

		@include media-breakpoint-down(md) {
			padding-left: 4px;
		}
	}

	.plp-feature-container {
		margin: 0;
	}

	.quick-add-action {
		@include media-breakpoint-down(md) {
			@include buttons.quick-add-action-icon;
		}
	}
}

%quick-add-list-wrapping-layout {
	.quick-add-item-wrapper {
		column-gap: 16px;
		grid-template-columns: minmax(auto, 128px) 1fr;
		grid-template-rows: auto min-content;
		height: 100%;
	}

	.quick-add-image {
		max-width: 128px;

		a {
			background: map-get(colors.$lfi-palette, snow);
			border-radius: 6px;
			display: inline-block;
			padding: 4px;
		}
	}

	.quick-add-item {
		padding: 16px;
		width: 100%;
	}

	.quick-add-item-content {
		min-width: 122px;
	}

	.price-box {
		margin-bottom: 12px;
	}

	.quick-add-actions {
		display: flex;
		grid-column: 1 / 3;
		justify-content: space-between;
		margin: 16px 0;
	}

	.quick-add-action {
		@include buttons.quick-add-action-icon;
	}
}
