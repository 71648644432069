@mixin ft-menu {
	--ft-menu-button-container-height: 15.8vh;
	--ft-menu-button-dim: 48px;
	--ft-menu-height: 86px;
	--ft-menu-width: 100%;

	@include media-breakpoint-up(md) {
		--ft-menu-height: 100vh;
		--ft-menu-width: 40px;
	}

	.factory-tour-menubar-wrapper {
		--ft-menu-wrapper-height: unset;
		--ft-menu-wrapper-position: sticky;

		@extend .avoid-header;

		background: map-get($lfi-palette, snow);
		height: var(--ft-menu-wrapper-height);
		position: var(--ft-menu-wrapper-position);
		z-index: 2;

		@include media-breakpoint-up(md) {
			--ft-menu-wrapper-height: 100%;
			--ft-menu-wrapper-position: absolute;
		}
	}

	.factory-tour-menubar {
		--ft-menu-align-items: end;
		--ft-menu-gap: 0;
		--ft-menu-grid-template-areas: "progress-bar progress-bar progress-bar" "media-controls menu-button .";
		--ft-menu-grid-template-columns: 1fr 1fr 12px;
		--ft-menu-grid-template-rows: auto auto;
		--ft-menu-position: static;

		align-items: var(--ft-menu-align-items);
		background: map-get($lfi-palette, snow);
		display: grid;
		gap: var(--ft-menu-gap);
		grid-template-areas: var(--ft-menu-grid-template-areas);
		grid-template-columns: var(--ft-menu-grid-template-columns);
		// stylelint-disable-next-line -- cannot use grid-template shorthand given how areas are defined
		grid-template-rows: var(--ft-menu-grid-template-rows);
		height: var(--ft-menu-height);
		left: 0;
		position: var(--ft-menu-position);
		top: 0;
		width: var(--ft-menu-width);

		@include media-breakpoint-up(md) {
			--ft-menu-align-items: normal;
			--ft-menu-gap: 4vh;
			--ft-menu-grid-template-areas: "progress-bar" "media-controls" "menu-button";
			--ft-menu-grid-template-columns: 40px;
			--ft-menu-grid-template-rows: 1fr auto var(--ft-menu-button-container-height);
			--ft-menu-position: sticky;
		}
	}

	.media-controls-container {
		grid-area: media-controls;
	}

	.menu-button-container {
		display: flex;
		flex-flow: row-reverse;
		grid-area: menu-button;
	}

	.menu-button,
	button.icon-close {
		--ft-menu-button-margin: #{rem-calc(0 0 -16 0)};

		@include button-appearance(
			$button-color: map-get($lfi-palette, jet),
			$border-radius: 50%,
			$padding: 0
		);

		align-items: center;
		border: rem-calc(4) map-get($lfi-palette, snow) solid;
		display: grid;
		height: var(--ft-menu-button-dim);
		justify-items: center;
		margin: var(--ft-menu-button-margin);
		width: var(--ft-menu-button-dim);

		@include media-breakpoint-up(md) {
			--ft-menu-button-margin: #{rem-calc(0 -16 0 0)};
		}

		.icon-plus {
			line-height: 1;

			&::before {
				vertical-align: bottom;
			}
		}
	}

	.media-controls {
		grid-area: media-controls;
	}

	.progress-bar {
		grid-area: progress-bar;
	}

	#factory-tour-menu {
		--bs-offcanvas-width: calc(640px + var(--ft-menu-button-overhang));
		--ft-menu-button-overhang: 16px;
		--ft-menu-offcanvas-close-margin: 0 var(--ft-menu-button-overhang) 0 0;
		--ft-menu-offcanvas-grid-areas: "header" "body";
		--ft-menu-offcanvas-grid-columns: auto;
		--ft-menu-offcanvas-grid-rows: auto 1fr;

		display: grid;
		grid-template-areas: var(--ft-menu-offcanvas-grid-areas);
		grid-template-columns: var(--ft-menu-offcanvas-grid-columns);
		// stylelint-disable-next-line
		grid-template-rows: var(--ft-menu-offcanvas-grid-rows);
		max-width: calc(100% + var(--ft-menu-button-overhang));

		@include media-breakpoint-up(md) {
			--ft-menu-offcanvas-close-margin: 0 calc(-1 * var(--ft-menu-button-overhang)) 0 0;
			--ft-menu-offcanvas-grid-areas: "body header";
			--ft-menu-offcanvas-grid-rows: auto;
			--ft-menu-offcanvas-grid-columns: 1fr auto;
		}

		&.offcanvas-start {
			left: calc(-1 * var(--ft-menu-button-overhang));
			padding-left: var(--ft-menu-button-overhang);
		}

		.offcanvas-body {
			--top-padding: 0;
			grid-area: body;
			justify-self: center;
			max-width: 480px;
			padding: var(--top-padding) 37px 0;
			width: 100%;

			@include media-breakpoint-up(md) {
				--top-padding: 128px;
			}
		}

		.offcanvas-title {
			text-align: center;
			white-space: nowrap;
		}

		.offcanvas-header {
			--header-height: 24px;
			align-self: end;
			display: grid;
			grid-area: header;
			height: var(--header-height);
			justify-content: end;
			margin: 16px 0;
			padding: 0;

			@include media-breakpoint-up(md) {
				--header-height: var(--ft-menu-button-container-height);
				align-content: start;
				margin: 0;
			}

			button {
				margin: var(--ft-menu-offcanvas-close-margin);
				&.action-close::before {
					font-size: 24px;
				}
			}
		}

		.nav-link {
			--border-bottom-color: transparent;
			--font-size: 20px;
			$border-bottom-height: 2px;
			$nav-link-height: 40px;

			@include bold-text;
			align-content: end;
			color: $base-color;
			display: flex;
			font-size: var(--font-size);

			@include media-breakpoint-up(md) {
				--font-size: 22px;
			}

			&:hover,
			&:active {
				color: $primary-hover;
			}

			&.active {
				span {
					--border-bottom-color: currentColor;
				}
			}

			span {
				border-bottom: $border-bottom-height solid var(--border-bottom-color);
				line-height: $nav-link-height - $border-bottom-height;
				margin-left: rem-calc(25);
				white-space: nowrap;
			}

			i[class^="icon-"] {
				$icon-size: $nav-link-height;
				display: grid;
				line-height: $icon-size;

				&::before {
					height: $icon-size;
					width: $icon-size;
				}
			}
		}

		ul {
			list-style: none;
			margin: 0;
		}

		li {
			align-content: end;
			display: grid;
			height: rem-calc(95);
		}
	}
}
