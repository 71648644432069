@import "../../default/styles/components/widgets/slick-gallery";

.slick-arrow-styles {
	.slick-arrow {
		height: rem-calc(29);
		position: absolute;
		width: rem-calc(17);
		z-index: z-index(header); // (modal?)

		&:before {
			color: $body-font-color;
			font-size: rem-calc(29);
			left: rem-calc(-5);
			position: absolute;
			top: 0;
			transition: $banner-foreground-transition;

			.light-header-fg & {
				color: $body-inverted-font-color;
			}
		}
	}

	.slick-arrow:focus:hover {
		background: transparent;
	}

	.slick-prev {
		left: rem-calc(20);

		&::before {
			@include svg-icon('../icons/icon-chevron-left.svg');
		}
	}

	.slick-next {
		right: rem-calc(20);

		&::before {
			@include svg-icon('../icons/icon-chevron-right.svg');
		}
	}
}

.slick-gallery-widget {
	@extend .slick-arrow-styles;

	.slick-arrow {
		padding-bottom: rem-calc(100);
	}

	h4.subheader {
		color: map_get($danner-palette, forest);
		letter-spacing: rem-calc(0.52);
		line-height: rem-calc(24);
		margin-bottom: rem-calc(30);
	}
}
