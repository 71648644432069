:root {
	--newleaf-slider-card-evo-shadow-space: 24px;
	--newleaf-slider-peek-length: 16px;

	@include media-breakpoint-up(md) {
		--newleaf-slider-peek-length: 24px;
	}

	@include media-breakpoint-up(vlarge) {
		--newleaf-slider-peek-length: 0;
	}
}

.newleaf-slider-card-evo-container {
	margin: 0 var(--newleaf-slider-card-evo-container-horizontal-space, auto);
	max-width: 1920px;
	padding: var(--newleaf-slider-card-evo-container-padding, 40px 0);

	@include media-breakpoint-up(vlarge) {
		padding-left: 40px;
		padding-right: 40px;
	}

	.newleaf-circle-button-wrapper {
		--newleaf-button-wrapper-margin: 0 var(--newleaf-slider-peek-length) 0 0;
	}

	.newleaf-slider-card-evo-title {
		margin: 0;
		padding: 0 0 0 var(--newleaf-slider-peek-length);
	}
}

.newleaf-slider.newleaf-slider-card-evo {
	margin-bottom: calc(-1 * var(--newleaf-slider-card-evo-shadow-space, 0));
	margin-top: calc(-1 * var(--newleaf-slider-card-evo-shadow-space, 0));
	padding-bottom: var(--newleaf-slider-card-evo-shadow-space, 0);
	padding-top: var(--newleaf-slider-card-evo-shadow-space, 0);

	@include media-breakpoint-up(md) {
		--newleaf-slide-snap-align: start;
	}
}
