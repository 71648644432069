// Boot Care Landing Styles

.page-layout-boot-care-landing {
	@extend %basic-category-styles;

	.category-description {
		font-size: 24px;
		line-height: 32px;
		order: 5 !important;
		padding: 80px 16px;
		text-align: center;

		@include media-breakpoint-up(md) {
			font-size: 28px;
		}

		> div {
			margin: 0 auto;
			max-width: 742px;
		}
	}

	.article-list-collapse-container {
		order: 6;
	}

	.icon-heart {
		color: $info-color;
		display: block;
		margin-top: 26px;
	}

	.style-lookup-result-container {
		align-items: center;
		display: flex;
		margin-top: 24px;

		@include media-breakpoint-up(lg) {
			justify-content: center;
		}

		img {
			margin-right: 24px;
			max-width: 64px;
		}
	}

	.article-list-collapse-container,
	.article-list-slider-container {
		background: map-get($lfi-palette, snow);
	}
}
